import React from 'react';
import color_classes from 'src/stylesheets/color_classes.module.scss';
import IconInterface from './IconInterface';

function IconDelete({
    width = 18,
    height = 18,
    fillColor = 'fillBlack',
}: IconInterface) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 18 18"
            fill="none"
        >
            <path
                d="M17.4 2.4C17.7314 2.4 18 2.66863 18 3C18 3.33137 17.7314 3.6 17.4 3.6H16.1515L15.0899 16.349C15.0125 17.282 14.2325 18 13.296 18H4.7048C3.76833 18 2.98827 17.2821 2.91087 16.349L1.848 3.6H0.6C0.28934 3.6 0.0338237 3.3639 0.00309774 3.06135L0 3C0 2.66863 0.268629 2.4 0.6 2.4H5.4V1.8C5.4 0.837955 6.15473 0.0521886 7.1044 0.00249504L7.2 0H10.8C11.7941 0 12.6 0.805887 12.6 1.8V2.4H17.4ZM14.9479 3.6H3.05211L4.10675 16.2496C4.13255 16.5607 4.39258 16.8 4.7048 16.8H13.296C13.6082 16.8 13.8682 16.5607 13.8941 16.2496L14.9479 3.6ZM7.2 6.6C7.53137 6.6 7.8 6.86863 7.8 7.2V13.2C7.8 13.5314 7.53137 13.8 7.2 13.8C6.86863 13.8 6.6 13.5314 6.6 13.2V7.2C6.6 6.86863 6.86863 6.6 7.2 6.6ZM10.8 6.6C11.1314 6.6 11.4 6.86863 11.4 7.2V13.2C11.4 13.5314 11.1314 13.8 10.8 13.8C10.4686 13.8 10.2 13.5314 10.2 13.2V7.2C10.2 6.86863 10.4686 6.6 10.8 6.6ZM10.8 1.2H7.2C6.86863 1.2 6.6 1.46863 6.6 1.8V2.4H11.4V1.8C11.4 1.46863 11.1314 1.2 10.8 1.2Z"
                className={color_classes[fillColor]}
            />
        </svg>
    );
}

export default IconDelete;
