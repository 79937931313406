/* eslint-disable import/prefer-default-export */
export const INDUSTRIES = [
  'Advertising and/or Marketing agency',
  'Agriculture',
  'Automative',
  'Construction/Infrastructure',
  'Consulting',
  'Customer Service',
  'Design/Architecture',
  'Engineering',
  'Financial Services (e.g., banking, investments, insurance)',
  'State/Local Government',
  'Federal Government',
  'Real Estate',
  'Food Service/Restaurant/Catering',
  'Education (K-12)',
  'Higher Education',
  'Healthcare/Medical/Dental',
  'Holistic Health & Wellness',
  'Industrial Maintenance, Repair & Organization',
  'Legal',
  'Leisure & Hospitality (e.g., accommodation, food services, entertainment, recreation)',
  'Manufacturing/Processing',
  'Natural Resources & Mining',
  'Publishing',
  'PR/Communications',
  'Religious/Church',
  'Retail',
  'Sales',
  'Technology',
  'Telecommunications',
  'Transportation/Logistics',
  'Travel',
  'Utilities',
];

export const userInfoAutoRefreshInterval = 1000 * 60; // in milliseconds

export const customDomains = [
  '@gmail.com',
  '@yahoo.com',
  '@hotmail.com',
  '@staples.com',
  '@outlook.com',
];


export const thinHeaderHeight = "70px";
export const storeModeStickyHeight = "66px";
export const footerHeight = "65px";
