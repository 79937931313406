import React, { useEffect, useState } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import FullWidthCard, {
    FullWidthCardProps,
} from 'src/components/cards/full-width-card';
import { Store } from 'src/services/types';
import {
    googleMapURL,
    describeStoreHours,
    storeDetailsUrl,
} from 'src/lib/utils/store-utils';
import MapPreview, { MapOverlay } from 'src/components/map-preview';
import useStoreData from 'src/hooks/useStoreData';
import AppLink from 'src/components/link';
import { formatPhoneNumber } from 'src/lib/utils/string-utils';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import COLORS from 'src/lib/colors';
import {
    CallReceverIcon,
    DirectionIcon,
    MarkerIcon,
    StoreIcon,
} from 'src/assets/icons/StoreDetailsIcons';
import StoreDetailsDrawer from '../store-details-drawer';
import { Body } from '../UI';
import Row from '../Row';

interface ActionIconButtonsProps {
    title: string;
    Icon: React.ReactElement;
}

const useStyles = makeStyles((theme) => ({
    underLineText: {
        textDecorationLine: 'underline',
    },
}));

const hasStoreData = (storeData: Store | undefined) =>
    !!storeData && !!storeData.address;

/** Displays community information on the home screen */
export default function StoreDetailCard() {
    const classes = useStyles();
    const [storeOpenMessage, setStoreOpenMessage] = useState('');
    const [storeOpenUntilMessage, setStoreOpenUntilMessage] = useState('');
    const { customEvent, analyticsCustomEvent } = useEventsReporter();
    const [isOpenStore, setIsOpenStore] = useState(false);
    const [storeDetailsModalOpen, setStoreDetailsModalOpen] = useState(false);
    const { mainStore, additionalStores, updateMainStore } = useStoreData();
    const onStoreSelected = (store: Store) => {
        updateMainStore(store);
        // setSearchModalOpen(false);
    };
    useEffect(() => {
        if (hasStoreData(mainStore)) {
            if (mainStore?.storeHours) {
                const { open, closingTime, nextDayOpenTime } =
                    describeStoreHours(
                        mainStore?.storeHours,
                        mainStore?.timezone
                    );
                setIsOpenStore(open);
                if (open) {
                    // setStoreOpenMessage('Open Now');
                    setStoreOpenUntilMessage(`Open until ${closingTime}`);
                } else if (nextDayOpenTime !== 'Closed') {
                    setStoreOpenUntilMessage(`Open At ${nextDayOpenTime}`);
                }
            } else {
                setStoreOpenMessage('Temporarily Closed');
            }
        }
    }, [mainStore]);
    // if (!storeData && !isLoadingStore) {
    //     return (
    //         <FullWidthCard
    //             cardContainerClassName={classes.container}
    //             noPadding
    //             hasManualShadow
    //             id="community-card"
    //         >
    //             <div className={classes.noLocationContainer}>
    //                 <span className={classes.noLocationIconContainer}>
    //                     <img
    //                         src={require('../../assets/icons/iconNolocationLarge.svg')}
    //                         alt="no location icon"
    //                     />
    //                 </span>

    //                 <Typography
    //                     className={classes.noLocationTitle}
    //                     id="community-card-no-location-title"
    //                 >
    //                     We're sorry but we could not find your location. Please
    //                     update your location to be connected to your local
    //                     community.
    //                 </Typography>
    //             </div>
    //         </FullWidthCard>
    //     );
    // }

    const ActionIconButtons = ({ Icon, title }: ActionIconButtonsProps) => (
        <Row
            direction="column"
            justifyContent={'center'}
            gap="10px"
            style={{
                cursor: 'pointer',
            }}
        >
            <>
                <Row justifyContent={'center'}>{Icon}</Row>
                <Body
                    size={12}
                    lineHeight={16}
                    weight="light"
                    color="textBlack"
                >
                    {title}
                </Body>
            </>
        </Row>
    );

    return (
        <>
            <Box
                sx={{
                    mt: '10px',
                    bgcolor: 'white',
                    borderRadius: '15px',
                }}
            >
                <Box
                    sx={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <img
                        src={require('../../assets/icons/storeMap.png')}
                        alt="map"
                        style={{ width: '100%', height: '50px' }}
                    />
                    <Box
                        sx={{
                            position: 'absolute',
                            width: '-webkit-fill-available',
                            px: '8px',

                            borderRadius: '10px',
                            bgcolor: 'white',
                            height: '30px',
                            mx: '10px',
                            bottom: '22%',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Row
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            width={'100%'}
                        >
                            <>
                                <Row alignItems={'center'} gap="5px">
                                    <>
                                        <Row alignItems={'center'}>
                                            <MarkerIcon />
                                        </Row>
                                        <Body size={14} lineHeight={18}>
                                            {mainStore?.address?.address_1}
                                        </Body>
                                    </>
                                </Row>
                                <Row alignItems={'center'} gap="3px">
                                    <>
                                        <Box
                                            sx={{
                                                borderRadius: '3px',
                                                width: 10,
                                                height: 10,
                                                bgcolor: isOpenStore
                                                    ? COLORS.successGreen
                                                    : COLORS.brandRed,
                                            }}
                                        />
                                        <Body
                                            size={12}
                                            weight="light"
                                            lineHeight={16}
                                        >
                                            {storeOpenUntilMessage}
                                        </Body>
                                    </>
                                </Row>
                            </>
                        </Row>
                    </Box>
                </Box>
                <Box
                    sx={{
                        px: '20px',
                        py: '16px',
                    }}
                >
                    <Row alignItems={'center'} justifyContent={'space-between'}>
                        <>
                            <ActionIconButtons
                                Icon={
                                    <AppLink
                                        href={googleMapURL(mainStore)}
                                        role="button"
                                        aria-label="Map Bring up directions to your Staples store"
                                        target="_blank"
                                        onClick={() => {
                                            analyticsCustomEvent(
                                                eventMetricsMap.stores,
                                                {
                                                    click_text:
                                                        'get directions',
                                                    element_location:
                                                        'your store information',
                                                    store_details: `${mainStore?.address?.city}-${mainStore?.storeNumber}`,
                                                }
                                            );
                                            optimoveEvents(
                                                eventMetricsMap.scm_stores,
                                                {
                                                    click_text:
                                                        'get directions',
                                                    element_location:
                                                        'your store information',
                                                    store_details: `${mainStore?.address?.city}-${mainStore?.storeNumber}`,
                                                }
                                            );
                                        }}
                                    >
                                        <DirectionIcon />
                                    </AppLink>
                                }
                                title="Get directions"
                            />
                            <ActionIconButtons
                                Icon={
                                    <AppLink
                                        aria-label="Call"
                                        role="button"
                                        href={`tel:${mainStore?.phoneNumber}`}
                                        onClick={() => {
                                            analyticsCustomEvent(
                                                eventMetricsMap.stores,
                                                {
                                                    click_text: 'call',
                                                    element_location:
                                                        'your store information',
                                                    store_details: `${mainStore?.address?.city}-${mainStore?.storeNumber}`,
                                                }
                                            );
                                            optimoveEvents(
                                                eventMetricsMap.scm_stores,
                                                {
                                                    click_text: 'call',
                                                    element_location:
                                                        'your store information',
                                                    store_details: `${mainStore?.address?.city}-${mainStore?.storeNumber}`,
                                                }
                                            );
                                        }}
                                    >
                                        <CallReceverIcon />
                                    </AppLink>
                                }
                                title={formatPhoneNumber(
                                    mainStore?.phoneNumber
                                )}
                            />
                            <ActionIconButtons
                                Icon={
                                    <AppLink
                                        id="main-store-card-store-details-link"
                                        href="#"
                                        // href={storeDetailsUrl(storeData, customEvent)}
                                        target="_blank"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setStoreDetailsModalOpen(true);
                                            analyticsCustomEvent(
                                                eventMetricsMap.stores,
                                                {
                                                    click_text: 'store details',
                                                    element_location:
                                                        'your store information',
                                                    store_details: `${mainStore?.address?.city}-${mainStore?.storeNumber}`,
                                                }
                                            );
                                            optimoveEvents(
                                                eventMetricsMap.scm_stores,
                                                {
                                                    click_text: 'store details',
                                                    element_location:
                                                        'your store information',
                                                    store_details: `${mainStore?.address?.city}-${mainStore?.storeNumber}`,
                                                }
                                            );
                                        }}
                                    >
                                        <StoreIcon />
                                    </AppLink>
                                }
                                title="Store details"
                            />
                        </>
                    </Row>
                </Box>
            </Box>
            {storeDetailsModalOpen ? (
                <StoreDetailsDrawer
                    mainStore={mainStore}
                    nearbyStores={additionalStores}
                    onStoreSelected={onStoreSelected}
                    open={storeDetailsModalOpen}
                    ignoreIsActiveScreen
                    handleClose={() => setStoreDetailsModalOpen(false)}
                />
            ) : null}
        </>
    );
}
