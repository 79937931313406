/* eslint-disable max-len */
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'src/hooks';
import COLORS from 'src/lib/colors';
import { useShipmentTracking } from 'src/lib/contexts/ShipmentTrackingContext';
import { stringCapitalize } from '../../../lib/utils/string-utils';
import ShippingServicesAccordianDetails from './shipping-services-accordian-details';

const useStyles = makeStyles((theme) => ({
    accordionContainer: {
        margin: '0px 0px 5px !important',
        boxShadow: 'none',
        background: 'none',
        // height: '115px',
        '& .MuiAccordionSummary-root': {
            padding: '24px',
            background: '#FFFFFF',
        },
        '&.Mui-expanded': {
            borderBottom: `1px solid ${COLORS.whiteSmoke}`,
        },
        '&:last-child': {
            '&.Mui-expanded': {
                borderBottom: `0px solid ${COLORS.whiteSmoke}`,
            },
        },
    },
    accordionDetails: {
        padding: '0px',
    },
    shippingName: {
        fontSize: '22px',
        fontWeight: 700,
        lineHeight: '26px',
        letterSpacing: '-0.47px',
        color: '#101820',
    },
    shippingAddress: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0',
        color: '#1E1E1E',
    },
    shippingDate: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
        letterSpacing: '0px',
        color: '#858585',
        marginTop: '5px',
    },
    shippingData: {
        '& .MuiAccordionSummary-content': {
            display: 'flex',
            flexDirection: 'column',
            margin: 0,
        },
    },
    deliveredStatus: {
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '20px',
        letterSpacing: '0px',
        color: '#000000',
    },
    shippedStatus: {
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '20px',
        letterSpacing: '0px',
        color: '#000000',
    },
    deliveredStatusName: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0px',
        color: '#000000',
    },
}));

const ShippingServicesAccordion = ({
    key,
    data,
    updatedData,
    activeTab,
}: any) => {
    const classes = useStyles();
    const { selectedShipmentService } = useShipmentTracking();
    console.log(
        'selectedShipmentService',
        data,
        updatedData,
        activeTab,
        selectedShipmentService
    );

    // const [expandedKey, setExpadedKey] = useState(selectedShipmentService?.shipmentdetailid);
    const selectedShipmentId = selectedShipmentService
        ? [selectedShipmentService.shipmentdetailid]
        : [];
    const [expandedPanels, setExpadedPanels] =
        useState<number[]>(selectedShipmentId);
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const [shipmentStatus, setShipmentStatus] = useState<any>('Varied');
    const { query } = useRouter();
    useEffect(() => {
        const allPackagesStatusCodes =
            data?.shimpmentstatus?.shipmenttrackingstatus.map(
                (status: any) => status.package[0].activity.status.statusCode
            );
        const allPackagesHaveSameStatusCode = allPackagesStatusCodes.every(
            (statusCode: any) => statusCode === allPackagesStatusCodes[0]
        );

        if (allPackagesHaveSameStatusCode) {
            switch (allPackagesStatusCodes[0]) {
                case '003':
                    setShipmentStatus('Shipped');
                    break;
                case '021':
                    setShipmentStatus('Out for Delivery');
                    break;
                default:
                    setShipmentStatus('In Transit');
            }
        }
    }, [data]);
    const handleExpandCollapse = (
        shipmentDetailsId: number,
        isExpanded: boolean
    ) => {
        if (isExpanded) {
            setExpadedPanels([...expandedPanels, shipmentDetailsId]);
        } else {
            const filterExpandedPanels = expandedPanels.filter(
                (id) => id !== shipmentDetailsId
            );
            setExpadedPanels(filterExpandedPanels);
        }
    };
    console.log(
        'expandedPanels',
        expandedPanels,
        expandedPanels.includes(data.shipmentdetailid)
    );
    return (
        <Accordion
            key={key}
            ref={scrollRef}
            onChange={(event, isExpanded: boolean) =>
                handleExpandCollapse(data.shipmentdetailid, isExpanded)
            }
            expanded={expandedPanels.includes(data.shipmentdetailid)}
            className={classes.accordionContainer}
            aria-label={`shipping_data_${key}`}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.shippingData}
            >
                {data?.shipmenttype === 'APPSHIPMENT' ? (
                    <>
                        <Typography className={classes.shippingName}>
                            {data?.recipientdetails?.fullname}
                        </Typography>
                        <Typography className={classes.shippingAddress}>
                            {data?.recipientdetails?.street1}
                        </Typography>
                    </>
                ) : (
                    <>
                        {data?.shimpmentstatus?.shipmenttrackingstatus?.map(
                            (packageItem: any, index: any) => (
                                <>
                                    <Typography
                                        className={classes.shippingName}
                                    >
                                        {stringCapitalize(
                                            data?.recipientdetails?.city
                                        )}{' '}
                                        ,&nbsp;
                                        {stringCapitalize(
                                            data?.recipientdetails?.state
                                        )}
                                    </Typography>
                                    <Typography
                                        className={classes.shippingAddress}
                                    >
                                        Scanned package
                                    </Typography>
                                </>
                            )
                        )}
                    </>
                )}

                {activeTab === 'create' ? (
                    <Typography className={classes.shippingDate}>
                        {data?.shipmentcreateddate
                            ? `as of ${moment(data?.shipmentcreateddate).format(
                                  'MMM DD, YYYY'
                              )}`
                            : null}
                    </Typography>
                ) : null}
                {activeTab === 'shipped' ? (
                    <Typography className={classes.shippingDate}>
                        <span className={classes.shippedStatus}>Status</span>
                        &nbsp;&nbsp;
                        <span className={classes.deliveredStatusName}>
                            {shipmentStatus}
                        </span>
                        &nbsp;&nbsp;
                        {data?.date}
                    </Typography>
                ) : null}
                {activeTab === 'delivered' ? (
                    <Typography className={classes.shippingDate}>
                        <span className={classes.deliveredStatus}>
                            Delivered
                        </span>
                        &nbsp;&nbsp;{' '}
                        {data?.shimpmentstatus
                            ? `on ${moment(
                                  data?.shimpmentstatus
                                      ?.shipmenttrackingstatus[0]?.package[0]
                                      ?.activity?.date
                              ).format('MMM DD, YYYY')}`
                            : null}
                    </Typography>
                ) : null}
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
                <ShippingServicesAccordianDetails
                    shippingData={data}
                    activeTab={activeTab}
                    updatedData={updatedData}
                />
            </AccordionDetails>
        </Accordion>
    );
};

export default ShippingServicesAccordion;
