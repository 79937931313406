/* eslint-disable import/prefer-default-export */
/* eslint import/namespace: [2, { allowComputed: false }] */
/* eslint-disable  import/no-cycle */

import axios, { AxiosInstance, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { SessionManager } from './sessionManager';
import { getSubscriptionKey } from './sign-on';
import axiosInstance from './custom-axios';
import { akamaiTelemetryHeader } from '../utils/app-utils';
import { getHeaderDataForCart } from './adobe';
import { User } from './types';

const cartInstance: AxiosInstance = axios.create({
  headers: {
    'content-type': 'application/json',
    'Cache-Control': 'no-cache',
  },
});

export const getHeaderData = async () => {

  const headerData = await getHeaderDataForCart()
  return headerData;
}

export const getCartDetails = async (isGuestModeOn = false): Promise<any | null> => {
  let subscriptionKey = "";
  if (isGuestModeOn) {
    subscriptionKey = await getSubscriptionKey('StaplesGuestToken');
  } else {
    subscriptionKey = await getSubscriptionKey('StaplesCart');
  }
  const headerData: any = await getHeaderData();
  try {
    const results: any = await axios.get(
      `${process.env.REACT_APP_EASY_API_URL}/v1/cc/api/checkout`,
      {
        headers: {
          client_id: subscriptionKey || '',
          Authorization: `Bearer ${SessionManager.access_token}`,
          "x-ctx-token": headerData?.["x-ctx-token"],
          "x-device-id": headerData?.["x-device-id"],
          ...akamaiTelemetryHeader,
          'content-type': 'application/json',
          'Cache-Control': 'no-cache',
        },
      }
    );
    Cookies.set('ctx-id', results?.data?.cartInfo?.ctxId);
    return results;
  } catch (e) {
    return null;
  }
};

export const mergeCartDetails = async (): Promise<any | null> => {
  const subscriptionKey = await getSubscriptionKey('StaplesCart');
  try {
    const results: any = await axios.put(
      `${process.env.REACT_APP_EASY_API_URL}/v1/cc/api/checkout/default`,
      {
        "mergeCart": true,
        "guestCtxId": Cookies.get('ctx-id'),
      },
      {
        headers: {
          client_id: subscriptionKey || '',
          Authorization: `Bearer ${SessionManager.access_token}`,
          ...akamaiTelemetryHeader,
          'content-type': 'application/json',
          'Cache-Control': 'no-cache',
        },
      }
    );

    return results;
  } catch (e) {
    return null;
  }
};

export async function addToCartProduct(data: any, isGuestModeOn = false): Promise<any | null> {
  let subscriptionKey = "";
  if (isGuestModeOn) {
    subscriptionKey = await getSubscriptionKey('StaplesGuestToken');
  } else {
    subscriptionKey = await getSubscriptionKey('StaplesCart');
  }
  const headerData: any = await getHeaderData();
  try {
    const results = await axios.post(
      `${process.env.REACT_APP_EASY_API_URL}/v1/cc/api/checkout/default/item`,
      { ...data }, {
      headers: {
        client_id: subscriptionKey || '',
        "x-ctx-token": headerData?.["x-ctx-token"],
        "x-device-id": headerData?.["x-device-id"],
        Authorization: `Bearer ${SessionManager.access_token}`,
        'content-type': 'application/json',
        'Cache-Control': 'no-cache',
      },
    }
    );
    Cookies.set('ctx-id', results?.data?.cartInfo?.ctxId);
    const cartCountDetails = await getCartDetails(isGuestModeOn);
    return { status: true, data: results, cartCountDetails };
  } catch (e) {
    return { status: false, data: [] };
  }
}


export async function navigateToCheckoutCheck(isGuestModeOn = false): Promise<any | null> {
  let subscriptionKey = "";
  if (isGuestModeOn) {
    subscriptionKey = await getSubscriptionKey('StaplesGuestToken');
  } else {
    subscriptionKey = await getSubscriptionKey('StaplesCart');
  }
  const headerData: any = await getHeaderData();
  try {
    const results = await axios.put(
      `${process.env.REACT_APP_EASY_API_URL}/v1/cc/api/checkout/default?buyNow=true&mmx=true&reset=true&ux2=true&memItem=true`,
      { proceedCheckout: "true" }, {
      headers: {
        client_id: subscriptionKey || '',
        "x-ctx-token": headerData?.["x-ctx-token"],
        "x-device-id": headerData?.["x-device-id"],
        Authorization: `Bearer ${SessionManager.access_token}`,
        'content-type': 'application/json',
        'Cache-Control': 'no-cache',
      },
    }
    );
    Cookies.set('ctx-id', results?.data?.cartInfo?.ctxId);
    return { status: true, data: results };
  } catch (e) {
    return { status: false, data: [] };
  }
}


export async function getDCNNumber(accessToken: string): Promise<any | null> {
  try {
    const results: any = await axiosInstance.get(
      `${process.env.REACT_APP_DCN_API_URL}/${accessToken}`
    );
    return results?.data?.directCustomerNumber ?? null;
  } catch (e) {
    return null;
  }
}