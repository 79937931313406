/* eslint-disable max-len */
import React from 'react';
import IconInterface from './IconInterface';

function IconInkDrops({ height = 28, width = 28 }: IconInterface) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.4686 0C9.3877 0 12.2234 3.03968 13.909 5.20453C15.4686 3.62336 16.8434 2.5864 17.4559 2.5864C18.7194 2.5864 23.1399 7.78812 23.1847 7.84028C25.3806 10.4718 28 14.2629 28 17.3816C28 23.2368 23.2701 28 17.4559 28C12.6315 28 8.5638 24.7164 7.3157 20.2542C3.1668 19.6839 0 16.0951 0 11.8119C0 7.44552 6.9902 0 8.4686 0ZM17.4188 4.43756C15.75 5.56405 9.7237 11.7766 8.7724 16.2579C8.7717 16.2607 8.7731 16.2628 8.7731 16.2657C8.7717 16.2727 8.7682 16.2776 8.7668 16.284C8.6884 16.6632 8.6408 17.0312 8.6408 17.3816C8.6408 22.2766 12.5958 26.2588 17.4559 26.2588C22.3167 26.2588 26.271 22.2766 26.271 17.3816C26.271 12.9419 19.1331 5.729 17.4188 4.43756ZM11.2987 18.4417C11.7509 18.283 12.2416 18.5206 12.4005 18.9739C13.153 21.1303 15.1851 22.5782 17.4559 22.5782C18.3757 22.5782 19.2836 22.3399 20.0802 21.8881C20.4967 21.6533 21.0231 21.8007 21.2576 22.2194C21.4921 22.6381 21.3444 23.1689 20.9286 23.4044C19.873 24.0029 18.6725 24.3187 17.4559 24.3187C14.4529 24.3187 11.7656 22.4027 10.7695 19.5526C10.612 19.0986 10.8486 18.6017 11.2987 18.4417ZM8.4336 1.87513C6.8964 3.01289 1.7276 8.46344 1.7276 11.8119C1.7276 15.0172 3.9557 17.7312 6.965 18.4228C6.9321 18.0802 6.9125 17.7333 6.9125 17.3816C6.9125 17.1962 6.9244 17.0051 6.944 16.8106C5.5013 16.3467 4.3253 15.2527 3.7779 13.7913C3.6099 13.3416 3.8353 12.8397 4.2819 12.6698C4.7285 12.4992 5.2262 12.7276 5.3956 13.1766C5.7344 14.0825 6.4414 14.7719 7.3157 15.1011C8.218 12.2623 10.5021 8.98016 12.7106 6.48822C11.109 4.35297 9.2106 2.50322 8.4336 1.87513Z"
                fill="#231F20"
            />
        </svg>
    );
}

export default IconInkDrops;
