import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import IconUpload from 'src/components/Icons/IconUpload';
import Row from 'src/components/Row';
import { Body, Heading } from 'src/components/UI';
import COLORS from 'src/lib/colors';
import IconPdf from 'src/components/Icons/IconPdf';
import IconDocs from 'src/components/Icons/IconDocs';
import { useYourFiles } from 'src/lib/contexts/YourFilesContext';
import CheckCirleRed from '../../../assets/images/CheckWithCircleRed.png';
import pdfIcon from '../../../assets/fileTypeIcon/pdf.svg';
import docIcon from '../../../assets/fileTypeIcon/doc.svg';
import htmlIcon from '../../../assets/fileTypeIcon/html.svg';
import pptIcon from '../../../assets/fileTypeIcon/ppt.svg';
import pubIcon from '../../../assets/fileTypeIcon/pub.svg';
import txtIcon from '../../../assets/fileTypeIcon/txt.svg';
import xlsIcon from '../../../assets/fileTypeIcon/xls.svg';
import { fileType } from './getFileIcon';

const useStyles = makeStyles((theme) => ({
    cancelButton: {
        cursor: 'pointer',
    },
    photoGroup: {
        display: 'flex',
        alignItems: 'center',
    },
    photoBox: {
        position: 'relative',
        transition: '0.2s',
        '&:not(:first-child)': {
            marginLeft: '-25px',
        },
    },
    file: {
        width: 50,
        height: 50,
        borderRadius: '4px',
        display: 'block',
        objectFit: 'cover',
        border: '2px solid #FFFFF',
    },
}));

interface IProps {
    loading: boolean;
    progress: number;
    files: File[];
    timeLeft: string;
    onCancel: () => void;
    headerText: string;
}

function UploadModal({
    loading,
    progress,
    files,
    timeLeft,
    headerText,
    onCancel,
}: IProps) {
    const classes = useStyles();
    const { currentFileIndex, fileErrorMsg } = useYourFiles();

    function renderProgressBar(progressNumber: number) {
        const percentage = ((progressNumber / files.length) * 100).toFixed(2);
        return (
            <Box
                sx={{
                    width: '100%',
                    height: '6px',
                    borderRadius: '20px',
                    bgcolor: COLORS.whiteSmoke,
                    my: '5px',
                    // overflow: 'hidden',
                    position: 'relative',
                }}
            >
                <Box
                    sx={{
                        width: `${percentage}%`,
                        height: '100%',
                        borderRadius: '20px',
                        bgcolor: COLORS.brandRed,
                    }}
                />
                {/* {progressNumber === 100 && (
                    <Box
                        sx={{
                            position: 'absolute',
                            width: 20,
                            height: 20,
                            right: -3,
                            bottom: -6,
                        }}
                    >
                        <img src={CheckCirleRed} alt="" />
                    </Box>
                )} */}
            </Box>
        );
    }

    function renderProgressAndText() {
        return (
            <div>
                {renderProgressBar(currentFileIndex)}
                {currentFileIndex === files.length ? (
                    <Body size={12} color="textBlack">
                        File has been uploaded successfully
                    </Body>
                ) : (
                    <Row gap="7px" sx={{ alignItems: 'center' }}>
                        <>
                            <Body size={12} weight="bold" color="textBlack">
                                {currentFileIndex} of {files.length}
                            </Body>
                            <Body
                                size={12}
                                weight="light"
                                color="textMediumGray"
                            >
                                Uploading...
                            </Body>
                            <Box
                                className="textMediumGray"
                                sx={{
                                    width: `3px`,
                                    height: '3px',
                                    borderRadius: '20px',
                                }}
                            />
                            <Body
                                size={12}
                                weight="light"
                                color="textMediumGray"
                            >
                                {`${timeLeft} left`}
                            </Body>
                        </>
                    </Row>
                )}
            </div>
        );
    }

    function renderSkeleton(length: number) {
        return Array.from(new Array(length)).map((_) => (
            <Skeleton variant="rect" width={75} height={75} />
        ));
    }
    function FileCard({ child }: { child: JSX.Element }) {
        return <Box className={classes.photoBox}>{child}</Box>;
    }

    function getFileIcon(file: File) {
        const type = fileType(file.name);
        console.log('type', type);
        switch (type) {
            case 'pdf':
                return (
                    <img
                        src={pdfIcon}
                        alt="file"
                        style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '4px',
                        }}
                    />
                );
            case 'doc':
                return (
                    <img
                        src={docIcon}
                        alt="file"
                        style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '4px',
                        }}
                    />
                );
            case 'xls':
                return (
                    <img
                        src={xlsIcon}
                        alt="file"
                        style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '4px',
                        }}
                    />
                );
            case 'ppt':
                return (
                    <img
                        src={pptIcon}
                        alt="file"
                        style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '4px',
                        }}
                    />
                );
            case 'txt':
                return (
                    <img
                        src={txtIcon}
                        alt="file"
                        style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '4px',
                        }}
                    />
                );
            case 'pub':
                return (
                    <img
                        src={pubIcon}
                        alt="file"
                        style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '4px',
                        }}
                    />
                );
            case 'html':
                return (
                    <img
                        src={htmlIcon}
                        alt="file"
                        style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '4px',
                        }}
                    />
                );
            default:
                return (
                    <img
                        src={URL.createObjectURL(file)}
                        alt="file"
                        style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '4px',
                        }}
                    />
                );
        }
    }

    function renderFileComponent() {
        if (loading) {
            return files.length > 4
                ? renderSkeleton(4)
                : renderSkeleton(files.length);
        }

        if (files.length > 3) {
            return (
                <>
                    {files.slice(0, 3).map((file) => (
                        <FileCard
                            key={file.size}
                            child={
                                <>
                                    <Box className={classes.file}>
                                        {getFileIcon(file)}
                                    </Box>
                                </>
                            }
                        />
                    ))}
                    <Box
                        sx={{
                            width: 30,
                            height: 30,
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            bgcolor: COLORS.linkText,
                            marginLeft: '5px',
                        }}
                    >
                        <Body color="textWhite">{` + ${
                            files.length - 3
                        } `}</Body>
                    </Box>
                </>
            );
        }

        return files.map((file) => (
            <FileCard
                key={file.size}
                child={<Box className={classes.file}>{getFileIcon(file)}</Box>}
            />
        ));
    }

    return (
        <>
            <Row
                direction="column"
                gap="10px"
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {loading ? (
                    <>
                        <IconUpload />
                        <Heading type="h5" weight="bold" size={20}>
                            {`Uploading ${headerText}`}
                        </Heading>
                    </>
                ) : (
                    <>
                        <CheckCircle
                            style={{ color: COLORS.brandRed, fontSize: 30 }}
                        />
                        <Heading type="h5" weight="bold" size={20}>
                            {`Uploaded ${headerText}`}
                        </Heading>
                    </>
                )}
            </Row>

            <Row direction="column" gap="10px">
                <>
                    <Row
                        className={classes.photoGroup}
                        gap="5px"
                        sx={{
                            py: '5px',
                        }}
                    >
                        <>{renderFileComponent()}</>
                    </Row>
                    {renderProgressAndText()}
                    {fileErrorMsg.length > 0 && (
                        <Body size={12} weight="light" color="textRed">
                            {fileErrorMsg}
                        </Body>
                    )}
                </>
            </Row>
            <Row
                sx={{
                    pt: '10px',
                    justifyContent: 'center',
                }}
            >
                <>
                    {loading && (
                        <Box onClick={onCancel}>
                            <Heading
                                type="h5"
                                weight="normal"
                                size={14}
                                color="textLinkBlue"
                                className={classes.cancelButton}
                            >
                                Cancel
                            </Heading>
                        </Box>
                    )}
                </>
            </Row>
        </>
    );
}

export default UploadModal;
