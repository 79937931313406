/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ButtonBase, makeStyles, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { phoneNumberValidationShipping } from 'src/lib/validations';
import ROUTES from 'src/lib/routes';
import USStates from 'src/constants/us-states';
import {
    useUserInfo,
    useRouter,
    eventMetricsMap,
    useEventsReporter,
} from 'src/hooks';
import { validateZipCode } from 'src/lib/utils/user-utils';
import { usePlacesWidget } from 'react-google-autocomplete';
import { getEstimatedLatLong } from 'src/lib/api-client/google/geolocation';
import {
    getLastShipmentListDetails,
    getShipmentValidation,
} from 'src/lib/api-client/shipment-services';
import LoadingOverlay from 'src/components/loading-overlay';
import ConfirmationDialog from 'src/components/shipping-services/creating-shipment/confirmation-dialog';
import ShippingErrorDialog from 'src/components/shipping-services/shipping-errors/shipping-error-dialog';
import ShippingInfoContext, {
    ShippingFields,
} from 'src/lib/contexts/shipping-info-context';
import clsx from 'clsx';
import { Body, Button, SwipeableDrawer } from 'src/components/UI';
import { customDomains } from 'src/lib/constants';
import AutoCompleteAddress from '../components/AutoCompleteAddress';

const useStyles = makeStyles(() => ({
    headerTitle: {
        fontSize: '30px',
        fontWeight: 700,
        lineHeight: '34px',
        letterSpacing: '-1px',
    },
    createShippingSenderContainer: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        padding: '24px',
    },
    createShippingSenderFieldContainer: {
        display: 'flex',
        gap: 15,
        marginBottom: '16px',
    },
    inputFieldActive: {
        fontFamily: 'StaplesNormsRegular',
        width: '100%',
        padding: '10px',
        fontSize: '16px',
        borderRadius: '10px !important',
        background: '#F2F2F2 !important',
        height: '50px',
        outline: 'none',
        boxShadow: 'none !important',
        fontWeight: 400,
    },
    inputFieldAlign: {
        fontFamily: 'StaplesNormsRegular',
        width: '100%',
        padding: '10px',
        fontSize: '16px',
        borderRadius: '10px !important',
        background: '#ffffff !important',
        height: '50px',
        outline: 'none',
        boxShadow: 'none !important',
    },
    stateSelectInput: {
        fontFamily: 'StaplesNormsRegular',
        width: '100%',
        padding: '10px',
        fontSize: '16px',
        borderRadius: '10px !important',
        background: '#ffffff !important',
        height: '50px',
        outline: 'none',
        boxShadow: 'none !important',
        fontWeight: 400,
    },
    addressDialogContainer: {
        padding: '0 30px 30px 30px',
    },
    uploadBtn: {
        height: '30px',
        width: '108px',
        borderRadius: '14.5px',
        background: '#FFFFFF',
        '& span': {
            fontSize: '12px',
            fontWeight: 700,
            lineHeight: '12px',
            letterSpacing: '0px',
            textAlign: 'center',
        },
    },
    saveBtn: {
        background: '#CC0000',
        height: '40px',
        borderRadius: '20px',
        marginTop: '14px',
        fontVariantLigatures: 'none',
        '& span': {
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '17px',
            letterSpacing: '0px',
            textAlign: 'center',
            color: '#ffffff',
        },
    },
    saveAddressBtn: {
        width: '100%',
        background: '#CC0000',
        height: '40px',
        borderRadius: '20px',
        marginTop: '14px',
        fontVariantLigatures: 'none',
        '& span': {
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '17px',
            letterSpacing: '0px',
            textAlign: 'center',
            color: '#ffffff',
        },
        '&:disabled': {
            background: '#D3D3D3',
        },
    },
    emailAutoComplete: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#ffffff',
        zIndex: 99,
        listStyleType: 'none',
        padding: '10px 0 0',
        margin: '-8px 0 0',
        border: '1px solid #00000033',
        borderRadius: '0 0px 10px 10px',
    },
    emailItem: {
        listStyle: 'none',
        padding: '4px 10px',
        justifyContent: 'flex-start',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '28px',
        color: '#000000',
        '&:hover': {
            backgroundColor: 'lightgrey',
        },
    },
    iconInfo: {},
    logoIconConatiner: {
        display: 'inline-flex',
        gap: 12,
        alignItems: 'center',
        margin: '6px 0px 30px',
    },
    labelAlign: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '18px',
        textAlign: 'left',
        marginBottom: '10px',
        display: 'flex',
        color: '#343434',
    },
    labelAlignRequired: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '18px',
        textAlign: 'left',
        marginBottom: '10px',
        display: 'flex',
        color: '#343434',
        '&::after': {
            content: '" *"',
            color: '#CC0000',
            whiteSpace: 'pre',
        },
    },
    errorMsgAlign: {
        color: '#cc0000',
        display: 'inline-block',
    },
    fieldContainer: {
        marginBottom: '20px',
        flexGrow: 1,
    },
    formContainer: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
        '& .MuiFormLabel-root': {
            color: '#444444 !important',
        },
    },
    menuPaper: {
        maxHeight: 300,
    },
    formControl: {
        minWidth: '50%',
    },
    errorAlign: {
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '-0.20px',
        textAlign: 'center',
        color: '#c0392b',
    },
    hidden: {
        display: 'none',
    },
}));
const validationSchema = yup.object({
    firstName: yup
        .string()
        .required('Full name or company is a required field.')
        .matches(
            /^ *[a-zA-Z]+( [a-zA-Z]+)* *$/,
            'Full name or company is invalid.'
        ),
    phoneNumber: phoneNumberValidationShipping.required(
        'Phone number is a required field.'
    ),
    address: yup.string().required('Street address is a required field.'),
    city: yup.string().required('City is a required field.'),
    zipCode: yup
        .string()
        .required('Zip code is a required field.')
        .matches(/^(?:\d{5}|\d{5}-\d{4})$/, 'Zip code is invalid'),
    state: yup.string().required('State is a required field.'),
    email: yup
        .string()
        .required('Email is required field')
        .matches(
            /^ *[\w-_.]+@([\w-]+\.)+[\w-]{2,4} *$/g,
            'Email is not valid.'
        ),
});

function formatPhoneNumber(phoneNumber: string): string {
    const input = phoneNumber.replace(/\D/g, ''); // Remove all non-digit characters
    let formattedNumber = '';
    // Format the phone number as (123) 134-2627
    if (input.length > 0) {
        formattedNumber += `(${input.substring(0, 3)}`;
    }
    if (input.length > 3) {
        formattedNumber += `) ${input.substring(3, 6)}`;
    }
    if (input.length > 6) {
        formattedNumber += `-${input.substring(6, 10)}`;
    }

    return formattedNumber; // Return the original input if it doesn't match the expected pattern
}

interface senderProps {
    setSenderInfo: (data: any) => void;
    senderInfo: any;
}

const CreateShippingSenderInformation = ({
    setSenderInfo,
    senderInfo,
}: senderProps) => {
    console.log('senderInfo', senderInfo);
    const classes = useStyles();
    const { history } = useRouter();
    const { analyticsCustomEvent } = useEventsReporter();
    const [queryText, setQuery] = useState('');
    const [showListEmail, setShowListEmail] = useState(false);
    const [activeField, setActiveField] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState<undefined | string>(undefined);
    const [errorTitle, setErrorTitle] = useState<undefined | string>(undefined);
    const [isConfirmationRequired, setIsConfirmationRequired] = useState(false);
    const [responseData, setResponseData] = useState({});
    const [isAddressDrawerOpen, setIsAddressDrawerOpen] = useState(false);
    const [showAddressSuggestions, setShowAddressSuggestions] = useState(false);
    const addressRef = useRef<HTMLInputElement>(null);

    const [showAdditionalAddressField, setShowAdditionalAddressField] =
        useState(false);
    const {
        sender,
        inReviewStage,
        updateSender,
        fetchSenderInfo,
        updateFetchSenderInfo,
    } = useContext(ShippingInfoContext);
    const { userInfo } = useUserInfo();
    const handleDialogClose = () => {
        setIsConfirmationRequired(false);
        setError(false);
        setErrorMsg(undefined);
        setErrorTitle(undefined);
    };
    const fields = [
        'firstName',
        'email',
        'phoneNumber',
        'companyName',
        'address',
        'city',
        'state',
        'zipCode',
        'aptNumb',
    ];
    const scrollToTop = () => {
        setTimeout(() => {
            window.scrollTo({
                top: -114,
                behavior: 'smooth',
            });
        }, 1000);
    };
    const getLastShipmentData = async () => {
        setIsLoading(true);
        try {
            const response = await getLastShipmentListDetails();
            if (
                !Object.keys(response).includes('error') &&
                Object.keys(response)?.length
            ) {
                if (response?.shipmenttype !== 'TRACKING') {
                    updateSender(response.shipperdetails);

                    // history.push({
                    //     pathname: ROUTES.createShippingInformation,
                    //     search: '?step=CreateShippingRecipientInformation',
                    // });
                }
                setIsLoading(false);
            } else if (Object.keys(response).includes('error')) {
                setIsLoading(false);
            }
        } catch (err) {
            console.log('err', err);
        }
    };
    useEffect(() => {
        if (fetchSenderInfo) {
            getLastShipmentData();
            updateFetchSenderInfo(false);
        }
    }, []);
    useEffect(() => {
        if (sender.fullname) {
            const data = {
                firstName: sender.fullname.split(' ')[0],
                lastName: sender.fullname.split(' ')[1],
                companyName: sender.companyname,
                address: sender.street1,
                aptNumb: sender.street2,
                phoneNumber: sender.phonenumber,
                city: sender.city,
                state: sender.state,
                zipCode: sender.postalcode,
                email: sender.email,
            };
            setSenderInfo(data);
            // updateSender({ ...ShippingFields });
        }
    }, [sender]);
    const sendData = (data: any, isUseModifyAddress = false) => {
        setSenderInfo(data);
        if (inReviewStage) {
            history.push({
                pathname: ROUTES.createShippingInformation,
                search: '?step=CreateShippingReviewInformation',
            });
        } else {
            history.push({
                pathname: ROUTES.createShippingInformation,
                search: '?step=CreateShippingRecipientInformation',
            });
        }
    };
    const handleNavigation = async (currentValues: any) => {
        setError(false);
        // setIsLoading(true)
        const bodyData = {
            fullName: `${currentValues?.firstName} ${currentValues?.lastName}`,
            companyName: currentValues?.companyName,
            street1: currentValues?.address ? currentValues?.address : '',
            street2: currentValues?.aptNumb,
            city: currentValues?.city,
            state: currentValues?.state,
            postalcode: currentValues?.zipCode,
            phoneNumber: currentValues?.phoneNumber,
            countrycode: 1,
            country: 'US',
            email: currentValues?.email,
        };
        setIsLoading(true);
        const responseDataValues = await getShipmentValidation(bodyData);
        analyticsCustomEvent(eventMetricsMap.shipping_info, {
            click_text: 'confirm',
            element_location: 'sender information',
        });
        setIsLoading(false);

        if (responseDataValues?.result === 'Success') {
            setIsConfirmationRequired(false);
            sendData(currentValues);
        } else if (
            responseDataValues?.result === 'Verify' &&
            responseDataValues?.modifiedAddress
        ) {
            setIsConfirmationRequired(true);
            setResponseData({
                ...responseDataValues,
                oldAddress: currentValues,
            });
        } else if (responseDataValues?.result === 'Warning') {
            setErrorMsg(responseDataValues?.messages[0]);
            setError(true);
        } else if (responseDataValues?.result === 'Failure') {
            setErrorTitle('Invalid Address');
            setErrorMsg(
                'The address provided is invalid and could not be used. Please check again.'
            );
            setError(true);
        } else {
            setError(true);
            setErrorMsg(
                'We couldn’t validate the provided address at this time. Please try again later.'
            );
        }
    };

    /*
     * Listening to browser back button
     * */
    window.onpopstate = () => {
        if (isConfirmationRequired) {
            history.push({
                pathname: ROUTES.createShippingInformation,
                search: '?step=createShipmentSenderInfo',
            });
            setSenderInfo((responseData as any)?.oldAddress);
        }
    };

    const getInitialValue = () => ({
        firstName: senderInfo?.firstName
            ? senderInfo?.firstName
            : sender?.fullname
            ? sender.fullname.split(' ')[0]
            : userInfo?.firstName,
        lastName: senderInfo?.lastName
            ? senderInfo?.lastName
            : sender?.fullname
            ? sender.fullname.split(' ')[1]
            : userInfo?.firstName,
        companyName: senderInfo?.companyName
            ? senderInfo?.companyName
            : sender?.companyname
            ? sender.companyname
            : '',
        address: senderInfo?.address
            ? senderInfo?.address
            : sender?.street1
            ? sender.street1
            : '',
        aptNumb: senderInfo?.aptNumb
            ? senderInfo?.aptNumb
            : sender?.street2
            ? sender.street2
            : '',
        phoneNumber: senderInfo?.phoneNumber
            ? formatPhoneNumber(senderInfo?.phoneNumber)
            : sender?.phonenumber
            ? formatPhoneNumber(sender.phonenumber)
            : '',
        city: senderInfo?.city
            ? senderInfo?.city
            : sender?.city
            ? sender.city
            : '',
        state: senderInfo?.state
            ? senderInfo?.state
            : sender?.state
            ? sender.state
            : '',
        zipCode: senderInfo?.zipCode
            ? senderInfo?.zipCode
            : sender?.postalcode
            ? sender.postalcode
            : '',
        email: senderInfo?.email
            ? senderInfo?.email
            : sender?.email
            ? sender.email
            : userInfo?.email,
    });

    const {
        values,
        handleSubmit,
        handleChange,
        errors,
        touched,
        setFieldError,
        setFieldValue,
        setFieldTouched,
    } = useFormik({
        initialValues: {
            firstName: `${getInitialValue().firstName} ${
                getInitialValue().lastName
            }`,
            email: getInitialValue().email,
            phoneNumber: getInitialValue().phoneNumber,
            companyName: getInitialValue().companyName,
            address: getInitialValue().address,
            city: getInitialValue().city,
            state: getInitialValue().state,
            zipCode: getInitialValue().zipCode,
            aptNumb: getInitialValue().aptNumb,
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: (currentValues) => {
            const phoneNumber = (currentValues?.phoneNumber || '').replace(
                /[^0-9]/gi,
                ''
            );
            handleNavigation(currentValues);
        },
    });

    const handleSuggetion = (val: any, email = false) => {
        if (val?.includes('@')) {
            if (email) {
                setShowListEmail(true);
            }
            const index = val.indexOf('@');
            const domainName = val.substring(index);
            setQuery(domainName);
        } else {
            setShowListEmail(false);
        }
    };

    const getEmailData =
        customDomains.filter((email) =>
            email.toLowerCase().includes(queryText.toLowerCase())
        ) || [];

    const addressError = useMemo(
        () =>
            (Boolean(errors.zipCode) && touched.zipCode) ||
            (Boolean(errors.state) && touched.state) ||
            (Boolean(errors.city) && touched.city) ||
            (Boolean(errors.address) && touched.address),
        [
            errors.zipCode,
            errors.state,
            errors.city,
            errors.address,
            touched.zipCode,
            touched.state,
            touched.city,
            touched.address,
        ]
    );

    const getAddressString = (addressString?: string) =>
        addressString ? `${addressString}, ` : '';

    const addressValue = useMemo(
        () =>
            values.address ||
            values.aptNumb ||
            values.city ||
            values.state ||
            values.zipCode
                ? `${getAddressString(values.address)}${getAddressString(
                      values.aptNumb
                  )}${getAddressString(values.city)}${getAddressString(
                      values.state
                  )}${values.zipCode || ''}`
                : '',
        [
            addressError,
            values.address,
            values.aptNumb,
            values.city,
            values.state,
            values.zipCode,
        ]
    );
    console.log('addressValue', values.address);
    const emailList = (
        <ul className={getEmailData?.length ? classes.emailAutoComplete : ''}>
            {getEmailData?.map((email) => (
                // eslint-disable-next-line
                <li
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                    role="link"
                    className={classes.emailItem}
                    onClick={(e) => {
                        e.preventDefault();
                        setShowListEmail(false);
                        const emailFieldVal = values.email || '';
                        const index = emailFieldVal.indexOf('@');
                        const clearedEmail = emailFieldVal.substring(0, index);
                        // eslint-disable-next-line
                        handleChange({
                            target: {
                                value: clearedEmail + email,
                                name: 'email',
                            },
                        });
                    }}
                >
                    {values.email?.substring(0, values.email.indexOf('@'))}
                    {email}
                </li>
            ))}
        </ul>
    );

    // const { ref: googlePlacesRef } = usePlacesWidget({
    //     apiKey: process.env.REACT_APP_PUBLIC_GOOGLE_API_KEY,
    //     onPlaceSelected: (place) => {
    //         const ADDRESS = {
    //             street_address: ['street_number', 'route'],
    //             postal_code: 'postal_code',
    //             city: 'locality',
    //             sublocality: 'sublocality',
    //             state: 'administrative_area_level_1',
    //         };
    //         const addressData = place?.address_components || [];
    //         const zipCode = (
    //             addressData.find((data: any) =>
    //                 data.types.includes(ADDRESS.postal_code)
    //             ) || {}
    //         ).short_name;
    //         const city = (
    //             addressData.find((data: any) =>
    //                 data.types.includes(ADDRESS.city)
    //             ) || {}
    //         ).long_name;
    //         const sublocality = (
    //             addressData.find((data) =>
    //                 data.types.includes(ADDRESS.sublocality)
    //             ) || {}
    //         ).long_name;

    //         const state = (
    //             addressData.find((data: any) =>
    //                 data.types.includes(ADDRESS.state)
    //             ) || {}
    //         ).short_name;
    //         const streetAddress = (
    //             addressData.find((data: any) =>
    //                 data.types.includes(ADDRESS.street_address[1])
    //             ) || {}
    //         ).long_name;
    //         const route = (
    //             addressData.find((data: any) =>
    //                 data.types.includes(ADDRESS.street_address[0])
    //             ) || {}
    //         ).long_name;

    //         const fullStreetAddress = route
    //             ? `${route} ${streetAddress}`
    //             : streetAddress;

    //         handleChange({ target: { value: state ?? '', name: 'state' } });
    //         setFieldValue('city', city ?? sublocality ?? '');
    //         setFieldValue('address', fullStreetAddress);
    //         setFieldValue('zipCode', zipCode ?? '');
    //     },
    //     options: {
    //         componentRestrictions: { country: ['us'] },
    //         fields: ['address_components', 'geometry'],
    //         types: ['address'],
    //     },
    // });
    const handleStateChange = (e: any) => {
        handleChange({
            target: { value: e.target.value, name: e.target.name },
        });
        // setFieldValue("state", state);
    };
    const changeLabel = (e: any) => {
        if (e.type === 'focus') {
            const fieldName = e?.target?.name;
            setFieldTouched(fieldName);
            setActiveField(fieldName);
        } else {
            setActiveField('');
        }
    };
    const handleZipChange = (e: any) => {
        const zipStatus = validateZipCode(e);
        if (zipStatus) {
            if (e.target.value.length === 7 && !e.target.value.includes('-')) {
                // eslint-disable-next-line
                e.target.value = `${e.target.value.slice(
                    0,
                    5
                )}-${e.target.value.slice(5)}`;
                handleChange({
                    target: { value: e.target.value, name: e.target.name },
                });
            } else {
                handleChange({
                    target: { value: e.target.value, name: e.target.name },
                });
            }
        } else {
            e.preventDefault();
        }
    };

    useEffect(() => {
        getEstimatedLatLong().then((res) => {
            console.log({ res });
        });
    }, []);

    useEffect(() => {
        const clearForm = localStorage.getItem('clearForm');
        if (clearForm === 'true') {
            fields.map((field) => setFieldValue(field, ''));
        }
    }, []);

    useEffect(() => {
        if (isAddressDrawerOpen && !values.address) {
            setTimeout(() => {
                addressRef.current?.focus();
            }, 1);
        }
    }, [isAddressDrawerOpen]);

    return (
        <>
            <div
                className={clsx(
                    classes.createShippingSenderContainer,
                    isLoading && classes.hidden
                )}
            >
                <Typography className={classes.errorAlign}>{error}</Typography>
                <form onSubmit={handleSubmit} className={classes.formContainer}>
                    <div>
                        <div
                            className={
                                classes.createShippingSenderFieldContainer
                            }
                        >
                            <div className={classes.fieldContainer}>
                                <label
                                    htmlFor="firstName"
                                    className={classes.labelAlignRequired}
                                >
                                    Full name or company
                                </label>
                                <input
                                    type="text"
                                    id="create-shipping-sender-first-name-input"
                                    name="firstName"
                                    value={values.firstName}
                                    onChange={(e) => {
                                        console.log(
                                            '273 hello',
                                            e.target.value
                                        );
                                        handleChange(e);
                                    }}
                                    maxLength={35}
                                    onBlur={(e: any) => changeLabel(e)}
                                    onFocus={(e: any) => changeLabel(e)}
                                    style={{
                                        border:
                                            touched.firstName &&
                                            Boolean(errors.firstName)
                                                ? '1px solid red'
                                                : '1px solid #ccc',
                                        boxShadow:
                                            touched.firstName &&
                                            Boolean(errors.firstName)
                                                ? '0px 0px 0px 4px #CC00001A'
                                                : 'none',
                                    }}
                                    className={
                                        activeField === 'firstName'
                                            ? classes.inputFieldActive
                                            : classes.inputFieldAlign
                                    }
                                    required
                                />

                                {touched.firstName &&
                                Boolean(errors.firstName) ? (
                                    <Body
                                        className={classes.errorMsgAlign}
                                        margin="5px 0 0 0"
                                        size={12}
                                        lineHeight={16}
                                        weight="light"
                                    >
                                        {errors.firstName}
                                    </Body>
                                ) : null}
                            </div>
                        </div>
                        <div
                            className={
                                classes.createShippingSenderFieldContainer
                            }
                        >
                            <div className={classes.fieldContainer}>
                                <label
                                    htmlFor="address"
                                    className={classes.labelAlignRequired}
                                >
                                    Address
                                </label>
                                <input
                                    type="text"
                                    id="create-shipping-sender-first-name-input"
                                    name="address"
                                    value={addressValue}
                                    readOnly
                                    onClick={() => setIsAddressDrawerOpen(true)}
                                    style={{
                                        textOverflow: 'ellipsis',
                                        border: addressError
                                            ? '1px solid red'
                                            : '1px solid #ccc',
                                        boxShadow: addressError
                                            ? '0px 0px 0px 4px #CC00001A'
                                            : 'none',
                                    }}
                                    className={classes.inputFieldAlign}
                                />

                                {addressError ? (
                                    <Body
                                        className={classes.errorMsgAlign}
                                        margin="5px 0 0 0"
                                        size={12}
                                        lineHeight={16}
                                        weight="light"
                                    >
                                        Address has missing fields
                                    </Body>
                                ) : null}
                            </div>
                        </div>
                        <div
                            className={
                                classes.createShippingSenderFieldContainer
                            }
                        >
                            <div className={classes.fieldContainer}>
                                <label
                                    htmlFor="phoneNumber"
                                    className={classes.labelAlignRequired}
                                >
                                    Phone number
                                </label>
                                <input
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    inputMode="numeric"
                                    aria-required="true"
                                    aria-label={
                                        touched.phoneNumber
                                            ? errors.phoneNumber
                                                ? 'Please enter a valid phone number.'
                                                : 'Phone number is a required field.'
                                            : 'Phone number'
                                    }
                                    onChange={(e) => {
                                        const new_e = { ...e };
                                        new_e.target.value = formatPhoneNumber(
                                            e.target.value
                                        );
                                        handleChange(new_e);
                                    }}
                                    type="tel"
                                    value={values.phoneNumber}
                                    onBlur={(e: any) => changeLabel(e)}
                                    onFocus={(e: any) => changeLabel(e)}
                                    style={{
                                        border:
                                            touched.phoneNumber &&
                                            Boolean(errors.phoneNumber)
                                                ? '1px solid red'
                                                : '1px solid #ccc',
                                        boxShadow:
                                            activeField === 'phoneNumber' &&
                                            Boolean(errors.phoneNumber)
                                                ? '0px 0px 0px 4px #CC00001A'
                                                : 'none',
                                    }}
                                    className={
                                        activeField === 'phoneNumber'
                                            ? classes.inputFieldActive
                                            : classes.inputFieldAlign
                                    }
                                />
                                {touched.phoneNumber &&
                                    Boolean(errors.phoneNumber) && (
                                        <Body
                                            className={classes.errorMsgAlign}
                                            margin="5px 0 0 0"
                                            size={12}
                                            lineHeight={16}
                                            weight="light"
                                        >
                                            {errors.phoneNumber}
                                        </Body>
                                    )}
                            </div>
                        </div>
                        <div
                            className={
                                classes.createShippingSenderFieldContainer
                            }
                        >
                            <div className={classes.fieldContainer}>
                                <label
                                    htmlFor="email"
                                    className={classes.labelAlignRequired}
                                >
                                    Email
                                </label>
                                <input
                                    type="email"
                                    aria-label="Email"
                                    id="email"
                                    name="email"
                                    autoComplete="username"
                                    aria-required="true"
                                    value={values.email}
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleSuggetion(e.target.value, true);
                                    }}
                                    onBlur={(e: any) => changeLabel(e)}
                                    onFocus={(e: any) => changeLabel(e)}
                                    style={{
                                        border:
                                            touched.email &&
                                            Boolean(errors.email)
                                                ? '1px solid red'
                                                : '1px solid #ccc',
                                        boxShadow:
                                            touched.email &&
                                            Boolean(errors.email)
                                                ? '0px 0px 0px 4px #CC00001A'
                                                : 'none',
                                    }}
                                    className={
                                        activeField === 'email'
                                            ? classes.inputFieldActive
                                            : classes.inputFieldAlign
                                    }
                                    required
                                />
                                {showListEmail && emailList}
                                {touched.email && Boolean(errors.email) && (
                                    <Body
                                        className={classes.errorMsgAlign}
                                        margin="5px 0 0 0"
                                        size={12}
                                        lineHeight={16}
                                        weight="light"
                                    >
                                        {errors.email}
                                    </Body>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* <div className={classes.logoIconConatiner}>
                    <ButtonBase className={classes.uploadBtn}><span>Upload a Logo</span></ButtonBase>
                    <img src={require('../../assets/icons/inforIcon.svg')}
                     alt="info icon" className={classes.iconInfo} />
                </div> */}
                    <ButtonBase className={classes.saveBtn} type="submit">
                        <span>Confirm</span>
                    </ButtonBase>
                </form>

                {isConfirmationRequired && (
                    <ConfirmationDialog
                        open={isConfirmationRequired}
                        handleClose={handleDialogClose}
                        data={responseData}
                        sendData={sendData}
                    />
                )}
                {error && (
                    <ShippingErrorDialog
                        title={errorTitle}
                        errorDescription={errorMsg}
                        open={error}
                        handleClose={handleDialogClose}
                    />
                )}
            </div>

            <SwipeableDrawer
                open={isAddressDrawerOpen}
                onOpen={() => setIsAddressDrawerOpen(true)}
                onClose={() => setIsAddressDrawerOpen(false)}
                anchor="bottom"
            >
                <div className={classes.addressDialogContainer}>
                    <Body
                        size={20}
                        lineHeight={26}
                        weight="bold"
                        margin="0 0 1rem 0"
                    >
                        {showAdditionalAddressField
                            ? 'Editing your address fields'
                            : 'Your address'}
                    </Body>
                    <div className={classes.fieldContainer}>
                        <label
                            htmlFor="address"
                            className={classes.labelAlignRequired}
                        >
                            Street address
                        </label>
                        <input
                            ref={addressRef}
                            type="text"
                            id="create-shipping-sender-street-address-input"
                            name="address"
                            value={values.address}
                            onChange={(e: any) => {
                                handleChange(e);
                                setShowAddressSuggestions(true);
                            }}
                            onBlur={(e: any) => changeLabel(e)}
                            onFocus={(e: any) => changeLabel(e)}
                            style={{
                                border:
                                    touched.address && Boolean(errors.address)
                                        ? '1px solid red'
                                        : '1px solid #ccc',
                                boxShadow:
                                    touched.address && Boolean(errors.address)
                                        ? '0px 0px 0px 4px #CC00001A'
                                        : 'none',
                            }}
                            className={
                                activeField === 'address'
                                    ? classes.inputFieldActive
                                    : classes.inputFieldAlign
                            }
                            required
                        />

                        {touched.address && Boolean(errors.address) ? (
                            <Body
                                className={classes.errorMsgAlign}
                                margin="5px 0 0 0"
                                size={12}
                                lineHeight={16}
                                weight="light"
                            >
                                {errors.address}
                            </Body>
                        ) : null}
                        <AutoCompleteAddress
                            input={values.address}
                            showSuggestions={showAddressSuggestions}
                            setShowSuggestions={setShowAddressSuggestions}
                            handleOnPlaceSelected={(placeDetails) => {
                                const ADDRESS = {
                                    street_address: ['street_number', 'route'],
                                    postal_code: 'postal_code',
                                    city: 'locality',
                                    sublocality: 'sublocality',
                                    state: 'administrative_area_level_1',
                                };
                                const addressData =
                                    placeDetails?.address_components || [];
                                const zipCode = (
                                    addressData.find((data: any) =>
                                        data.types.includes(ADDRESS.postal_code)
                                    ) || {}
                                ).short_name;
                                const city = (
                                    addressData.find((data: any) =>
                                        data.types.includes(ADDRESS.city)
                                    ) || {}
                                ).long_name;
                                const sublocality = (
                                    addressData.find((data) =>
                                        data.types.includes(ADDRESS.sublocality)
                                    ) || {}
                                ).long_name;

                                const state = (
                                    addressData.find((data: any) =>
                                        data.types.includes(ADDRESS.state)
                                    ) || {}
                                ).short_name;
                                const streetAddress = (
                                    addressData.find((data: any) =>
                                        data.types.includes(
                                            ADDRESS.street_address[1]
                                        )
                                    ) || {}
                                ).long_name;
                                const route = (
                                    addressData.find((data: any) =>
                                        data.types.includes(
                                            ADDRESS.street_address[0]
                                        )
                                    ) || {}
                                ).long_name;

                                const fullStreetAddress = route
                                    ? `${route} ${streetAddress}`
                                    : streetAddress;

                                handleChange({
                                    target: {
                                        value: state ?? '',
                                        name: 'state',
                                    },
                                });
                                setFieldValue(
                                    'city',
                                    city ?? sublocality ?? ''
                                );
                                setFieldValue('address', fullStreetAddress);
                                setFieldValue('zipCode', zipCode ?? '');
                            }}
                        />
                        {!showAdditionalAddressField && (
                            <Button
                                onClick={() =>
                                    setShowAdditionalAddressField(true)
                                }
                                variant="text"
                                color="textLinkBlue"
                                fontSize={14}
                            >
                                Add fields for unit number and more
                            </Button>
                        )}
                    </div>

                    {showAdditionalAddressField && (
                        <div className={classes.fieldContainer}>
                            <label
                                htmlFor="aptNumb"
                                className={classes.labelAlign}
                            >
                                Additional address field
                            </label>
                            <input
                                type="text"
                                id="create-shipping-sender-aptNumb-input"
                                name="aptNumb"
                                value={values.aptNumb}
                                onChange={handleChange}
                                onBlur={(e: any) => changeLabel(e)}
                                onFocus={(e: any) => changeLabel(e)}
                                style={{
                                    border: '1px solid #ccc',
                                    boxShadow: 'none',
                                }}
                                className={
                                    activeField === 'aptNumb'
                                        ? classes.inputFieldActive
                                        : classes.inputFieldAlign
                                }
                            />
                        </div>
                    )}
                    <div className={classes.fieldContainer}>
                        <label
                            htmlFor="city"
                            className={classes.labelAlignRequired}
                        >
                            City
                        </label>
                        <input
                            type="text"
                            id="create-shipping-sender-city-input"
                            name="city"
                            value={values.city}
                            onChange={handleChange}
                            onBlur={(e: any) => changeLabel(e)}
                            onFocus={(e: any) => changeLabel(e)}
                            style={{
                                border:
                                    touched.city && Boolean(errors.city)
                                        ? '1px solid red'
                                        : '1px solid #ccc',
                                boxShadow:
                                    touched.city && Boolean(errors.city)
                                        ? '0px 0px 0px 4px #CC00001A'
                                        : 'none',
                            }}
                            className={
                                activeField === 'city'
                                    ? classes.inputFieldActive
                                    : classes.inputFieldAlign
                            }
                        />

                        {touched.city && Boolean(errors.city) ? (
                            <Body
                                className={classes.errorMsgAlign}
                                margin="5px 0 0 0"
                                size={12}
                                lineHeight={16}
                                weight="light"
                            >
                                {errors.city}
                            </Body>
                        ) : null}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginBottom: '16px',
                        }}
                    >
                        <label
                            htmlFor="state"
                            className={classes.labelAlignRequired}
                        >
                            State
                        </label>
                        <select
                            className={classes.stateSelectInput}
                            name="state"
                            id="create-shipping-sender-state-input"
                            onChange={handleStateChange}
                            onBlur={(e: any) => changeLabel(e)}
                            onFocus={(e: any) => changeLabel(e)}
                            value={values.state}
                            style={{
                                color: values.state ? '#000' : '#ccc',
                                border:
                                    touched.zipCode && Boolean(errors.zipCode)
                                        ? '1px solid red'
                                        : '1px solid #ccc',
                                boxShadow:
                                    touched.zipCode && Boolean(errors.zipCode)
                                        ? '0px 0px 0px 4px #CC00001A'
                                        : 'none',
                            }}
                        >
                            <option value="">Select a state</option>
                            {USStates.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.value}
                                </option>
                            ))}
                        </select>
                        {touched.state && Boolean(errors.state) ? (
                            <Body
                                className={classes.errorMsgAlign}
                                margin="5px 0 0 0"
                                size={12}
                                lineHeight={16}
                                weight="light"
                            >
                                State is a required field.
                            </Body>
                        ) : null}
                    </div>
                    <div className={classes.fieldContainer}>
                        <label htmlFor="zipCode" className={classes.labelAlign}>
                            Zip code *
                        </label>
                        <input
                            type="text"
                            id="create-shipping-sender-zipCode-input"
                            name="zipCode"
                            value={values.zipCode}
                            onChange={handleZipChange}
                            onBlur={(e: any) => changeLabel(e)}
                            onFocus={(e: any) => changeLabel(e)}
                            style={{
                                border:
                                    touched.zipCode && Boolean(errors.zipCode)
                                        ? '1px solid red'
                                        : '1px solid #ccc',
                                boxShadow:
                                    touched.zipCode && Boolean(errors.zipCode)
                                        ? '0px 0px 0px 4px #CC00001A'
                                        : 'none',
                            }}
                            className={
                                activeField === 'name'
                                    ? classes.inputFieldActive
                                    : classes.inputFieldAlign
                            }
                        />

                        {touched.zipCode && Boolean(errors.zipCode) ? (
                            <Body
                                className={classes.errorMsgAlign}
                                margin="5px 0 0 0"
                                size={12}
                                lineHeight={16}
                                weight="light"
                            >
                                {errors.zipCode}
                            </Body>
                        ) : null}
                    </div>
                    <ButtonBase
                        className={classes.saveAddressBtn}
                        type="button"
                        disabled={!!addressError}
                        onClick={() => setIsAddressDrawerOpen(false)}
                    >
                        <span>Confirm</span>
                    </ButtonBase>
                </div>
            </SwipeableDrawer>
            <LoadingOverlay
                variant="wordmark"
                theme="transparent-light"
                open={isLoading}
            />
        </>
    );
};

export default CreateShippingSenderInformation;
