import React, { ChangeEvent, useEffect, useState } from 'react';
import {
    Dialog,
    Typography,
    makeStyles,
    InputBase,
    ButtonBase,
} from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import COLORS from 'src/lib/colors';
import { MapPreview } from 'src/components/map-preview';
import TransitionUp from 'src/components/store-search-modal/transition-up';
import SearchResults from 'src/components/store-search-modal/search-results';
import NearbyResults from 'src/components/store-search-modal/nearby-results';
import useStoreSearch from 'src/hooks/useStoreSearch';
import clsx from 'clsx';
import NoStoreResults from 'src/components/store-search-modal/no-store-result';
import { useIsMobileWidth } from 'src/hooks/useWidth';
import { Store } from 'src/services/types';
import { useIsActiveScreen } from 'src/lib/contexts/screen-order-context';
import { SCREEN_NAMES } from 'src/controllers/config';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useEventsReporter from 'src/hooks/useEventsReporter';
import useRouter from 'src/hooks/useRouter';
import ROUTES from 'src/lib/routes';
import { Body } from '../UI';
import Button from '../UI/Button';
import { useGeolocation } from '../../hooks';

const useStyles = makeStyles({
    dialogEdge: {
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        width: '100%',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxHeight: '100%',
        height: '100%',
        overflow: 'hidden',
        flexShrink: 1,
        marginTop: '1rem',
    },
    titleAndClose: {
        width: '100%',
    },
    titleText: {
        marginTop: '.5rem',
        // fontWeight: 'bold',
        // fontSize: '1.25rem',
        letterSpacing: '-0.34pt',
        textAlign: 'center',
        width: '100%',
    },
    closeButton: {
        position: 'absolute',
        right: '.25rem',
        top: '.25rem',
        width: '2.25rem',
        height: '2.25rem',
        '& img': {
            width: '1.5rem',
            height: '1.5rem',
        },
        zIndex: 100,
    },
    searchInputFormWrapper: {
        display: 'flex',
        width: '100%',
        flexShrink: 1,
        flexDirection: 'column',
        padding: '0 1rem',
        marginBottom: '1rem',
    },
    searchInputForm: {
        width: '100%',
        display: 'contents',
    },
    searchInput: {
        width: '100%',
        margin: '1rem 0',
        padding: '0 0.25rem 0 0.75rem',
        borderRadius: '1.5rem',
        border: `2px solid ${COLORS.secondaryNeutralBlack}`,
    },
    searchInputActive: {
        borderColor: COLORS.brandRed,
    },
    searchIconBtn: {
        width: '2.15rem',
        height: '1.65rem',
        padding: '0 .25rem',
        marginLeft: '-.5rem',
        marginRight: '.25rem',
    },
    searchIcon: {
        width: '1.65rem',
        height: '1.65rem',
    },
    locationButton: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        marginRight: '10px',
    },
});

export default function StoreSearchModal({
    isLoading = false,
    open,
    itemNumber,
    withInventory,
    title = 'Change location',
    selectStoreCtaText = 'Set as my store',
    handleClose,
    mainStore,
    nearbyStores,
    onStoreSelected,
    ignoreIsActiveScreen,
    includeMap = true,
    reportEvents = {
        open: eventMetricsMap.change_location_tapped,
        search: eventMetricsMap.search_complete,
        selected: eventMetricsMap.store_set,
        close: eventMetricsMap.change_location_close,
    },
}: any) {
    const classes = useStyles();
    const [isSearching, setIsSearching] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [submittedSearchString, setSubmittedSearchString] = useState('');
    const { customEvent, analyticsCustomEvent } = useEventsReporter();
    const { history, pathname } = useRouter();
    const [locationStores, setLocationStores] = useState<Store[]>([]);

    const {
        isFetching,
        placeholderStoresData,
        stores: storeResults,
        clearStores,
    } = useStoreSearch({
        partialAddress: submittedSearchString,
        enabled: submittedSearchString.length !== 0,
        withInventory,
        itemNumber,
    });

    function onUpdateSearch(
        event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) {
        setSearchString(event.target.value);
    }

    useEffect(() => {
        setIsSearching(
            storeResults.length === 0 ||
            isFetching ||
            submittedSearchString !== ''
        );
    }, [storeResults.length, isFetching, submittedSearchString]);

    useEffect(() => {
        if (isSearching && !isFetching) {
            if (reportEvents?.search) {
                customEvent(reportEvents.search);
            }
        }
    }, [isSearching, isFetching]);

    function clear() {
        setSearchString('');
        setIsSearching(false);
        setSubmittedSearchString('');
        setLocationStores([]);
        clearStores();
    }

    function handleSubmit(e: any) {
        console.log("🚀 ~ handleSubmit ~ handleSubmit:")
        e.preventDefault();
        if (searchString !== submittedSearchString) {
            setLocationStores([]);
            clearStores();
            setSubmittedSearchString(searchString);
        }
    }

    function onClose() {
        clear();
        if (reportEvents?.close) {
            customEvent(reportEvents.close);
        }
        handleClose();
    }

    useEffect(() => {
        if (!open) {
            setIsSearching(false);
        }
        if (open) {
            const unblock = history.block(() => {
                onClose();
                return false;
            });
            return () => {
                unblock();
            };
        }
        return undefined;
    }, [open]);

    function onEnter() {
        if (reportEvents?.open) {
            customEvent(reportEvents.open);
        }
    }

    function handleStoreSelected(newStore: Store) {
        if (reportEvents?.selected) {
            customEvent(reportEvents.selected);
            analyticsCustomEvent(eventMetricsMap.stores, {
                click_text: 'set as my store',
                element_location: 'change location',
                store_details: `${mainStore?.address?.city}-${mainStore?.storeNumber}`,
            });
            optimoveEvents(eventMetricsMap.scm_stores, {
                click_text: 'set as my store',
                element_location: 'change location',
                store_details: `${mainStore?.address?.city}-${mainStore?.storeNumber}`,
            });
        }
        clear();
        onStoreSelected(newStore);
    }

    const hasStores = !!nearbyStores?.length || !!mainStore;
    const showMap = includeMap && hasStores;
    const showNearbyResults = !isFetching && hasStores && !isLoading;
    const showNoStoreResults = !isFetching && !hasStores && !isLoading;
    const isMobileWidth = useIsMobileWidth();

    const isStoreSearchModalOpen = useIsActiveScreen(
        SCREEN_NAMES.CHANGE_LOCATION,
        open && !ignoreIsActiveScreen
    );

    const { detectAndSaveZipcode, isDetectingLocation } = useGeolocation();
    const showStoreResult = (stores: any) => {
        if (stores?.length) {
            setLocationStores(stores);
        } else if (stores?.length === 0) {
            console.log('No stores found within 250 miles of this zip code');
        }
    }

    return (
        <Dialog
            open={ignoreIsActiveScreen ? open : isStoreSearchModalOpen}
            onClose={onClose}
            onEnter={onEnter}
            fullScreen={isMobileWidth}
            closeAfterTransition
            maxWidth="md"
            TransitionComponent={TransitionUp}
            classes={{ paper: classes.dialogEdge }}
        >
            <div className={classes.container}>
                <div className={classes.titleAndClose}>
                    <Body size={20} weight='bold' className={classes.titleText}>
                        {title}
                    </Body>
                    <ButtonBase
                        className={classes.closeButton}
                        onClick={onClose}
                        id="store-change-modal-search-close-button"
                    >
                        <img
                            src={require('../../assets/icons/iconCloseBlack.svg')}
                            alt="close icon"
                        />
                    </ButtonBase>
                </div>
                <div className={classes.searchInputFormWrapper}>
                    <form
                        className={classes.searchInputForm}
                        onSubmit={handleSubmit}
                    >
                        <InputBase
                            id="store-change-modal-search-input"
                            className={clsx(
                                classes.searchInput,
                                isSearching && classes.searchInputActive
                            )}
                            placeholder="Search city, state, zip code"
                            onChange={onUpdateSearch}
                            value={searchString}
                            inputMode="search"
                            type="search"
                            startAdornment={
                                <ButtonBase
                                    onClick={handleSubmit}
                                    id="store-change-modal-search-button"
                                    className={classes.searchIconBtn}
                                >
                                    <img
                                        id="store-change-modal-search-icon-img"
                                        src={require('../../assets/icons/iconSearchThin.svg')}
                                        className={classes.searchIcon}
                                        alt="search icon"
                                    />
                                </ButtonBase>
                            }
                        />
                    </form>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>

                        <Button
                            variant="contained-rounded"
                            bgColor="bgBlack"
                            onClick={() => {
                                detectAndSaveZipcode({ showStoreResult });
                            }}

                            contentClassName={classes.locationButton}
                        >
                            <img
                                src={require('../../assets/icons/iconDirectionWhite.svg')}
                                alt="maps icon"
                                className={classes.icon}
                            /> Use my location
                        </Button>
                    </div>
                </div>
                {showMap && (
                    <MapPreview
                        mapHeight={160}
                        center={{
                            longitude: mainStore?.longitude || 0,
                            latitude: mainStore?.latitude || 0,
                        }}
                    />
                )}

                {isSearching && storeResults && !locationStores.length && (
                    <SearchResults
                        withInventory={withInventory}
                        isFetching={isFetching}
                        results={storeResults}
                        placeholderStoreResults={placeholderStoresData}
                        onStoreSelected={handleStoreSelected}
                        selectStoreCtaText={selectStoreCtaText}
                    />
                )}

                {locationStores.length ? (<SearchResults
                    withInventory={withInventory}
                    isFetching={isFetching}
                    results={locationStores}
                    placeholderStoreResults={placeholderStoresData}
                    onStoreSelected={handleStoreSelected}
                    selectStoreCtaText={selectStoreCtaText}
                />) : null}

                {!isSearching && showNearbyResults && !locationStores.length && (
                    <NearbyResults
                        withInventory={withInventory}
                        mainStore={mainStore}
                        nearbyStores={nearbyStores}
                        onStoreSelected={handleStoreSelected}
                        selectStoreCtaText={selectStoreCtaText}
                    />
                )}
                {isLoading && <NoStoreResults subText="Loading..." />}
                {showNoStoreResults && (
                    <NoStoreResults subText="Please enter in a search criteria above and select your Staples store." />
                )}
            </div>
        </Dialog>
    );
}
