/* eslint-disable import/prefer-default-export */
import { format, parse } from 'date-fns';
import moment from 'moment';
import {
    StoreHours,
    Store,
    StoreHourDetail,
    StoreInventory,
} from 'src/services/types';
import useEventsReporter from '../../hooks/useEventsReporter';
import eventMetricsMap from '../../hooks/useEventsReporter/event-types';

function getDayOfWeek() {
    return format(new Date(), 'EEEE').toLowerCase();
}


function nextDayTime() {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    return format(currentDate, 'EEEE').toLowerCase();
}

/**
 * Helper function to determine if a store is currently open and when it closes
 * @returns {open}
 * @returns {closingTime} string in format of 9:00pm
 * @returns {todaysHours} object describing the current store hours
 */
export function describeStoreHours(storeHours: StoreHours, timezone: string): {
    open: boolean;
    closingTime: string;
    todaysHours: StoreHourDetail | Record<string, unknown>;
    nextDayOpenTime: string;
} {
    if (!storeHours) {
        return { open: false, closingTime: '', todaysHours: {}, nextDayOpenTime: '' };
    }
    const todaysHours = (storeHours as any)[getDayOfWeek()] as StoreHourDetail;
    const nextDay: any = (storeHours as any)[nextDayTime()] as any;
    const nextDayTimeDis = parse(nextDay.open24Hr, 'HHmm', new Date());
    if (!todaysHours || todaysHours?.open24Hr === 'Closed') {
        return { open: false, closingTime: '', todaysHours: {}, 
        nextDayOpenTime: moment(nextDayTimeDis, 'HH:mm', true).isValid()
        ? format(nextDayTimeDis, 'h:mmaaa')
        : 'Closed' };
    }
    const dateOfClose = parse(todaysHours.close24Hr, 'HHmm', new Date());
    const now = moment(new Date()).tz(timezone).format('HHmm');
    return {
        open: now < todaysHours.close24Hr,
        closingTime: format(dateOfClose, 'h:mm a'),
        todaysHours,
        nextDayOpenTime: moment(nextDayTimeDis, 'HH:mm', true).isValid()
            ? format(nextDayTimeDis, 'h:mm a')
            : 'Closed',
    };
}

export function storeDistance(storeData: Store): number {
    if (storeData?.distance) {
        return Math.round(storeData?.distance * 100) / 100;
    }
    return 0;
}

export function storeCommunity(store?: Store) {
    if (store && store?.address) {
        return `${store?.address?.city}, ${store?.address?.region}`;
    }
    return '';
}

/** Approiximates the location of a given store on google maps */
export function googleMapURL(storeData: Store | undefined): string {
    let query = new URLSearchParams({
        q: 'Staples',
    }).toString();
    if (storeData?.address) {
        query = new URLSearchParams({
            // eslint-disable-next-line max-len
            q: `Staples near ${storeData.address.address_1} ${storeData.address.address_2} ${storeData.address.city} ${storeData.address.region}, ${storeData.address.postal_code}`,
        }).toString();
    }
    return `https://maps.google.com/?${query}`;
}

const notAlphanumericOrWhitespace = /[^a-zA-Z0-9()\s]+/g;
const whitespace = /\s+/g;

/**
 * Used for converting store addresses to store details urls:
 * 1. remove all chars that are non-alphanumerics nor whitespace
 * 2. replace all instances of one or more whitespace chars with a hyphen
 * 3. convert to lowercase
 * @param i the input string we want to convert
 */
function convertToUrlSubpath(i?: string): string {
    if (!i) return '';
    return i
        .replace(notAlphanumericOrWhitespace, '')
        .replace(whitespace, '-')
        .toLowerCase();
}

/** Gets the stores.staples.com url of a given store */
export function storeDetailsUrl(
    storeData: Store | undefined | null,
    customEvent: any
): string {
    customEvent(eventMetricsMap.store_details);
    if (!storeData?.address) {
        return 'https://stores.staples.com';
    }
    const { region, city, address_1: address } = storeData.address;
    return `https://stores.staples.com/${convertToUrlSubpath(
        region
    )}/${convertToUrlSubpath(city)}/${convertToUrlSubpath(address)}`;
}

/** Gets the url of the webpage displaying the weekly deals information of a given store */
export function storeFlyerUrl(storeData: Store | undefined): string {
    if (!storeData) {
        return 'https://www.staplesconnect.com/weeklyad';
    }
    return `https://www.staplesconnect.com/weeklyad?setstore=${storeData?.storeNumber}`;
}

export function mapStoreInventory(
    store: Store,
    storeInventoryData: StoreInventory[]
) {
    const matchingInventoryData = storeInventoryData?.find(
        (inventory) => inventory.storeId === store.storeNumber
    );
    const updatedStore: Store = {
        ...store,
        ...{
            onHandQuantity: matchingInventoryData?.onHandQuantity,
            price: matchingInventoryData?.price,
        },
    } as Store;

    return updatedStore;
}

export function mapStoresInventory(
    stores: Store[],
    storeInventoryData?: StoreInventory[]
) {
    return storeInventoryData
        ? (stores as Store[]).map((store) =>
              mapStoreInventory(store, storeInventoryData)
          )
        : stores;
}

export const storeNameForProduct = (s?: Store) =>
    s?.address?.address_1 ? `Staples - ${s?.address?.address_1}` : '';
