import {
    Box,
    Button,
    ButtonBase,
    Dialog,
    makeStyles,
    Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { Body, Heading } from 'src/components/UI';
import COLORS from 'src/lib/colors';
import { useYourFiles } from 'src/lib/contexts/YourFilesContext';

const useStyles = makeStyles(() => ({
    linkCta: {
        fontWeight: 500,
        marginTop: '15px',
        color: '#086DD2',
        fontSize: '14px',
        lineHeight: '22px',
        marginBottom: '32px',
    },
    startShipment: {
        height: '40px',
        width: '100%',
        borderRadius: '20px',
        background: '#CC0000',
        marginBottom: '10px',
        '& span': {
            fontSize: '14px',
            fontWeight: 'normal',
            lineHeight: '17px',
            color: '#FFFFFF',
            textAlign: 'center',
        },
        '&:hover': {
            opacity: 0.8,
        },
    },
    paper: {
        borderRadius: '15px',
        margin: '24px',
        padding: '10px 0 0 0',
        position: 'relative',
    },
    dialogTitle: {
        fontSize: '18px',
        color: '#000',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '-0.2px',
        margin: '10px 0 10px 0px',
    },
    dialogWrapper: {
        padding: '20px 20px 30px 30px',
        textAlign: 'center',
        color: COLORS.secondaryNeutralBlack,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    dialogMessage: {
        marginBottom: '30px',
        fontSize: '14px',
        fontWeight: 400,
    },
    closeIcon: {
        position: 'absolute',
        top: '-3px',
        right: '10px',
        backgroundColor: 'rgba(0,0,0,0)',
        minWidth: 'initial',
        padding: '0px',
    },
    closeButton: {
        position: 'relative',
        textAlign: 'center',
    },
    anotherPackage: {
        margin: '0px',
    },
    successIcon: {
        width: '50px',
        marginBottom: '5px',
    },
    'MuiSwipeableDrawer-root': {
        position: 'relative',
    },
}));

function ErrorMsgDialog() {
    const classes = useStyles();
    const { isErrorMsgModalOpen, handleErrorMsgModal, fileErrorMsg } =
        useYourFiles();

    return (
        <>
            <Dialog
                open={isErrorMsgModalOpen}
                classes={{
                    paper: classes.paper,
                }}
            >
                <div className={classes.closeButton}>
                    <Button
                        onClick={handleErrorMsgModal}
                        className={classes.closeIcon}
                        id="close-button"
                    >
                        <img
                            src={require('../../../assets/icons/iconCloseBlack.svg')}
                            alt="close icon"
                        />
                    </Button>
                </div>
                <div className={classes.dialogWrapper}>
                    <Heading
                        type="h3"
                        color="textBlack"
                        size={18}
                        lineHeight={22}
                    >
                        Invalid file type
                    </Heading>

                    <Box
                        sx={{
                            display: 'ruby',
                            alignItems: 'center',
                            my: '10px',
                        }}
                    >
                        <Body color="textRed">{fileErrorMsg}</Body>
                    </Box>
                </div>
            </Dialog>
        </>
    );
}

export default ErrorMsgDialog;
