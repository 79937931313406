import Cookies from "js-cookie";
import { getMinutes } from "date-fns";
import axios from "axios";
import { akamaiTelemetryHeader } from "../utils/app-utils";
import { getHeaderData } from "./cart-api";
import { COMMON_HEADERS, getUserToken, SessionManager, useRefreshToken } from "./sessionManager";
import { getSubscriptionKey } from "./sign-on";



export interface UserCredentialRequest {
    username:string;
    tenant:string;
    userAgent:string;
    requestUrl:string;
    stplSessionId:string;
    userIp:string;
    placement:string;
    channel:string;
    docId:string;
    ndsModeValue?:any
    captchaAnswer?: string
    page?: number
    nuCaptchaToken?: string
}

export interface OtpRequestBody {
    eUserName: string;
    otpCode?: string;
    tenant: string;
    userAgent: string;
    requestUrl: string;
    stplSessionId?: string| null;
    userIp: string;
    placement: string;
    channel: string;
    eDocId: string;
}

export interface VerifyOtpResponse {
    code: 2451| 0|3|1,
    msg: string,
    status?: boolean,
    data?: any,
}

export const verifyOtp = async (otpBody: OtpRequestBody): Promise<any | null> => {
    const isLocalHost =  window?.location?.host === "localhost:3000";

    const subscriptionKey = await getSubscriptionKey("StaplesIDP");
        if (!subscriptionKey) {
            return null;
        };
        let headerData: any = "";
        if(!isLocalHost) {
            headerData = await getHeaderData();
       } 

    const otpResponse: any = await axios.post(
        `${process.env.REACT_APP_EASY_API_URL}/v1/idm/api/identityProxy/app/verify-otp`,otpBody, {
        headers: {
            ...COMMON_HEADERS,
            client_id: subscriptionKey,
            'ocp-apim-subscription-key': process.env.REACT_APP_PUBLIC_API_KEY || '',
            'content-type': 'application/json',
            'Cache-Control': 'no-cache',
            "x-device-id": headerData?.["x-device-id"],
                ...akamaiTelemetryHeader,
          }}
    );

    const otpResponseInstance = otpResponse.data;
    
    if(otpResponseInstance?.ssoToken) {
        SessionManager.setIsUserMigrated({ isUserMigrated: true, userName: otpBody?.eUserName });
        otpResponseInstance.userName = otpBody?.eUserName;
        otpResponseInstance.access_token_issued_at = new Date().valueOf();
       
        SessionManager.setTokens(otpResponseInstance);
        const userTokenResponse = await getUserToken();
        Cookies.set('SMSESSION', otpResponseInstance?.ssoToken,
            { domain: process.env.REACT_APP_Domain });
        Cookies.set('SOMNIAPP', 'Y',
            {
                domain: process.env.REACT_APP_Domain,
                expires: 54 * 60 * 60 * 24 * 7,
            });
        if (!userTokenResponse.status) {
            return { status: false, data: otpResponse?.data };
        }
        SessionManager.isLoggedIn = true;
        // if refresh token expires, refresh rmTokem
        useRefreshToken(getMinutes(+otpResponseInstance.expires_in) - 2, new Date().valueOf())
        
        return { status: true, data: otpResponse?.data };
    } 
        return otpResponseInstance;
    

   
    
}
export const resendOtp = async (otpBody: UserCredentialRequest): Promise<any> => {
    const isLocalHost =  window?.location?.host === "localhost:3000";

    const subscriptionKey = await getSubscriptionKey("StaplesIDP");
    if (!subscriptionKey) {
        return null;
    };
    let headerData: any = ""
    if(!isLocalHost) {
        headerData = await getHeaderData();
    } 
    
    const otpResponse: any = await axios.post(
        `${process.env.REACT_APP_EASY_API_URL}/v1/idm/api/identityProxy/app/resend-otp`,otpBody, {
            headers: {
                ...COMMON_HEADERS,
                client_id: subscriptionKey,
                'ocp-apim-subscription-key': process.env.REACT_APP_PUBLIC_API_KEY || '',
                'content-type': 'application/json',
                'Cache-Control': 'no-cache',
                "x-device-id": headerData?.["x-device-id"],
                    ...akamaiTelemetryHeader,
            }}
    );
    return otpResponse?.data;
    
}