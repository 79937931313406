import { Box, ButtonBase, makeStyles } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import Webcam from 'react-webcam';
import Row from 'src/components/Row';
import { getFileUploadPath } from 'src/lib/api-client/file-upload-api';
import COLORS from 'src/lib/colors';
import { useYourFiles } from 'src/lib/contexts/YourFilesContext';

const useStyles = makeStyles((theme) => ({
    openCameraBtn: {
        alignSelf: 'center',
        backgroundColor: COLORS.brandRed,
        color: 'white',
        lineHeight: '2.5rem',
        borderRadius: '2rem',
        width: '116px',
        height: '40px',
        fontWeight: 500,
        fontSize: '0.95rem',
        letterSpacing: '0',
        userSelect: 'none',
    },
}));

function TakePhoto() {
    const classes = useStyles();
    const { isCameraOpen, setIsCameraOpen, onUploadFile } = useYourFiles();
    const webcamRef = React.useRef<Webcam>(null);
    const [capturedImage, setCapturedImage] = useState<string | null>(null);
    const [photoFile, setPhotoFile] = useState<File | null>(null);
    const [facingMode, setFacingMode] = useState('environment');
    const videoConstraints = {
        width: window.innerWidth,
        height: window.innerHeight - 58,
        facingMode,
    };

    const takeAPhoto = useCallback(() => {
        if (webcamRef.current) {
            const imageSrc = webcamRef.current.getScreenshot();

            if (imageSrc) {
                setCapturedImage(imageSrc);
                const byteCharacters = atob(imageSrc.split(',')[1]);
                const byteArrays = [];

                for (
                    let offset = 0;
                    offset < byteCharacters.length;
                    offset += 1024
                ) {
                    const slice = byteCharacters.slice(offset, offset + 1024);
                    const byteNumbers = new Array(slice.length);
                    for (let i = 0; i < slice.length; i += 1) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    byteArrays.push(byteArray);
                }

                const blob = new Blob(byteArrays, { type: 'image/jpeg' });

                const file = new File([blob], 'captured-photo.jpg', {
                    type: 'image/jpeg',
                });
                setPhotoFile(file);
            }
        }
    }, [webcamRef]);

    const reTakeAPhoto = () => {
        setCapturedImage(null);
    };

    const toggleCamara = useCallback(
        (mode: string) => {
            setFacingMode(mode === 'user' ? 'environment' : 'user');
        },
        [webcamRef, facingMode]
    );

    const submitPhoto = async () => {
        if (photoFile) {
            const data = await getFileUploadPath();
            await onUploadFile(photoFile, data, 0, 1);
            reTakeAPhoto();
            setIsCameraOpen(false);
            setPhotoFile(null);
        }
    };

    return (
        <Box
            sx={{
                position: 'relative',
                overflow: 'hidden',
                height: '100%',
            }}
        >
            <Webcam
                audio={false}
                // height={720}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                // width={1280}
                videoConstraints={videoConstraints}
                style={{
                    height: '100%',
                }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    top: 20,
                    right: 20,
                }}
            >
                <Row
                    gap="10px"
                    sx={{
                        justifyContent: 'center',
                    }}
                >
                    <ButtonBase
                        className={classes.openCameraBtn}
                        onClick={() => toggleCamara(facingMode)}
                    >
                        {facingMode === 'user' ? 'Back' : 'Front'} Camera
                    </ButtonBase>
                </Row>
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 20,
                    left: capturedImage ? '22%' : '37%',
                }}
            >
                <Row
                    gap="10px"
                    sx={{
                        justifyContent: 'center',
                    }}
                >
                    <>
                        {capturedImage ? (
                            <>
                                <ButtonBase
                                    className={classes.openCameraBtn}
                                    onClick={submitPhoto}
                                >
                                    Submit
                                </ButtonBase>
                                <ButtonBase
                                    className={classes.openCameraBtn}
                                    onClick={reTakeAPhoto}
                                >
                                    Re-take
                                </ButtonBase>
                            </>
                        ) : (
                            <ButtonBase
                                className={classes.openCameraBtn}
                                onClick={takeAPhoto}
                            >
                                Click
                            </ButtonBase>
                        )}
                    </>
                </Row>
            </Box>
        </Box>
    );
}

export default TakePhoto;
