/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */

import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Button as MUIButton,
    ButtonBase,
    Box,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { optimoveEvents } from 'src/screens/optimove';
import useUserInfo from 'src/hooks/useUserInfo';
import { saveDigitalCoupon } from 'src/lib/api-client/wallet-activities';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useCardCanvas from 'src/hooks/useCardCanvas';
import { makeSearchString } from 'src/lib/utils/url-utils';
import useRouter from 'src/hooks/useRouter';
import Copy from 'src/assets/icons/copy.svg';
import COLORS from 'src/lib/colors';
import { Body, Button } from '../UI';
import { IconSmallCheckmark } from '../Icons';
import useStyles from './styles';
import CommonModal from '../CommonModal';
import Row from '../Row';

function CouponSaveButton({
    expirationDate,
    rewardsNumber,
    couponNumber,
    descriptionText1,
    descriptionText2,
    setSaveCouponCall,
    setSaveCouponFetch,
    setIsOpenUnclipModal,
    setModalText,
}: {
    expirationDate: string;
    rewardsNumber?: string;
    couponNumber: number;
    descriptionText1: string;
    descriptionText2: string;
    setSaveCouponCall: (flag: boolean) => void;
    setSaveCouponFetch: (flag: boolean) => void;
    setIsOpenUnclipModal: React.Dispatch<React.SetStateAction<boolean>>;
    setModalText: React.Dispatch<React.SetStateAction<string>>;
}) {
    const classes = useStyles();
    const { customEvent, analyticsCustomEvent, analyticsScreenCustomNames } =
        useEventsReporter();
    const { userInfo } = useUserInfo();
    const saveDigitalCouponQuery = async (e: any) => {
        e.preventDefault();
        setSaveCouponFetch(true);
        if (rewardsNumber) {
            const offerdescription = `${descriptionText1} ${descriptionText2}`;
            const parameter = {
                enrolled: rewardsNumber !== '0',
                coupon_id: couponNumber,
                rewards_number: rewardsNumber,
                offer_title: `${descriptionText1} ${descriptionText2}`,
            };
            customEvent(eventMetricsMap.save_reward, parameter);
            optimoveEvents(eventMetricsMap.scm_wallet, {
                click_text: 'save & extend reward',
                element_location: 'available',
                wallet_location: 'digital coupons',
                offer_description: `${descriptionText1} ${descriptionText2}`,
            });
            analyticsCustomEvent(eventMetricsMap.easy_rewards, {
                click_text: 'clip coupon',
                element_location: 'coupon view',
                offer_description: offerdescription?.substring(0, 50),
                coupon_number: couponNumber,
            });
            try {
                const saveCouponData = await saveDigitalCoupon({
                    rewardsNumber,
                    couponCode: couponNumber,
                    isSaved: true,
                });
                if (saveCouponData?.data?.success) {
                    setSaveCouponCall(true);
                    setIsOpenUnclipModal(false);
                } else {
                    setSaveCouponFetch(false);
                    setIsOpenUnclipModal(true);
                }
            } catch (error) {
                setModalText('Unable to clip coupon');
                setIsOpenUnclipModal(true);
                setSaveCouponFetch(false);
            }
        }
        return null;
    };

    useEffect(() => {
        if (userInfo?.rewardsNumber) {
            analyticsScreenCustomNames({
                rewards_number: userInfo?.rewardsNumber,
            });
        } else {
            analyticsScreenCustomNames({
                rewards_number: '',
            });
        }
        if (userInfo?.preferredStoreId) {
            analyticsScreenCustomNames({
                store_id: userInfo?.preferredStoreId,
            });
        } else {
            analyticsScreenCustomNames({
                store_id: '',
            });
        }
    }, []);

    return (
        <Button
            fontSize={16}
            onClick={(e) => saveDigitalCouponQuery(e)}
            variant="contained-rounded"
            className={classes.clipBtnAlign}
        >
            {'Clip coupon'}
        </Button>
    );
}

function CouponContent({
    couponApplicable,
    imageURL,
    descriptionText1,
    descriptionText2,
    couponClass,
}: {
    couponApplicable: string;
    imageURL?: string;
    descriptionText1: string;
    descriptionText2: string;
    couponClass?: string;
}) {
    const classes = useStyles();
    return (
        <div className={classes.couponPromoContainer}>
            {(couponApplicable === 'Product' ||
                couponClass === 'StoreCredit') &&
                imageURL && (
                    <img
                        className={classes.promoImg}
                        src={imageURL}
                        alt="Coupon"
                    />
                )}
            <Body
                size={36}
                weight="bold"
                color="$red"
                margin="1rem 0 0 0"
                className={
                    couponApplicable !== 'Product'
                        ? classes.promoText1Primary
                        : classes.promoText1Secondary
                }
            >
                {descriptionText1}
            </Body>
            <Body
                size={26}
                weight="bold"
                color="$black"
                margin="5px 0 0 0"
                className={classes.promoText2}
            >
                {descriptionText2}
            </Body>
            {/*  <p
                className={
                    couponApplicable !== 'Product'
                        ? classes.promoText1Primary
                        : classes.promoText1Secondary
                }
            >
                {descriptionText1}
            </p> */}
            {/* <p className={classes.promoText2}>{descriptionText2}</p> */}
        </div>
    );
}

type ScannableCouponProps = {
    rewardsNumber?: string;
    couponApplicable: string;
    couponNumber: number;
    availableUntil: Date;
    expirationDate: Date;
    descriptionText1: string;
    descriptionText2: string;
    imageURL?: string;
    isSaved: boolean;
    validForDotcom: boolean;
    validForRetail: boolean;
    terms: string;
    isCouponNumberDisplayed: boolean;
    couponLimitedTime: boolean;
    setSaveCouponCall: (flag: boolean) => void;
    setSaveCouponFetch: (flag: boolean) => void;
    couponClass?: string;
    isClippable?: boolean;
};

function ScannableCoupon({
    rewardsNumber,
    couponApplicable,
    couponNumber,
    availableUntil,
    expirationDate,
    descriptionText1,
    descriptionText2,
    imageURL,
    isSaved,
    validForDotcom,
    validForRetail,
    terms,
    isCouponNumberDisplayed,
    couponLimitedTime,
    setSaveCouponCall,
    setSaveCouponFetch,
    couponClass,
    isClippable,
}: ScannableCouponProps) {
    const classes = useStyles();
    const { canvasRef } = useCardCanvas(`${couponNumber}`);
    const [copied, setCopied] = useState<boolean>(false);
    const [showBarcode, setShowBarcode] = useState(false);
    const [isOpenUnclipModal, setIsOpenUnclipModal] = useState(false);
    const [modalText, setModalText] = useState<string>('Unable to clip coupon');
    const { analyticsCustomEvent } = useEventsReporter();

    const expirationDateObj = new Date(expirationDate);
    const formattedExpirationDate = `${
        expirationDateObj.getMonth() + 1
    }/${expirationDateObj.getDate()}/${expirationDateObj
        .getFullYear()
        .toString()
        .slice(2)}`;

    const availableUntilObj = new Date(availableUntil);
    const formattedAvailableUntil = `${
        availableUntilObj.getMonth() + 1
    }/${availableUntilObj.getDate()}/${availableUntilObj
        .getFullYear()
        .toString()
        .slice(2)}`;
    const handleUnclipped = async (e: any) => {
        e.preventDefault();
        setSaveCouponFetch(true);
        const offerdescription = `${descriptionText1} ${descriptionText2}`;

        if (rewardsNumber) {
            try {
                const saveCouponData = await saveDigitalCoupon({
                    rewardsNumber,
                    couponCode: couponNumber,
                    isSaved: false,
                });
                if (saveCouponData?.data?.success) {
                    setSaveCouponCall(true);
                    analyticsCustomEvent(eventMetricsMap.easy_rewards, {
                        click_text: 'unclip coupon',
                        element_location: 'coupon view',
                        offer_description: offerdescription?.substring(0, 50),
                        coupon_number: couponNumber,
                    });
                }
                setIsOpenUnclipModal(false);
            } catch (error) {
                setModalText('Unable to unclip coupon');
                setIsOpenUnclipModal(true);
                setSaveCouponFetch(false);
            }
        }
        return null;
    };
    let redeemText = <>Redeem this coupon</>;
    if (validForDotcom && validForRetail) {
        redeemText = (
            <>
                <img
                    src={require('../../assets/icons/iconShoppingBag.svg')}
                    alt="shopping bag icon"
                />{' '}
                <img
                    src={require('../../assets/icons/iconComputer.svg')}
                    alt="computer icon"
                />{' '}
                Redeem in-store or online
            </>
        );
    } else if (validForDotcom) {
        redeemText = (
            <>
                <img
                    src={require('../../assets/icons/iconComputer.svg')}
                    alt="computer icon"
                />{' '}
                Redeem online
            </>
        );
    } else if (validForRetail) {
        redeemText = (
            <>
                <img
                    src={require('../../assets/icons/iconShoppingBag.svg')}
                    alt="shopping bag icon"
                />{' '}
                Redeem in-store
            </>
        );
    }
    return (
        <>
            <div className={classes.couponContainer}>
                <div className={classes.promoContainer}>
                    {couponLimitedTime ? (
                        <div className={classes.limitedTimeBtn}>
                            {' '}
                            limited time only
                        </div>
                    ) : null}

                    <CouponContent
                        couponApplicable={couponApplicable}
                        imageURL={imageURL}
                        descriptionText1={descriptionText1}
                        descriptionText2={descriptionText2}
                        couponClass={couponClass}
                    />
                </div>
                <div className={classes.barcodeAndTCContainer}>
                    <div className={classes.barcodeContainer}>
                        <p>
                            <span
                                className={isSaved ? classes.bold : undefined}
                            >
                                {`Exp ${formattedExpirationDate}.`}
                            </span>{' '}
                            Exclusions apply.
                        </p>
                        <p>{redeemText}</p>

                        {isClippable ? (
                            isSaved ? (
                                <div className={classes.clipContainer}>
                                    <div className={classes.savedMessage}>
                                        <IconSmallCheckmark /> Applies at
                                        checkout
                                    </div>
                                    <Button
                                        variant="text"
                                        onClick={handleUnclipped}
                                        fontSize={14}
                                        fontWeight="normal"
                                        className={classes.unclipText}
                                    >
                                        Unclip this coupon
                                    </Button>
                                </div>
                            ) : (
                                <CouponSaveButton
                                    expirationDate={formattedExpirationDate}
                                    couponNumber={couponNumber}
                                    rewardsNumber={rewardsNumber}
                                    descriptionText1={descriptionText1}
                                    descriptionText2={descriptionText2}
                                    setSaveCouponCall={setSaveCouponCall}
                                    setSaveCouponFetch={setSaveCouponFetch}
                                    setIsOpenUnclipModal={setIsOpenUnclipModal}
                                    setModalText={setModalText}
                                />
                            )
                        ) : null}
                        {!showBarcode ? (
                            <Button
                                className={classes.showBarcodeButton}
                                onClick={() => setShowBarcode(true)}
                                variant="text"
                                color="textLinkBlue"
                            >
                                Show barcode
                            </Button>
                        ) : (
                            <>
                                <canvas
                                    ref={canvasRef}
                                    className={classes.renderedCardNumber}
                                />
                                {isCouponNumberDisplayed && (
                                    <Box>
                                        <Box
                                            className={
                                                classes.couponNumberContainer
                                            }
                                        >
                                            <span
                                                className={classes.couponNumber}
                                            >
                                                {couponNumber}
                                            </span>
                                            {validForDotcom && (
                                                <Box
                                                    onClick={() => {
                                                        const offerdescription = `${descriptionText1} ${descriptionText2}`;

                                                        setCopied(true);
                                                        navigator?.clipboard?.writeText(
                                                            `${couponNumber}`
                                                        );
                                                        setTimeout(
                                                            () =>
                                                                setCopied(
                                                                    false
                                                                ),
                                                            1500
                                                        );
                                                        analyticsCustomEvent(
                                                            eventMetricsMap.easy_rewards,
                                                            {
                                                                click_text:
                                                                    'copy coupon code',
                                                                element_location:
                                                                    'coupon view',
                                                                offer_description:
                                                                    offerdescription?.substring(
                                                                        0,
                                                                        50
                                                                    ),
                                                                coupon_number:
                                                                    couponNumber,
                                                            }
                                                        );
                                                    }}
                                                    sx={{
                                                        position: 'relative',
                                                        mt: '3px',
                                                    }}
                                                >
                                                    <img
                                                        src={Copy}
                                                        alt="Copy-Coupon-Code"
                                                    />

                                                    {copied && (
                                                        <span
                                                            className={
                                                                classes.infoCopied
                                                            }
                                                        >
                                                            Copied!
                                                        </span>
                                                    )}
                                                </Box>
                                            )}
                                        </Box>
                                        {validForDotcom && (
                                            <Typography
                                                className={classes.infoText}
                                            >
                                                Copy coupon code to redeem
                                                online
                                            </Typography>
                                        )}
                                    </Box>
                                )}
                            </>
                        )}
                    </div>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Terms of use</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography
                                className={classes.tncText}
                                variant="caption"
                            >
                                {terms}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
            {isOpenUnclipModal && (
                <CommonModal open={isOpenUnclipModal} isNoPadding>
                    <>
                        <Row
                            sx={{
                                justifyContent: 'center',
                                p: 2,
                                borderBottom: `1px solid ${COLORS.whiteSmoke}`,
                            }}
                        >
                            <>
                                <Body>{modalText}</Body>
                            </>
                        </Row>
                        <Row
                            style={{
                                padding: '16px',
                                justifyContent: 'center',
                            }}
                        >
                            <Button
                                onClick={() => setIsOpenUnclipModal(false)}
                                variant="text"
                                color="textLinkBlue"
                            >
                                Close
                            </Button>
                        </Row>
                    </>
                </CommonModal>
            )}
        </>
    );
}

export function UnenrolledCoupon() {
    const classes = useStyles();
    const { history, pathname } = useRouter();
    const descriptionText1 =
        'Login into your Staples Rewards® account to unlock even more deals.';
    return (
        <div className={classes.couponContainer}>
            <div className={classes.couponPromoContainer}>
                <img
                    className={classes.promoImg}
                    style={{ margin: '3rem auto' }}
                    src={require('../../assets/icons/iconStaplesConnect.svg')}
                    alt="Coupon"
                />
                <p
                    className={classes.promoText2}
                    style={{ fontWeight: 500, marginBottom: '2rem' }}
                >
                    {descriptionText1}
                </p>
            </div>
            <div style={{ padding: '1rem' }}>
                <MUIButton
                    className={classes.rewardsSignInButton}
                    onClick={() => {
                        history.push({
                            pathname,
                            search: makeSearchString({
                                enrollStep: 'step2',
                                flowName: 'SRW',
                            }),
                        });
                    }}
                >
                    Sign in now
                </MUIButton>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="caption">Not a member yet?</Typography>
                    <ButtonBase
                        className={classes.joinNowButton}
                        onClick={() => {
                            history.push({
                                pathname,
                                search: makeSearchString({
                                    enrollStep: 'step2',
                                    flowName: 'SRW',
                                }),
                            });
                        }}
                    >
                        Sign up today, it's free to join!
                    </ButtonBase>
                </div>
            </div>
        </div>
    );
}

export default ScannableCoupon;
