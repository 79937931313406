import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import Row from 'src/components/Row';
import { Body } from 'src/components/UI';
import { useYourFiles } from 'src/lib/contexts/YourFilesContext';

const useStyles = makeStyles((theme) => ({
    uploadButton: {
        backgroundColor: '#000',
        borderRadius: '100px',
        display: 'flex',
        alignItems: 'center',
    },
}));

interface IUploadInputProps {
    htmlFor: string;
    title: string;
    redirect: number;
    acceptFileType: string;
    iconComponent: JSX.Element;
}

function UploadInput({
    htmlFor,
    iconComponent,
    title,
    redirect,
    acceptFileType,
}: IUploadInputProps) {
    const classes = useStyles();
    const { handleFilesChange, handleTabChange } = useYourFiles();
    return (
        <label htmlFor={htmlFor} className={classes.uploadButton}>
            <Row
                gap="10px"
                sx={{
                    alignItems: 'center',
                }}
                onClick={() => handleTabChange(redirect)}
            >
                <>
                    <Box
                        sx={{
                            minWidth: 34,
                            minHeight: 34,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            bgcolor: '#585858',
                            borderRadius: '50%',
                        }}
                    >
                        {iconComponent}
                    </Box>
                    <Body color="textWhite" lineHeight={20} size={16}>
                        {title}
                    </Body>
                </>
            </Row>
            <input
                type="file"
                name={htmlFor}
                multiple
                accept={acceptFileType}
                id={htmlFor}
                hidden
                onChange={(e) => handleFilesChange(e)}
            />
        </label>
    );
}

export default UploadInput;
