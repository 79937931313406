import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    makeStyles,
    Typography,
} from '@material-ui/core';
import ManageFavoritesCoachScreen from 'src/screens/EasyRewards/ManageFavorites/ManageFavoritesCoachScreen';
import { Body, Heading, Button } from 'src/components/UI';
import IconDown from 'src/components/Icons/IconDown';
import IconDownCoachMark from 'src/components/Icons/IconDownCoachMark';
import { eventMetricsMap, useEventsReporter, useUserInfo } from 'src/hooks';
import { useLoyaltyAccountDetailsQuery } from 'src/services/easyRewardsServices';
import {
    DigitalWallet,
    DownChevron,
    IconEasyRewardsHorizontal,
} from 'src/components/Icons';
import { numberWithCommas } from 'src/lib/utils/app-utils';
import layout_classes from '../../../stylesheets/layout_classes.module.scss';
import styles from './styles.module.scss';
import '../../../assets/animate/animate.css';

const Membership = ({ handleSkipAll, handleNext }: any) => {
    const animationClass =
        'animate__animated animate__fadeIn animate__delay-0.8s';

    const { analyticsCustomEvent } = useEventsReporter();

    const handleNextScreen = () => {
        handleNext(4);
        analyticsCustomEvent(eventMetricsMap.coachmarks, {
            click_text: `next`,
        });
    };

    const handleSkip = () => {
        handleSkipAll();
        analyticsCustomEvent(eventMetricsMap.coachmarks, {
            click_text: `skip all`,
        });
    };

    return (
        <div className={`${styles.mainConatiner} ${animationClass}`}>
            <div className={styles.absContainer}>
                <div className={styles.cardImg}>
                    <div className={styles.digitalCard}>
                        <DigitalWallet />
                        <Body
                            className={styles.scancode}
                            aria-label="Scan my Easy Rewards number"
                        >
                            Scan my
                            <span
                                className={styles.scanIcon}
                                aria-hidden="true"
                            >
                                <IconEasyRewardsHorizontal
                                    width={99}
                                    fillColor="fillPurple"
                                />
                            </span>
                            number{' '}
                            <span
                                className={styles.scanIconLast}
                                aria-hidden="true"
                            >
                                <DownChevron />
                            </span>
                        </Body>
                    </div>
                </div>
                <div className={styles.arrowIcon}>
                    <img src={require('../../Icons/Layer_1.png')} alt="img" />
                </div>
                <div className={styles.boxConatiner}>
                    <Body
                        size={12}
                        className={styles.alignCount}
                        weight="light"
                        lineHeight={16}
                        aria-label="Three out of five"
                    >
                        3/5
                    </Body>
                    <Heading
                        type="h2"
                        weight="bold"
                        size={26}
                        lineHeight={32}
                        className={styles.alignheaderDsc}
                        aria-label="Membership number"
                    >
                        Membership number
                    </Heading>
                    <Body
                        size={16}
                        weight="light"
                        lineHeight={20}
                        aria-label="Tap to reveal your Easy Rewards number to show at checkout"
                    >
                        Tap to reveal your Easy Rewards number to show at
                        checkout.
                    </Body>
                    <div className={styles.btnConatiner}>
                        <Button
                            variant="text"
                            onClick={handleSkip}
                            className={styles.Link}
                            fontWeight="bold"
                            aria-label="Skip all"
                        >
                            Skip all
                        </Button>
                        <Button
                            onClick={handleNextScreen}
                            bgColor="bgRed"
                            className={styles.btnAlign}
                            aria-label="Next"
                            role="button"
                        >
                            Next
                        </Button>
                    </div>
                </div>
            </div>
            <div className={styles.alignImg}>
                <img
                    src={
                        'https://de-app.staplesconnect.com/images/CoachMarks/cm_img_membership_card_sm.png'
                    }
                    alt="img"
                />
            </div>
        </div>
    );
};

export default Membership;
