import React, { ReactNode } from 'react';
import { useScreenSize } from 'src/hooks';
import { mergeClasses } from 'src/stylesheets/utils';
import CircularLoader from '../CircularLoader';
import styles from './styles.module.scss';

interface ButtonProps {
    variant?:
        | 'base'
        | 'icon'
        | 'text'
        | 'contained'
        | 'contained-rounded'
        | 'outlined-rounded'
        | 'outlined';
    display?: 'flex' | 'inlineFlex';
    color?: string;
    bgColor?: string;
    borderColor?: string;
    decoration?: string;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    children: ReactNode;
    disabled?: boolean;
    loading?: boolean;
    align?: string;
    className?: string;
    contentClassName?: string;
    size?: 'small' | 'default' | 'medium' | 'large';
    fontSize?: number;
    fontWeight?: 'light' | 'normal' | 'bold';
    breakpoint?: number;
    fullWidth?: boolean;
    tabIndex?: number;
    role?: string;
    ariaLabel?: string;
    id?: string;
    ariaDescription?: string;
}

function Button({
    variant = 'contained-rounded',
    display = 'flex',
    bgColor = variant.includes('outlined') ? 'bgWhite' : 'bgPurple',
    borderColor = variant.includes('outlined') ? 'borderPurple' : 'borderNone',
    color = variant.includes('outlined') ? 'textPurple' : 'textWhite',
    decoration = 'none',
    onClick,
    children,
    disabled,
    loading,
    fontSize,
    align = 'default',
    size = 'default',
    fontWeight = variant === 'text' ? 'light' : 'normal',
    className,
    contentClassName,
    breakpoint = 390,
    fullWidth,
    tabIndex = undefined,
    role = '',
    ariaLabel = '',
    id = '',
    ariaDescription = '',
}: ButtonProps) {
    const mergedClassname = mergeClasses([
        styles[variant],
        styles[display],
        styles[color],
        styles[bgColor],
        styles[borderColor],
        styles[align],
        styles[decoration],
        styles[size],
        styles[fontWeight],
        styles[fullWidth ? 'full-width' : 'default'],
        className,
    ]);
    let computedFontSize = fontSize || 16;
    const { width } = useScreenSize();
    if (width < breakpoint) {
        computedFontSize -= 2;
    }
    return (
        <button
            type="button"
            className={mergedClassname}
            disabled={disabled}
            onClick={onClick}
            style={{ fontSize: computedFontSize }}
            tabIndex={tabIndex}
            role={role}
            aria-label={ariaLabel}
            id={id}
            aria-roledescription={ariaDescription}
        >
            <div
                className={
                    loading
                        ? styles.hideChildren
                        : mergeClasses([styles.showChildren, contentClassName])
                }
            >
                {children}
            </div>
            {loading && (
                <span className={styles.loadingContainer}>
                    <CircularLoader height="50%" width="100%" />
                </span>
            )}
        </button>
    );
}

export default Button;
