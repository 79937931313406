/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@material-ui/core';
import { IconSmallCheckmark } from 'src/components/Icons';
import { getExpDate } from 'src/screens/FeaturedList/FeaturedList';
import { Body, Button, Heading } from 'src/components/UI';
import { Player } from '@lottiefiles/react-lottie-player';
import {
    useFeaturedLoyaltyOffersQuery,
    useLoyaltyOffersQuery,
    LoyaltyOffer,
    LoyaltyOfferResponse,
    updateLoyaltyOfferCache,
    useActivateOfferMutation,
} from 'src/services/easyRewardsServices';
import {
    eventMetricsMap,
    useEventsReporter,
    useRouter,
    useUserInfo,
} from 'src/hooks';
import { useSetAtom } from 'jotai';
import { mergeClasses } from 'src/stylesheets/utils';
import ROUTES from 'src/lib/routes';
import { makeSearchString } from 'src/lib/utils/url-utils';
import ShippingRewards from 'src/components/shipping-services/shipping-rewards/shipping-rewards';
import { isActivationSuccess, toastAtom } from '../OfferList/OfferCard';
import OfferList from '../OfferList';
import { initialInfoDrawerState } from '../OfferList/OfferList';
import { AllOffersActivationAnimation } from '../StoreModeOfferPage';
import styles from './styles.module.scss';

// TODO fix types DO NOT USE ANY
// interface TabPanelProps {
//     children?: React.ReactNode;
//     index: any;
//     value: any;
// }

// function TabPanel(props: TabPanelProps) {
//     const { children, value, index, ...other } = props;

//     return (
//         <div
//             role="tabpanel"
//             hidden={value !== index}
//             id={`simple-tabpanel-${index}`}
//             aria-labelledby={`simple-tab-${index}`}
//             {...other}
//         >
//             {value === index && (
//                 <Box p={3} className={styles.tablePanelBox}>
//                     {children}
//                 </Box>
//             )}
//         </div>
//     );
// }

// function a11yProps(index: any) {
//     return {
//         id: `offer-tab-${index}`,
//         'aria-controls': `offer-tabpanel-${index}`,
//     };
// }
type FilterValues = 'All' | 'InStore' | 'Online';
interface OfferListHeaderProps {
    headerTitle: string;
    showActivateAllButton: boolean;
    activateAllOffers: () => void;
    filterValue: FilterValues;
    setFilterValue: (newValue: FilterValues) => void;
}
function OfferListHeader({
    headerTitle,
    showActivateAllButton,
    activateAllOffers,
    filterValue,
    setFilterValue,
}: OfferListHeaderProps) {
    const { analyticsCustomEvent } = useEventsReporter();
    const { userInfo } = useUserInfo();
    return (
        <>
            <div className={styles.offerHeading}>
                <p
                    className={styles.heading}
                    // tabIndex={dynamicTabIndex}
                    aria-roledescription="These offers are personalized for you"
                >
                    {headerTitle}
                </p>
                {showActivateAllButton ? (
                    <Button
                        // tabIndex={dynamicTabIndex + 1}
                        aria-selected="true"
                        aria-label="Activate all"
                        className={styles.activateAllButton}
                        variant="text"
                        onClick={() => {
                            activateAllOffers();
                            analyticsCustomEvent(eventMetricsMap.easy_rewards, {
                                click_text: 'activate all',
                                element_location: 'Offers - For You',
                                event_rewards_number:
                                    userInfo?.rewardsNumber || '',
                            });
                        }}
                    >
                        Activate all
                    </Button>
                ) : (
                    <Body
                        className={styles.activateAllSuccessText}
                        color="textPurple"
                        weight="bold"
                        size={12}
                    >
                        <IconSmallCheckmark />
                        All offers are activated
                    </Body>
                )}
            </div>
            <div className={styles.offerCapsules}>
                <Button
                    className={`${styles.capsuleBtn} ${
                        filterValue === 'All' ? styles.active : ''
                    }`}
                    fontSize={12}
                    fontWeight={filterValue === 'All' ? 'normal' : 'light'}
                    onClick={() => setFilterValue('All')}
                    tabIndex={4}
                    ariaLabel="All"
                    role="Button"
                    ariaDescription="You are viewing both in store and online offers"
                >
                    All
                </Button>
                <Button
                    className={`${styles.capsuleBtn} ${
                        filterValue === 'InStore' ? styles.active : ''
                    }`}
                    fontSize={12}
                    fontWeight={filterValue === 'InStore' ? 'normal' : 'light'}
                    onClick={() => setFilterValue('InStore')}
                    tabIndex={5}
                    ariaLabel="In store"
                    role="Button"
                    ariaDescription="You are viewing in store offers only"
                >
                    In store
                </Button>
                <Button
                    className={`${styles.capsuleBtn} ${
                        filterValue === 'Online' ? styles.active : ''
                    }`}
                    fontSize={12}
                    fontWeight={filterValue === 'Online' ? 'normal' : 'light'}
                    onClick={() => setFilterValue('Online')}
                    tabIndex={6}
                    ariaLabel="Online"
                    role="Button"
                    ariaDescription="You are viewing online offers only"
                >
                    Online
                </Button>
            </div>
        </>
    );
}

interface OfferTabProps {
    couponData?: any;
    handleAllMassOffers?: () => void;
    isHideShippingRewards?: boolean;
    toggleIndex?: (v: number, flag: boolean) => void;
    isMainRewardsPage?: boolean;
    startIndex?: number;
    shouldActivateAllOffers: boolean;
}

export const initialScrollToOffer: {
    offerId: string;
    isScroll: boolean;
} = {
    offerId: '',
    isScroll: false,
};

function OfferTab({
    couponData,
    handleAllMassOffers,
    toggleIndex,
    isMainRewardsPage = false,
    isHideShippingRewards = false,
    startIndex = 10,
    shouldActivateAllOffers,
}: OfferTabProps) {
    const offerRef = useRef<any>(null);
    const { query, push, replace } = useRouter();
    const setToastMessage = useSetAtom(toastAtom);
    const [scrollToOffer, setScrollToOffer] = useState(initialScrollToOffer);
    const { analyticsCustomEvent } = useEventsReporter();
    const { userInfo, isRewardShipperEnable } = useUserInfo();
    const [deeplinkActiveOffer, setDeeplinkActiveOffer] = useState(false);
    const [infoDrawerData, setinfoDrawerData] = useState(
        initialInfoDrawerState
    );
    const [showActivateAllButton, setShowActivateAllButton] = useState(true);

    const [offerFilters, setOfferFilters] = useState<{
        yourOffers: FilterValues;
        moreOffers: FilterValues;
    }>({
        yourOffers: 'All',
        moreOffers: 'All',
    });

    const { mutateAsync: handleOfferActivate } = useActivateOfferMutation();

    const {
        data: featuredOffers,
        refetch: refetchFeaturedLoyaltyOffers,
        isLoading: isFeaturedOfferLoading,
        isFetched: isFeaturedOfferFetched,
    } = useFeaturedLoyaltyOffersQuery({
        config: {
            refetchOnMount: false,
        },
    });

    const {
        data: targetedOffers,
        refetch: refetchTargetedLoyaltyOffers,
        isLoading: isTargetedOffersLoading,
        isFetched: isTargetedOffersFetched,
    } = useLoyaltyOffersQuery({
        offerType: 'TARGETED',
        config: {
            refetchOnMount: false,
        },
    });

    const {
        data: massOffers,
        refetch: refetchMassLoyaltyOffers,
        isLoading: isMassOffersLoading,
        isFetched: isMassOffersFetched,
    } = useLoyaltyOffersQuery({
        offerType: 'MASS',
        config: {
            refetchOnMount: false,
        },
    });

    const refetchLoyaltyOffers = () =>
        Promise.all([
            refetchFeaturedLoyaltyOffers(),
            refetchTargetedLoyaltyOffers(),
            refetchMassLoyaltyOffers(),
        ]);

    const handleCouponNavigation = (e: any) => {
        e.preventDefault();
        push({
            pathname: ROUTES.coupons,
            search: makeSearchString({
                couponNumber: couponData?.couponNumber,
                deepLink: false,
                tabIndex: 1,
            }),
        });
    };

    const filteredFeaturedOffers = useMemo(() => {
        if (featuredOffers?.offers && offerFilters.yourOffers !== 'All') {
            const filterTerm =
                offerFilters.yourOffers === 'Online' ? 'Dotcom' : 'Retail';
            return featuredOffers?.offers?.filter((offer) =>
                offer.channel.includes(filterTerm)
            );
        }
        return featuredOffers?.offers;
    }, [featuredOffers?.offers, offerFilters.yourOffers]);

    const filteredTargetedOffers = useMemo(() => {
        if (targetedOffers?.offers && offerFilters.yourOffers !== 'All') {
            const filterTerm =
                offerFilters.yourOffers === 'Online' ? 'Dotcom' : 'Retail';
            return targetedOffers?.offers?.filter((offer) =>
                offer.channel.includes(filterTerm)
            );
        }
        return targetedOffers?.offers;
    }, [targetedOffers?.offers, offerFilters.yourOffers]);

    const filteredMassOffers = useMemo(() => {
        if (massOffers?.offers && offerFilters.moreOffers !== 'All') {
            const filterTerm =
                offerFilters.moreOffers === 'Online' ? 'Dotcom' : 'Retail';
            return massOffers?.offers?.filter((offer) =>
                offer.channel.includes(filterTerm)
            );
        }
        return massOffers?.offers;
    }, [massOffers?.offers, offerFilters.moreOffers]);

    const dynamicTabIndx = Math.max(
        startIndex + (featuredOffers?.offers.length || 0)
    );

    // const handleViewAll = () => {
    //     handleviewAllTargetedOffers?.();
    //     analyticsCustomEvent(eventMetricsMap.easy_rewards, {
    //         click_text: 'view all',
    //         element_location: 'Offers - For You',
    //         event_rewards_number:
    //             userInfo?.rewardsNumber?.padStart(10, '0') || '',
    //     });
    // };

    // const handleViewMassAll = () => {
    //     handleAllMassOffers?.();
    //     analyticsCustomEvent(eventMetricsMap.easy_rewards, {
    //         click_text: 'view all',
    //         element_location: 'Offers - More Offers',
    //         event_rewards_number:
    //             userInfo?.rewardsNumber?.padStart(10, '0') || '',
    //     });
    // };

    const updateOfferActivationStatus = (
        offerId: string,
        offerCacheKey: 'TARGETED' | 'MASS' | 'FEATURED',
        newActivationStatus: boolean
    ) => {
        updateLoyaltyOfferCache({
            offerType: offerCacheKey,
            updater: (oldData) => {
                if (oldData) {
                    const newData: typeof oldData = {
                        ...oldData,
                    };
                    newData.offers.map((item) => {
                        if (item.offerid === offerId) {
                            item.activated = newActivationStatus;
                        }
                        return item;
                    });
                    return newData;
                }
                return {} as LoyaltyOfferResponse;
            },
        });
    };

    const activateOffer = (
        offerId: string,
        offerCacheKey: 'TARGETED' | 'MASS' | 'FEATURED'
    ) => {
        updateOfferActivationStatus(offerId, offerCacheKey, true);
        handleOfferActivate([offerId])
            .then((res) => {
                if (
                    !(
                        res?.data?.issuccess &&
                        isActivationSuccess([offerId], res.data.offers)
                    )
                ) {
                    updateOfferActivationStatus(offerId, offerCacheKey, false);
                }
            })
            .catch(() => {
                setScrollToOffer({
                    offerId,
                    isScroll: true,
                });
                updateOfferActivationStatus(offerId, offerCacheKey, false);
            });
    };

    const activeFeatureOffer = (findFeaturedOffers: any) => {
        handleOfferActivate([findFeaturedOffers?.offerid])
            .then((res) => {
                if (
                    res?.data?.issuccess &&
                    isActivationSuccess(
                        [findFeaturedOffers?.offerid],
                        res.data.offers
                    )
                ) {
                    analyticsCustomEvent(eventMetricsMap.easy_rewards, {
                        click_text: 'Activate',
                        element_location: 'Featured offers',
                        offer_description:
                            `${findFeaturedOffers.name} ${findFeaturedOffers?.offerdescription}`
                                ?.substring(0, 50)
                                ?.toLocaleLowerCase(),
                        coupon_number: findFeaturedOffers?.offerid,
                        event_rewards_number:
                            userInfo?.rewardsNumber?.padStart(10, '0') || '',
                    });
                    updateLoyaltyOfferCache({
                        offerType: 'FEATURED',
                        updater: (oldData) => {
                            if (oldData) {
                                const newData: typeof oldData = {
                                    ...oldData,
                                };
                                newData.offers.map((item) => {
                                    if (
                                        item.offerid ===
                                        findFeaturedOffers?.offerid
                                    ) {
                                        item.activated = true;
                                    }
                                    return item;
                                });
                                return newData;
                            }
                            return {} as LoyaltyOfferResponse;
                        },
                    });
                } else {
                    setToastMessage(
                        "This offer couldn't be activated, try again."
                    );
                }
            })
            .catch(() => {
                setToastMessage("This offer couldn't be activated, try again.");
            });
    };

    const activateAllOffers = () => {
        const targetedOfferIdsToActivate =
            targetedOffers?.offers
                ?.filter((item) => !item.activated)
                .map((item) => item.offerid) || [];
        const massOfferIdsToActivate =
            massOffers?.offers
                ?.filter((item) => !item.activated)
                .map((item) => item.offerid) || [];
        const featuredOfferIdsToActivate =
            featuredOffers?.offers
                ?.filter((item) => !item.activated)
                .map((item) => item.offerid) || [];
        const idsToActivate = [
            ...targetedOfferIdsToActivate,
            ...featuredOfferIdsToActivate,
            ...massOfferIdsToActivate,
        ];
        setShowActivateAllButton(false);
        ['MASS', 'TARGETED', 'FEATURED'].forEach((offerCacheKey) => {
            updateLoyaltyOfferCache({
                offerType: offerCacheKey as 'TARGETED' | 'MASS' | 'FEATURED',
                updater: (oldData) => {
                    if (oldData) {
                        const newData: typeof oldData = {
                            ...oldData,
                        };
                        newData.offers.map((item) => {
                            item.activated = true;
                            return item;
                        });
                        return newData;
                    }
                    return {} as LoyaltyOfferResponse;
                },
            });
        });
        handleOfferActivate(idsToActivate)
            .then((res) => {
                if (
                    !(
                        res?.data?.issuccess &&
                        isActivationSuccess(idsToActivate, res.data.offers)
                    )
                ) {
                    setShowActivateAllButton(true);
                    throw new Error(
                        "All offers couldn't be activated, try again."
                    );
                }
            })
            .catch(() => {
                setShowActivateAllButton(true);
                setToastMessage("All offers couldn't be activated, try again.");
            })
            .finally(() => {
                refetchLoyaltyOffers();
            });
    };

    useEffect(() => {
        setShowActivateAllButton(
            !!(
                targetedOffers?.offers?.some((offer) => !offer.activated) ||
                massOffers?.offers?.some((offer) => !offer.activated) ||
                featuredOffers?.offers?.some((offer) => !offer.activated)
            )
        );
    }, [targetedOffers, massOffers, featuredOffers]);

    const allOffersLoaded = useMemo(
        () =>
            !isFeaturedOfferLoading &&
            !isTargetedOffersLoading &&
            !isMassOffersLoading,
        [isFeaturedOfferLoading, isTargetedOffersLoading, isMassOffersLoading]
    );

    useEffect(() => {
        if (allOffersLoaded && shouldActivateAllOffers) {
            activateAllOffers();
            replace({});
        }
    }, [allOffersLoaded]);

    useEffect(() => {
        const isActivateOffer = localStorage.getItem('isActivateOffer');
        const findFeaturedOffers = featuredOffers?.offers?.find(
            (offerData: any) => offerData?.offerid === query?.offerId
        );
        const findMassOfferIndex = massOffers?.offers?.findIndex(
            (offerData: any) => offerData?.offerid === query?.offerId
        );
        const findAllMassOffer = massOffers?.offers?.find(
            (offerData: any) => offerData?.offerid === query?.offerId
        );
        const findTargetOfferIndex = targetedOffers?.offers?.findIndex(
            (offerData: any) => offerData?.offerid === query?.offerId
        );
        const findAllTargetOffer = targetedOffers?.offers?.find(
            (offerData: any) => offerData?.offerid === query?.offerId
        );
        const offerId =
            findAllMassOffer?.offerid || findAllTargetOffer?.offerid;
        const isTargeted = !!findAllTargetOffer?.offerid;
        if (isActivateOffer === 'false') {
            setScrollToOffer({
                offerId: '',
                isScroll: false,
            });
            if (offerId) {
                const offer = findAllTargetOffer?.offerid
                    ? findAllTargetOffer
                    : findAllMassOffer || null;
                if (findAllTargetOffer?.offerid) {
                    if (findTargetOfferIndex && findTargetOfferIndex > 3) {
                        // handleviewAllTargetedOffers?.();
                    }
                } else if (findMassOfferIndex && findMassOfferIndex > 3) {
                    handleAllMassOffers?.();
                }
                setTimeout(() => {
                    localStorage.setItem('isActivateOffer', 'true');
                    setinfoDrawerData({
                        offer,
                        isOpen: true,
                    });

                    setScrollToOffer({
                        offerId,
                        isScroll: true,
                    });
                }, 2000);
                if (
                    query.shouldActivate === 'true' &&
                    !offer?.activated &&
                    !deeplinkActiveOffer
                ) {
                    setDeeplinkActiveOffer(true);
                    setTimeout(() => {
                        // check isTargeted role here
                        activateOffer(offerId, 'TARGETED');
                    }, 2500);
                }
            } else if (
                !offerId &&
                !findFeaturedOffers?.offerid &&
                isMassOffersFetched &&
                isTargetedOffersFetched &&
                isFeaturedOfferFetched
            ) {
                localStorage.setItem('isActivateOffer', 'true');
                setToastMessage('This offer does not exist');
            }
        }
    }, [
        massOffers?.offers?.length,
        targetedOffers?.offers?.length,
        isMassOffersFetched,
        isTargetedOffersFetched,
        featuredOffers?.offers?.length,
        isFeaturedOfferFetched,
    ]);

    useEffect(() => {
        const findFeaturedOffers = featuredOffers?.offers?.find(
            (offerData: any) => offerData?.offerid === query?.offerId
        );
        if (findFeaturedOffers?.offerid) {
            const isActivateOffer = localStorage.getItem('isActivateOffer');
            if (isActivateOffer === 'false') {
                if (findFeaturedOffers?.offerid) {
                    setTimeout(() => {
                        localStorage.setItem('isActivateOffer', 'true');
                        setinfoDrawerData({
                            offer: findFeaturedOffers,
                            isOpen: true,
                        });
                    }, 1500);
                    if (query.shouldActivate === 'true') {
                        if (!findFeaturedOffers?.activated) {
                            setTimeout(() => {
                                activeFeatureOffer(findFeaturedOffers);
                            }, 2500);
                        }
                    }
                }
            }
        }
    }, [featuredOffers?.offers?.length, isFeaturedOfferFetched]);

    if (isTargetedOffersLoading || isMassOffersLoading) {
        return (
            <Player
                autoplay
                loop
                src={require('../../../assets/lotties/stapleRevealWordmark.json')}
                style={{ height: '100px' }}
            />
        );
    }

    if (!targetedOffers?.offers?.length && !massOffers?.offers?.length) {
        return (
            <Body weight="bold" size={18}>
                Offers are unavailable at this time, please check back later.
            </Body>
        );
    }

    return (
        <div ref={offerRef}>
            {allOffersLoaded && shouldActivateAllOffers && (
                <AllOffersActivationAnimation />
            )}
            <Box sx={{ borderBottom: 4, borderColor: 'divider' }}>
                <>
                    <section
                        className={mergeClasses(
                            styles.px4,
                            styles.listContainer
                        )}
                        id="your-offer-id"
                    >
                        <OfferListHeader
                            headerTitle="Your offers"
                            activateAllOffers={activateAllOffers}
                            showActivateAllButton={showActivateAllButton}
                            filterValue={offerFilters.yourOffers}
                            setFilterValue={(newValue: FilterValues) =>
                                setOfferFilters((oldFilterValues) => ({
                                    ...oldFilterValues,
                                    yourOffers: newValue,
                                }))
                            }
                        />
                        <OfferList
                            data={filteredFeaturedOffers}
                            isDataLoading={isTargetedOffersLoading}
                            activateOffer={handleOfferActivate}
                            infoDrawerData={infoDrawerData}
                            setinfoDrawerData={setinfoDrawerData}
                            scrollToOffer={scrollToOffer}
                            setScrollToOffer={setScrollToOffer}
                            offerCacheKey="FEATURED"
                            startIndex={dynamicTabIndx + 2}
                        />
                        <OfferList
                            data={filteredTargetedOffers}
                            isDataLoading={isTargetedOffersLoading}
                            activateOffer={handleOfferActivate}
                            infoDrawerData={infoDrawerData}
                            setinfoDrawerData={setinfoDrawerData}
                            scrollToOffer={scrollToOffer}
                            setScrollToOffer={setScrollToOffer}
                            offerCacheKey="TARGETED"
                            startIndex={dynamicTabIndx + 2}
                        />
                    </section>
                    {isRewardShipperEnable && !isHideShippingRewards ? (
                        <ShippingRewards noMargin />
                    ) : null}
                    <section
                        className={mergeClasses(
                            styles.px4,
                            styles.listContainer
                        )}
                    >
                        <OfferListHeader
                            headerTitle="More offers"
                            activateAllOffers={activateAllOffers}
                            showActivateAllButton={showActivateAllButton}
                            filterValue={offerFilters.moreOffers}
                            setFilterValue={(newValue: FilterValues) =>
                                setOfferFilters((oldFilterValues) => ({
                                    ...oldFilterValues,
                                    moreOffers: newValue,
                                }))
                            }
                        />
                        <OfferList
                            data={filteredMassOffers}
                            isDataLoading={isMassOffersLoading}
                            activateOffer={handleOfferActivate}
                            infoDrawerData={infoDrawerData}
                            setinfoDrawerData={setinfoDrawerData}
                            scrollToOffer={scrollToOffer}
                            setScrollToOffer={setScrollToOffer}
                            offerCacheKey="MASS"
                            isMainRewardsPage={isMainRewardsPage}
                            startIndex={dynamicTabIndx + 7}
                        />
                    </section>
                    {couponData?.couponInfo &&
                    Object?.keys?.(couponData?.couponInfo)?.length ? (
                        <section
                            className={mergeClasses(
                                styles.px4,
                                styles.listContainer
                            )}
                            style={{ marginBottom: '25px' }}
                        >
                            <div className={styles.offerHeading}>
                                <p
                                    className={styles.heading}
                                    tabIndex={dynamicTabIndx + 11}
                                >
                                    Available coupons
                                </p>
                            </div>

                            <Box
                                className={styles.CardBox}
                                onClick={handleCouponNavigation}
                                tabIndex={dynamicTabIndx + 12}
                            >
                                <div className={styles.CardDetailsSection}>
                                    <img
                                        className={styles.CardImg}
                                        src={couponData?.couponInfo?.imageURL}
                                        alt="product"
                                    />
                                    <div className={styles.CardTextSection}>
                                        <Heading
                                            textAlign={'textLeft'}
                                            size={20}
                                            type="h3"
                                            weight="bold"
                                            color="#CC0000"
                                            lineHeight={26}
                                            tabIndex={dynamicTabIndx + 13}
                                            ariaLabel={
                                                couponData?.couponInfo
                                                    ?.descriptionText1
                                            }
                                        >
                                            {
                                                couponData?.couponInfo
                                                    ?.descriptionText1
                                            }
                                        </Heading>
                                        <Body
                                            size={16}
                                            textAlign={'textLeft'}
                                            weight="light"
                                            color="Black"
                                            lineHeight={20}
                                            tabIndex={dynamicTabIndx + 14}
                                            ariaLabel={
                                                couponData?.couponInfo
                                                    ?.descriptionText2
                                            }
                                        >
                                            {
                                                couponData?.couponInfo
                                                    ?.descriptionText2
                                            }
                                        </Body>
                                    </div>
                                </div>
                                <div className={styles.BottomSection}>
                                    {couponData?.couponInfo?.expirationDate && (
                                        <Body
                                            size={12}
                                            textAlign={'textLeft'}
                                            weight="light"
                                            color="#585858"
                                        >
                                            {getExpDate(
                                                couponData?.couponInfo
                                                    ?.expirationDate,
                                                couponData?.couponInfo
                                                    ?.validForRetail &&
                                                    couponData?.couponInfo
                                                        ?.validForDotcom
                                                    ? ''
                                                    : couponData?.couponInfo
                                                          ?.validForRetail
                                                    ? 'retail'
                                                    : 'dotcom',
                                                'M/d/yy'
                                            )}
                                        </Body>
                                    )}
                                    <Button
                                        tabIndex={dynamicTabIndx + 16}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            toggleIndex?.(2, true);
                                            analyticsCustomEvent(
                                                eventMetricsMap.easy_rewards,
                                                {
                                                    click_text:
                                                        'see all coupons',
                                                    element_location:
                                                        'available coupons',
                                                    event_rewards_number:
                                                        userInfo?.rewardsNumber ||
                                                        '',
                                                }
                                            );
                                        }}
                                        className={styles.seeAllButton}
                                        fontSize={12}
                                        fontWeight="bold"
                                    >
                                        See all coupons
                                    </Button>
                                </div>
                            </Box>
                        </section>
                    ) : null}
                </>
            </Box>
        </div>
    );
}
export default OfferTab;
