import React, { useState } from 'react';
import {
    Button,
    ButtonBase,
    Typography,
    Collapse,
    makeStyles,
    ListItem,
    List,
    ListSubheader,
    Divider,
} from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import { StoreHours, Store } from 'src/services/types';
import {
    googleMapURL,
    storeCommunity,
    describeStoreHours,
    storeDistance,
} from 'src/lib/utils/store-utils';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import { formatPhoneNumber } from 'src/lib/utils/string-utils';
import AppLink from 'src/components/link';
import COLORS from 'src/lib/colors';
import clsx from 'clsx';
import NoStoreResults from 'src/components/store-search-modal/no-store-result';
import { Body } from '../UI';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 4,
        overflowY: 'auto',
    },
    mainStoreInfo: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'stretch',
    },
    mainStoreTitle: {
        background: COLORS.primaryWhite,
        width: '100%',
        fontSize: '1rem',
        margin: 0,
        color: COLORS.secondaryNeutralBlack,
        marginBottom: '-.5rem',
        fontFamily:'StaplesNormsMedium',
    },
    storeTitle: {
        background: '#f2f2f2',
        position: 'relative',
    },
    nearbyStoresTitle: {
        background: COLORS.primaryWhite,
        width: '100%',
        fontSize: '1rem',
        margin: 0,
        color: COLORS.secondaryNeutralBlack,
        paddingTop: '.75rem',
        marginBottom: '-.5rem',
        fontFamily:'StaplesNormsMedium',
    },
    nearbyTitle: {
        paddingLeft: 0,
    },
    mainStoreCommunityCTAs: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    mainStoreCommunity: {
        // fontSize: '1.25rem',
        // fontWeight: 'bold',
        letterSpacing: '0.04pt',
    },
    mainStoreCTAs: {
        marginLeft: 'auto',
        '& a': {
            marginRight: '.75rem',
        },
    },
    StoreCTAs: {
        position: 'absolute',
        right: '5px',
        bottom: '10px',
    },
    icon: {},
    smallInfoText: {
        fontSize: '1rem',
    },
    milesAwayText: {
        marginTop: '.1rem',
        fontSize: '1rem',
    },
    storeHours: {
        display: 'flex',
    },
    quantityText: {
        marginTop: '.1rem',
        fontSize: '1rem',
        fontWeight: 'bold',
    },
    expandIcon: {
        marginTop:'-10px',
        transition: 'transform .4s',
    },
    expandIconOpen: {
        transform: 'rotate(180deg)',
    },
    allHours: {
        display: 'flex',
        flexDirection: 'column',
    },
    storeHourDetail: {
        display: 'flex',
        fontSize: '1rem',
        width: '100%',
        maxWidth: '10rem',
        justifyContent: 'space-between',
        '& p': {
            paddingRight: '1rem',
        },
    },
    nearbyResult: {
        paddingLeft: 0,
    },
    nearbyStoreResult: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'flex-start',
        justifyItems: 'flex-start',
        paddingRight: 0,
        alignItems: 'baseline',
    },
    nearbyStoreResultCTAs: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginRight: '.75rem',
        marginBottom: '.25rem',
    },
    setAsMyStoreButton: {
        textTransform: 'none',
        fontSize: '1rem',
        color: COLORS.brandCyan,
        marginLeft: '-.5rem',
    },
    nearbyStoreCTAs: {
        display: 'flex',
        justifyContent: 'space-between',
        marginRight: '.25rem',
        '& a': {
            marginRight: '.75rem',
        },
    },

    nearbyStoreDivider: {
        width: '100%',
    },
    mainWrapper: {
        width: '100%',
        background: '#F2F2F2',
    },
});

function currentStoreHours(storeData: Store) {
    const { todaysHours } = describeStoreHours(storeData?.storeHours, storeData?.timezone);
    if (!todaysHours) {
        return '';
    }
    return todaysHours.formattedStoreHours;
}

type NearbyResultsProps = {
    nearbyStores: Store[];
    mainStore: Store;
    selectStoreCtaText: string;
    withInventory: boolean;
    onStoreSelected: (newStore: Store) => void;
    mainWrapper?: boolean;
};

function StoreHourInfo({
    storeHours,
    subId,
    ...rest
}: {
    subId: string;
    storeHours: StoreHours;
} & any) {
    return (
        <div id={`main-store-hours-${subId}-text`} {...rest}>
            <Typography>{storeHours?.dayShort}:</Typography>
            <Typography>{storeHours?.formattedStoreHours}</Typography>
        </div>
    );
}

/** The nearby results of a store in list form */
export default function NearbyResults({
    nearbyStores = [],
    mainStore,
    selectStoreCtaText,
    withInventory,
    onStoreSelected,
    mainWrapper,
}: NearbyResultsProps) {
    const classes = useStyles();
    const { analyticsCustomEvent } = useEventsReporter();
    const [storeHoursExpanded, setStoreHoursExpanded] = useState(false);
    function toggleStoreHours() {
        setStoreHoursExpanded((value) => !value);
    }
    return (
        <div className={classes.container}>
            <List component="div">
                <div className={`${mainWrapper ? classes.mainWrapper : ''}`}>
                    <ListSubheader component="p" className={`${classes.mainStoreTitle} ${mainWrapper ? classes.storeTitle : ''}`}>
                        {mainWrapper ? 'Your Store:' : 'My Store'}
                    </ListSubheader>
                    <ListItem component="div" className={classes.mainStoreInfo}>
                        <div className={classes.mainStoreCommunityCTAs}>
                            <Body size={20} weight='bold'
                                className={classes.mainStoreCommunity}
                            >
                                {storeCommunity(mainStore)}
                            </Body>
                            
                        </div>
                        <Typography
                            className={classes.smallInfoText}
                            id="main-store-address-text"
                        >
                            {mainStore?.address?.address_1}
                        </Typography>
                        <Typography
                            className={classes.smallInfoText}
                            id="main-store-city-text"
                        >
                            {mainStore?.address?.city}, {mainStore?.address?.region}{' '}
                            {mainStore?.address?.postal_code}
                        </Typography>
                        <Typography
                            className={classes.smallInfoText}
                            id="main-store-phonenumber-text"
                        >
                            Tel: {formatPhoneNumber(mainStore?.phoneNumber)}
                        </Typography>

                        <div className={classes.storeHours}>
                            <Typography id="main-store-hours-text">
                                Store Hours: {currentStoreHours(mainStore)}
                            </Typography>
                            <ButtonBase
                                aria-label='Store Hours'
                                onClick={toggleStoreHours}
                                aria-expanded={storeHoursExpanded}
                                id="toggle-main-store-hours-button"
                            >
                                <img
                                    src={require('../../assets/icons/iconCaret.svg')}
                                    alt="collapse icon"
                                    className={clsx(
                                        classes.expandIcon,
                                        storeHoursExpanded && classes.expandIconOpen
                                    )}
                                />
                            </ButtonBase>
                            <div className={`${classes.mainStoreCTAs} ${mainWrapper ? classes.StoreCTAs : ''}`}>
                                <AppLink
                                    href={`tel:${mainStore?.phoneNumber}`}
                                    id="main-store-telephone-link"
                                    aria-label='Call'
                                    role='button'
                                    onClick={() => {
                                        analyticsCustomEvent(
                                            eventMetricsMap.stores,
                                            {
                                                click_text: 'call',
                                                element_location: 'change location',
                                                store_details: `${mainStore?.address?.city}-${mainStore?.storeNumber}`,
                                            }
                                        );
                                        optimoveEvents(eventMetricsMap.scm_stores, {
                                            click_text: 'call',
                                            element_location: 'change location',
                                            store_details: `${mainStore?.address?.city}-${mainStore?.storeNumber}`,
                                        });
                                    }}
                                >
                                    <img
                                        src={require('../../assets/icons/iconPhone.svg')}
                                        alt="phone icon"
                                        className={classes.icon}
                                    />
                                </AppLink>
                                <AppLink
                                    href={googleMapURL(mainStore)}
                                    target="_blank"
                                    id="main-store-maps-link"
                                    role='button'
                                    aria-label='Map Bring up directions to your Staples store'
                                    onClick={() => {
                                        analyticsCustomEvent(
                                            eventMetricsMap.stores,
                                            {
                                                click_text: 'get directions',
                                                element_location:
                                                    'your store information',
                                                store_details: `${mainStore?.address?.city}-${mainStore?.storeNumber}`,
                                            }
                                        );
                                        optimoveEvents(eventMetricsMap.scm_stores, {
                                            click_text: 'get directions',
                                            element_location:
                                                'your store information',
                                            store_details: `${mainStore?.address?.city}-${mainStore?.storeNumber}`,
                                        });
                                    }}
                                >
                                    <img
                                        src={require('../../assets/icons/iconDirection.svg')}
                                        alt="maps icon"
                                        className={classes.icon}
                                    />
                                </AppLink>
                            </div>
                        </div>
                        {withInventory && (
                            <Typography
                                className={classes.quantityText}
                                id="main-store-quantity-text"
                            >
                                {mainStore?.onHandQuantity
                                    ? `${mainStore?.onHandQuantity} in stock`
                                    : 'Out of stock'}
                            </Typography>
                        )}
                        <Collapse
                            in={storeHoursExpanded}
                            className={classes.allHours}
                            classes={{ wrapperInner: classes.allHours }}
                        >
                            <StoreHourInfo
                                className={classes.storeHourDetail}
                                storeHours={mainStore?.storeHours?.monday}
                                subId="monday"
                            />
                            <StoreHourInfo
                                className={classes.storeHourDetail}
                                storeHours={mainStore?.storeHours?.tuesday}
                                subId="tuesday"
                            />
                            <StoreHourInfo
                                className={classes.storeHourDetail}
                                storeHours={mainStore?.storeHours?.wednesday}
                                subId="wednesday"
                            />
                            <StoreHourInfo
                                className={classes.storeHourDetail}
                                storeHours={mainStore?.storeHours?.thursday}
                                subId="thursday"
                            />
                            <StoreHourInfo
                                className={classes.storeHourDetail}
                                storeHours={mainStore?.storeHours?.friday}
                                subId="friday"
                            />
                            <StoreHourInfo
                                className={classes.storeHourDetail}
                                storeHours={mainStore?.storeHours?.saturday}
                                subId="saturday"
                            />
                            <StoreHourInfo
                                className={classes.storeHourDetail}
                                storeHours={mainStore?.storeHours?.sunday}
                                subId="sunday"
                            />
                        </Collapse>
                    </ListItem>
                </div>
                <Divider color={COLORS.fordGray} />
                <ListSubheader
                    component="p"
                    className={`${classes.nearbyStoresTitle} 
                    ${mainWrapper ? classes.nearbyTitle : ''}`}
                >
                    Nearby Stores
                </ListSubheader>
                {!nearbyStores?.length && (
                    <NoStoreResults subText="There are no nearby stores." />
                )}
                {nearbyStores.map((s: Store, indx: number) => (
                    <ListItem
                        key={s.id}
                        component="div"
                        className={`${classes.nearbyStoreResult} 
                        ${mainWrapper ? classes.nearbyResult : ''}`}
                        id={`nearby-item-row-${indx}`}
                    >
                        <Typography
                            className={classes.smallInfoText}
                            id={`nearby-item-row-${indx}-address-text`}
                        >
                            {s?.address?.address_1}
                        </Typography>
                        <Typography
                            className={classes.smallInfoText}
                            id={`nearby-item-row-${indx}-city-text`}
                        >
                            {s?.address?.city}, {s?.address?.region}{' '}
                            {s?.address?.postal_code}
                        </Typography>
                        <Typography
                            className={classes.smallInfoText}
                            id={`nearby-item-row-${indx}-phonenumber-text`}
                        >
                            Tel: {formatPhoneNumber(s?.phoneNumber)}
                        </Typography>
                        <Typography
                            className={classes.milesAwayText}
                            id={`nearby-item-row-${indx}-milesAway-text`}
                        >
                            {storeDistance(s)} miles away
                        </Typography>
                        {withInventory && (
                            <Typography
                                className={classes.quantityText}
                                id={`nearby-item-row-${indx}-quantity-text`}
                            >
                                {s?.onHandQuantity
                                    ? `${s?.onHandQuantity} in stock`
                                    : 'Out of stock'}
                            </Typography>
                        )}
                        <div className={classes.nearbyStoreResultCTAs}>
                            <Button
                                id={`nearby-item-row-${indx}-set-store-button`}
                                className={classes.setAsMyStoreButton}
                                onClick={() => onStoreSelected(s)}
                            >
                                {selectStoreCtaText}
                            </Button>
                            <div className={classes.nearbyStoreCTAs}>
                                <AppLink
                                    aria-label='Call'
                                    role='button'
                                    href={`tel:${s?.phoneNumber}`}
                                    id={`nearby-item-row-${indx}-telephone-link`}
                                    onClick={() => {
                                        analyticsCustomEvent(
                                            eventMetricsMap.stores,
                                            {
                                                click_text: 'call',
                                                element_location:
                                                    'change location',
                                                store_details: `${mainStore?.address?.city}-${mainStore?.storeNumber}`,
                                            }
                                        );
                                        optimoveEvents(
                                            eventMetricsMap.scm_stores,
                                            {
                                                click_text: 'call',
                                                element_location:
                                                    'change location',
                                                store_details: `${mainStore?.address?.city}-${mainStore?.storeNumber}`,
                                            }
                                        );
                                    }}
                                >
                                    <img
                                        src={require('../../assets/icons/iconPhone.svg')}
                                        alt="phone icon"
                                        className={classes.icon}
                                    />
                                </AppLink>
                                <AppLink
                                    role='button'
                                    aria-label='Map Bring up directions to your Staples store'
                                    href={googleMapURL(s)}
                                    target="_blank"
                                    id={`nearby-item-row-${indx}-map-link`}
                                    onClick={() => {
                                        analyticsCustomEvent(
                                            eventMetricsMap.stores,
                                            {
                                                click_text: 'get directions',
                                                element_location:
                                                    'your store information',
                                                store_details: `${mainStore?.address?.city}-${mainStore?.storeNumber}`,
                                            }
                                        );
                                        optimoveEvents(
                                            eventMetricsMap.scm_stores,
                                            {
                                                click_text: 'get directions',
                                                element_location:
                                                    'your store information',
                                                store_details: `${mainStore?.address?.city}-${mainStore?.storeNumber}`,
                                            }
                                        );
                                    }}
                                >
                                    <img
                                        src={require('../../assets/icons/iconDirection.svg')}
                                        alt="maps icon"
                                        className={classes.icon}
                                    />
                                </AppLink>
                            </div>
                        </div>
                        <Divider className={classes.nearbyStoreDivider} />
                    </ListItem>
                ))}
            </List>
        </div>
    );
}
