import React from 'react';
import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
import COLORS from 'src/lib/colors';

// eslint-disable-next-line @typescript-eslint/ban-types
type LoadingButtonProps = ButtonProps & {
  loading?: boolean;
  ariaLabel?: string;
};

/**
 * A button with a loading state
 * Loading also disables the button
 */
export default function LoadingButton(props: LoadingButtonProps) {
  const { children, loading, disabled, ariaLabel, ...rest } = props;
  return (
    <Button disabled={loading || disabled} {...rest} aria-label={ariaLabel}>
      {loading ? (
        <CircularProgress size="1rem" style={{ color: COLORS.textBlack }} />
      ) : (
        children
      )}
    </Button>
  );
}
