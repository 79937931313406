import React, { useEffect } from 'react';
import usePlacesAutocompleteService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { IconPlaceMarker } from 'src/components/Icons';
import { Button } from 'src/components/UI';
import styles from './styles.module.scss';

interface AutoCompleteAddressProps {
    input: string;
    handleOnPlaceSelected: (
        placeDetails: google.maps.places.PlaceResult | null
    ) => void;
    showSuggestions: boolean;
    setShowSuggestions: React.Dispatch<React.SetStateAction<boolean>>;
}

function AutoCompleteAddress({
    input,
    handleOnPlaceSelected,
    showSuggestions,
    setShowSuggestions,
}: AutoCompleteAddressProps) {
    const { placesService, placePredictions, getPlacePredictions } =
        usePlacesAutocompleteService({
            apiKey: process.env.REACT_APP_PUBLIC_GOOGLE_API_KEY,
            options: {
                componentRestrictions: { country: ['us'] },
                input,
                types: ['address'],
            },
        });

    useEffect(() => {
        getPlacePredictions({ input });
    }, [input]);

    return showSuggestions ? (
        <ul
            style={{
                listStyle: 'none',
                display: 'flex',
                flexDirection: 'column',
                rowGap: '20px',
                padding: '0 12px',
            }}
        >
            {placePredictions.map((place) => (
                <li
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: '16px',
                    }}
                    key={place.place_id}
                >
                    <IconPlaceMarker />
                    <Button
                        variant="text"
                        color="textBlack"
                        className={styles.autoCompleteAddressButton}
                        onClick={() => {
                            placesService?.getDetails(
                                {
                                    placeId: place.place_id,
                                },
                                handleOnPlaceSelected
                            );
                            setShowSuggestions(false);
                        }}
                    >
                        {place.description}
                    </Button>
                </li>
            ))}
        </ul>
    ) : null;
}

export default AutoCompleteAddress;
