import React from 'react';

export const MarkerIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="14"
        viewBox="0 0 11 14"
        fill="none"
    >
        <path
            d="M5.07028 0C2.27451 0 0 2.27451 0 5.07025C0 8.53985 4.5374 13.6334 4.73058 13.8486C4.91204 14.0507 5.22884 14.0503 5.40997 13.8486C5.60315 13.6334 10.1406 8.53985 10.1406 5.07025C10.1405 2.27451 7.86601 0 5.07028 0ZM5.07028 7.62123C3.66366 7.62123 2.51932 6.47687 2.51932 5.07025C2.51932 3.66363 3.66368 2.51929 5.07028 2.51929C6.47687 2.51929 7.6212 3.66366 7.6212 5.07028C7.6212 6.47689 6.47687 7.62123 5.07028 7.62123Z"
            fill="#231F20"
        />
    </svg>
);
export const DirectionIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.0243 5.92579L15.9189 25.4023C15.286 26.75 13.54 26.4453 13.54 25.0039L13.5165 15.8399C13.5165 15.6992 13.4579 15.6406 13.3173 15.6406L4.10636 15.6055C2.65324 15.6055 2.37199 13.8711 3.62589 13.2852L23.1962 4.17969C24.5439 3.54688 25.6923 4.48438 25.0243 5.92579ZM14.911 14.9375L14.8759 24.0547C14.8759 24.1484 14.9931 24.1836 15.0282 24.0781L23.5478 5.79688C23.6181 5.64454 23.5126 5.55079 23.372 5.6211L5.04386 14.1172C4.96183 14.1523 4.97355 14.2578 5.0673 14.2578L14.1728 14.2227C14.6532 14.2227 14.911 14.5156 14.911 14.9375Z"
            fill="#231F20"
        />
    </svg>
);
export const CallReceverIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.1728 23.8906C22.9892 25.168 21.6181 25.6953 19.7431 25.6953C16.4735 25.6953 12.5243 23.5391 8.91495 19.9414C5.29385 16.3203 3.16104 12.3594 3.16104 9.0664C3.16104 7.21484 3.7001 5.85546 4.96573 4.68359C5.00504 4.64989 5.04166 4.6162 5.07687 4.5838C5.11515 4.54858 5.15177 4.51488 5.18839 4.48437C5.93839 3.78124 6.72354 3.42968 7.43839 3.4414C8.2001 3.46484 8.90323 3.87499 9.5126 4.76562L11.8564 8.16406C12.5243 9.12499 12.6298 10.2617 11.6337 11.2812L10.7899 12.1367C10.5321 12.3945 10.4853 12.6758 10.661 12.9922C11.1298 13.8125 11.9032 14.6914 12.9931 15.7812C13.9892 16.7773 15.2899 17.8437 15.8642 18.1953C16.1806 18.3711 16.4618 18.3242 16.7196 18.0664L17.5751 17.2226C18.5946 16.2266 19.7314 16.332 20.6923 17L24.0907 19.3437C24.9814 19.9531 25.4149 20.6562 25.4149 21.418C25.4149 22.1328 25.0751 22.9062 24.372 23.668C24.3415 23.7046 24.3078 23.7412 24.2726 23.7795C24.2401 23.8147 24.2065 23.8513 24.1728 23.8906ZM9.95792 18.9805C13.0282 22.0625 16.7665 24.3125 19.7548 24.2773C21.079 24.2656 22.2392 23.8203 23.1181 22.8711C23.1524 22.8196 23.1804 22.787 23.216 22.7456C23.229 22.7305 23.243 22.7141 23.2587 22.6953C23.6571 22.2617 23.8564 21.8281 23.8564 21.4414C23.8564 21.0664 23.704 20.75 23.3173 20.5039L19.9306 18.2305C19.497 17.9375 19.0048 17.8906 18.5243 18.3711L17.5751 19.332C16.872 20.0352 15.9696 20.0117 15.2665 19.5312C14.4462 18.9687 13.0985 17.8203 12.0321 16.7422C10.9774 15.6758 9.8876 14.4101 9.3251 13.5898C8.84464 12.8867 8.8212 11.9844 9.52432 11.2812L10.4853 10.332C10.9657 9.85156 10.9189 9.35937 10.6259 8.92578L8.35245 5.53906C8.10635 5.15234 7.78995 4.99999 7.41495 4.99999C7.03995 4.99999 6.59464 5.19921 6.16104 5.59765L6.04906 5.6914L5.98526 5.73828C5.03604 6.61718 4.59073 7.76562 4.57901 9.0664C4.54385 12.0664 6.86417 15.8984 9.95792 18.9805Z"
            fill="#231F20"
        />
    </svg>
);
export const StoreIcon = () => (
    <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M21.3125 20.625H20.625V11.6875C20.625 11.308 20.317 11 19.9375 11C19.558 11 19.25 11.308 19.25 11.6875V20.625H14.4375V15.675C14.4375 14.7565 14.08 13.8935 13.431 13.2445C12.782 12.5955 11.9185 12.2375 11 12.2375C10.0815 12.2375 9.218 12.595 8.56955 13.2445C7.92 13.8941 7.5625 14.7571 7.5625 15.675V20.625H2.75V11.6875C2.75 11.308 2.442 11 2.0625 11C1.683 11 1.375 11.308 1.375 11.6875V20.625H0.6875C0.308 20.625 0 20.933 0 21.3125C0 21.692 0.308 22 0.6875 22H21.3125C21.692 22 22 21.692 22 21.3125C22 20.933 21.692 20.625 21.3125 20.625ZM8.9375 20.625V15.675C8.9375 15.1239 9.152 14.6064 9.54195 14.217C10.3208 13.4371 11.6798 13.4365 12.4586 14.217C12.8486 14.6058 13.0631 15.1234 13.0631 15.6745V20.6245H8.93805L8.9375 20.625Z"
            fill="#231F20"
        />
        <path
            d="M3.2681 9.9935C4.10575 9.9935 4.9027 9.67725 5.5121 9.10305C5.6122 9.009 5.70515 8.9089 5.7915 8.80495C5.8894 8.93805 5.99775 9.064 6.11655 9.1828C6.7331 9.79935 7.5526 10.1387 8.42435 10.1387C9.2961 10.1387 10.1156 9.79935 10.7322 9.1828C10.851 9.064 10.9593 8.93805 11.0567 8.80495C11.143 8.90945 11.2365 9.009 11.3361 9.10305C11.9455 9.67725 12.7424 9.9935 13.5801 9.9935C14.4177 9.9935 15.2152 9.67725 15.8246 9.10305C15.9247 9.009 16.0182 8.90945 16.104 8.80495C16.2019 8.9375 16.3103 9.064 16.4291 9.1828C17.0456 9.7988 17.8651 10.1382 18.7363 10.1382C19.6075 10.1382 20.4276 9.7988 21.0436 9.1828C21.6601 8.56625 21.9995 7.74675 21.9995 6.875C21.9995 6.82495 21.994 6.7749 21.983 6.72595L20.7284 1.0824C20.6618 0.77825 20.4919 0.5027 20.2482 0.30525C20.0057 0.10945 19.701 0.0011 19.3875 0H2.6103C2.299 0.0011 1.9932 0.10945 1.7512 0.3058C1.5092 0.50105 1.33925 0.7766 1.2727 1.0791L0.0165 6.72595C0.00275 6.78865 -0.00275 6.85245 0.00165 6.91625C0.05115 7.75225 0.41415 8.52885 1.0241 9.1036C1.6335 9.6778 2.43045 9.99405 3.26865 9.99405L3.2681 9.9935ZM2.6125 1.375L19.3859 1.37775L20.6239 6.94485C20.6063 7.42335 20.4116 7.86995 20.0717 8.2104C19.3584 8.92375 18.1143 8.92375 17.4015 8.2104C17.0456 7.854 16.8487 7.37935 16.8487 6.875C16.8487 6.5032 16.5534 6.19905 16.1821 6.1875H16.1607C15.7987 6.1875 15.4968 6.46965 15.4748 6.8332C15.4457 7.3183 15.235 7.7693 14.8819 8.10205C14.1752 8.767 12.9866 8.76755 12.2799 8.10205C11.9262 7.76875 11.7161 7.3183 11.687 6.83375C11.6644 6.46305 11.3548 6.16935 10.9802 6.1875C10.6084 6.1985 10.3131 6.5032 10.3131 6.875C10.3131 7.37935 10.1167 7.85345 9.7603 8.2104C9.04695 8.9232 7.8023 8.9232 7.09005 8.2104C6.7331 7.85345 6.53675 7.37935 6.53675 6.875C6.53675 6.5032 6.2414 6.19905 5.8696 6.1875C5.4791 6.1721 5.18485 6.46305 5.16285 6.83375C5.1337 7.3183 4.92305 7.76875 4.5694 8.10205C3.8632 8.76755 2.67355 8.76755 1.96735 8.10205C1.63515 7.7891 1.42945 7.3733 1.3816 6.9223L2.6125 1.375Z"
            fill="#231F20"
        />
    </svg>
);
