/* eslint-disable max-len */
import React from 'react';

function IconLiveChat() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.25 0H21.75C22.9926 0 24 1.0073 24 2.24987V17.249C24 18.4916 22.9926 19.4989 21.75 19.4989H11.5L5.7 23.8486C5.23466 24.1976 4.58228 23.9057 4.50714 23.3549L4.5 23.2487V19.4989H2.25C1.05914 19.4989 0.0843551 18.5738 0.00519081 17.4031L0 17.249V2.24987C0 1.0073 1.00736 0 2.25 0ZM21.75 1.49992H2.25C1.83579 1.49992 1.5 1.83568 1.5 2.24987V17.249C1.5 17.6632 1.83579 17.999 2.25 17.999H5.25C5.66421 17.999 6 18.3347 6 18.7489V21.7488L10.8 18.149C10.9039 18.0711 11.0257 18.0218 11.1533 18.0052L11.25 17.999H21.75C22.1642 17.999 22.5 17.6632 22.5 17.249V2.24987C22.5 1.83568 22.1642 1.49992 21.75 1.49992ZM7.62255 9.0066L7.49997 9L7.37739 9.0066C6.81373 9.06768 6.37497 9.5451 6.37497 10.125C6.37497 10.7463 6.87865 11.25 7.49997 11.25C8.12129 11.25 8.62497 10.7463 8.62497 10.125C8.62497 9.5451 8.18621 9.06768 7.62255 9.0066ZM12.1226 9.0066L12 9L11.8774 9.0066C11.3137 9.06768 10.875 9.5451 10.875 10.125C10.875 10.7463 11.3787 11.25 12 11.25C12.6213 11.25 13.125 10.7463 13.125 10.125C13.125 9.5451 12.6862 9.06768 12.1226 9.0066ZM16.6226 9.0066L16.5 9L16.3774 9.0066C15.8137 9.06768 15.375 9.5451 15.375 10.125C15.375 10.7463 15.8787 11.25 16.5 11.25C17.1213 11.25 17.625 10.7463 17.625 10.125C17.625 9.5451 17.1862 9.06768 16.6226 9.0066Z" fill="black" />
        </svg>
    );
}

export default IconLiveChat;
