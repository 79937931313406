/* eslint-disable max-len */
import React from 'react';

const IconLocation = () => (
    <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.0244 5.92576L15.9189 25.4023C15.2861 26.75 13.54 26.4453 13.54 25.0039L13.5166 15.8398C13.5166 15.6992 13.458 15.6406 13.3173 15.6406L4.1064 15.6054C2.65327 15.6054 2.37202 13.8711 3.62593 13.2851L23.1962 4.17966C24.5439 3.54685 25.6923 4.48435 25.0244 5.92576ZM14.9111 14.9375L14.8759 24.0547C14.8759 24.1484 14.9931 24.1836 15.0283 24.0781L23.5478 5.79685C23.6181 5.64451 23.5126 5.55076 23.372 5.62107L5.0439 14.1172C4.96186 14.1523 4.97358 14.2578 5.06733 14.2578L14.1728 14.2226C14.6533 14.2226 14.9111 14.5156 14.9111 14.9375Z"
            fill="#086DD2"
        />
    </svg>
);

export default IconLocation;
