import { Box, Button, ButtonBase, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { getShipmentDrawerMessage } from 'src/lib/api-client/staples-home';
import FullWidthCard from 'src/components/cards/full-width-card';
import { IconCornerSplash } from 'src/components/Icons';
import { eventMetricsMap, useEventsReporter, useRouter } from 'src/hooks';
import { Body } from 'src/components/UI';
import useConfigInfo from 'src/hooks/useConfigInfo';
import styles from './style.module.scss';
import chevronBlue from '../../assets/icons/iconChevronBlue.svg';
import ShippingRewardsInfoDialog from '../shipping-services/ShippingRewardsInfoDialog';
import IconCornerSplashStore from '../Icons/IconCornerSplashStore';

function ShippingRewardsStore({
    noMargin,
    noBottomMargin,
    isBorder,
    hideBtn,
}: {
    noMargin?: boolean;
    noBottomMargin?: boolean;
    isBorder?: boolean;
    hideBtn?: boolean;
}) {
    const { shortdesc: shippingConfigPoints } = useConfigInfo(
        'ShippingOfferPoints'
    );
    const [openInfoDialog, setOpenInfoDialog] = useState(false);
    const router = useRouter();
    const { analyticsCustomEvent } = useEventsReporter();
    const handleOpen = () => {
        setOpenInfoDialog(true);
        analyticsCustomEvent(eventMetricsMap.easy_rewards, {
            click_text: `${shippingConfigPoints} points when you ship a package`,
            element_location:
                router.pathname === '/wallet'
                    ? 'banner offers - easy rewards'
                    : 'banner offers - stores page',
        });
    };

    const handleClose = () => {
        setOpenInfoDialog(false);
    };

    const { data, isFetching } = useQuery(
        ['shipmentDrawerMessage', 'PWA'],
        () => getShipmentDrawerMessage('PWA'),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
        }
    );
    console.log('ShippingServicesIntro', data);

    const styleFn = () => {
        if (!noMargin) {
            if (noBottomMargin) {
                return styles.noBottomMargin;
            }
            return styles.rewardingShipping;
        }
        return styles.rewardingShippingFullWidth;
    };
    return (
        <>
            <FullWidthCard
                noPadding
                hasManualShadow={false}
                id="shipping-rewards-container"
                className={styleFn()}
                style={{
                    border: '1px solid #F2F2F2',
                    borderRadius: '15px 15px 0px 0px',
                }}
                elevation={0}
                onClick={handleOpen}
            >
                <div className={styles.cardHeader}>
                    <div className={styles.tileAdorment} aria-hidden="true">
                        <IconCornerSplashStore />
                    </div>

                    <div className={styles.description}>
                        <Box>
                            <Body
                                size={26}
                                weight="bold"
                                lineHeight={32}
                                color="textPurple"
                            >
                                EARN {shippingConfigPoints} POINTS
                            </Body>
                            <Body size={14} weight="light" lineHeight={18}>
                                when you ship or drop off a package
                            </Body>
                            <Body size={14} weight="light" lineHeight={18}>
                                in store.{' '}
                                <Body
                                    size={14}
                                    weight="light"
                                    className={styles.learnMore}
                                >
                                    Learn more
                                </Body>
                            </Body>
                        </Box>
                    </div>
                </div>
            </FullWidthCard>
            <ShippingRewardsInfoDialog
                points={shippingConfigPoints}
                onClose={handleClose}
                onOpen={handleOpen}
                open={openInfoDialog}
                messagesData={data}
                hideBtn={hideBtn}
            />
        </>
    );
}

export default ShippingRewardsStore;
