/* eslint-disable max-len */
import React from 'react';
import { Box } from '@material-ui/core';
import FullWidthCard from 'src/components/cards/full-width-card';
import { Button, Heading, Body } from 'src/components/UI';
import { useRouter } from 'src/hooks';
import { IconShield } from 'src/components/Icons';
import ROUTES from 'src/lib/routes';
import styles from './styles.module.scss';

function MissingInfoConfirmationScreen() {
    const { push } = useRouter();
    return (
        <Box className={styles.screen} style={{ justifyContent: 'flex-start' }}>
            <div className={styles.headingContainer}>
                <Heading
                    type="h3"
                    size={26}
                    weight="bold"
                    textAlign="textLeft"
                    color="textWhite"
                >
                    First, we just need a little more info
                </Heading>
            </div>
            <FullWidthCard
                cardContainerClassName={styles.formContainer}
                noPadding
            >
                <div className={styles.form}>
                    <IconShield />
                    <Body weight="light">
                        On the next screen, please fill out any missing
                        information. By updating your account, we'll be able to
                        help keep your profile details secure.
                    </Body>
                    <Button
                        variant="contained-rounded"
                        bgColor="bgBlack"
                        onClick={() => {
                            push({
                                pathname: ROUTES.userProfile,
                                state: {
                                    showStaplesAccountPage: true,
                                },
                            });
                        }}
                    >
                        Continue
                    </Button>
                    <Button
                        variant="text"  
                        onClick={() => {
                            push(ROUTES.home);
                        }}
                    >
                        <Body textAlign='textCenter' size={14} color="textLinkBlue" weight="normal">
                            I'll do this later
                        </Body>
                    </Button>
                </div>
            </FullWidthCard>
            <div className={styles.bottomContainer} />
        </Box>
    );
}

export default MissingInfoConfirmationScreen;
