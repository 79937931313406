/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
import React, {
    useContext,
    useCallback,
    useState,
    useEffect,
    useRef,
    useMemo,
} from 'react';
import { motion } from 'framer-motion';
import { ButtonBase, makeStyles, Typography } from '@material-ui/core';
import ROUTES from 'src/lib/routes';
import useRouter from 'src/hooks/useRouter';
import { IconBack } from 'src/components/Icons';
import ShippingInfoContext from 'src/lib/contexts/shipping-info-context';
import COLORS from 'src/lib/colors';
import CreateShippingSenderInformation from './creating-shipping-sender-information';
import CreateShippingRecipientInformation from './creating-shipping-recipient-information';
import CreateShippingPackageInformation from './creating-shipping-package-information';
import CreateShippingReviewInformation from './creating-shipping-review-information';
import CreateShippingQRInformation from './creating-shipping-QR-information';

const useStyles = makeStyles({
    shippingServicesContainer: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
        height: '100vh',
        background: COLORS.primaryWhite,
    },
    CreateShipingHeader: {
        display: 'flex',
        // flexDirection: (isFixedTitle) => (isFixedTitle ? 'row' : 'column'),
        background: '#ffffff',
        gap: '36px',
        position: 'sticky',
        zIndex: 1,
        width: '100%',
        // alignItems: (isFixedTitle) => (isFixedTitle ? 'center' : 'flex-start'),
        // justifyContent: (isFixedTitle) =>
        //     isFixedTitle ? 'flex-start' : 'space-between',
        // padding: (isFixedTitle) => (isFixedTitle ? '20px 24px' : '24px 24px'),
        // borderBottom: (isFixedTitle) =>
        //     isFixedTitle ? '1px solid #F2F2F2' : 'none',
    },
    fixedHeaderTitle: {
        borderBottom: `1px solid ${COLORS.whiteSmoke}`,
    },
    startCreate: {
        height: '30px',
        width: '166px',
        borderRadius: '20px',
        background: '#CC0000',
        marginBottom: '24px',
        '& span': {
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: '11.8px',
            color: '#FFFFFF',
            textAlign: 'center',
        },
    },
    CreateShipingHeaderImgTextAlign: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: '100%',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    labelCreate: {
        fontSize: (isFixedTitle) => (isFixedTitle ? '20px' : '26px'),
        fontWeight: 700,
        lineHeight: '25.96px',
        color: '#000000',
        letterSpacing: '0px',
    },
    headerSubTitle: {
        display: (isFixedTitle) => (isFixedTitle ? 'none' : 'block'),
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '24px',
        letterSpacing: '-0.6px',
    },
    backIconAlign: {
        position: 'absolute',
        left: '20px',
    },
    shippingServicesMainSubContainer: {
        height: '100%',
        overflow: 'auto',
        backgroundColor: 'white',
    },
    shippingServicesQRInfoSubContainer: {
        height: '100vh',
        overflow: 'auto',
        background: '#ffffff',
    },
});

const CreateShippingContainer = () => {
    const { history, query } = useRouter();
    const [senderInfo, setSenderInfo] = useState({});
    const [recipientInfo, setRecipientInfo] = useState({});
    const [packageInfo, setPackageInfo] = useState({});
    const [qrCodeValue, setQrCodeValue] = useState('');
    const { step } = query;
    const [isFixedTitle, setIsFixedTitle] = useState(false);
    const classes = useStyles(isFixedTitle);
    const containerRef = useRef<HTMLDivElement>(null);

    const headerText = useMemo(() => {
        switch (step) {
            case 'createShipmentSenderInfo':
                return {
                    mainHeader: 'Create a shipment',
                    subHeader: 'Let’s start with your name and address.',
                };
            case 'CreateShippingRecipientInformation':
                return {
                    mainHeader: 'Next, where is the shipment headed?',
                };
            case 'CreateShippingReviewInformation': {
                return {
                    mainHeader: 'Review and finalize',
                    subHeader:
                        'Check the info to make sure this shipment is correct.',
                };
            }
            case 'CreateShippingPackageInformation':
                return isFixedTitle
                    ? {
                          mainHeader: 'Package contents',
                      }
                    : {
                          mainHeader:
                              'What are the contents of your package(s)?',
                      };
            default:
                return null;
        }
    }, [step, isFixedTitle]);

    const ComponentBasedOnRoute = useCallback(() => {
        switch (step) {
            case 'createShipmentSenderInfo':
                return (
                    <CreateShippingSenderInformation
                        setSenderInfo={setSenderInfo}
                        senderInfo={senderInfo}
                    />
                );
            case 'CreateShippingRecipientInformation':
                return (
                    <CreateShippingRecipientInformation
                        senderInfo={senderInfo}
                        setSenderInfo={setSenderInfo}
                        setRecipientInfo={setRecipientInfo}
                        recipientInfo={recipientInfo}
                    />
                );
            case 'CreateShippingPackageInformation':
                return (
                    <CreateShippingPackageInformation
                        packageInfo={packageInfo}
                        setPackageInfo={setPackageInfo}
                    />
                );
            case 'CreateShippingReviewInformation':
                return (
                    <CreateShippingReviewInformation
                        senderInfo={senderInfo}
                        recipientInfo={recipientInfo}
                        packageInfo={packageInfo}
                        setQrCodeValue={setQrCodeValue}
                    />
                );
            case 'CreateShippingQRInformation':
                return (
                    <CreateShippingQRInformation qrCodeValue={qrCodeValue} />
                );
            default:
                return null;
        }
    }, [step]);

    // THIS IS CAUSING ISSUES. SHOULD BE FIXED ON DESIGN END
    // useEffect(() => {
    //     const handleScroll = () => {
    //         if (
    //             containerRef.current?.scrollTop &&
    //             containerRef.current?.scrollTop >= 155
    //         ) {
    //             setIsFixedTitle(true);
    //         } else {
    //             setIsFixedTitle(false);
    //         }
    //     };
    //     containerRef.current?.addEventListener('scroll', handleScroll);
    //     return () => {
    //         containerRef.current?.removeEventListener('scroll', handleScroll);
    //     };
    // }, [containerRef.current]);

    const goBack = () => {
        let search;
        switch (step) {
            case 'createShipmentSenderInfo':
                search = '';
                break;
            case 'CreateShippingRecipientInformation':
                search = 'createShipmentSenderInfo';
                break;
            case 'CreateShippingPackageInformation':
                search = 'CreateShippingRecipientInformation';
                break;
            case 'CreateShippingReviewInformation':
                search = 'CreateShippingPackageInformation';
                break;
            case 'CreateShippingQRInformation':
                search = 'CreateShippingReviewInformation';
                break;
            default:
                search = '';
        }
        if (search) {
            history.push({
                pathname: ROUTES.createShippingInformation,
                search: search ? `step=${search}` : '',
            });
        } else {
            const path = (history?.location?.state as { path?: string })?.path;
            path
                ? history.push({
                      pathname: `${ROUTES.shippingServices}`,
                      search: `?backpath=${path}`,
                  })
                : history.push({ pathname: ROUTES.shippingServices });
        }
    };

    return (
        <div className={classes.shippingServicesContainer}>
            {step !== 'CreateShippingQRInformation' && (
                <motion.div
                    layout
                    style={{
                        flexDirection: isFixedTitle ? 'row' : 'column',
                        alignItems: isFixedTitle ? 'center' : 'flex-start',
                        justifyContent: isFixedTitle
                            ? 'flex-start'
                            : 'space-between',
                        padding: isFixedTitle ? '20px 24px' : '24px 24px',
                        borderBottom: isFixedTitle
                            ? '1px solid #F2F2F2'
                            : 'none',
                    }}
                    className={classes.CreateShipingHeader}
                >
                    <ButtonBase onClick={goBack}>
                        <IconBack />
                    </ButtonBase>
                    <motion.div layout>
                        <Typography className={classes.labelCreate}>
                            {headerText?.mainHeader}
                        </Typography>
                        {headerText?.subHeader && (
                            <Typography className={classes.headerSubTitle}>
                                {headerText.subHeader}
                            </Typography>
                        )}
                    </motion.div>
                </motion.div>
            )}
            <div
                ref={containerRef}
                className={
                    step !== 'CreateShippingQRInformation'
                        ? classes.shippingServicesMainSubContainer
                        : classes.shippingServicesQRInfoSubContainer
                }
            >
                <ComponentBasedOnRoute />
            </div>
        </div>
    );
};

export default CreateShippingContainer;
