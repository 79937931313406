import React from 'react';
import {
    Box,
    Button as ButtonMui,
    ButtonBase,
    Dialog,
    Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { Body, Heading, Button } from 'src/components/UI';
import { useShipmentTracking } from 'src/lib/contexts/ShipmentTrackingContext';
import COLORS from 'src/lib/colors';
import { useUserInfo } from 'src/hooks';
import useStyles from '../styles/shipmentServicesStyles';
import scanModalTopImg from '../../../assets/screenshots/scanModal.png';

interface ScanModalProps {
    handleSavedLabelShipment: (value: string) => void;
    handleScan: (value: string) => void;
}

function ScanModal({ handleScan, handleSavedLabelShipment }: ScanModalProps) {
    const classes = useStyles();
    const { isRewardShipperEnable } = useUserInfo();
    const { handleClose, scanMessage, isScanCompleted, trackingError } =
        useShipmentTracking();

    return (
        <>
            <Dialog
                open={isScanCompleted}
                classes={{
                    paper: classes.paper,
                }}
            >
                {!trackingError && isRewardShipperEnable && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                        }}
                    >
                        <img src={scanModalTopImg} alt="img" />
                    </Box>
                )}
                <div className={classes.closeButton}>
                    <ButtonMui
                        onClick={handleClose}
                        className={classes.closeIcon}
                        id="close-button"
                    >
                        <img
                            src={require('../../../assets/icons/iconCloseBlack.svg')}
                            alt="close icon"
                        />
                    </ButtonMui>
                </div>
                <div className={classes.dialogWrapper}>
                    <Heading
                        type="h3"
                        color="textBlack"
                        size={18}
                        lineHeight={22}
                    >
                        {scanMessage.title}
                    </Heading>
                    {!trackingError && (
                        <Box mt={'10px'}>
                            <img
                                className={classes.successIcon}
                                src={
                                    isRewardShipperEnable
                                        ? require('../../../assets/icons/tickCirle.svg')
                                        : require('../../../assets/icons/tickCircleOutline.svg')
                                }
                                alt="scan successful"
                            />
                        </Box>
                    )}
                    <Box
                        sx={{
                            display: 'ruby',
                            alignItems: 'center',
                            marginBottom: '30px',
                        }}
                    >
                        {isRewardShipperEnable && (
                            <Box
                                sx={{
                                    display: 'ruby',
                                    alignItems: 'center',
                                }}
                            >
                                <Body size={14} weight="light" lineHeight={18}>
                                    {scanMessage.description1.length > 0 &&
                                        scanMessage.description1}{' '}
                                </Body>
                                {scanMessage.point.length > 0 && (
                                    <Body
                                        size={14}
                                        weight="bold"
                                        color="textPurple"
                                        lineHeight={18}
                                    >
                                        {' '}
                                        &nbsp; {scanMessage.point} points &nbsp;
                                    </Body>
                                )}
                            </Box>
                        )}
                        <Box
                            sx={{
                                width: '77%',
                            }}
                        >
                            <Body
                                size={14}
                                weight="light"
                                lineHeight={18}
                                textAlign="textCenter"
                            >
                                {scanMessage.description}
                            </Body>
                        </Box>
                    </Box>
                    <Button
                        fontSize={17}
                        fontWeight="normal"
                        className={classes.startShipment}
                        onClick={() => {
                            handleSavedLabelShipment('shipped');
                            handleClose();
                        }}
                    >
                        Go to labels and tracking
                    </Button>

                    <Button
                        fontSize={16}
                        fontWeight="bold"
                        variant="text"
                        onClick={() => handleScan('scan_successful_modal')}
                        className={classes.linkAlign}
                    >
                        Scan another package
                    </Button>
                </div>
            </Dialog>
        </>
    );
}

export default ScanModal;
