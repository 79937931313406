import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import COLORS from 'src/lib/colors';
import CarouselPagination from './CarouselPagination';

const useStyles = makeStyles({
    dotsContainer: {
        display: 'flex',
        width: '100%',
        // marginTop: '1rem',
        justifyContent: 'center',
        alignItems: 'center',
    },
    carouselWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    carouselContainer: {
        maxWidth: '85%',
    },
    navigateBack: {
        width: '28px',
        height: '28px',
        border: '0px',
        background: `url(${require('../../assets/icons/iconChevron.svg')}) 0 0 no-repeat`,
        transform: 'rotate(180deg)',
        '& span': {
            display: 'block',
            width: '1px !important',
            height: '1px !important',
            padding: '0 !important',
            margin: '-1px !important',
            overflow: 'hidden !important',
            clip: 'rect(0, 0, 0, 0) !important',
            whiteSpace: 'nowrap !important',
            border: '0 !important',
        },
    },
    navigateNext: {
        width: '28px',
        height: '28px',
        border: '0px',
        background: `url(${require('../../assets/icons/iconChevron.svg')}) 0 0 no-repeat`,
        '& span': {
            display: 'block',
            width: '1px !important',
            height: '1px !important',
            padding: '0 !important',
            margin: '-1px !important',
            overflow: 'hidden !important',
            clip: 'rect(0, 0, 0, 0) !important',
            whiteSpace: 'nowrap !important',
            border: '0 !important',
        },
    },
});

export type CarouselWithNextPreProps = {
    content: any;
    dots: number;
    styles?: any;
    swipeableViewsRef?: any;
    activeColor?: string;
    dotPosition?: 'top' | 'bottom';
    defaultIndex?: number;
    dotStyle?: any;
    isAutoPlay?: boolean;
    animateHeight?: boolean;
    dotSizeStyle?: any;
    stepData?: any;
    showNavigation?: boolean;
    type?: 'dot' | 'number';
    setDefaultIndex?: (i: any) => void;
    scrollAnimation?: boolean;
};

function CarouselWithNextPre(props: CarouselWithNextPreProps) {
    const {
        content,
        dots,
        styles,
        dotStyle,
        swipeableViewsRef,
        activeColor = COLORS.homeBlack,
        dotPosition = 'bottom',
        type = 'dot',
        defaultIndex = 0,
        isAutoPlay = false,
        dotSizeStyle,
        animateHeight = true,
        stepData = [],
        setDefaultIndex,
        scrollAnimation = false,
        showNavigation = false,
    } = props;
    const classes = useStyles();
    // const { analyticsCustomEvent } = useEventsReporter();

    const [activeIndex, setActiveIndex] = React.useState(defaultIndex);
    const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

    const handleChangeIndex = (index: number) => {
        setActiveIndex(index);
        setDefaultIndex?.(index);
    };
    const handleNavigateBack = (index: any) => {
        setActiveIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    };
    const handleNavigateNext = (index: any) => {
        setActiveIndex((prevIndex) =>
            Math.min(prevIndex + 1, content.length - 1)
        );
    };

    useEffect(() => {
        if (scrollAnimation) {
            const animateCarousel = () => {
                setTimeout(() => {
                    setActiveIndex(
                        (prevIndex) => (prevIndex + 0.3) % content.length
                    );
                }, 500);

                setTimeout(() => {
                    setActiveIndex(defaultIndex);
                }, 1200);
            };
            animateCarousel();
        }
    }, [defaultIndex, content.length]);

    return (
        <div className={showNavigation ? classes.carouselWrapper : ''}>
            {showNavigation && (
                <button
                    type="button"
                    className={classes.navigateBack}
                    onClick={() => handleNavigateBack(activeIndex)}
                >
                    <span>Previous</span>
                </button>
            )}

            <div className={showNavigation ? classes.carouselContainer : ''}>
                {dotPosition === 'top' && (
                    <div className={classes.dotsContainer} style={dotStyle}>
                        <CarouselPagination
                            type={type}
                            dots={dots}
                            index={activeIndex}
                            activeColor={activeColor}
                            dotSizeStyle={dotSizeStyle}
                            onChangeIndex={handleChangeIndex}
                        />
                    </div>
                )}
                {isAutoPlay ? (
                    <AutoPlaySwipeableViews
                        ref={swipeableViewsRef}
                        id="onboarding-swipeable"
                        style={styles?.root}
                        slideStyle={styles?.slideContainer}
                        containerStyle={styles?.containerStyle}
                        enableMouseEvents
                        index={activeIndex}
                        interval={6000}
                        onChangeIndex={handleChangeIndex}
                    >
                        {content}
                    </AutoPlaySwipeableViews>
                ) : (
                    <SwipeableViews
                        ref={swipeableViewsRef}
                        id="onboarding-swipeable"
                        style={styles?.root}
                        slideStyle={styles?.slideContainer}
                        containerStyle={styles?.containerStyle}
                        enableMouseEvents
                        index={activeIndex}
                        animateHeight={animateHeight}
                        onChangeIndex={handleChangeIndex}
                        springConfig={{
                            duration: '0.7s',
                            easeFunction: 'ease',
                            delay: '0s',
                        }}
                    >
                        {content}
                    </SwipeableViews>
                )}

                {dotPosition === 'bottom' && dots && (
                    <div className={classes.dotsContainer} style={dotStyle}>
                        <CarouselPagination
                            type={type}
                            dots={dots}
                            index={activeIndex}
                            activeColor={activeColor}
                            dotSizeStyle={dotSizeStyle}
                            onChangeIndex={handleChangeIndex}
                        />
                    </div>
                )}
            </div>
            {showNavigation && (
                <button
                    type="button"
                    className={classes.navigateNext}
                    onClick={() => handleNavigateNext(activeIndex)}
                >
                    <span>Next</span>
                </button>
            )}
        </div>
    );
}

export default CarouselWithNextPre;
