/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-fallthrough */
/* eslint-disable camelcase */
import React, {
    useState,
    useEffect,
    useMemo,
    useRef,
    useCallback,
} from 'react';
import { Html5Qrcode, Html5QrcodeSupportedFormats } from 'html5-qrcode';
import { Html5QrcodeResult } from 'html5-qrcode/esm/core';
import { IDetectedBarcode, Scanner } from '@yudiel/react-qr-scanner';
import {
    Button,
    ButtonBase,
    Dialog,
    Typography,
    IconButton,
    Box,
} from '@material-ui/core';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import { useEventsReporter, eventMetricsMap } from 'src/hooks';
import { getProduct } from 'src/lib/api-client/adept-mind';
import useStoreData from 'src/hooks/useStoreData';
import { useProductBrowseContext } from 'src/lib/contexts/product-browse-context';
import ROUTES from 'src/lib/routes';
import useRouter from 'src/hooks/useRouter';
import { setPreviousPath } from 'src/lib/utils/setPreviousPath';
import COLORS from 'src/lib/colors';
import platform from 'platform-detect';
import { BarcodeScanner, DetectedBarcode } from 'react-barcode-scanner';

import { useShipmentTracking } from 'src/lib/contexts/ShipmentTrackingContext';
import LoadingOverlay from '../loading-overlay';
import useStyles from './styles';
import { Heading } from '../UI';
import 'react-barcode-scanner/polyfill';

interface BarcodeScannerProps {
    isScannerOpen: boolean;
    onClose: () => void;
    shippingScan?: boolean;
}

interface ScannerError {
    title: string;
    body: string;
    ctaText: string;
    redirectText?: string;
    redirectTextTest?: string;
    ctaAction: () => void;
    redirectAction?: () => void;
}

function trimStart(str: string, trimChar: string): string {
    let index = 0;
    for (; index < str.length; index += 1) {
        if (str.charAt(index) !== trimChar) {
            break;
        }
    }
    return str.slice(index);
}

// Verify UPC check digit
function verifyUpcCheckDigit(scannedCode: string): boolean {
    const scannedCheckDigit = Number(scannedCode[scannedCode.length - 1]);
    const code = scannedCode
        .slice(0, scannedCode.length - 1)
        .split('')
        .map(Number);
    const [evenSum, oddSum]: number[] = code.reduce(
        (accumulator: number[], currentValue: number, index) => {
            const newValue = [...accumulator];
            if ((index + 1) % 2 === 0) {
                newValue[0] += currentValue;
            } else {
                newValue[1] += currentValue;
            }
            return newValue;
        },
        [0, 0]
    );
    let calculatedCheckDigit = (oddSum * 3 + evenSum) % 10;
    calculatedCheckDigit =
        calculatedCheckDigit > 0
            ? 10 - calculatedCheckDigit
            : calculatedCheckDigit;
    return calculatedCheckDigit === scannedCheckDigit;
}

const Brcd = ({
    isScannerOpen,
    onClose,
    shippingScan,
}: BarcodeScannerProps) => {
    const classes = useStyles();
    const { analyticsCustomEvent } = useEventsReporter();
    const { fromPath, setFromPath, end } = useProductBrowseContext();
    const { push, location } = useRouter();
    const androidSettings =
        'intent:#Intent;action=android.settings.SETTINGS;end';
    const browserSettings = 'chrome://settings/content/camera';

    const { getShipmentTrackingDetails } = useShipmentTracking();

    const scannerRef: any = useRef<HTMLDivElement | null>(null);
    const [showLoader, setShowLoader] = useState(false);
    const [scannerDialog, setScannerError] = useState<ScannerError | null>(
        null
    );
    const { mainStoreId = '' } = useStoreData();
    const [qrcodeRegionId] = useState('html5qr-code-full-region');

    const html5QrcodeScanner = useMemo(() => {
        if (scannerRef?.current && isScannerOpen) {
            return new Html5Qrcode(qrcodeRegionId, {
                formatsToSupport: [
                    Html5QrcodeSupportedFormats.QR_CODE,
                    Html5QrcodeSupportedFormats.UPC_A,
                    Html5QrcodeSupportedFormats.CODE_128,
                ],
                verbose: false,
            });
        }
        return null;
    }, [scannerRef?.current && isScannerOpen]);

    const onTryAgainClick = () => {
        setScannerError(null);
        html5QrcodeScanner?.resume();
    };

    const onRedirect = () => {
        if (platform?.phone) {
            window.open(androidSettings);
        } else {
            window.open(browserSettings);
        }
    };
    const testingOnRedirect = () => {
        const link = document.createElement('a');
        if (platform?.phone) {
            link.href = androidSettings;
        }
        link.click();
    };
    const isUPSTrackingNumber = (barcode: string) => {
        const pattern = /^1Z[A-Z0-9]{16}$/;
        return pattern.test(barcode);
    };

    const handleSearchError = () => {
        analyticsCustomEvent(eventMetricsMap.scan, {
            click_text: 'scanned',
            scan_result: 'error',
        });
        setShowLoader(false);
        setScannerError({
            title: 'Whoops!',
            body: 'We could not find this item. Please try scanning again.',
            ctaText: 'Try again',
            ctaAction: onTryAgainClick,
        });
        end();
        onClose();
    };

    function extractBarcode(barcodeData: string) {
        // Use a regular expression to find the first special character (anything non-alphanumeric)
        const specialCharRemoved = barcodeData?.replace(/^[^\d]*(\d+)/, '$1');
        // This will match the first non-alphanumeric characte

        // If no special character is found, return the original string (or handle as needed)
        return specialCharRemoved;
    }

    const scannerBoxRef = useRef<HTMLDivElement>(null);

    function handleOnDetected(barcodes: IDetectedBarcode[]) {
        console.log('🚀 ~ handleOnDetected ~ barcodes:', barcodes);
        const barcodeData = barcodes[0];
        console.log('🚀 ~ handleOnDetected ~ barcodeData:', barcodeData);
        let scannedAndVerifiedCode = barcodeData.rawValue;
        const codeType = barcodeData.format;

        try {
            switch (codeType) {
                case 'qr_code': {
                    // Handle QR code
                    const decodedURL = new URL(scannedAndVerifiedCode);
                    if (
                        decodedURL.origin ===
                        process.env.REACT_APP_COUPON_DEEPLINK_DOMAIN
                    ) {
                        return window.location.replace(scannedAndVerifiedCode);
                    }
                    throw new Error('Invalid URL scanned');
                }
                // @ts-ignore
                case 'upc_a':
                    if (!verifyUpcCheckDigit(scannedAndVerifiedCode)) {
                        return null;
                    }
                    scannedAndVerifiedCode = `00${trimStart(
                        scannedAndVerifiedCode,
                        '0'
                    )}`;
                    break;
                // @ts-ignore
                case 'code_128':
                    if (scannedAndVerifiedCode) {
                        const extractedValue = extractBarcode(
                            scannedAndVerifiedCode
                        );
                        scannedAndVerifiedCode = extractedValue;
                    }
                    break;
                default:
                    throw new Error('Product not found');
            }

            setShowLoader(true);
            if (scannedAndVerifiedCode) {
                if (shippingScan) {
                    getShipmentTrackingDetails(scannedAndVerifiedCode);
                } else {
                    setFromPath(
                        ROUTES.productDetailsPage === location.pathname
                            ? fromPath
                            : location.pathname
                    );
                    push(
                        `${ROUTES.productDetailsPage}?barcode=${scannedAndVerifiedCode}&isBarCodeScan=true`,
                        setPreviousPath(location, fromPath)
                    );
                    end();
                    onClose();
                    console.log({ scannedAndVerifiedCode });
                }
            } else {
                setShowLoader(false);
                throw new Error('Product not found');
            }
            end();
            onClose();
            setShowLoader(false);
        } catch (error) {
            handleSearchError();
        }
        console.log({ scannedAndVerifiedCode });

        return null;
    }

    return (
        <Dialog fullScreen open={isScannerOpen}>
            <Box
                sx={{
                    height: '100%',
                    position: 'relative',
                }}
            >
                <Box className={classes.topHeader}>
                    <IconButton
                        className={classes.leftButton}
                        onClick={onClose}
                    >
                        <img
                            src={require('../../assets/icons/right-arrow.svg')}
                            alt="arrow icon"
                        />
                    </IconButton>

                    <Heading
                        type="h3"
                        weight="bold"
                        size={20}
                        color="textWhite"
                    >
                        Scanner
                    </Heading>
                </Box>
                <Box
                    sx={{
                        bgcolor: 'black',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: 'calc(100% - 60px)',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                        }}
                    >
                        <div
                            style={{
                                width: '90%',
                                position: 'relative',
                                margin: '0 auto',
                                padding: '15px',
                                height: '100%',
                            }}
                        >
                            <BarcodeScanner
                                options={{
                                    formats: ['code_128', 'qr_code', 'upc_a'],
                                    delay: 100,
                                }}
                                ref={scannerRef}
                                onCapture={handleOnDetected}
                                width={'100%'}
                                height={'100%'}
                                trackConstraints={{
                                    facingMode: {
                                        // ideal: 'environment',
                                        exact: 'environment',
                                    },
                                    // facingMode:'environment'
                                }}
                                style={{
                                    borderRadius: '8px',
                                }}
                            />
                            <div className="corner">
                                <span className="topLeft" />
                                <span className="topRight" />
                                <span className="bottomLeft" />
                                <span className="bottomRight" />
                            </div>
                        </div>
                    </Box>
                </Box>
            </Box>

            <Dialog classes={{ paper: classes.dialog }} open={!!scannerDialog}>
                <ButtonBase
                    className={classes.errorDialogCloseButton}
                    onClick={onClose}
                >
                    <img
                        src={require('../../assets/icons/iconCloseBlack.svg')}
                        alt="close icon"
                    />
                </ButtonBase>
                <div className={classes.contentWrapper}>
                    {scannerDialog?.title && (
                        <Typography
                            className={classes.title}
                            id="search-dialog-title-text"
                        >
                            {scannerDialog?.title}
                        </Typography>
                    )}
                    {scannerDialog?.body && (
                        <Typography
                            className={classes.desc}
                            id="search-dialog-desc-text"
                        >
                            {scannerDialog?.body}
                        </Typography>
                    )}
                    {scannerDialog?.ctaText && (
                        <div className={classes.buttons}>
                            <Button
                                className={classes.tryAgainBtn}
                                onClick={scannerDialog?.ctaAction}
                            >
                                {scannerDialog?.ctaText}
                            </Button>
                            <Button
                                className={classes.tryAgainBtn}
                                onClick={scannerDialog?.redirectAction}
                            >
                                {scannerDialog?.redirectText}
                            </Button>
                        </div>
                    )}
                </div>
            </Dialog>
            <LoadingOverlay
                variant="wordmark"
                theme="light"
                open={showLoader}
            />
        </Dialog>
    );
};

export default Brcd;
