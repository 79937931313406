/* eslint-disable no-unused-expressions */
import React, { useEffect, useMemo, useState } from 'react';
import { Box, ButtonBase, makeStyles } from '@material-ui/core';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import { useProductBrowseContext } from 'src/lib/contexts/product-browse-context';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import useRouter from 'src/hooks/useRouter';
import PullRefresh from 'src/components/pull-refresh';
import NavBottom from 'src/components/NavBottom';
import Header from 'src/components/header';
import BrandIframe from 'src/components/BrandIframe';
import ROUTES from 'src/lib/routes';
import { useTabsUIStateContext } from 'src/lib/contexts/tabs-ui-state-context';
import NavTop from 'src/components/nav-top';
import COLORS from 'src/lib/colors';
import { thinHeaderHeight } from 'src/lib/constants';
import { useStoreData } from 'src/hooks';

const useStyles = makeStyles({
    productPageView: {
        height: 'calc(100% - 210px)',
    },
    iframe: {
        width: '100vw',
        height: '100vh',
        border: 'none',
    },
    backIcon: {
        fontSize: '1.2rem',
        color: COLORS.whiteSmoke,
    },
    headerLogo: {
        margin: '0 auto',
    },
    cartHeader: {
        display: 'flex',
        width: '100%',
        padding: '25px 20px',
        height: thinHeaderHeight,
    },
});

export default function ProductDetailsPage() {
    const classes = useStyles();
    const { begin, fromPath } = useProductBrowseContext();
    const { navBottomActiveTab } = useUserUpdateContext();
    const { query, history } = useRouter();
    const { cartPage, productLinkInNative, setProductLinkInNative } =
        useTabsUIStateContext();
    const { isStoreModeOn } = useStoreData();

    let url = '';

    const handleClick = () => {
        if (query?.searchString || query?.itemId) {
            localStorage.removeItem('searchedQuery');
            localStorage.removeItem('shopSearchNavigation');
            setProductLinkInNative({ src: '', show: false, route: '' });
            history.goBack();
            begin();
        } else {
            history.goBack();
        }
    };

    if (query?.barcode) {
        url = `${process.env.REACT_APP_DOT_COM_URL}/${
            query?.barcode
        }/directory_${query?.barcode}?isBarCodeScan=${true}`;
    } else if (query?.itemId) {
        url = `${process.env.REACT_APP_DOT_COM_URL}/${query?.itemId}
            /directory_${query?.itemId}`;
    } else if (query?.searchString) {
        if (productLinkInNative.show && productLinkInNative.src) {
            console.log('Products URL get', productLinkInNative.src);
            url = productLinkInNative.src;
        } else {
            query.searchString = query?.searchString.replace(/\//g, '%252F');
            url = `${process.env.REACT_APP_DOT_COM_URL}/${query?.searchString}
                /directory_${query?.searchString}`;
        }
    }

    useEffect(() => {
        if (
            navBottomActiveTab === ROUTES.productDetailsPage &&
            fromPath === ROUTES.productDetailsPage
        ) {
            if (query?.searchString || query?.itemId) {
                localStorage.removeItem('searchedQuery');
                history.goBack();
            }
        }
        console.log('Products URL get');
    }, [navBottomActiveTab]);

    const iframeHeight = useMemo(() => {
        const baseHeight = cartPage
            ? `calc(100vh - ${thinHeaderHeight})`
            : 'calc(100vh - 135px)';

        return isStoreModeOn ? `calc(${baseHeight} - 66px)` : baseHeight;
    }, [cartPage, thinHeaderHeight, isStoreModeOn]);

    return (
        <>
            <PullRefresh className="hiddenScrollForPullToRefresh">
                {cartPage ? (
                    <NavTop
                        withTopScroll
                        bgColor={COLORS.brandRed}
                        bgImageSrc={require('../assets/bgs/header-bg.svg')}
                        noBorderRadius
                        minHeight="0"
                    >
                        <Box className={classes.cartHeader}>
                            <ButtonBase onClick={handleClick}>
                                <ArrowBackIosRoundedIcon
                                    className={classes.backIcon}
                                />
                            </ButtonBase>
                            <img
                                className={classes.headerLogo}
                                src={require('../assets/icons/iconStaplesLogo.svg')}
                                alt="staples connect logo"
                                id="staples-connect-logo"
                            />
                        </Box>
                    </NavTop>
                ) : (
                    <Header backButton onClickHandler={handleClick} />
                )}
                <BrandIframe
                    title="product-results"
                    id="product-results-iframe"
                    src={url}
                    height={iframeHeight}
                />
                {!cartPage ? <NavBottom /> : null}
            </PullRefresh>
        </>
    );
}
