/* eslint-disable max-len */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* eslint-disable arrow-body-style */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    Box,
    Button as MUIButton,
    makeStyles,
    Typography,
    ButtonBase,
    SwipeableDrawer,
    Dialog,
} from '@material-ui/core';
import { useAtom } from 'jotai';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import { useGuestMode, useRouter } from 'src/hooks';
import bgImage from 'src/assets/bgs/bgRedUserProfile.svg';
import bgStoreCardImage from 'src/assets/bgs/storeBg.png';
import { optimoveEvents } from 'src/screens/optimove';
import NavTop from 'src/components/nav-top';
import COLORS from 'src/lib/colors';
import FullWidthCard from 'src/components/cards/full-width-card';
import { GUTTER_WIDTH } from 'src/theme';
import { storeCommunity } from 'src/lib/utils/store-utils';
import StoreSearchModal from 'src/components/store-search-modal';
import useStoreData from 'src/hooks/useStoreData';
import { Store } from 'src/services/types';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import RecycleEveryDay from 'src/components/recycle-every-day';
import LocalImpact from 'src/components/local-impact';
import axiosInstance from 'src/lib/api-client/custom-axios';
import useUserInfo from 'src/hooks/useUserInfo';
import InkTonerRecycle from 'src/components/ink-toner-recycle';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import Header from 'src/components/header';
import IconPenEditVector from 'src/components/Icons/IconPenEditVector';
import { Heading, Button, Body } from 'src/components/UI';
import BrandIframe from 'src/components/BrandIframe';
import { mergeClasses } from 'src/stylesheets/utils';
import layout_classes from 'src/stylesheets/layout_classes.module.scss';
import { useTabsUIStateContext } from 'src/lib/contexts/tabs-ui-state-context';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import ROUTES from 'src/lib/routes';
import { useProductBrowseContext } from 'src/lib/contexts/product-browse-context';
import ViewAllDigitalCoupons from 'src/components/view-all-digital-coupons';
import StoreServiceCard from 'src/components/cards/store-service-card';
import TsaPreCheck from 'src/components/tsa-pre-check/tsa-pre-check';
import ShippingServicesHome from 'src/components/shipping-services/shipping-services-list/shipping-services-home';
import CreditCelebrationDialog from 'src/components/credit-celebration-dialog';
import CreateShippingContainer from 'src/components/shipping-services/creating-shipment/creating-shipping-container';
import ShippingServicesContainer from 'src/components/shipping-services/shipping-services-list/shipping-services-container';
import ShippingQuoteEstimatorContainer from 'src/components/shipping-services/shipping-get-quote/shipping-quote-estimator-container';
import SelfServicesPrintingCard from 'src/components/cards/SelfServicesPrintingCard';
import { useQuery } from 'react-query';
import { getStaplesRewardsData } from 'src/lib/api-client/wallet-activities';
import { format } from 'date-fns';
import styles from 'src/stylesheets/utilities.module.scss';
import ShippingRewards from 'src/components/shipping-services/shipping-rewards';
import { isListEnabled } from 'src/lib/api-client/lists';
import { IconClose } from 'src/components/Icons';
import Row from 'src/components/Row';

import {
    isOpenBrowseFileAtom,
    YourFilesProvider,
} from 'src/lib/contexts/YourFilesContext';
import DialogBox from 'src/components/DialogBox';
import ShippingServicesTracker from 'src/components/ShippingServicesTracker';
import StoreDetailCard from 'src/components/cards/StoreDetailCard';

import { locationStoreURL } from 'src/services/urls';
import {
    footerHeight,
    storeModeStickyHeight,
    thinHeaderHeight,
} from 'src/lib/constants';
import { getShipmentListDetails } from 'src/lib/api-client/shipment-services';
import {
    ShipmentListDataProps,
    ShipmentStatusProps,
    TrackingStatus,
} from 'src/types/store';
import ShippingCard from '../components/shipping-card';
import ProtectionPlans from '../components/protection-plans/protection-plans';
import { CACHE_ONLY, NO_REFETCH } from '../lib/utils/react-query-utils';
import MaskImg from '../assets/icons/maskImag.png';

interface Props {
    showStoreModeNotificationHeader: boolean;
}

interface SharedFile {
    name: string;
    type: string;
    size: number;
}

interface LaunchQueue {
    setConsumer(consumer: (launchParams: LaunchParams) => Promise<void>): void;
}

interface FileSystemFileHandle {
    getFile(): Promise<File>;
}

interface LaunchParams {
    files?: FileSystemFileHandle[];
}
type DeliveryDate = {
    date: string;
    type: 'DEL' | 'RDD' | 'SDD';
};

type Shipment = {
    shipmentdetailid: number;
    shimpmentstatus: {
        shipmenttrackingstatus: Array<{
            package: Array<{
                activity: {
                    status: {
                        statusCode: '011' | '021' | '003';
                    };
                };
                deliveryDate: DeliveryDate[];
            }>;
        }>;
    };
};

const priorityMap: Record<string, number> = { DEL: 1, RDD: 2, SDD: 3 };
declare global {
    interface Window {
        launchQueue?: LaunchQueue;
    }
}

const useStyles = makeStyles((theme) => ({
    screen: {
        flexGrow: 1,
        background: COLORS.whiteSmoke,
        overflow: 'auto',
    },
    darkContent: {
        color: COLORS.primaryWhite,
        marginTop: '0',
        marginBottom: '7.5rem',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    storeHeaderTextContainer: {
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',
        height: '153.2px',
        [theme.breakpoints.down(520)]: {
            padding: '24px 24px 14px',
            height: '110px',
        },
    },
    communityTitle: {
        textAlign: 'center',
        fontSize: '1.25rem',
        [theme.breakpoints.down(520)]: {
            fontSize: '0.8rem',
        },
    },
    container: {
        minHeight: '9.75rem',
    },
    noStoresContainer: {
        marginTop: '1rem',
        marginBottom: '1rem',
    },
    noStoresIconContainer: {
        textAlign: 'center',
        display: 'block',
    },
    noStoresText: {
        textAlign: 'center',
        fontSize: '1.0625rem',
        fontWeight: 500,
    },
    yourStoreContainer: {
        backgroundSize: '200px',
        backgroundImage: `url(${bgImage})`,
        height: '30vh',
        backgroundColor: COLORS.brandRed,
        userSelect: 'none',
    },
    yourStoreSubContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        // padding: '0px 15px',
    },
    locationIcon: {
        [theme.breakpoints.down(370)]: {
            width: '1.5rem',
        },
    },
    yourStoreContainerTitle: {
        fontSize: '1.125rem',
        lineHeight: '15px',
        letterSpacing: '-0.37px',
        color: COLORS.primaryWhite,
        padding: '15px 0px 8px 15px',
        [theme.breakpoints.down(370)]: {
            fontSize: '1rem',
            paddingLeft: '18px',
        },
    },
    storeLocation: {
        display: 'flex',
        alignSelf: 'center',
        justifyContent: 'center',
    },
    storeLocationText: {
        // fontWeight: 'bold',
        fontSize: '1.5rem !important',
        paddingLeft: '.25rem',
        lineHeight: '28px',
        letterSpacing: '0.0411429px',
        color: COLORS.primaryWhite,
        [theme.breakpoints.down(500)]: {
            fontSize: '1.3rem !important',
            paddingLeft: '.18rem',
        },
        [theme.breakpoints.down(370)]: {
            fontSize: '1.1rem !important',
            paddingLeft: '.12rem',
        },
    },
    blueButton: {
        color: COLORS.primaryWhite,
        fontWeight: 700,
        fontSize: '0.875rem',
        lineHeight: '17px',
        letterSpacing: '-0.336px',
        textDecorationLine: 'underline',
        [theme.breakpoints.down(370)]: {
            fontSize: '0.7rem',
        },
    },
    sectionTitle: {
        fontWeight: 'bold',
        marginBottom: '.85rem',
        marginTop: '1.4212rem',
        fontSize: '1.416rem',
        marginLeft: GUTTER_WIDTH,
    },
    nearByStoreTitle: {
        fontWeight: 500,
        marginBottom: '.85rem',
        marginTop: '1.4212rem',
        fontSize: '1.0625rem',
        marginLeft: GUTTER_WIDTH,
    },
    localStaplesCTA: {
        marginTop: '1rem',
        height: '7rem',
        marginLeft: GUTTER_WIDTH,
        marginRight: GUTTER_WIDTH,
    },
    localStaplesCTALeftContent: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    localStaplesCTATopText: {
        marginTop: '1.188rem',
        marginBottom: '.25rem',
        alignSelf: 'center',
        fontWeight: 'bold',
        fontSize: '.5rem',
        color: COLORS.secondaryNeutralCharcoal,
    },
    localStaplesCTALogo: {
        alignSelf: 'center',
    },
    localStaplesCTABottomText: {
        alignSelf: 'center',
        fontWeight: 'bold',
        marginTop: '.55rem',
        marginBottom: '.55rem',
        color: COLORS.brandCyan,
    },
    localStaplesCTARightImage: {
        objectFit: 'cover',
        width: '7rem',
    },
    nearbyStoreCard: {
        minHeight: '15.3824rem',
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'start',
    },
    nearbyStoreAddress: {
        paddingLeft: '1.3376rem',
        paddingRight: '1.3376rem',
        fontSize: '1.1666rem',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        marginTop: '1.5rem',
    },
    selectStore: {
        color: COLORS.brandCyan,
        fontSize: '1.0625rem',
        fontWeight: 500,
        textTransform: 'none',
        paddingLeft: '1.3376rem',
        textAlign: 'left',
    },
    searchForProductWrapper: {
        display: 'flex',
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
        width: '100%',
    },
    backButton: {
        right: '1rem',
        position: 'relative',
    },
    nearbyContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    nearbyStoreMainContainerExpandedMode: {
        background: '#ffffff',
        margin: '-9rem 2rem 0rem',
        borderRadius: '15px',
        paddingBottom: '2rem',
        [theme.breakpoints.down(400)]: {
            marginTop: '-9rem',
        },
        [theme.breakpoints.down(380)]: {
            marginTop: '-7rem',
        },
    },
    nearbyStoreMainContainer: {
        background: '#ffffff',
        margin: '-10rem 1rem 0px ',
        borderRadius: '15px',
        [theme.breakpoints.down(400)]: {
            marginTop: '-9rem',
        },
        [theme.breakpoints.down(380)]: {
            marginTop: '-7rem',
        },
    },
    closeBtn: {
        width: '14px',
        height: '14px',
        color: '#858585',
        background: 'none !important',
    },
    iconContainer: {
        display: 'flex',
        gap: '10px',
        padding: '0px 1.3376rem 1rem',
    },
    closeButon: {
        marginRight: '1.25rem',
    },
    indicator: {
        backgroundColor: 'lightgray',
        height: '7px',
        width: '20%',
        margin: '0 auto',
        borderRadius: '2rem',
        position: 'relative',
        top: '0px',
    },
    infoWrapper: {
        padding: '0.625rem 1.625rem 2.063rem 1.625rem',
    },
    infoHeading: {
        // fontSize: '1.625rem',
        // fontWeight: 700,
        lineHeight: 'normal',
        marginTop: '20px',
        marginBottom: '10px',
    },
    infoDrawerText: {
        fontSize: '1rem',
        fontWeight: 400,
        marginBottom: '20px',
    },
    recycleButton: {
        alignSelf: 'center',
        backgroundColor: 'black',
        color: 'white',
        lineHeight: '2.5rem',
        borderRadius: '2rem',
        width: '100%',
        fontWeight: 500,
        // margin: '1.75rem 0',
        fontSize: '0.95rem',
        letterSpacing: '0',
    },
    btnAlign: {
        width: '100%',
    },
    cartHeader: {
        display: 'flex',
        width: '100%',
        padding: '25px 20px',
        height: thinHeaderHeight,
    },
    backIcon: {
        fontSize: '1.2rem',
        color: COLORS.whiteSmoke,
    },
    title: {
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '20px',
        color: '#ffffff',
        margin: 'auto',
    },
    storeCredit: {
        margin: '16px',
        padding: '0',
        boxShadow: 'none',
        '& .MuiCardContent-root': {
            padding: '0px',
        },
    },
    storeCreditContainer: {
        padding: '10px 20px',
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
    },
    icon: {
        width: '1.2rem',
        height: '1.2rem',
        marginTop: '0.3rem',
        marginLeft: 'auto',
    },
    superScript: {
        fontSize: '12px',
    },
    infoText: {
        color: COLORS.fordGray,
        fontSize: '14px',
        margin: '0',
    },
    promoText: {
        fontSize: '14px',
    },
    underLineText: {
        textDecorationLine: 'underline',
    },
    storeDetailCard: {
        backgroundColor: COLORS.brandRed,
        padding: '30px 16px 16px 16px',
        backgroundImage: `url(${bgStoreCardImage})`,
    },
    stickyStoreDetailCard: {
        backgroundImage: `url(${MaskImg})`,
        backgroundColor: COLORS.brandRed,
        padding: '16px 16px 16px 16px',
        height: '54px',
    },
}));

function Stores({ showStoreModeNotificationHeader }: Props) {
    const { mainStore, additionalStores, updateMainStore } = useStoreData();

    const classes = useStyles();
    const location = useLocation();

    const rewardcenterURL = `${process.env.REACT_APP_DOTCOM_URL}/grs/rewards/sr/loyaltycenter?openrecycledrawer=yes&closebutton=no`;
    const { customEvent, analyticsCustomEvent, analyticsScreenCustomNames } =
        useEventsReporter();
    const [searchModalOpen, setSearchModalOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isOpenBrowseFile, setIsOpenBrowseFile] =
        useAtom(isOpenBrowseFileAtom);
    const [recycleData, setRecycleData] = useState();
    const [offSetTop, setOffSetTop] = useState(0);
    const [localShow, setLocalShow] = useState(false);
    const [isShowStickyHeader, setIsShowStickyHeader] = useState(false);
    const { userInfo } = useUserInfo();
    const { isGuestModeOn } = useGuestMode();
    const openInfo = false;
    const [state, setState] = React.useState(openInfo);
    const [joinFree, setJoinFree] = React.useState(false);
    const [shipmentListData, setShipmentListData] = useState<
        ShipmentListDataProps[]
    >([]);
    const [storeServicesExternalLink, setStoreServicesExternalLink] =
        useState(false);
    const { isStoreModeOn } = useStoreData();
    const recycleRef = useRef<HTMLDivElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const storeCardRef = useRef<HTMLDivElement | null>(null);
    const [holidayShippingLink, setHolidayShippingLink] = useState('');
    const {
        storeRecycleLink,
        setStoreRecycleLink,
        setDotComUrl,
        storeServicesLink,
        setStoreServicesLink,
        storeLinkInNative,
        setStoreLinkInNative,
        homeLinkInNative,
        setHomeLinkInNative,
        printPage,
        setPrintPage,
    } = useTabsUIStateContext();
    const { navBottomActiveTab, setNavBottomActiveTab, setGuestModal } =
        useUserUpdateContext();
    const { fromPath, setFromPath } = useProductBrowseContext();

    const SelfServicesPrintingCardMemo = useMemo(
        () => (
            <SelfServicesPrintingCard
                setIsOpenBrowseFile={setIsOpenBrowseFile}
            />
        ),
        [isOpenBrowseFile]
    );

    const toggleDrawer = (open: boolean) => {
        setState(open);
        // currentMode(open);
    };
    const toggleJoinFree = (open: boolean) => {
        setJoinFree(open);
        // currentMode(open);
    };
    const handleClickLocal = (open: boolean) => {
        setLocalShow(open);
    };

    const { data: printFromAppEnabled } = useQuery(
        ['PrintFromAppEnabled'],
        () => isListEnabled('PrintFromApp'),
        {
            refetchOnMount: true,
        }
    );

    const handleSignIn = () => {
        setGuestModal({ open: true });
        analyticsCustomEvent(eventMetricsMap.home, {
            click_text: 'sign in',
            element_location: `guest mode`,
        });
    };

    useEffect(() => {
        toggleDrawer(openInfo);
    }, [openInfo]);
    const [storeCreditData, setStoreCreditData] = useState<any>();
    const { push, query } = useRouter();

    const { data: staplesRewardsDetail } = useQuery(
        ['details', userInfo?.rewardsNumber],
        () => getStaplesRewardsData(userInfo?.rewardsNumber),
        {
            ...CACHE_ONLY,
        }
    );

    function separateTrackingStatus(
        shipments: ShipmentListDataProps[]
    ): ShipmentListDataProps[] {
        const currentDate = new Date();

        let processedShipments = shipments
            .map((shipment) => {
                const trackingStatus =
                    shipment.shimpmentstatus.shipmenttrackingstatus;

                if (trackingStatus.length === 0) {
                    return []; // Remove shipments with empty shipmenttrackingstatus
                }
                if (trackingStatus.length > 1) {
                    shipment.shimpmentstatus.shipmenttrackingstatus = [];
                    return trackingStatus.map((status) => ({
                        ...shipment,
                        shimpmentstatus: {
                            ...shipment.shimpmentstatus,
                            shipmenttrackingstatus: [status],
                        },
                    }));
                }
                return shipment;
            })
            .flat();

        console.log({ processedShipments });
        processedShipments = processedShipments.filter((shipment) => {
            const deliveryDateStr =
                shipment.shimpmentstatus.shipmenttrackingstatus[0]?.package[0]
                    ?.deliveryDate?.[0]?.date;
            const isPackageDelived =
                shipment.shimpmentstatus.shipmenttrackingstatus[0].package[0]
                    .activity.status.statusCode === '011';
            if (!deliveryDateStr && !isPackageDelived) return true;

            const deliveryDate = new Date(
                parseInt(deliveryDateStr.substring(0, 4), 10),
                parseInt(deliveryDateStr.substring(4, 6), 10) - 1,
                parseInt(deliveryDateStr.substring(6, 8), 10)
            );

            const timeDiff = currentDate.getTime() - deliveryDate.getTime();
            return timeDiff <= 3 * 24 * 60 * 60 * 1000;
        });
        const sortedByDeliverStatusShipments = processedShipments.sort(
            (a: any, b: any) => {
                const getPrimaryDate = (
                    shipment: Shipment
                ): DeliveryDate | undefined => {
                    return shipment.shimpmentstatus.shipmenttrackingstatus
                        .flatMap((status) =>
                            status.package.flatMap((pkg) => pkg.deliveryDate)
                        )
                        .sort((date1, date2) => {
                            return (
                                priorityMap[date1.type] -
                                    priorityMap[date2.type] ||
                                date2.date.localeCompare(date1.date) // Ensuring descending order
                            );
                        })[0];
                };

                const dateA = getPrimaryDate(a);
                const dateB = getPrimaryDate(b);

                if (!dateA || !dateB) return 0;

                return (
                    priorityMap[dateA.type] - priorityMap[dateB.type] ||
                    dateB.date.localeCompare(dateA.date) // Fixing the undefined `d2`
                );
            }
        );

        return sortedByDeliverStatusShipments.slice(0, 10);
    }

    const getShipmentListData = async () => {
        const response = await getShipmentListDetails();
        if (response?.length) {
            const sortedShipmentList = response?.sort((a: any, b: any) => {
                const da = new Date(a.shipmentcreateddate).getTime();
                const db = new Date(b.shipmentcreateddate).getTime();
                return db - da;
            });
            if (sortedShipmentList) {
                const separateTrackingStatusData =
                    separateTrackingStatus(sortedShipmentList);
                console.log({ separateTrackingStatusData });
                setShipmentListData(separateTrackingStatusData);
            }
        } else {
            setShipmentListData([]);
        }
    };
    useEffect(() => {
        setTimeout(() => {
            if (query?.recycleData) {
                if (recycleRef.current) {
                    recycleRef.current.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }, 1000);
    }, [query?.recycleData]);

    useEffect(() => {
        const storeCredit = staplesRewardsDetail?.data?.couponDataList?.filter(
            (data: any) => data?.couponInfo?.couponClass === 'StoreCredit'
        );
        if (storeCredit?.length) {
            setStoreCreditData(storeCredit[0]);
        }
    }, [staplesRewardsDetail]);
    useEffect(() => {
        if (
            navBottomActiveTab === ROUTES.stores &&
            fromPath === ROUTES.stores
        ) {
            setStoreRecycleLink('');
            setNavBottomActiveTab('');
        }
    }, [navBottomActiveTab]);

    function handleScroll() {
        console.log(containerRef.current, 'hi');
        if (!containerRef.current || !storeCardRef.current) return;

        const { scrollTop, offsetTop } = containerRef.current;
        setOffSetTop(offsetTop);
        const { offsetHeight: storeCardHeight } = storeCardRef.current;

        if (storeCardHeight) {
            setIsShowStickyHeader(scrollTop >= storeCardHeight);
        }
    }
    /* eslint-disable consistent-return */
    useEffect(() => {
        const container = containerRef.current;
        getShipmentListData();
        if (
            container &&
            !storeRecycleLink &&
            !homeLinkInNative?.src &&
            !storeServicesLink &&
            !storeRecycleLink &&
            !storeLinkInNative?.show
        ) {
            container.addEventListener('scroll', handleScroll);

            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [
        storeRecycleLink,
        homeLinkInNative?.src,
        storeServicesLink,
        storeRecycleLink,
        storeLinkInNative?.show,
    ]);
    useEffect(() => {
        if (!showStoreModeNotificationHeader) {
            setOffSetTop(0);
        }
    }, [showStoreModeNotificationHeader]);

    const handleStoreCreditNavigation = (coupon: any) => {
        push(`${ROUTES.coupons}?couponNumber=${coupon}&deepLink=false`);
        analyticsCustomEvent(eventMetricsMap.stores, {
            click_text: `${storeCreditData.couponInfo.couponValue} promo card is ready to redeem!`,
            element_location: 'promo card',
        });
    };

    const showSearchModal = () => {
        analyticsCustomEvent(eventMetricsMap.stores, {
            click_text: 'change location',
            element_location: 'your store information',
            store_details: `${mainStore?.address?.city}-${mainStore?.storeNumber}`,
        });
        optimoveEvents(eventMetricsMap.scm_stores, {
            click_text: 'change location',
            element_location: 'your store information',
            store_details: `${mainStore?.address?.city}-${mainStore?.storeNumber}`,
        });
        setSearchModalOpen(true);
    };
    const handleSearchModalClose = () => {
        setSearchModalOpen(false);
    };

    const getRecycleData = async (pId: string) => {
        // `${userInfo?.preferredStoreId}`
        const response = await axiosInstance.get(
            locationStoreURL.replace('%storeIdentifier%', `${pId}`)
        );
        if (response.data.recycling) {
            setRecycleData(response.data.recycling);
            if (location.hash) {
                const elem = document.getElementById(location.hash.slice(1));
                if (elem) {
                    elem.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }
    };
    function onStoreSelected(store: Store) {
        updateMainStore(store);
        if (store?.storeNumber) {
            getRecycleData(store?.storeNumber);
        }
        setSearchModalOpen(false);
    }
    useEffect(() => {
        if (userInfo?.preferredStoreId) {
            getRecycleData(userInfo?.preferredStoreId);
        }
    }, [userInfo?.preferredStoreId]);

    useEffect(() => {
        if (userInfo?.rewardsNumber) {
            analyticsScreenCustomNames({
                rewards_number: userInfo?.rewardsNumber,
            });
        } else {
            analyticsScreenCustomNames({
                rewards_number: '',
            });
        }
        if (userInfo?.preferredStoreId) {
            analyticsScreenCustomNames({
                store_id: userInfo?.preferredStoreId,
            });
        } else {
            analyticsScreenCustomNames({
                store_id: '',
            });
        }
    }, []);

    const handleShareFiles = async () => {
        if ('launchQueue' in window) {
            const { launchQueue } = window;
            if (launchQueue) {
                launchQueue.setConsumer(async (launchParams) => {
                    if (!launchParams.files || launchParams.files.length === 0)
                        return;

                    const files: SharedFile[] = await Promise.all(
                        launchParams.files.map(async (fileHandle) => {
                            const file = await fileHandle.getFile();
                            return {
                                name: file.name,
                                type: file.type,
                                size: file.size,
                            };
                        })
                    );

                    console.log('files', files);
                    if (files.length) {
                        setIsOpenBrowseFile(true);
                    }
                });
            }
        }
    };

    useEffect(() => {
        handleShareFiles();
    }, []);

    useEffect(() => {
        return () => {
            setStoreServicesLink('');
            setPrintPage(false);
        };
    }, []);

    const handleClickOnBackBtnRecycleOnline = () => {
        setStoreRecycleLink('');
        setOffSetTop(0);
        setIsShowStickyHeader(false);
    };

    const handleClickOnBackBtnOnline = () => {
        if (storeServicesLink) {
            setStoreServicesLink('');
            setPrintPage(false);
        }
        setOffSetTop(0);
        setIsShowStickyHeader(false);
    };
    const handleHolidatShipping = (e: any) => {
        e.preventDefault();
        setStoreServicesLink(
            `${process.env.REACT_APP_DOTCOM_URL}/stores/services/holidayshipping`
        );
    };
    const iframeHeight = useMemo(() => {
        const baseHeight = printPage
            ? `calc(100vh - ${thinHeaderHeight})`
            : 'calc(100vh - 135px)';

        return isStoreModeOn ? `calc(${baseHeight} - 66px)` : baseHeight;
    }, [printPage, thinHeaderHeight, isStoreModeOn]);

    if (storeServicesLink.includes('/stores/services/holidayshipping')) {
        return (
            <>
                <NavTop
                    withTopScroll
                    bgColor={COLORS.brandRed}
                    bgImageSrc={require('../assets/bgs/header-bg.svg')}
                    noBorderRadius
                    minHeight="0"
                >
                    <Box className={classes.cartHeader}>
                        <ButtonBase onClick={handleClickOnBackBtnOnline}>
                            <ArrowBackIosRoundedIcon
                                className={classes.backIcon}
                            />
                        </ButtonBase>
                        <Typography className={classes.title}>
                            Holiday Shipping
                        </Typography>
                    </Box>
                </NavTop>
                <BrandIframe
                    title="holidayShippingLink"
                    id="holidayShippingLink-iframe"
                    src={storeServicesLink}
                    height={
                        isStoreModeOn
                            ? `calc(100vh - ${thinHeaderHeight} - ${storeModeStickyHeight})`
                            : `calc(100vh - ${thinHeaderHeight})`
                    }
                />
            </>
        );
    }

    if (homeLinkInNative.show) {
        return (
            <>
                <Header
                    backButton
                    onClickHandler={() => {
                        setHomeLinkInNative({ src: '', show: false });
                        setOffSetTop(0);
                        setIsShowStickyHeader(false);
                    }}
                />
                <BrandIframe
                    src={homeLinkInNative.src}
                    title="home-page"
                    id="home-screen"
                    height={
                        isStoreModeOn
                            ? `calc(100vh - ${thinHeaderHeight} - ${storeModeStickyHeight})`
                            : `calc(100vh - ${thinHeaderHeight})`
                    }
                />
            </>
        );
    }

    if (storeRecycleLink) {
        return (
            <>
                <NavTop
                    withTopScroll
                    bgColor={COLORS.brandRed}
                    bgImageSrc={require('../assets/bgs/header-bg.svg')}
                    noBorderRadius
                    minHeight="0"
                >
                    <Box className={classes.cartHeader}>
                        <ButtonBase onClick={handleClickOnBackBtnRecycleOnline}>
                            <ArrowBackIosRoundedIcon
                                className={classes.backIcon}
                            />
                        </ButtonBase>
                        <Typography className={classes.title}>
                            Store Services
                        </Typography>
                    </Box>
                </NavTop>
                <BrandIframe
                    title="store-recycle"
                    id="store-recycle-iframe"
                    src={storeRecycleLink}
                    height={
                        isStoreModeOn
                            ? `calc(100vh - ${thinHeaderHeight} - ${storeModeStickyHeight} - ${footerHeight})`
                            : `calc(100vh - ${thinHeaderHeight} - ${footerHeight})`
                    }
                />
            </>
        );
    }
    if (storeLinkInNative.show) {
        return (
            <>
                {storeLinkInNative.src.includes('/stores/services/tsa') ||
                storeLinkInNative.src.includes('/stores/tech/pcsupport') ||
                storeLinkInNative.src.includes(
                    '/stores/services/tsa#participating-locations'
                ) ? (
                    <NavTop
                        withTopScroll
                        bgColor={COLORS.brandRed}
                        bgImageSrc={require('../assets/bgs/header-bg.svg')}
                        noBorderRadius
                        minHeight="0"
                    >
                        <Box className={classes.cartHeader}>
                            <ButtonBase
                                onClick={() => {
                                    setStoreLinkInNative({
                                        src: '',
                                        show: false,
                                    });

                                    setOffSetTop(0);
                                    setIsShowStickyHeader(false);
                                }}
                            >
                                <ArrowBackIosRoundedIcon
                                    className={classes.backIcon}
                                />
                            </ButtonBase>
                            <Typography className={classes.title}>
                                Store Services
                            </Typography>
                        </Box>
                    </NavTop>
                ) : (
                    <Header
                        backButton
                        onClickHandler={() => {
                            setStoreLinkInNative({ src: '', show: false });
                            setOffSetTop(0);
                            setIsShowStickyHeader(false);
                        }}
                    />
                )}
                <BrandIframe
                    src={storeLinkInNative.src}
                    title="store-page"
                    id="store-screen"
                    height={
                        isStoreModeOn
                            ? `calc(100vh - ${thinHeaderHeight} - ${storeModeStickyHeight} - ${footerHeight})`
                            : `calc(100vh - ${thinHeaderHeight} - ${footerHeight})`
                    }
                />
            </>
        );
    }
    if (storeServicesLink !== '' && !storeServicesExternalLink) {
        return (
            <>
                {storeServicesLink.includes('printing') ? (
                    <NavTop
                        withTopScroll
                        bgColor={COLORS.brandRed}
                        bgImageSrc={require('../assets/bgs/header-bg.svg')}
                        noBorderRadius
                        minHeight="0"
                    >
                        <Box className={classes.cartHeader}>
                            <Typography className={classes.title}>
                                Print Shop
                            </Typography>
                            <ButtonBase onClick={handleClickOnBackBtnOnline}>
                                <IconClose />
                            </ButtonBase>
                        </Box>
                    </NavTop>
                ) : (
                    <NavTop
                        withTopScroll
                        bgColor={COLORS.brandRed}
                        bgImageSrc={require('../assets/bgs/header-bg.svg')}
                        noBorderRadius
                        minHeight="0"
                    >
                        <Box className={classes.cartHeader}>
                            <ButtonBase onClick={handleClickOnBackBtnOnline}>
                                <ArrowBackIosRoundedIcon
                                    className={classes.backIcon}
                                />
                            </ButtonBase>
                            <Typography className={classes.title}>
                                Store Services
                            </Typography>
                        </Box>
                    </NavTop>
                )}

                <BrandIframe
                    title="store-services"
                    id="store-services-iframe"
                    src={storeServicesLink}
                    height={iframeHeight}
                />
            </>
        );
    }

    return (
        <>
            <div ref={containerRef} className={mergeClasses(classes.screen)}>
                {/* <Header /> */}
                {isShowStickyHeader && (
                    <div
                        className={classes.stickyStoreDetailCard}
                        style={{
                            position: 'fixed',
                            top: offSetTop,
                            zIndex: 1,
                            width: '100%',
                        }}
                    >
                        <Row
                            justifyContent={'space-between'}
                            alignItems={'flex-start'}
                        >
                            <>
                                <Box>
                                    <img
                                        src={require('../assets/icons/iconStaplesLogo.svg')}
                                        alt="staples connect logo"
                                    />
                                </Box>
                                <Body
                                    // className={classes.storeLocationText}

                                    id="YourStoreLocation"
                                    size={16}
                                    lineHeight={20}
                                    color="textWhite"
                                >
                                    {storeCommunity(mainStore) || 'Unknown'}
                                </Body>
                            </>
                        </Row>
                    </div>
                )}
                <div className={classes.storeDetailCard} ref={storeCardRef}>
                    <Box
                        sx={{
                            pb: '20px',
                        }}
                    >
                        <img
                            src={require('../assets/icons/iconStaplesLogo.svg')}
                            alt="staples connect logo"
                        />
                    </Box>
                    <Body size={16} lineHeight={20} color="textWhite">
                        Your store
                    </Body>
                    <Row justifyContent={'space-between'} alignItems={'center'}>
                        <>
                            <Body
                                // className={classes.storeLocationText}
                                weight="bold"
                                id="YourStoreLocation"
                                size={26}
                                lineHeight={32}
                                color="textWhite"
                            >
                                {storeCommunity(mainStore) || 'Unknown'}
                            </Body>
                            <Box
                                style={{
                                    cursor: 'pointer',
                                }}
                                onClick={showSearchModal}
                            >
                                <Body
                                    size={14}
                                    lineHeight={18}
                                    color="textWhite"
                                    className={classes.underLineText}
                                >
                                    Change
                                </Body>
                            </Box>
                        </>
                    </Row>
                    <StoreDetailCard />
                </div>

                {storeCreditData?.couponInfo?.couponValue && (
                    <FullWidthCard
                        cardContainerClassName={classes.storeCredit}
                        hasManualShadow
                    >
                        <Box
                            className={classes.storeCreditContainer}
                            onClick={() =>
                                handleStoreCreditNavigation(
                                    storeCreditData?.couponNumber
                                )
                            }
                        >
                            <Heading
                                size={26}
                                weight="bold"
                                type="h2"
                                ariaLabel={`Your ${storeCreditData.couponInfo.couponValue} $`}
                            >
                                <sup className={classes.superScript}>$</sup>
                                {storeCreditData.couponInfo.couponValue}
                            </Heading>
                            <div className={classes.promoText}>
                                <strong>promo card </strong>is now available!
                                <p className={classes.infoText}>
                                    In store only.{' '}
                                    <span aria-label="Expires">Exp&nbsp;</span>
                                    {format(
                                        new Date(
                                            storeCreditData.couponInfo.expirationDate
                                        ),
                                        'M/d/yy'
                                    )}
                                </p>
                            </div>

                            <div style={{ marginLeft: 'auto' }}>
                                <button
                                    className={styles.ariaButton}
                                    type="button"
                                    aria-label="Go to promo card"
                                >
                                    <img
                                        aria-hidden="true"
                                        className={classes.icon}
                                        src={require('../assets/icons/iconChevron.svg')}
                                        alt="chevron"
                                    />
                                </button>
                            </div>
                        </Box>
                    </FullWidthCard>
                )}
                {printFromAppEnabled?.isenabled
                    ? SelfServicesPrintingCardMemo
                    : null}
                <StoreServiceCard
                    storeData={mainStore}
                    recycleRef={recycleRef}
                    openStoreSearch={() => setSearchModalOpen(true)}
                    storeServicesLink={storeServicesLink}
                    setStoreServicesLink={setStoreServicesLink}
                    storeServicesExternalLink={storeServicesExternalLink}
                    setStoreServicesExternalLink={setStoreServicesExternalLink}
                />
                <ViewAllDigitalCoupons
                    userInfo={userInfo}
                    setDotComUrl={setDotComUrl}
                    setStoreLinkInNative={setStoreServicesLink}
                />
                {/* {recycleData && ( */}
                <div ref={recycleRef} id="recycleData">
                    <RecycleEveryDay
                        recycleData={recycleData}
                        toggleDrawer={toggleDrawer}
                        setStoreRecycleLink={setStoreRecycleLink}
                        toggleLocal={handleClickLocal}
                        toggleJoinFree={toggleJoinFree}
                    />
                    {localShow && <LocalImpact recycleData={recycleData} />}
                </div>
                {/* )} */}
                <TsaPreCheck />
                {/* to remove ups box from screen */}

                {!isGuestModeOn && (
                    <ShippingCard
                        handleHolidatShipping={handleHolidatShipping}
                        shipmentListData={shipmentListData}
                    />
                )}

                <ProtectionPlans />
                <SwipeableDrawer
                    anchor="bottom"
                    open={state}
                    onClose={() => toggleDrawer(false)}
                    onOpen={() => toggleDrawer(true)}
                >
                    <Box className={classes.infoWrapper}>
                        <div className={classes.indicator}> </div>
                        <Heading
                            type="h2"
                            weight="bold"
                            size={26}
                            className={classes.infoHeading}
                        >
                            Mail-back recycling program
                        </Heading>
                        {/* <Typography className={classes.infoHeading}>
                        Mail-back recycling program
                    </Typography> */}
                        <Typography className={classes.infoDrawerText}>
                            We'll create a shipping label for you to ship your
                            ink and toner cartridges out to be recycled at no
                            cost.
                        </Typography>
                        <Button
                            variant="contained-rounded"
                            bgColor="bgBlack"
                            color="textWhite"
                            onClick={() => {
                                if (isGuestModeOn) {
                                    handleSignIn();
                                } else {
                                    setStoreRecycleLink(rewardcenterURL);
                                }
                            }}
                            className={classes.btnAlign}
                        >
                            Get Started
                        </Button>
                    </Box>
                </SwipeableDrawer>

                <StoreSearchModal
                    open={searchModalOpen}
                    handleClose={handleSearchModalClose}
                    nearbyStores={additionalStores}
                    mainStore={mainStore}
                    onStoreSelected={onStoreSelected}
                />
            </div>
            {/* {isOpenBrowseFile && YourFileSectionMemo} */}
        </>
    );
}

const StoresBaseRouter = ({ showStoreModeNotificationHeader }: Props) => {
    const match = useRouteMatch();
    const { history } = useRouter();
    const goBack = () => history.push(match.path);

    return (
        <Switch>
            <Route
                path={`${match.path}/shippingServices/createShippingInformation`}
            >
                <CreateShippingContainer />
            </Route>
            <Route path={`${match.path}/shippingServices/labelShipment`}>
                <ShippingServicesContainer />
            </Route>
            <Route
                path={`${match.path}/shippingServices/quoteShippingInformation`}
            >
                <ShippingQuoteEstimatorContainer />
            </Route>
            <Route path={`${match.path}/shippingServices`}>
                <ShippingServicesHome goBack={goBack} />
            </Route>
            <Route path={match.path}>
                <Stores
                    showStoreModeNotificationHeader={
                        showStoreModeNotificationHeader
                    }
                />
            </Route>
        </Switch>
    );
};

export default StoresBaseRouter;
