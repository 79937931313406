import React, { useContext, useEffect, useState } from 'react';
import { Box, ButtonBase, makeStyles } from '@material-ui/core';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useEventsReporter from 'src/hooks/useEventsReporter';
import useRouter from 'src/hooks/useRouter';
import {
    deleteShipment,
    deleteTracking,
} from 'src/lib/api-client/shipment-services';
import COLORS from 'src/lib/colors';
import { Body, Heading } from 'src/components/UI';
import AppLink from 'src/components/link';
import FullWidthCard from '../../cards/full-width-card';
import ROUTES from '../../../lib/routes';
import { phoneNumberFormat } from '../../../lib/utils/string-utils';
import ShippingInfoContext from '../../../lib/contexts/shipping-info-context';
import DeleteStatusDialog from './delete-status-dialog';

const useStyles = makeStyles((theme) => ({
    shippingAddressContainer: {
        display: 'flex',
        flexDirection: 'column',
        background: 'none',
        margin: '0px 23px',
        '& .MuiCardContent-root': {
            padding: 0,
        },
    },
    shippingReceiverAddressContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '30px',
    },
    shippingNamePackages: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    shippingReceiverName: {
        display: 'flex',
        flexDirection: 'column',
    },
    shippingGoingTo: {
        position: 'relative',
        '&::before': {
            content: '" "',
            background: `url(${require('../../../assets/icons/iconMarker.svg')}) 0 0 no-repeat`,
            position: 'absolute',
            width: '20px',
            height: '20px',
            left: '-27px',
        },
    },

    shippingReceiverPackagesContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    shippingPackageCount: {
        textAlign: 'right',
        marginTop: '10px',
    },
    shippingSenderAddressContainer: {
        margin: '30px 0 30px',
        paddingLeft: '25px',
        position: 'relative',
        '&::after': {
            content: '" "',
            backgroundImage: `linear-gradient(${COLORS.whiteSmoke} 25%, rgba(255,255,255,0) 0%)`,
            backgroundPosition: 'right',
            backgroundSize: '3px 14px',
            backgroundRepeat: 'repeat-y',
            position: 'absolute',
            top: '22px',
            left: '-1px',
            width: '10px',
            height: '100%',
        },
    },
    shippingSenderName: {
        position: 'relative',
        '&::before': {
            position: 'absolute',
            content: '" "',
            border: `1.5px solid ${COLORS.brandRed}`,
            borderRadius: '50%',
            width: '15px',
            height: '15px',
            top: '3px',
            left: '-25px',
        },
    },

    shippingSenderInfoDetails: {
        marginTop: '-12px',
    },
    shippingNeedDetails: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '39px',
        marginTop: '25px',
    },
    shippingNeedDetailsText: {
        width: '203px',
    },
    shippingStartShipmentText: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '0px',
        color: '#086DD2',
    },
    shippingDeleteLabelContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '9px',
        marginBottom: '30px',
    },
    shippingDeleteLabel: {
        fontWeight: 'bold',
    },
    shippingSenderContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

const ShippingServicesSenderReceiverAddress = ({
    data,
    activeTab,
    updatedData,
}: any) => {
    const {
        recipientdetails,
        packagedetails,
        shipperdetails,
        shimpmentstatus,
    }: any = data;
    const address = data?.recipientdetails;
    const { analyticsCustomEvent } = useEventsReporter();
    const { history } = useRouter();
    const {
        updateRecipient,
        updateSender,
        updateOpenScanToTrack,
        openScanToTrack,
    } = useContext(ShippingInfoContext);
    const [deleteStatusDialogOpen, setDeleteStatusDialogOpen] = useState(false);
    const [deleteStatusSuccess, setDeleteStatusSuccess] = useState(false);
    const [deleteTrackingSuccess, setDeleteTrackingSuccess] = useState(false);
    const [deleteTrackingDialogOpen, setDeleteTrackingDialogOpen] =
        useState(false);
    const handleDeleteStatusModel = () => {
        setDeleteStatusDialogOpen(true);
    };
    const handleOnDeleteTrackingModel = () => {
        setDeleteTrackingDialogOpen(true);
    };
    const handleCloseDialog = () => {
        setDeleteStatusDialogOpen(false);
        setDeleteTrackingDialogOpen(false);
    };
    const handleOnDeleteStatus = async () => {
        const deleteShipmentResponse = await deleteShipment(
            shimpmentstatus?.shipmentnumber
        );
        if (deleteShipmentResponse?.isSuccess === true) {
            setDeleteStatusSuccess(true);
            setDeleteStatusDialogOpen(false);
            updatedData();
        }
    };

    const handleOnDeleteTracking = async () => {
        const deleteTrackingResponse = await deleteTracking(
            shimpmentstatus?.shipmenttrackingnumbers[0]
        );
        if (deleteTrackingResponse?.issuccess === true) {
            setDeleteTrackingSuccess(true);
            setDeleteTrackingDialogOpen(false);
            updatedData();
        }
    };

    const classes = useStyles();
    const handleStartShipment = () => {
        updateRecipient(recipientdetails);
        updateSender(shipperdetails);
        // openInNewTab("https://shipping.staples.com/")
        analyticsCustomEvent(eventMetricsMap.start_shipment, {
            click_text: 'start shipment',
            element_location: 'labels and shipment - created',
        });
        history.push({
            pathname: ROUTES.createShippingInformation,
            search: '?step=CreateShippingRecipientInformation',
        });
    };
    const scanAnotherLabel = () => {
        updateOpenScanToTrack(true);
        history.push({
            pathname: ROUTES.shippingServices,
        });
    };
    return (
        <FullWidthCard className={classes.shippingAddressContainer}>
            {data?.shipmenttype === 'APPSHIPMENT' ? (
                <>
                    <div className={classes.shippingSenderAddressContainer}>
                        <div className={classes.shippingSenderContainer}>
                            <div>
                                <Heading
                                    type="h4"
                                    size={18}
                                    className={classes.shippingSenderName}
                                >
                                    Ship from
                                </Heading>
                                <Body size={16} weight="light">
                                    {shipperdetails?.fullname}
                                </Body>
                            </div>
                            <div
                                className={
                                    classes.shippingReceiverPackagesContainer
                                }
                            >
                                <Body size={18} weight="normal">
                                    Packages
                                </Body>
                                <Body
                                    size={26}
                                    weight="bold"
                                    className={classes.shippingPackageCount}
                                >
                                    {packagedetails?.length}
                                </Body>
                            </div>
                        </div>

                        <div className={classes.shippingSenderInfoDetails}>
                            <Body weight="light">
                                {shipperdetails?.street1}
                            </Body>
                            {shipperdetails?.street2 ? (
                                <Body weight="light">
                                    {shipperdetails?.street2}
                                </Body>
                            ) : null}
                            <Body weight="light">
                                {shipperdetails.city}, {shipperdetails.state}{' '}
                                {shipperdetails.postalcode}
                            </Body>
                            <Body weight="light">
                                {phoneNumberFormat(shipperdetails?.phonenumber)}
                            </Body>
                            <Body weight="light">{shipperdetails?.email}</Body>
                        </div>
                    </div>
                    <div className={classes.shippingReceiverAddressContainer}>
                        <div className={classes.shippingNamePackages}>
                            <div className={classes.shippingReceiverName}>
                                <Heading
                                    size={18}
                                    className={classes.shippingGoingTo}
                                    type="h4"
                                >
                                    Ship to
                                </Heading>
                                <Body weight="light">
                                    {recipientdetails?.fullname}
                                </Body>
                            </div>
                        </div>
                        <div>
                            <Body weight="light">
                                {recipientdetails.companyname}
                            </Body>
                            <Body weight="light">
                                {recipientdetails?.street1}
                            </Body>
                            {recipientdetails?.street2 ? (
                                <Body weight="light">
                                    {recipientdetails?.street2}
                                </Body>
                            ) : null}

                            <Body weight="light">
                                {recipientdetails.city},{' '}
                                {recipientdetails.state}{' '}
                                {recipientdetails.postalcode}
                            </Body>
                            <Body weight="light">
                                {phoneNumberFormat(
                                    recipientdetails?.phonenumber
                                )}
                            </Body>
                            <Body weight="light">
                                {recipientdetails?.email}
                            </Body>
                        </div>
                    </div>

                    <div className={classes.shippingNeedDetails}>
                        <Body
                            weight="light"
                            size={14}
                            className={classes.shippingNeedDetailsText}
                        >
                            Need to send another shipment to the same address as
                            above?
                        </Body>
                        <AppLink
                            className={classes.shippingStartShipmentText}
                            onClick={handleStartShipment}
                        >
                            Start shipment
                        </AppLink>
                    </div>
                </>
            ) : (
                <>
                    <div className={classes.shippingReceiverAddressContainer}>
                        <div className={classes.shippingNamePackages}>
                            <div className={classes.shippingReceiverName}>
                                <Heading
                                    type="h4"
                                    weight="normal"
                                    size={18}
                                    className={classes.shippingGoingTo}
                                >
                                    Shipping to
                                </Heading>
                                <Body weight="light">
                                    {`${address.city}, ${address.state}`}
                                </Body>
                            </div>
                            <div
                                className={
                                    classes.shippingReceiverPackagesContainer
                                }
                            >
                                <Body size={18} weight="normal">
                                    Packages
                                </Body>
                                <Body
                                    size={26}
                                    weight="bold"
                                    className={classes.shippingPackageCount}
                                >
                                    1
                                </Body>
                            </div>
                        </div>
                    </div>
                    <div
                        className={classes.shippingNeedDetails}
                        style={{ marginTop: '25px' }}
                    >
                        <Body
                            weight="light"
                            size={14}
                            className={classes.shippingNeedDetailsText}
                        >
                            Need to scan another shipment?
                        </Body>
                        <AppLink
                            className={classes.shippingStartShipmentText}
                            onClick={scanAnotherLabel}
                        >
                            Scan label
                        </AppLink>
                    </div>
                </>
            )}
            {activeTab === 'create' ? (
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <ButtonBase
                        className={classes.shippingDeleteLabelContainer}
                        onClick={handleDeleteStatusModel}
                    >
                        <img
                            src={require('../../../assets/icons/icon_delete.svg')}
                            alt="delete"
                        />
                        <Body
                            size={12}
                            color="textRed"
                            className={classes.shippingDeleteLabel}
                        >
                            Delete Label
                        </Body>
                    </ButtonBase>
                    {deleteStatusDialogOpen && (
                        <DeleteStatusDialog
                            open={deleteStatusDialogOpen}
                            onClose={handleCloseDialog}
                            onDelete={handleOnDeleteStatus}
                            message={`This will erase your label and new one 
                            will need to be created for your shipment.`}
                            title={
                                'Are you sure you want to delete this shipment label?'
                            }
                            buttonLabel={'Delete Label'}
                        />
                    )}
                </div>
            ) : null}
            {activeTab === 'delivered' || activeTab === 'shipped' ? (
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    {data?.shipmenttype === 'APPSHIPMENT' ? (
                        <ButtonBase
                            className={classes.shippingDeleteLabelContainer}
                            onClick={handleDeleteStatusModel}
                        >
                            <img
                                src={require('../../../assets/icons/icon_delete.svg')}
                                alt="delete"
                            />
                            <Body
                                size={12}
                                color="textRed"
                                className={classes.shippingDeleteLabel}
                            >
                                Delete Shipment
                            </Body>
                        </ButtonBase>
                    ) : (
                        <ButtonBase
                            className={classes.shippingDeleteLabelContainer}
                            onClick={handleOnDeleteTrackingModel}
                        >
                            <img
                                src={require('../../../assets/icons/icon_delete.svg')}
                                alt="delete"
                            />
                            <Body
                                size={12}
                                color="textRed"
                                className={classes.shippingDeleteLabel}
                            >
                                Delete Tracking
                            </Body>
                        </ButtonBase>
                    )}
                    {deleteStatusDialogOpen && (
                        <DeleteStatusDialog
                            open={deleteStatusDialogOpen}
                            onClose={handleCloseDialog}
                            onDelete={handleOnDeleteStatus}
                            message={`Deleting this shipment status will not stop the shipment.
                            to view the future statuses of this shipment, you will need to enter
                             your tracking number at UPS.com`}
                            title={
                                'Are you sure you want to delete this shipment status?'
                            }
                            buttonLabel={'Delete Shipment'}
                        />
                    )}
                    {deleteTrackingDialogOpen && (
                        <DeleteStatusDialog
                            open={deleteTrackingDialogOpen}
                            onClose={handleCloseDialog}
                            onDelete={handleOnDeleteTracking}
                            message={`Deleting this shipment status will not stop the shipment.
                            to view the future statuses of this shipment, you will need to enter
                             your tracking number at UPS.com`}
                            title={
                                'Are you sure you want to delete this shipment status?'
                            }
                            buttonLabel={'Delete Tracking'}
                        />
                    )}
                </div>
            ) : null}
        </FullWidthCard>
    );
};

export default ShippingServicesSenderReceiverAddress;
