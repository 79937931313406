/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import clsx from 'clsx';
import {
    Box,
    Typography,
    ListItem,
    List,
    ListItemText,
    Drawer,
} from '@material-ui/core';
import Row from 'src/components/Row';
import * as yup from 'yup';
import {
    OtpRequestBody,
    resendOtp,
    UserCredentialRequest,
    verifyOtp,
} from 'src/lib/api-client/otpServices';
import Captcha from 'src/components/login-flow/captcha';
import { getStplSessionIdUniqueId, initNudata } from 'src/lib/utils/app-utils';
import useLoginScreenStyles from 'src/components/login-flow/useLoginScreenStyles';
import AppLink from 'src/components/link';
import LoadingButton from '../../components/loading-button';
import { Body, Button, Heading, SwipeableDrawer } from '../../components/UI';
import styles from './styles.module.scss';
import CheckWithCircle from '../../assets/images/CheckWithCircle.png';
import InfoRedCircle from '../../assets/icons/infoRedCircle.svg';
import SiteDown from '../../assets/icons/siteDown.svg';

interface OtpScreenProps {
    toggleOtpScreen: (
        openState: boolean,
        otpBody: Partial<OtpRequestBody> | null
    ) => void;
    userInfoBody: Partial<OtpRequestBody> | null;
    nPayload?: any;
    loginSucessfull?: (data: any) => void;
    setMessageToShowOnLogin?: (data: any) => void;
}

const validationSchemaFields = yup.object({
    otp: yup
        .string() // Ensures exactly 6 digits
        .required('Passcode is required field.'),
});

function OtpScreen({
    toggleOtpScreen,
    userInfoBody,
    nPayload,
    loginSucessfull,
    setMessageToShowOnLogin,
}: OtpScreenProps) {
    const classes = useLoginScreenStyles();
    const [loginErrorMessage, setLoginErrorMessage] = useState('');
    const [errorState, setErrorState] = useState<'success' | 'error'>(
        'success'
    );
    const [tosSource, setTosSource] = useState<string | null>(null);

    const [message, setMessage] = useState('');
    const [showErrorPage, setShowErrorPage] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const toggleErrorPage = () => setShowErrorPage(!showErrorPage);
    const [captchaHtml, setCaptchaHtml] = useState('');

    useEffect(() => {
        initNudata('LoginWebSC');
    });

    const onSubmitOtp = async (otp: string) => {
        setLoading(true);
        setShowAlert(false);
        setMessageToShowOnLogin?.('');
        setMessage('');
        try {
            const body = {
                ...userInfoBody,
                otpCode: otp.toString(),
                page: 1,
            } as OtpRequestBody;
            const response = await verifyOtp(body);

            if (response) {
                if (response.code === 3) {
                    setShowAlert(false);
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    formikFieldValue.setErrors({ otp: response.msg });
                } else if (response.code === 2451 || response.code === 1) {
                    setShowAlert(true);
                    setErrorState('error');
                    setMessage(response.msg);
                    if (response.code === 2451) {
                        setMessageToShowOnLogin?.(response.msg);
                    }
                } else if (response.code === 6902) {
                    setShowAlert(true);
                    setErrorState('success');
                    setMessage(response.msg);
                } else if (response.code === 0) {
                    setShowAlert(false);
                    toggleErrorPage();
                } else if (response.status) {
                    loginSucessfull?.(response);
                } else {
                    setShowAlert(false);
                    toggleErrorPage();
                }
            }
        } catch (error) {
            console.log('error', error);
            setShowAlert(false);
        } finally {
            setLoading(false);
        }
    };
    const onResendOtp = async () => {
        const nuCaptchaAnswer = (document.getElementById(
            'nucaptcha-answer'
        ) as HTMLInputElement)
            ? (document.getElementById('nucaptcha-answer') as HTMLInputElement)
                  .value
            : '';
        const nuCaptchaToken = (document.getElementById(
            'nucaptcha-token'
        ) as HTMLInputElement)
            ? (document.getElementById('nucaptcha-token') as HTMLInputElement)
                  .value
            : '';
        setLoading(true);
        setLoading(true);
        setShowAlert(false);
        setMessageToShowOnLogin?.('');
        setMessage('');
        try {
            const body = {
                username: userInfoBody?.eUserName,
                tenant: userInfoBody?.tenant,
                requestUrl: '/app/resend-otp',
                stplSessionId: userInfoBody?.stplSessionId,
                placement: userInfoBody?.placement,
                channel: userInfoBody?.channel,
                docId: userInfoBody?.eDocId,
                ndsModeValue: nPayload,
                captchaAnswer: nuCaptchaAnswer,
                page: 2,
                nuCaptchaToken,
            } as UserCredentialRequest;
            const response = await resendOtp(body);

            const captchaInRes = response?.captchaResponse?.captchaResponseHtml;
            if (captchaInRes) {
                setLoading(false);
                return setCaptchaHtml(captchaInRes);
            }
            if (response.code === 3) {
                setShowAlert(false);
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                formikFieldValue.setErrors({ otp: response.msg });
            } else if (response.code === 2451 || response.code === 1) {
                setShowAlert(true);
                setErrorState('error');
                setMessage(response.msg);
                if (response.code === 2451) {
                    setMessageToShowOnLogin?.(response.msg);
                }
            } else if (response.code === 6902) {
                setShowAlert(true);
                setErrorState('success');
                setMessage(response.msg);
            } else if (response.code === 0) {
                setShowAlert(false);
                toggleErrorPage();
            }
            return response?.data;
        } catch (error) {
            setShowAlert(false);
            return console.log('error', error);
        } finally {
            setLoading(false);
        }
    };
    const formikFieldValue = useFormik({
        initialValues: {
            otp: '',
        },
        validationSchema: validationSchemaFields,
        validateOnMount: true,
        onSubmit: (values) => {
            onSubmitOtp(values.otp);
        },
    });
    const { handleSubmit } = formikFieldValue;

    const handleOnBlur = (e: string) => {
        if (loginErrorMessage) {
            setLoginErrorMessage('');
        }
        if (e) {
            formikFieldValue.setTouched({
                ...formikFieldValue.touched,
                [e]: true,
            });
        }
    };
    const [drawerState, setDrawerState] = useState({
        bottom: false,
    });
    const toggleDrawer = (anchor: any, open: boolean) => () => {
        setDrawerState({ ...drawerState, [anchor]: open });
    };
    const openEmail = () => {
        setDrawerState({ ...drawerState, bottom: true });
    };

    const isOtpErrorMsg =
        formikFieldValue.touched.otp && Boolean(formikFieldValue.errors.otp);

    const AlertMessageMemo = useMemo(
        () => (
            <Row
                gap="8px"
                sx={{
                    alignItems: 'center',
                    mb: '30px',
                    p: '16px',
                    borderRadius: '10px',
                    bgcolor: errorState === 'error' ? '#FBEBEB' : '#E0F7E2',
                }}
            >
                <>
                    <img
                        width={24}
                        height={24}
                        src={
                            errorState === 'error'
                                ? InfoRedCircle
                                : CheckWithCircle
                        }
                        alt="check"
                    />
                    {errorState === 'error' ? (
                        <Body size={16} weight="light" lineHeight={20}>
                            {message}
                        </Body>
                    ) : (
                        <div style={{ position: 'relative' }}>
                            <Body
                                className={classes.rubyText}
                                size={16}
                                weight="light"
                                lineHeight={20}
                            >
                                {message} &nbsp;
                                <div
                                    role="button"
                                    tabIndex={0}
                                    style={{
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                    }}
                                    // onClick={onResendOtp}
                                    onKeyDown={(e) => {
                                        if (
                                            e.key === 'Enter' ||
                                            e.key === ' '
                                        ) {
                                            // onResendOtp();
                                        }
                                    }}
                                >
                                    <Button
                                        onClick={openEmail}
                                        variant="text"
                                        fontSize={16}
                                        className={classes.openEmailTextAlign}
                                    >
                                        Open email
                                    </Button>
                                </div>
                            </Body>
                        </div>
                    )}
                </>
            </Row>
        ),
        [errorState, message, showAlert]
    );
    const emailList = [
        {
            displayText: 'Gmail',
            target: 'https://gmail.com',
        },
        {
            displayText: 'Yahoo',
            target: 'https://yahoomail.com',
        },
        {
            displayText: 'Outlook',
            target: 'https://outlook.com',
        },
        {
            displayText: 'iCloud',
            target: 'https://icloud.com',
        },
        {
            displayText: 'Aol',
            target: 'https://login.aol.com',
        },
    ];
    const list = (anchor: any) => (
        <div
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Typography className={classes.helperText}>Choose email</Typography>

            <List style={{ background: '#fff', borderRadius: '0 0 15px 15px' }}>
                {emailList.map(({ displayText, target }, index) => (
                    <ListItem
                        button
                        key={displayText}
                        style={{
                            borderBottom:
                                index === emailList.length - 1
                                    ? '0px none'
                                    : '1px solid #d9d9d6',
                            paddingTop: index === 0 ? '0px' : '',
                            paddingBottom:
                                index === emailList.length - 1 ? '0px' : '',
                            textAlign: 'center',
                        }}
                    >
                        <AppLink
                            id="email-button"
                            href={target}
                            className={classes.emailLink}
                            target="_blank"
                        >
                            <ListItemText primary={displayText} />
                        </AppLink>
                    </ListItem>
                ))}
            </List>
            <List
                style={{
                    background: '#fff',
                    borderRadius: '15px',
                    marginTop: '25px',
                    marginBottom: '15px',
                }}
            >
                <ListItem
                    style={{
                        textAlign: 'center',
                        paddingBottom: '0px',
                        paddingTop: '0px',
                    }}
                >
                    <AppLink
                        id="cancel-button"
                        href="#"
                        className={classes.emailLink}
                    >
                        <ListItemText primary="Cancel" />
                    </AppLink>
                </ListItem>
            </List>
        </div>
    );
    return (
        <Box
            className={styles.screen}
            style={{
                justifyContent: 'flex-start',
                height: 'auto',
            }}
        >
            <Box
                onClick={
                    showErrorPage
                        ? toggleErrorPage
                        : () => toggleOtpScreen(false, null)
                }
            >
                <img
                    // className={styles.backIcon}
                    src={require('../../assets/icons/iconBackArrow.svg')}
                    alt="iconBack"
                />
            </Box>
            <div className={styles.loginContainer}>
                {showErrorPage ? (
                    <>
                        <Heading
                            size={26}
                            weight="bold"
                            type="h2"
                            className={styles.title}
                        >
                            Sorry, there’s an issue on our end.
                        </Heading>
                        <Row
                            gap="60px"
                            direction="column"
                            sx={{
                                mt: '50px',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <>
                                <img
                                    width={200}
                                    height={200}
                                    src={SiteDown}
                                    alt="iconBack"
                                />
                                <Body size={14} weight="light" lineHeight={18}>
                                    Please try again later
                                </Body>
                            </>
                        </Row>
                    </>
                ) : (
                    <>
                        {showAlert && AlertMessageMemo}
                        <Heading
                            size={26}
                            weight="bold"
                            type="h2"
                            className={styles.title}
                        >
                            Let’s verify it’s you
                        </Heading>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <Body size={16} weight="light" lineHeight={20}>
                                We sent a passcode to your email. Please check
                                your inbox (and spam folder) and enter the code
                                to continue.{' '}
                                <Button
                                    onClick={openEmail}
                                    variant="text"
                                    fontSize={16}
                                    className={classes.openEmailTextAlign}
                                >
                                    Open email
                                </Button>
                            </Body>
                        </div>
                        <form
                            id="otpForm"
                            onSubmit={handleSubmit}
                            className={styles.form}
                        >
                            <div
                                className={styles.fieldContainer}
                                style={{ position: 'relative' }}
                            >
                                <label
                                    id="otp-label"
                                    htmlFor="otp"
                                    className={styles.labelAlign}
                                >
                                    One time passcode
                                </label>
                                <input
                                    type={'text'}
                                    id="otp"
                                    name="otp"
                                    value={formikFieldValue.values.otp}
                                    onChange={(e) => {
                                        formikFieldValue.handleChange(e);
                                    }}
                                    onBlur={() => handleOnBlur('otp')}
                                    onFocus={(e) => {
                                        e.target.className =
                                            styles.inputFieldActive;
                                    }}
                                    className={styles.inputFieldAlign}
                                    required
                                    style={{
                                        border: isOtpErrorMsg
                                            ? '1px solid red'
                                            : '1px solid #ccc',
                                        boxShadow: isOtpErrorMsg
                                            ? '0px 0px 0px 4px #CC00001A'
                                            : 'none',
                                    }}
                                    maxLength={6}
                                />
                                {isOtpErrorMsg ? (
                                    <Body
                                        className={styles.errorAlign}
                                        margin="5px 0 0 0"
                                        size={12}
                                        lineHeight={16}
                                        weight="light"
                                    >
                                        {formikFieldValue.errors.otp}{' '}
                                    </Body>
                                ) : null}
                            </div>

                            <LoadingButton
                                id="loginButton"
                                variant="contained"
                                fullWidth
                                loading={isLoading}
                                classes={{
                                    root: clsx(styles.loginButton),
                                }}
                                type="submit"
                                disabled={
                                    !(
                                        formikFieldValue?.values?.otp
                                            ?.length === 6
                                    )
                                }
                            >
                                Verify
                            </LoadingButton>
                        </form>
                        <Box
                            sx={{
                                mt: '20px',
                            }}
                        >
                            <Row>
                                <>
                                    <Body
                                        size={14}
                                        weight="light"
                                        lineHeight={18}
                                    >
                                        Didn’t get the email? &nbsp;
                                    </Body>
                                    <Button
                                        tabIndex={-1}
                                        variant="text"
                                        display="inlineFlex"
                                        fontSize={14}
                                        className={classes.hyperLinkText}
                                        id="otp-contact-support"
                                        onClick={onResendOtp}
                                    >
                                        Resend code
                                    </Button>
                                    <Body
                                        size={14}
                                        weight="light"
                                        lineHeight={18}
                                    >
                                        , or &nbsp;
                                    </Body>
                                    <Button
                                        tabIndex={-1}
                                        variant="text"
                                        display="inlineFlex"
                                        fontSize={14}
                                        className={classes.hyperLinkText}
                                        id="otp-contact-support"
                                        onClick={() => {
                                            setTosSource(
                                                `${process.env.REACT_APP_DOTCOM_URL}/hc`
                                            );
                                        }}
                                    >
                                        contact support.
                                    </Button>
                                </>
                            </Row>
                        </Box>
                        <Captcha
                            capthaHtml={captchaHtml}
                            sessionId={getStplSessionIdUniqueId()}
                            onCaptchaSumbit={onResendOtp}
                            placement="LoginWebSC"
                        />
                    </>
                )}
            </div>
            <div id="drawer-container" style={{ position: 'relative' }}>
                <Drawer
                    PaperProps={{
                        style: {
                            position: 'absolute',
                            margin: '0 24px',
                            borderRadius: '15px',
                            background: 'transparent',
                            boxShadow: 'none',
                        },
                    }}
                    ModalProps={{
                        container: document.getElementById('drawer-container'),
                        keepMounted: true,
                    }}
                    anchor="bottom"
                    open={drawerState.bottom}
                    onClose={toggleDrawer('bottom', false)}
                >
                    {list('bottom')}
                </Drawer>
            </div>

            <SwipeableDrawer
                anchor="bottom"
                open={!!tosSource}
                onClose={() => {
                    setTosSource(null);
                }}
                onOpen={() => console.log('open')}
            >
                <iframe
                    id="privacy-link"
                    title="privacy link iFrame"
                    style={{ height: '90vh', width: '100%', marginTop: 16 }}
                    src={tosSource || '#'}
                />
            </SwipeableDrawer>
        </Box>
    );
}

export default OtpScreen;
