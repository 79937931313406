import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { Player } from '@lottiefiles/react-lottie-player';
import styles from './styles.module.scss';

interface PullToRefreshProps {
    children: React.ReactNode;
    onRefresh: () => Promise<any>;
    bgColor?: string;
    scrollContainerRef?: React.MutableRefObject<any>;
}

function PullToRefresh({
    children,
    onRefresh,
    bgColor = 'bgWhite',
    scrollContainerRef,
}: PullToRefreshProps) {
    const [startPoint, setStartPoint] = useState(0);
    const [pullChange, setPullChange] = useState(0);
    const [loading, setLoading] = useState(false);
    const [lottie, setLottie] = useState<any>();
    const containerRef = useRef<HTMLDivElement | null>(null);

    const initLoading = () => {
        lottie?.play();
        setLoading(true);
        onRefresh().finally(() => {
            setLoading(false);
            lottie?.stop();
        });
    };

    const pullStart = (e: any) => {
        const { screenY } = e.targetTouches[0];
        setStartPoint(screenY);
    };

    const pull = (e: any) => {
        const touch = e.targetTouches[0];
        const { screenY } = touch;
        const pullLength =
            startPoint < screenY ? Math.abs(screenY - startPoint) : 0;
        if (containerRef?.current?.scrollTop === 0) {
            setPullChange(pullLength);
        }
    };

    const endPull = () => {
        if (pullChange > 180) initLoading();
        setStartPoint(0);
        setPullChange(0);
    };

    React.useEffect(() => {
        containerRef?.current?.addEventListener('touchstart', pullStart);
        containerRef?.current?.addEventListener('touchmove', pull);
        containerRef?.current?.addEventListener('touchend', endPull);
        return () => {
            containerRef?.current?.removeEventListener('touchstart', pullStart);
            containerRef?.current?.removeEventListener('touchmove', pull);
            containerRef?.current?.removeEventListener('touchend', endPull);
        };
    });

    return (
        <div
            ref={(element: HTMLDivElement) => {
                containerRef.current = element;
                if (scrollContainerRef) {
                    scrollContainerRef.current = element;
                }
            }}
            className={styles.container}
        >
            <div className={styles[bgColor]}>
                <motion.div
                    className={styles.loaderContainer}
                    initial={false}
                    transition={{ ease: 'linear' }}
                    animate={{
                        height: loading ? 50 : pullChange / 3.118 || 0,
                    }}
                >
                    <Player
                        lottieRef={(lottieRef) => setLottie(lottieRef as any)}
                        loop
                        src={require('../../assets/lotties/stapleRevealWordmark.json')}
                        style={{ height: '100px', width: '100px' }}
                    />
                </motion.div>
            </div>
            <div>{children}</div>
        </div>
    );
}

export default PullToRefresh;
