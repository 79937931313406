/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
// creating-shipping-package-information

import React, { useContext, useEffect, useState, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Box, ButtonBase, makeStyles, Typography } from '@material-ui/core';
import ROUTES from 'src/lib/routes';
import useRouter from 'src/hooks/useRouter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useEventsReporter from 'src/hooks/useEventsReporter';
import { IconCircularDelete } from 'src/components/Icons';
import { Button } from 'src/components/UI';
import COLORS from '../../../lib/colors';
import ShippingInfoContext from '../../../lib/contexts/shipping-info-context';
import PackageContentTooltipImage from '../../../assets/images/packageContentTooltipImage.svg';

const useStyles = makeStyles(() => ({
    header: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 20px',
        height: '66px',
        position: 'sticky',
        top: 0,
        backgroundColor: COLORS.primaryWhite,
        zIndex: 1000,
    },
    title: {
        width: '90%',
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '26px',
    },
    headerSubTitle: {
        fontSize: '22px',
        fontWeight: 700,
        lineHeight: '25.96px',
        letterSpacing: '-0.6px',
        width: '274px',
    },
    packageInfoContainer: {
        flexGrow: 1,
    },
    pkgTitle: {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '18.88px',
    },
    confirmBtnContainer: {
        backgroundColor: COLORS.primaryWhite,
        minHeight: '90px',
        position: 'sticky',
        bottom: '0px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderTop: '1px solid #F2F2F2',
    },
    confirmBtn: {
        background: '#CC0000',
        height: '40px',
        borderRadius: '20px',
        width: '80%',
        '& span': {
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '17px',
            letterSpacing: '0px',
            textAlign: 'center',
            color: '#ffffff',
        },
        '&.Mui-disabled': {
            backgroundColor: COLORS.disabledBackgroundGray,
            color: COLORS.disabledTextGray,
        },
    },
    mainPackageConatiner: {
        padding: '0 24px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        rowGap: 40,
    },
    packageTitleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '30px',
        paddingBottom: '5px',
    },
    addPackage: {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '20px',
        color: '#086DD2',
    },
    addPackageContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        columnGap: '10px',
        margin: '0 auto',
    },
    addPackagePlus: {
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '16.64px',
        letterSpacing: '-0.38px',
        color: '#086DD2',
    },
    infoText: {
        fontSize: '12px',
        textAlign: 'center',
        marginTop: '7px',
    },
    packagesFieldContainer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        '& .MuiFormLabel-root': {
            color: '#444444 !important',
        },
    },
    packageTextField: {
        marginBottom: '24px',
    },
    tooltip: {
        position: 'absolute',
        transform: 'translateY(-101%)',
        top: '0',
        width: '90vw',
        borderRadius: '20px',
        backgroundColor: COLORS.textBlack,
        opacity: 0.85,
        display: 'flex',
        backgroundImage: `url(${PackageContentTooltipImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: '80%',
        zIndex: 100,
    },
    tooltipText: {
        width: '60%',
        color: COLORS.primaryWhite,
        fontSize: '14px',
        padding: '30px 20px',
        marginRight: '10px',
    },
    tooltipCloseIcon: {
        position: 'absolute',
        right: '15px',
        top: '10px',
    },
    infoIcon: {
        width: '12px',
    },
    deleteBtn: {
        display: 'flex',
    },
    hide: {
        display: 'none',
    },
    errorText: {
        fontSize: '12px',
        fontWeight: 400,
        marginTop: '8px',
        lineHeight: '16px',
        color: '#CC0000',
        display: 'block',
        width: '100%',
    },
    fieldContainer: {
        marginBottom: '20px',
    },
    labelAlign: {
        position: 'relative',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '18px',
        textAlign: 'left',
        marginBottom: '10px',
        display: 'flex',
        color: COLORS.darkGray,
    },
    inputFieldActive: {
        fontFamily: 'StaplesNormsRegular',
        width: '100%',
        padding: '10px',
        fontSize: '16px',
        borderRadius: '10px !important',
        background: '#F2F2F2 !important',
        height: '50px',
        outline: 'none',
        boxShadow: 'none !important',
        fontWeight: 400,
        color: COLORS.textBlack,
    },
    inputFieldAlign: {
        fontFamily: 'StaplesNormsRegular',
        width: '100%',
        padding: '10px',
        fontSize: '16px',
        borderRadius: '10px !important',
        height: '50px',
        outline: 'none',
        background: '#ffffff !important',
        boxShadow: 'none !important',
        color: COLORS.textBlack,
    },
}));
interface packageProps {
    packageInfo: any;
    setPackageInfo: (data: any) => void;
}
const CreateShippingPackageInformation = ({
    packageInfo,
    setPackageInfo,
}: packageProps) => {
    const newPackageField = useRef<HTMLInputElement>(null);
    const classes = useStyles();
    const { history } = useRouter();
    const { analyticsCustomEvent } = useEventsReporter();
    const [packages, setPackages] = useState<any>({
        package1: { package: '', weight: '' },
    });
    const totalPkg = Object.keys(packages).length;
    const [totalPackages, setTotalPackages] = useState(totalPkg);
    const [fieldFocus, setfieldFocus] = useState('');
    const [showTooltip, setShowTooltip] = useState(false);

    let isFormValid = true;
    Object?.keys(packages).forEach((item) => {
        if (
            packages[item]?.package === '' ||
            packages[item]?.weight === '' ||
            packages[item]?.weight > 150 ||
            packages[item]?.package.length > 35
        ) {
            isFormValid = false;
        }
    });

    const handleOnAddPackage = () => {
        setTotalPackages(totalPackages + 1);
        setPackages((prevPackages: any) => ({
            ...prevPackages,
            [`package${totalPackages + 1}`]: { package: '', weight: '' },
        }));
        setTimeout(() => {
            newPackageField.current?.scrollIntoView({ behavior: 'smooth' });
        }, 0);
        analyticsCustomEvent(eventMetricsMap.shipping_info, {
            click_text: 'add package',
            element_location: 'package information',
        });
    };

    const handleOnRemovePackage = (key: string) => {
        setTotalPackages(totalPackages - 1);
        setPackages((prevPackages: any) => {
            const newPackages = { ...prevPackages };
            delete newPackages[key];
            return newPackages;
        });
    };

    const { inReviewStage } = useContext(ShippingInfoContext);

    const handleOnNext = () => {
        if (isFormValid) {
            setPackageInfo(packages);
            if (inReviewStage) {
                history.push({
                    pathname: ROUTES.createShippingInformation,
                    search: '?step=CreateShippingReviewInformation',
                });
            } else {
                history.push({
                    pathname: ROUTES.createShippingInformation,
                    search: '?step=CreateShippingReviewInformation',
                });
            }

            analyticsCustomEvent(eventMetricsMap.shipping_info, {
                click_text: 'next',
                element_location: 'package information',
            });
        }
    };
    useEffect(() => {
        if (Object.keys(packageInfo).length) {
            setPackages(packageInfo);
        }
    }, []);

    const validateValue = (name: string, value: any) => {
        if (name === 'package') {
            if (value.length === 0) {
                return 'Package contents is required field.';
            }
            if (value.length > 35) {
                return 'Should be less than 35 characters.';
            }
            return null;
        }
        if (name === 'weight') {
            if (value.length === 0) {
                return 'Weight is required field.';
            }
            if (value > 150) {
                return 'Please enter a weight value less than or equal to 150 lb.';
            }
            return null;
        }
        return null;
    };

    const handleChange = (e: any, key: any) => {
        const { name, value } = e.target;
        const trimmedValue = value.trim();
        setfieldFocus(name);
        // setContentError('');
        setPackages({
            ...packages,
            [key]: {
                ...packages[key],
                [name]: trimmedValue,
                contentError:
                    name === 'package'
                        ? validateValue(name, trimmedValue)
                        : packages[key]?.contentError,
                weightError:
                    name === 'weight'
                        ? validateValue(name, trimmedValue)
                        : packages[key]?.weightError,
            },
        });
    };

    const handleOnBlur = (e: any, key: any) => {
        const { name, value } = e.target;
        const trimmedValue = value.trim();
        setfieldFocus('');
        setPackages({
            ...packages,
            [key]: {
                ...packages[key],
                [name]: trimmedValue,
                contentError:
                    name === 'package'
                        ? validateValue(name, trimmedValue)
                        : packages[key]?.contentError,
                weightError:
                    name === 'weight'
                        ? validateValue(name, trimmedValue)
                        : packages[key]?.weightError,
            },
        });
    };

    console.log('273 packages', packages);

    return (
        <div className={classes.mainPackageConatiner}>
            <div className={classes.packageInfoContainer}>
                <AnimatePresence initial={false}>
                    {Object.keys(packages).map((key: any, index: any) => (
                        <motion.div
                            initial={{ scale: 0 }}
                            animate={{ scale: 1 }}
                            exit={{ scale: 0 }}
                            key={key}
                        >
                            <div className={classes.packageTitleContainer}>
                                <Typography
                                    className={classes.pkgTitle}
                                    aria-label={`Package ${index + 1}`}
                                >{`Package ${index + 1}`}</Typography>
                                {totalPkg > 1 && (
                                    <Button
                                        variant="icon"
                                        onClick={() =>
                                            handleOnRemovePackage(key)
                                        }
                                        contentClassName={classes.deleteBtn}
                                    >
                                        <IconCircularDelete />
                                    </Button>
                                )}
                            </div>
                            <div className={classes.fieldContainer}>
                                <label
                                    htmlFor="package"
                                    className={classes.labelAlign}
                                >
                                    Contents
                                    <span
                                        style={{
                                            color: COLORS.brandRed,
                                            marginLeft: '3px',
                                        }}
                                    >
                                        *
                                    </span>
                                </label>
                                <input
                                    type="text"
                                    id="create-shipping-package-input"
                                    aria-label="package content input"
                                    name="package"
                                    value={packages[key].package}
                                    onChange={(e) => handleChange(e, key)}
                                    onBlur={(e) => handleOnBlur(e, key)}
                                    style={{
                                        border: packages[key]?.contentError
                                            ? '1px solid red'
                                            : '1px solid #ccc',
                                        boxShadow: packages[key]?.contentError
                                            ? '0px 0px 0px 4px #CC00001A'
                                            : 'none',
                                    }}
                                    className={classes.inputFieldActive}
                                    required
                                />
                                {packages[key]?.contentError && (
                                    <span className={classes.errorText}>
                                        {packages[key]?.contentError}
                                    </span>
                                )}
                            </div>
                            <div className={classes.fieldContainer}>
                                <label
                                    htmlFor="email"
                                    className={classes.labelAlign}
                                >
                                    Estimated weight (lb.)
                                    <span
                                        style={{
                                            color: COLORS.brandRed,
                                            margin: '0px 3px',
                                        }}
                                    >
                                        *
                                    </span>
                                    {index === 0 && showTooltip && (
                                        <Box className={classes.tooltip}>
                                            <Typography
                                                className={classes.tooltipText}
                                            >
                                                Weight is for rate estimates.
                                                Packages are weighed at the
                                                store before they ship.
                                            </Typography>
                                            <img
                                                src={require('src/assets/icons/close-icon-white.svg')}
                                                className={
                                                    classes.tooltipCloseIcon
                                                }
                                                alt="close-icon"
                                                onClick={() =>
                                                    setShowTooltip(false)
                                                }
                                            />
                                        </Box>
                                    )}
                                    {index === 0 && (
                                        <img
                                            src={require('src/assets/icons/inforIcon.svg')}
                                            aria-label="info icon"
                                            alt="info icon"
                                            className={classes.infoIcon}
                                            onClick={() => setShowTooltip(true)}
                                        />
                                    )}
                                </label>
                                <input
                                    type="number"
                                    id="estimated-weight-input"
                                    aria-label="package weight input"
                                    name="weight"
                                    value={packages[key]?.weight}
                                    onChange={(e) => handleChange(e, key)}
                                    onBlur={(e) => handleOnBlur(e, key)}
                                    style={{
                                        border: packages[key]?.weightError
                                            ? '1px solid red'
                                            : '1px solid #ccc',
                                        boxShadow: packages[key]?.weightError
                                            ? '0px 0px 0px 4px #CC00001A'
                                            : 'none',
                                    }}
                                    className={
                                        fieldFocus === 'weight'
                                            ? classes.inputFieldActive
                                            : classes.inputFieldAlign
                                    }
                                    required
                                />
                                {packages[key]?.weightError && (
                                    <span className={classes.errorText}>
                                        {packages[key]?.weightError}
                                    </span>
                                )}
                            </div>
                        </motion.div>
                    ))}
                </AnimatePresence>
                <div ref={newPackageField}>
                    <ButtonBase
                        className={classes.addPackageContainer}
                        style={{
                            opacity:
                                Object.keys(packages).length >= 10 ? 0.5 : 1,
                        }}
                        disabled={Object.keys(packages).length >= 10}
                        onClick={handleOnAddPackage}
                    >
                        <div className={classes.addPackagePlus}>+</div>
                        <div
                            className={classes.addPackage}
                            id="create-shipping-add-package"
                            aria-label=" Add package"
                        >
                            Add package
                        </div>
                    </ButtonBase>
                    <Typography
                        className={classes.infoText}
                        aria-label="Ship up to 10 packages to the same address."
                    >
                        Ship up to 10 packages to the same address.
                    </Typography>
                </div>
            </div>
            <Box className={classes.confirmBtnContainer}>
                <ButtonBase
                    className={classes.confirmBtn}
                    onClick={handleOnNext}
                    disabled={!isFormValid}
                >
                    <span
                        id="create-shipping-confirm-button"
                        aria-label="Confirm"
                    >
                        Confirm
                    </span>
                </ButtonBase>
            </Box>
        </div>
    );
};

export default CreateShippingPackageInformation;
