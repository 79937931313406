/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useEffect, useState } from 'react';
import { ButtonBase, Typography, makeStyles } from '@material-ui/core';
import { Body } from 'src/components/UI';
import { useLoyaltyRewardPointsQuery } from 'src/services/easyRewardsServices';
import { useRouter } from 'src/hooks';
import ROUTES from 'src/lib/routes';

const useStyles = makeStyles({
    recycleText: {
        color: '#000000',
        lineHeight: '18px',
    },
    container: {
        display: 'flex',
        gap: '10px',
        background: '#ffffff',
        padding: '12px',
        borderRadius: '10px',
        margin: '0.625rem 1rem 1.25rem',
        alignItems: 'center',
        letterSpacing: '.25px',
    },
});

const RecyclingCartridgeEarning = () => {
    const classes = useStyles();

    const { push } = useRouter();

    const [loyaltyRewardPoints, setLoyaltyRewardPoints] = useState<
        Record<any, number | string>
    >({});
    const { data: RewardPoints }: any = useLoyaltyRewardPointsQuery();

    useEffect(() => {
        setLoyaltyRewardPoints(RewardPoints?.[0]?.results?.[0]);
    }, [RewardPoints]);

    const handleStoreNavigation = () => {
        push(`${ROUTES.stores}#recycleData`);
    };

    return (
        <ButtonBase
            className={classes.container}
            onClick={handleStoreNavigation}
            tabIndex={7}
            role="Button"
            aria-label={`Earn ${loyaltyRewardPoints?.points} points on each ink or toner cartridge you recycle, up to ${loyaltyRewardPoints?.maxnumberofcartridgesrecycled} per month.`}
        >
            <img
                src={require('../../../assets/icons/recyleIcon.svg')}
                alt="recycle icon"
            />
            <Body className={classes.recycleText} size={14} weight="light">
                Earn <b>{loyaltyRewardPoints?.points || 'additional'}</b> points
                on each ink or toner cartridge you recycle, up to
                <b>
                    {' '}
                    {loyaltyRewardPoints?.maxnumberofcartridgesrecycled ||
                        'a limit'}
                </b>{' '}
                per month.
            </Body>
        </ButtonBase>
    );
};

export default RecyclingCartridgeEarning;
