import React from 'react';
import { Body, Button } from 'src/components/UI';
import { page_favorite_selection_bottom_right_button } from 'src/utils/stringConstants';
import { mergeClasses } from 'src/stylesheets/utils';
import styles from './styles.module.scss';

interface FavoriteSelectionFooterProps {
    // variant: 'enrollment' | 'manage-favorites';
    selectedCategoriesLength: number;
    handleConfirm: () => void;
    isLoading: boolean;
}

function FavoriteSelectionFooter({
    selectedCategoriesLength,
    handleConfirm,
    isLoading,
}: FavoriteSelectionFooterProps) {
    return (
        <div className={styles.footer}>
            <div
                className={mergeClasses([
                    styles.flex,
                    styles.flexCol,
                    styles.alignCenter,
                ])}
            >
                <Body weight="light" size={14}>
                    Selected ({selectedCategoriesLength}/3)
                </Body>
            </div>
            <Button
                disabled={!(selectedCategoriesLength > 0) || isLoading}
                onClick={handleConfirm}
                loading={isLoading}
                breakpoint={420}
                fullWidth
            >
                {page_favorite_selection_bottom_right_button}
            </Button>
        </div>
    );
}

export default FavoriteSelectionFooter;
