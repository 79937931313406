import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogContent,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import LoadingButton from '../loading-button';
import COLORS from '../../lib/colors';

const useStyles = makeStyles((theme) => ({
    description: {
        textAlign: 'center',
        fontWeight: 500,
        fontSize: '16px',
        marginBottom: '20px',
    },
    btnCta: {
        margin: '20px 0px',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: COLORS.whiteSmoke,
        textAlign: 'center',
        background: COLORS.homeBlack,
        height: '2.5rem',
        // maxWidth: '17.5rem',
        borderRadius: '20px',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
        },
    },
    captchapaper: {
        [theme.breakpoints.down(360)]: {
            height: '70%',
        },
    },
}));

const Captcha = (props: any) => {
    const classes = useStyles();
    const [captchaAnswer, setCaptchaAnswer] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const { setLoginCaptchaResData } = useUserUpdateContext();

    const insertHTMLAndRunScripts = (elementID: string, incomingHtml: any) => {
        const element = document.getElementById(elementID) as HTMLElement;
        const scriptIDs = [];
        element.innerHTML = '';
        element.insertAdjacentHTML('beforeend', incomingHtml);

        const scripts = element.getElementsByTagName('script');

        while (scripts.length) {
            const script = scripts[0];
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            script?.parentNode.removeChild(script);
            const newScript = document.createElement('script');
            if (script?.src) {
                newScript.src = script.src;
            } else if (script.textContent) {
                newScript.textContent = script.textContent;
            } else if (script.innerText) {
                newScript.innerText = script.innerText;
            }
            // manage nonce attribute
            const nonce = script.getAttribute('nonce');
            if (nonce !== undefined && nonce !== null && nonce !== '') {
                newScript.setAttribute('nonce', nonce);
            }
            // set the ID of the incoming script

            newScript.id = uuidv4();
            scriptIDs.push(newScript.id);

            // only add the script if the same ID doesn’t exist on the page
            if (document.getElementById(newScript.id) == null) {
                document.body.appendChild(newScript);
            }
        }
        return scriptIDs;
    };

    const submitCaptcha = () => {
        props.onCaptchaSumbit();
        setLoginCaptchaResData({
            email: '',
            key: '',
            captcha: '',
        });
        setOpenDialog(false);
    };

    useEffect(() => {
        if (props.capthaHtml && props.capthaHtml.length) {
            setOpenDialog(true);
            setTimeout(() => {
                insertHTMLAndRunScripts('captcha-holder', props.capthaHtml);
            }, 10);

            setTimeout(() => {
                if (document.getElementById('nucaptcha-answer')) {
                    (
                        document.getElementById(
                            'nucaptcha-answer'
                        ) as HTMLInputElement
                    ).addEventListener('input', (e) => {
                        setCaptchaAnswer(
                            (e?.target as HTMLInputElement)?.value
                        );
                    });
                }
            }, 300);
        } else {
            setOpenDialog(false);
        }
    }, [props.capthaHtml]);

    return (
        <Dialog
            open={openDialog}
            classes={{ scrollPaper: classes.captchapaper }}
        >
            <DialogContent>
                <Typography className={classes.description}>
                    For security purposes, please type the characters to proceed
                </Typography>
                <div id="captcha-holder" />
                <LoadingButton
                    onClick={() => submitCaptcha()}
                    variant="contained"
                    fullWidth
                    classes={{
                        root: classes.btnCta,
                    }}
                    disabled={captchaAnswer.length === 0}
                    loading={false}
                >
                    Submit
                </LoadingButton>
            </DialogContent>
        </Dialog>
    );
};
export default Captcha;
