/* eslint-disable react/prop-types */
/* eslint-disable max-len */

import React, { useMemo, useState } from 'react';
import { ButtonBase, SwipeableDrawer } from '@material-ui/core';
import { Body, Heading, Button } from 'src/components/UI';
import { mergeClasses } from 'src/stylesheets/utils';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import { useEventsReporter, eventMetricsMap, useUserInfo } from 'src/hooks';
import IconBack from 'src/components/Icons/IconBack';
import { useTabsUIStateContext } from 'src/lib/contexts/tabs-ui-state-context';
import BrandIframe from 'src/components/BrandIframe';
import { useHistory } from 'react-router-dom';
import {
    IconBonusCategories,
    IconERCart,
    IconERGift,
    IconInkRecycle,
} from 'src/components/Icons';
import useCardCanvas from 'src/hooks/useCardCanvas';
import PointCalculator from 'src/screens/store-mode/point-calculator';
import {
    useLoyaltyRewardPointsQuery,
    FavoriteOffersList,
    useFavoriteOffersQuery,
} from 'src/services/easyRewardsServices';
import ROUTES from 'src/lib/routes';
import styles from './styles.module.scss';

interface InfoSectionProps {
    title: string;
    body: string;
    link?: string;
    linkCta?: () => void;
    ariaLabel?: string;
    image?: React.ReactElement;
    id: string;
}
function InfoSection({
    title,
    body,
    link,
    linkCta,
    ariaLabel,
    image,
    id,
}: InfoSectionProps) {
    return (
        <>
            <div className={styles.infoSection}>
                {image}
                <Heading
                    className={styles.infoTitle}
                    type="h3"
                    color="textPurple"
                    size={20}
                    weight="bold"
                >
                    {title}
                </Heading>
                <Body
                    className={styles.staplesGray}
                    weight="light"
                    size={14}
                    textAlign="textCenter"
                >
                    {body}
                </Body>

                {link && linkCta && (
                    <Button
                        onClick={linkCta}
                        variant="contained-rounded"
                        color="textWhite"
                        borderColor="borderPurple"
                        fontSize={14}
                        ariaLabel={link}
                        id={id}
                        className={styles.pointsButton}
                    >
                        {link}
                    </Button>
                )}
            </div>
        </>
    );
}

interface PointHistoryInfoDrawerProps {
    open: boolean;
    setOpen: (v: boolean) => void;
    variant: 'points-history' | 'points-info';
    hanRewardsWorks: (v: boolean) => void;
}
function PointHistoryDrawer({
    open,
    setOpen,
    variant,
    hanRewardsWorks,
}: PointHistoryInfoDrawerProps) {
    const { analyticsCustomEvent } = useEventsReporter();
    const { openChooseCategory } = useUserUpdateContext();
    const [isLoading, setIsLoading] = useState(true);
    const [currentIframeUrl, setCurrentIframeUrl] = useState('');
    const { pointHistoryPage, isIframeLoading } = useTabsUIStateContext();
    const { userInfo } = useUserInfo();
    const { data: loyaltyRewardPoints } = useLoyaltyRewardPointsQuery();
    const history = useHistory();
    const { canvasRef } = useCardCanvas(userInfo?.rewardsNumber);
    const documentData = document?.getElementById?.(
        'point-history'
    ) as HTMLIFrameElement;
    const [isCalculatorOn, setIsCalculatorOn] = useState(false);
    const [isElementScrolled, setIsElementScrolled] = useState(false);

    const pointHistoryUrl = `${process.env.REACT_APP_DOTCOM_URL}/grs/rewards/sr/pointhistory`;

    console.log(
        '🚀 ~ file: PointHistoryDrawer.tsx:58 ~ pointHistoryUrl:',
        pointHistoryUrl
    );

    const goBack = () => {
        if (!pointHistoryPage && documentData?.src) {
            documentData.src = pointHistoryUrl;
        }
    };

    const { data: offerList, isFetching } = useFavoriteOffersQuery({
        initialData: [] as unknown as FavoriteOffersList,
    });

    const subscribedList = useMemo(
        () => offerList?.filter((offer) => offer.subscribed),
        [offerList]
    );
    const isSubscribedOffersListEmpty = subscribedList?.length === 0;

    const pointInfoList: InfoSectionProps[] = [
        {
            title: 'Earn 1 point per $1 spent',
            body: 'In store, online and in the app. Exclusions apply.*',
            link: 'Points calculator',
            image: <IconERCart />,
            linkCta: () => {
                analyticsCustomEvent(eventMetricsMap.easy_rewards, {
                    click_text: 'shop and earn',
                    element_location: 'how it works',
                    event_rewards_number:
                        userInfo?.rewardsNumber?.padStart(10, '0') || '',
                });
                // setOpen(false);
                setIsCalculatorOn(true);
            },
            ariaLabel: 'Points calculator',
            id: 'points-calculator',
        },
        {
            title: 'Bonus points categories',
            body: 'Earn 2x or more on the 3 categories you buy most.',
            link: isSubscribedOffersListEmpty
                ? 'Choose categories'
                : 'View categories',
            image: <IconBonusCategories />,
            id: 'choose-categories',
            linkCta: () => {
                analyticsCustomEvent(eventMetricsMap.easy_rewards, {
                    click_text: 'choose categories',
                    element_location: 'how it works',
                    event_rewards_number:
                        userInfo?.rewardsNumber?.padStart(10, '0') || '',
                });
                openChooseCategory(true);
            },
            ariaLabel: 'Choose categories link',
        },

        {
            title: 'Redeem points at checkout',
            body: 'Save instantly on your purchases, plus points never expire for active members.**',
            image: <IconERGift />,
            id: 'redeem-points',
        },
        {
            title: 'Ink and toner recycling',
            body: `Earn ${
                loyaltyRewardPoints?.[0]?.results?.[0].points || 'additional'
            } points on each ink or toner cartridge you recycle, up to ${
                loyaltyRewardPoints?.[0]?.results?.[0]
                    .maxnumberofcartridgesrecycled || 'a limit'
            } per month. No purchase required. Exclusions and limitations apply.†`,
            link: 'Recycle cartridges',
            image: <IconInkRecycle />,
            id: 'recycle-cartridges',
            linkCta: () => {
                analyticsCustomEvent(eventMetricsMap.easy_rewards, {
                    click_text: 'recycle cartridges',
                    element_location: 'how it works',
                    event_rewards_number:
                        userInfo?.rewardsNumber?.padStart(10, '0') || '',
                });
                document.location.href = '/somniapp/stores?recycleData=true';
            },
            ariaLabel: 'Recycle cartridges link',
        },
    ];

    const handleClose = () => {
        if (!isCalculatorOn) {
            hanRewardsWorks(false);
            setOpen(false);
        }
    };

    React.useEffect(() => {
        if (open) {
            const unblock = history.block(() => {
                handleClose();
                return false;
            });
            return () => {
                unblock();
            };
        }
        return undefined;
    }, [open]);

    React.useEffect(() => {
        if (open) {
            setTimeout(() => {
                const drawerElement = document.getElementById(
                    'point-history-drawer'
                );
                const handleScroll = () => {
                    if (drawerElement && drawerElement.scrollTop >= 245) {
                        setIsElementScrolled(true);
                    } else {
                        setIsElementScrolled(false);
                    }
                };
                drawerElement?.addEventListener('scroll', handleScroll);
                return () => {
                    drawerElement?.removeEventListener('scroll', handleScroll);
                };
            }, 1000);
        }
        return undefined;
    }, [open]);

    return (
        <SwipeableDrawer
            anchor="bottom"
            open={open}
            onClose={handleClose}
            onOpen={() => setOpen(true)}
            className={styles.swipDrawer}
            classes={{
                paper: isCalculatorOn ? styles.paperDrawer : undefined,
            }}
            onTouchStart={(event: any) => {
                if (isCalculatorOn) {
                    event.nativeEvent.muiHandled = true;
                }
            }}
        >
            {isCalculatorOn ? (
                <div className={styles.calculatorWrapper}>
                    <PointCalculator
                        handleCalculatorBack={() => setIsCalculatorOn(false)}
                    />
                </div>
            ) : (
                <>
                    <div
                        className={
                            !isElementScrolled ? styles.purpleBg : undefined
                        }
                    >
                        <div
                            role="button"
                            aria-label="close drawer"
                            tabIndex={0} // Ensures it is focusable
                            className={styles.indicator}
                        />
                    </div>

                    {variant === 'points-history' && (
                        <>
                            <div style={{ height: '91vh' }}>
                                {!isIframeLoading && (
                                    <>
                                        {!pointHistoryPage ? (
                                            <ButtonBase
                                                onClick={goBack}
                                                className={styles.iconBack}
                                            >
                                                <IconBack />
                                            </ButtonBase>
                                        ) : (
                                            <ButtonBase
                                                className={styles.title}
                                                onClick={() =>
                                                    hanRewardsWorks(true)
                                                }
                                            >
                                                How Easy Rewards works
                                            </ButtonBase>
                                        )}
                                    </>
                                )}
                                <BrandIframe
                                    id="point-history"
                                    title="Point history IFrame"
                                    height="81vh"
                                    src={pointHistoryUrl}
                                />
                            </div>
                        </>
                    )}
                    {variant === 'points-info' && (
                        <>
                            <div
                                id="point-history-drawer"
                                className={mergeClasses(
                                    styles.flex,
                                    styles.flexCol,
                                    styles.rowGap4,
                                    styles.drawer
                                )}
                                style={{ height: '90vh', marginTop: '-1px' }}
                            >
                                <div className={styles.erWrapper}>
                                    <div className={styles.pointsInfoContainer}>
                                        <Heading
                                            type="h2"
                                            size={26}
                                            weight="bold"
                                            color="#ffffff"
                                            lineHeight={30}
                                            textAlign="textCenter"
                                        >
                                            It's easy to earn, redeem and save
                                        </Heading>
                                        <div
                                            className={
                                                styles.easyRewardsWrapper
                                            }
                                        >
                                            <canvas
                                                aria-label="Your personal Easy Rewards barcode is here to scan at checkout when you are in a Staples store"
                                                ref={canvasRef}
                                                className={
                                                    styles.renderedCardNumber
                                                }
                                            />
                                            <Body
                                                weight="bold"
                                                className={styles.textRed}
                                                ariaLabel={`Your Easy Rewards number is ${userInfo?.rewardsNumber
                                                    ?.split('')
                                                    .join(' ')}`}
                                            >
                                                Easy Rewards #{' '}
                                                {userInfo?.rewardsNumber}
                                            </Body>
                                        </div>
                                    </div>
                                </div>

                                {pointInfoList.map((infoSectionProps) => (
                                    <InfoSection {...infoSectionProps} />
                                ))}
                                <div
                                    className={styles.termsAndConditionsWrapper}
                                >
                                    <div className={styles.termsAndConditions}>
                                        <Body
                                            size={12}
                                            color="textMediumGray"
                                            weight="light"
                                        >
                                            *Excludes gift cards, phone cards,
                                            postage stamps, cash, taxes,
                                            shipping fees/charges, promotional
                                            products, large quantity orders and
                                            purchases placed on
                                            directmail.staples.com, third-party
                                            websites and select online
                                            marketplaces. For full program
                                            details, please visit
                                            staples.com/easy.
                                        </Body>
                                        <Body
                                            size={12}
                                            color="textMediumGray"
                                            weight="light"
                                        >
                                            **An Easy Rewards member must earn
                                            or redeem at least one point in an
                                            18-month period to remain active.
                                        </Body>
                                        <Body
                                            size={12}
                                            color="textMediumGray"
                                            weight="light"
                                        >
                                            †Members may recycle cartridges
                                            purchased at any U.S. retail store
                                            or on staples.com® only. Limitation
                                            on the number of recycled cartridges
                                            per month which are eligible for
                                            points may be found in your Easy
                                            Rewards dashboard.
                                        </Body>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
        </SwipeableDrawer>
    );
}

// export default PointHistoryDrawer;

const WrappedPointHistoryDrawer = (props: PointHistoryInfoDrawerProps) => (
    <PointHistoryDrawer {...props} />
);
export default WrappedPointHistoryDrawer;
