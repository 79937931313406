import React from 'react';

interface IconStaplesNavigationProps {
    className: string;
    fillColor?: string;
}
function IconStaplesNavigation({ className, fillColor = '#CC0000' }: IconStaplesNavigationProps) {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_10427_1683)">
                <circle cx="13" cy="13" r="13" fill={fillColor}/>
            </g>
            <path d="M18.1922 9.53334H7.8057C7.33663 9.5352 6.88808 9.71413 6.56104 10.0299C6.23401 10.3456 6.05599 10.7715 6.06708 11.2118V16.4279C6.06654 16.4377 6.07018 16.4473 6.07718 16.4546C6.08418 16.4618 6.09398 16.4662 6.1044 16.4667H7.44226C7.46287 16.4667 7.47958 16.451 7.47958 16.4316V11.1325H18.5105V16.4279C18.5115 16.4476 18.5288 16.463 18.5498 16.463H19.8935C19.9145 16.463 19.9318 16.4476 19.9328 16.4279V11.2118C19.9434 10.7713 19.7649 10.3454 19.4376 10.0297C19.1102 9.71409 18.6615 9.53521 18.1922 9.53334" fill="white"/>
            <defs>
                <filter id="filter0_d_10427_1683" x="0" y="0" width="26" height="26" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10427_1683"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10427_1683" result="shape"/>
                </filter>
            </defs>
        </svg>
    );
}

export default IconStaplesNavigation;
