/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Typography, makeStyles, ButtonBase } from '@material-ui/core';
import { optimoveEvents } from 'src/screens/optimove';
import useUserInfo from 'src/hooks/useUserInfo';
import useCardCanvas from 'src/hooks/useCardCanvas';
import COLORS from 'src/lib/colors';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import { useLoyaltyAccountDetailsQuery } from 'src/services/easyRewardsServices';

const useStyles = makeStyles({
    paper: {
        borderRadius: '1rem',
        margin: '1rem',
        overflow: 'visible',
    },
    contentWrapper: {
        display: 'flex',
        alignItems: 'center',
        background: '#ffffff',
        flexDirection: 'column',
        margin: '0 24px',
        height: 'auto',
        borderRadius: '1.2rem',
        padding: '1.2rem 1.5rem',
        position: 'relative',
    },
    darkMode: {
        background: '#ffffff !important',
    },
    title: {
        fontSize: '1rem',
        fontWeight: 400,
        margin: '13px 0px 10px 0px',
    },
    memberType: {
        fontWeight: 400,
        color: '#979797',
        fontSize: '1rem',
        lineHeight: '1',
    },
    ChamberMemberType: {
        fontWeight: 400,
        color: '#979797',
        fontSize: '1rem',
        lineHeight: '23px',
        letterSpacing: '1px',
    },
    rewardsNumber: {
        fontSize: '1.1rem',
        color: COLORS.brandRed,
    },
    renderedCardNumber: {
        height: '97px',
        width: '286px',
        background: '#ffffff',
    },
    memberName: {
        fontWeight: 700,
        color: COLORS.homeBlack,
        fontSize: '27.4px',
        lineHeight: '30.68px',
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
    },
    subtitle: {
        paddingTop: '.5rem',
        fontSize: '.75rem',
        paddingBottom: '0.5rem',
    },
    benefitWrapper: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    learnMore: {
        color: COLORS.brandCyan,
        textDecoration: 'none',
        fontWeight: 500,
    },
    benefitText: {
        color: COLORS.brandCyan,
        textDecoration: 'none',
        fontWeight: 500,
        fontSize: '.812rem',
    },
    chevronIcon: {
        position: 'absolute',
        right: '0.8rem',
        top: '0.8rem',
    },
});
type SrwMemberCardType = {
    expanded?: boolean;
    customerPriceTypeData: any;
};

function SrwMemberCard(props: SrwMemberCardType) {
    const { expanded, customerPriceTypeData } = props;
    const classes = useStyles();
    const { userInfo } = useUserInfo();
    const { canvasRef, cardRedraw } = useCardCanvas(userInfo?.rewardsNumber);
    const [isExpanded, setIsExpanded] = useState(expanded);
    const [isDarkmode, setIsDarkmode] = useState(false);
    const { customEvent, analyticsCustomEvent } = useEventsReporter();
    const [rewardstiercode, setRewardstiercode] = useState('');
    const toggleChevronIcon = () => {
        setIsExpanded(!isExpanded);
    };
    useLoyaltyAccountDetailsQuery({
        config: {
            enabled: !!userInfo?.rewardsNumber,
            onSuccess: (res) => {
                setRewardstiercode(res?.accounts[0]?.rewardstiercode);
            },
        },
        rewardsNumber: userInfo?.rewardsNumber,
    });
    useEffect(() => {
        if (
            window.matchMedia &&
            window.matchMedia('(prefers-color-scheme: dark)').matches
        ) {
            setIsDarkmode(true);
        } else {
            setIsDarkmode(false);
        }
    }, [isExpanded]);

    return (
        <div
            className={
                isDarkmode
                    ? `${classes.contentWrapper} ${classes.darkMode}`
                    : classes.contentWrapper
            }
        >
            {isExpanded ? (
                <ButtonBase
                    onClick={toggleChevronIcon}
                    className={classes.chevronIcon}
                    id="expandeBtn"
                >
                    <img
                        src={require('../../assets/icons/iconChevronBlackUp.svg')}
                        alt="expande chevronicon"
                    />
                </ButtonBase>
            ) : (
                <ButtonBase
                    onClick={toggleChevronIcon}
                    className={classes.chevronIcon}
                    id="collapseBtn"
                >
                    <img
                        src={require('../../assets/icons/iconChevronBlackDown.svg')}
                        alt="collapse chevronicon"
                    />
                </ButtonBase>
            )}
            {customerPriceTypeData?.data?.customerPriceType === 'Chamber' ? (
                <Typography className={classes.ChamberMemberType}>
                    CHAMBER MEMBER
                </Typography>
            ) : (
                <Typography className={classes.memberType}>
                    {
                        // eslint-disable-next-line
                        userInfo?.rewardsMemberType === 'Associate' ||
                        rewardstiercode === 'ARW'
                            ? 'VALUED ASSOCIATE'
                            : userInfo?.rewardsMemberType === 'Base'
                            ? 'BASE MEMBER'
                            : `${userInfo?.rewardsMemberType} Member`
                    }
                </Typography>
            )}
            <Typography className={classes.memberName}>
                {userInfo?.rewardsMemberType === 'Associate' ? (
                    <img
                        src={require('../../assets/icons/memberStaplesLogo.svg')}
                        alt="member staples connect logo"
                    />
                ) : null}
                {customerPriceTypeData?.data?.customerPriceType ===
                'Chamber' ? (
                    <img
                        src={require('../../assets/icons/srwChamberMember.svg')}
                        alt="chamber member logo"
                    />
                ) : null}
                {userInfo?.firstName} {userInfo?.lastName}
            </Typography>
            <Typography className={classes.title}>
                Staples Rewards #{' '}
                <b className={classes.rewardsNumber}>
                    {userInfo?.rewardsNumber}
                </b>
            </Typography>
            {isExpanded && (
                <>
                    <canvas
                        ref={canvasRef}
                        className={classes.renderedCardNumber}
                    />
                    <Typography className={classes.subtitle}>
                        How do I use my Rewards Card?{' '}
                        <a
                            href="https://www.staples.com/grs/rewards/programoverview"
                            className={classes.learnMore}
                            onClick={() => {
                                customEvent(
                                    eventMetricsMap.wallet_staplesrewards_learnmore
                                );
                                analyticsCustomEvent(eventMetricsMap.wallet, {
                                    click_text: 'learn more',
                                    element_location: 'member tile',
                                    wallet_location: 'digital coupons',
                                });
                                analyticsCustomEvent(eventMetricsMap.outbound, {
                                    click_text: 'rewards program overview',
                                    link_domain: 'staples.com',
                                    link_url:
                                        'https://www.staples.com/grs/rewards/programoverview',
                                });
                                optimoveEvents(eventMetricsMap.scm_outbound, {
                                    click_text: 'learn more',
                                    element_location: 'member tile',
                                    wallet_location: 'digital coupons',
                                });
                                optimoveEvents(eventMetricsMap.scm_outbound, {
                                    click_text: 'rewards program overview',
                                    link_domain: 'staples.com',
                                    link_url:
                                        'https://www.staples.com/grs/rewards/programoverview',
                                });
                            }}
                        >
                            Learn more
                        </a>
                    </Typography>
                    {userInfo?.rewardsMemberType === 'Associate' && (
                        <Typography className={classes.benefitWrapper}>
                            <a
                                href="https://associateconnection.staples.com/psp/psext/EXTRANET2/ASSOCIATE/c/STP_EXTR_LINK_MENU.STP_RWD_ASSOC_RWRD.GBL?Page=STP_RWD_API_EMPLOY&Action=U"
                                className={classes.benefitText}
                                onClick={() => {
                                    customEvent(
                                        eventMetricsMap.wallet_staplesrewards_learnmore
                                    );
                                    analyticsCustomEvent(
                                        eventMetricsMap.outbound,
                                        {
                                            click_text:
                                                'View my associate benefits',
                                            link_domain:
                                                'associateconnection.staples.com',
                                            link_url:
                                                'https://associateconnection.staples.com/psp/psext/EXTRANET2/ASSOCIATE/c/STP_EXTR_LINK_MENU.STP_RWD_ASSOC_RWRD.GBL?Page=STP_RWD_API_EMPLOY&Action=U',
                                        }
                                    );
                                    optimoveEvents(
                                        eventMetricsMap.scm_outbound,
                                        {
                                            click_text:
                                                'View my associate benefits',
                                            link_domain:
                                                'associateconnection.staples.com',
                                            link_url:
                                                'https://associateconnection.staples.com/psp/psext/EXTRANET2/ASSOCIATE/c/STP_EXTR_LINK_MENU.STP_RWD_ASSOC_RWRD.GBL?Page=STP_RWD_API_EMPLOY&Action=U',
                                        }
                                    );
                                }}
                            >
                                View my associate benefits
                            </a>
                        </Typography>
                    )}
                </>
            )}
        </div>
    );
}

export default SrwMemberCard;
