/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import FullWidthCard from 'src/components/cards/full-width-card';
import ValidationTextField from 'src/components/validation-text-field';
import { Body, Button, Heading } from 'src/components/UI';
import { useEnrollInRewardsMutation } from 'src/services/easyRewardsServices';
import {
    useUserInfo,
    useRouter,
    useEventsReporter,
    eventMetricsMap,
} from 'src/hooks';
import { REWARDS_ENROLLMENT_ROUTES } from 'src/routers/routes';
import { RewardsEnrollErrorDialog } from 'src/components/EasyRewards';
import styles from './styles.module.scss';

function MissingInfoFormScreen() {
    const [enrollmentError, setEnrollmentError] = useState<{
        errorCode: string;
        errorDialogMessage: string;
    } | null>(null);
    const { push } = useRouter();
    const { analyticsCustomEvent } = useEventsReporter();

    const [fields, setFields] = useState({
        firstName: ['', false] as [string, boolean],
        lastName: ['', false] as [string, boolean],
        phone: ['', false] as [string, boolean],
    });
    const { userInfo, isFetchingUserInfo, refetchUserInfo } = useUserInfo();
    const { mutateAsync: enroll, isLoading: isEnrolling } =
        useEnrollInRewardsMutation({
            onSuccess: () => refetchUserInfo(),
        });

    const handleChange = (
        fieldName: keyof typeof fields,
        event?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const value = event ? event?.target.value : fields[fieldName][0];
        if (fieldName === 'firstName' || fieldName === 'lastName') {
            setFields((oldFields) => ({
                ...oldFields,
                [fieldName]: [value, !value || value.includes('@')],
            }));
        } else {
            if (value.length > 10) return;
            setFields((oldFields) => ({
                ...oldFields,
                [fieldName]: [value, !value || !(value.length === 10)],
            }));
        }
    };

    useEffect(() => {
        if (userInfo) {
            setFields({
                firstName: [userInfo.firstName, false],
                lastName: [userInfo.lastName, false],
                phone: [userInfo.firstName, false],
            });
        }
    }, [userInfo]);

    if (isFetchingUserInfo || !userInfo) {
        // TODO add splash screen
        return null;
    }

    return (
        <>
            <Box
                className={styles.screen}
                style={{
                    backgroundColor: 'white',
                    height: '100vh',
                }}
            >
                <div className={styles.headingContainer}>
                    <Heading
                        type="h3"
                        size={26}
                        weight="bold"
                        color="textBlack"
                    >
                        Complete your profile
                    </Heading>
                </div>
                <div className={styles.form}>
                    <div className={styles.fieldContainer}>
                        <label htmlFor="fname" className={styles.label}>
                            First name
                        </label>
                        <input
                            className={styles.formInput}
                            type="text"
                            id="fname"
                            name="fname"
                            value={fields.firstName[0]}
                            onChange={(e) => handleChange('firstName', e)}
                            style={{
                                border: fields.firstName[1]
                                    ? '1px solid red'
                                    : '1px solid #ccc',
                                boxShadow: fields.firstName[1]
                                    ? '0px 0px 0px 4px #CC00001A'
                                    : 'none',
                            }}
                            // error={fields.firstName[1]}
                        />
                        {fields.firstName[1] && (
                            <Body
                                margin="-7px 0 0 0"
                                size={12}
                                lineHeight={16}
                                weight="light"
                                color="textRed"
                            >
                                Please enter a valid first name
                            </Body>
                        )}
                    </div>
                    {/* <ValidationTextField
                            fullWidth
                            id="lname"
                            name="lname"
                            label="Last Name*"
                            value={fields.lastName[0]}
                            onChange={(e) => handleChange('lastName', e)}
                            error={fields.lastName[1]}
                            helperText={
                                fields.lastName[1]
                                    ? 'Please enter a valid last name'
                                    : null
                            }
                        /> */}
                    <div className={styles.fieldContainer}>
                        <label htmlFor="lname" className={styles.label}>
                            Last name
                        </label>
                        <input
                            className={styles.formInput}
                            type="text"
                            id="lanme"
                            name="lanme"
                            value={fields.lastName[0]}
                            onChange={(e) => handleChange('lastName', e)}
                            style={{
                                border: fields.lastName[1]
                                    ? '1px solid red'
                                    : '1px solid #ccc',
                                boxShadow: fields.lastName[1]
                                    ? '0px 0px 0px 4px #CC00001A'
                                    : 'none',
                            }}
                            // error={fields.firstName[1]}
                        />
                        {fields.lastName[1] && (
                            <Body
                                size={12}
                                lineHeight={16}
                                weight="light"
                                margin="-7px 0 0 0"
                                color="textRed"
                            >
                                Please enter a valid last name
                            </Body>
                        )}
                    </div>
                    <div className={styles.fieldContainer}>
                        <label htmlFor="phone" className={styles.label}>
                            Phone number
                        </label>
                        <input
                            className={styles.formInput}
                            type="number"
                            id="phone"
                            name="phone"
                            value={fields.phone[0]}
                            onChange={(e) => handleChange('phone', e)}
                            style={{
                                border: fields.phone[1]
                                    ? '1px solid red'
                                    : '1px solid #ccc',
                                boxShadow: fields.phone[1]
                                    ? '0px 0px 0px 4px #CC00001A'
                                    : 'none',
                            }}
                            // error={fields.firstName[1]}
                        />
                    </div>
                    {fields.phone[1] && (
                        <Body
                            size={12}
                            lineHeight={16}
                            weight="light"
                            margin="-7px 0 0 0"
                            color="textRed"
                        >
                            Please enter a valid phone number
                        </Body>
                    )}
                    {/* <ValidationTextField
                            fullWidth
                            id="phone"
                            name="phone"
                            type="number"
                            label="Phone Number*"
                            value={fields.phone[0]}
                            onChange={(e) => handleChange('phone', e)}
                            error={fields.phone[1]}
                            helperText={
                                fields.phone[1]
                                    ? 'Please enter a valid phone number'
                                    : null
                            }
                        /> */}
                    <Button
                        variant="contained-rounded"
                        bgColor="bgRed"
                        loading={isEnrolling}
                        disabled={Object.keys(fields).some(
                            (key) => fields[key as keyof typeof fields][1]
                        )}
                        onClick={() => {
                            // Validate fields before proceeding with enrollment
                            Object.keys(fields).forEach((key) =>
                                handleChange(key as keyof typeof fields)
                            );

                            // Check if all fields are valid before making API call
                            if (
                                !Object.keys(fields).some(
                                    (key) =>
                                        fields[key as keyof typeof fields][1]
                                )
                            ) {
                                enroll({
                                    FirstName: fields.firstName[0],
                                    LastName: fields.lastName[0],
                                    PhoneNumber: fields.phone[0],
                                    EmailId: userInfo.email,
                                })
                                    .then(() => {
                                        push(
                                            REWARDS_ENROLLMENT_ROUTES.welcomeAnimation,
                                            {
                                                onCompleteNavigateTo:
                                                    REWARDS_ENROLLMENT_ROUTES.favorites,
                                            }
                                        );
                                        analyticsCustomEvent(
                                            eventMetricsMap.new_loyalty_member,
                                            {
                                                account_type:
                                                    'non-migrated account',
                                            }
                                        );
                                        analyticsCustomEvent(
                                            eventMetricsMap.easy_rewards_enroll,
                                            {
                                                click_text:
                                                    'continue - success',
                                                element_location:
                                                    'complete profile information',
                                                event_rewards_number:
                                                    userInfo?.rewardsNumber?.padStart(
                                                        10,
                                                        '0'
                                                    ) || '',
                                                logged_in_user: true, // Set the logged-in user property to true
                                            }
                                        );
                                    })
                                    .catch((error) => {
                                        analyticsCustomEvent(
                                            eventMetricsMap.pop_up,
                                            {
                                                click_text:
                                                    'We were unable to enroll you in Easy Rewards at this time.',
                                                element_location:
                                                    'easy_rewards',
                                            }
                                        );
                                        analyticsCustomEvent(
                                            eventMetricsMap.easy_rewards_enroll,
                                            {
                                                click_text:
                                                    'continue - failure',
                                                element_location:
                                                    'complete profile information',
                                                event_rewards_number:
                                                    userInfo?.rewardsNumber ||
                                                    '',
                                            }
                                        );
                                        setEnrollmentError({
                                            errorCode:
                                                error?.response?.data?.code ||
                                                'default',
                                            errorDialogMessage:
                                                error?.response?.data?.msg ||
                                                'Please try again',
                                        });
                                    });
                            }
                        }}
                    >
                        Continue
                    </Button>
                </div>
                <div className={styles.bottomContainer} />
            </Box>
            <RewardsEnrollErrorDialog
                error={enrollmentError}
                setError={setEnrollmentError}
            />
        </>
    );
}

export default MissingInfoFormScreen;
