import {
    Box,
    ButtonBase,
    Dialog,
    makeStyles,
    Typography,
} from '@material-ui/core';
import React from 'react';
import DialogBox from 'src/components/DialogBox';
import Row from 'src/components/Row';
import COLORS from 'src/lib/colors';
import { useYourFiles } from 'src/lib/contexts/YourFilesContext';
import { Body } from 'src/components/UI';
import ScanQrCodeBox from './ScanQrCodeBox';

const useStyles = makeStyles((theme) => ({
    scanQrBox: {
        padding: '16px',
        borderRadius: '15px',
    },
    openCameraBtn: {
        alignSelf: 'center',
        backgroundColor: COLORS.brandRed,
        color: 'white',
        lineHeight: '2.5rem',
        borderRadius: '2rem',
        width: '116px',
        height: '40px',
        fontWeight: 500,
        fontSize: '0.95rem',
        letterSpacing: '0',
        userSelect: 'none',
    },
    qrBox: {
        width: 53,
        height: 70,
    },
    connectedBox: {
        width: 30,
        height: 30,
    },
    qrImg: {
        height: '100%',
    },
    scanText: {
        fontSize: 18,
        fontWeight: 500,
        lineHeight: '24px',
        color: '#fff',
    },
    showMeText: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '18px',
        color: '#fff',
        textDecoration: 'underline',
    },
}));

export default function ScanQr() {
    const classes = useStyles();
    const { setIsScanQrOpen, isScanQrOpen, isQrScanSuccess } = useYourFiles();

    const handleQRCodeDetected = (code: any) => {
        // alert(`Detected QR Code: ${code}`);
    };
    return isQrScanSuccess ? (
        <Box
            style={{
                minHeight: 62,
                // height: 142,

                background:
                    'linear-gradient(98deg, #3E009F 0%, #5B0069 153.14%)',
                // boxShadow: ' 0px 5px 4px 0px rgba(0, 0, 0, 0.10)',
                marginBottom: '2px',
                padding: '16px',
            }}
        >
            <Row gap="10px" sx={{ alignItems: 'center' }}>
                <>
                    <Box className={classes.connectedBox}>
                        <img
                            src={require('../../../assets/icons/CheckPrint.svg')}
                            className={classes.qrImg}
                            alt="QR"
                        />
                    </Box>
                    <Body
                        size={16}
                        weight="normal"
                        lineHeight={20}
                        color="textWhite"
                    >
                        You are connected to [p_name].
                    </Body>
                </>
            </Row>
        </Box>
    ) : (
        <Box
            style={{
                minHeight: 142,
                // height: 142,

                background:
                    'linear-gradient(98deg, #3E009F 0%, #5B0069 153.14%)',
                boxShadow: ' 0px 5px 4px 0px rgba(0, 0, 0, 0.10)',
                marginBottom: '2px',
            }}
        >
            <Box className={classes.scanQrBox}>
                <Row gap="10px" sx={{ alignItems: 'start', marginTop: '-3px' }}>
                    <>
                        <Box className={classes.qrBox}>
                            <img
                                src={require('../../../assets/icons/qr.svg')}
                                className={classes.qrImg}
                                alt="QR"
                            />
                        </Box>
                        <Row direction="column" gap="10px">
                            <>
                                <Row direction="column">
                                    <>
                                        <Body
                                            color={'textWhite'}
                                            size={20}
                                            weight="bold"
                                            lineHeight={26}
                                        >
                                            Ready to print?
                                        </Body>
                                        <Body
                                            color={'textWhite'}
                                            size={14}
                                            lineHeight={18}
                                        >
                                            Scan the QR code on the printer when
                                            you are in store.
                                        </Body>
                                    </>
                                </Row>
                                <Row gap="16px" alignItems={'center'}>
                                    <>
                                        <ButtonBase
                                            className={classes.openCameraBtn}
                                            onClick={() =>
                                                setIsScanQrOpen(true)
                                            }
                                        >
                                            Open camera
                                        </ButtonBase>
                                        <Box
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <Body
                                                className={classes.showMeText}
                                            >
                                                How it works?
                                            </Body>
                                        </Box>
                                    </>
                                </Row>
                            </>
                        </Row>
                    </>
                </Row>
            </Box>
            <DialogBox
                noPedding
                open={isScanQrOpen}
                onBack={() => setIsScanQrOpen(false)}
                header="Scan the QR code"
                isLeftButton
            >
                <ScanQrCodeBox onDetected={handleQRCodeDetected} />
            </DialogBox>
        </Box>
    );
}
