/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react/self-closing-comp */
/* eslint-disable max-len */
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import {
    Tabs,
    Tab as MUITab,
    withStyles,
    SwipeableDrawer,
} from '@material-ui/core';
import { useQuery, useQueryClient } from 'react-query';
import axiosInstance from 'src/lib/api-client/custom-axios';
import {
    eventMetricsMap,
    useEventsReporter,
    useGuestMode,
    useRouter,
    useStickyNotification,
    useUserInfo,
} from 'src/hooks';
import ManageFavorites from 'src/screens/EasyRewards/ManageFavorites';
import { rewardsGetCouponDataURL } from 'src/services/urls';
import {
    useEnrollInRewardsMutation,
    useLoyaltyAccountDetailsQuery,
    LoyaltyOffersQueryKey,
} from 'src/services/easyRewardsServices';
import AlertMessage from 'src/screens/alert-message';
import { BackToTopFAB, Body, Button } from 'src/components/UI';
import { saveDigitalCoupon } from 'src/lib/api-client/wallet-activities';
import {
    IntroCarousel,
    RewardsEnrollErrorDialog,
} from 'src/components/EasyRewards';
import { REWARDS_ENROLLMENT_ROUTES } from 'src/routers/routes';
import { validateUserInfo } from 'src/utils/userUtils';
import PullToRefresh from 'src/components/PullToRefresh';
import { IconStaplesRound } from 'src/components/Icons';
import { useAtom } from 'jotai';
import { useHistory } from 'react-router';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import { isListEnabled } from 'src/lib/api-client/lists';
import CommonModal from 'src/components/CommonModal';
import Row from 'src/components/Row';
import COLORS from 'src/lib/colors';
import OfferTab from './OfferTab';
import Header from './Header';
import CouponTab from '../CouponTab';
import styles from './styles.module.scss';
import RecyclingCartridgeEarning from './RecyclingCartridgeEarning';
import { toastAtom } from './OfferList/OfferCard';
import Coachmarks from '../Coachmarks/Coachmarks';
import { initialInfoDrawerState } from './OfferList/OfferList';

const Tab = withStyles(() => ({
    root: {
        fontSize: 18,
        textTransform: 'none',
        fontFamily: 'StaplesNormsRegular',
        '&$selected': {
            fontFamily: 'StaplesNormsBold',
        },
    },
    selected: {},
}))((props: { label: string }) => <MUITab {...props} />);

function EasyRewards() {
    const coachmarks: any = localStorage?.getItem('coachmarks');
    const parseCoachmarks = JSON.parse(coachmarks);
    const [coachmarksData, setCoachmarksData] = useState(parseCoachmarks);
    const scrollContainerRef = useRef<HTMLElement | null>(null);
    const { isGuestModeOn } = useGuestMode();
    const { addNotification } = useStickyNotification();
    const history = useHistory();
    const [toastMessage, setToastMessage] = useAtom(toastAtom);
    const { userInfo, isFetchingUserInfo, refetchUserInfo } = useUserInfo();
    const { query, push, location } = useRouter();
    const shouldActivateAllOffers = useMemo(() => {
        const locationState = location.state as any;
        return !!locationState?.activateAll || query?.activateAllOffers;
    }, []);
    const [enrollmentError, setEnrollmentError] = useState<{
        errorCode: string;
        errorDialogMessage: string;
    } | null>(null);

    const { analyticsCustomEvent } = useEventsReporter();

    const { mutateAsync: enroll } = useEnrollInRewardsMutation({
        onSuccess: () => refetchUserInfo(),
    });
    const rewardsNumber = userInfo?.rewardsNumber || '';
    // TODO Remove all coupon logic from this component and move it to it's own component.
    // Use this component only to consolidate all the sections of the easy rewards page.
    // Do not use this component to write any logic which can be isolated in its own component.

    const tabIndex = parseInt(query?.tabIndex, 10) || 1;
    const [activeTabIndex, setActiveTabIndex] = useState(tabIndex);
    const [tosSource, setTosSource] = useState<string | null>(null);
    const [showTabs, setShowTabs] = useState(false);
    const [tosTabIndex, setTosTabIndex] = useState(0);
    const [isCouponsFetchingError, setCouponsFetchingError] = useState(false);

    const [available, setAvailable] = useState<any[]>([]);
    const [saved, setSaved] = useState<any[]>([]);
    const [redeemed, setRedeemed] = useState<any[]>([]);
    const [isCouponDataFetching, setCouponDataFetching] = useState(false);
    const [isViewAllOffers, setIsViewAllOffers] = useState(false);
    const [isViewAllMassOffers, setIsViewAllMassOffers] = useState(false);
    const { setHideFooter, setGuestModal } = useUserUpdateContext();
    const [isOpenUnclipModal, setIsOpenUnclipModal] = useState(false);

    const [heroCoupon, setHeroCoupon] = useState({});
    const viewcoupon = localStorage.getItem('viewcoupon');

    const queryClient = useQueryClient();

    const { data: isSaveCouponsEnabled } = useQuery(
        ['isSaveCouponsEnabled'],
        () => isListEnabled('SaveCoupons'),
        {
            refetchOnMount: true,
        }
    );
    const {
        data: loyaltyAccountDetails,
        isLoading: isLoyaltyAccountDetailsLoading,
    } = useLoyaltyAccountDetailsQuery({
        rewardsNumber: userInfo?.rewardsNumber,
        config: {
            enabled: !!userInfo?.rewardsNumber,
        },
    });

    const getCouponData = () => {
        setCouponDataFetching(true);
        // TODO use react query to write the service call
        return axiosInstance
            .get(
                `${rewardsGetCouponDataURL.replace(
                    '%rewardsnumber%',
                    rewardsNumber || '0'
                )}`
            )
            .then((couponRes: any) => {
                const data = couponRes?.data?.couponDataList;
                const filterHeroCoupon = data.find(
                    (item: any) =>
                        item?.category === 'Coupon' &&
                        item?.couponInfo?.isNewCoupon &&
                        !item?.couponInfo?.isRedeemed &&
                        item?.couponInfo?.offerType === 'Hero'
                );
                if (filterHeroCoupon?.couponInfo) {
                    setHeroCoupon(filterHeroCoupon);
                } else if (data.length > 0) {
                    setHeroCoupon(
                        data.find(
                            (coupon: any) => !coupon?.couponInfo?.isRedeemed
                        )
                    );
                }
                const filterCouponData = data
                    .filter((coupon: any) => coupon.category === 'Coupon')
                    .sort(
                        (a: any, b: any) =>
                            a.couponInfo.priority - b.couponInfo.priority
                    );

                setAvailable(
                    filterCouponData?.filter(
                        (item: any) =>
                            item?.couponInfo?.isNewCoupon &&
                            (!item?.couponInfo?.isRedeemed ||
                                item?.couponInfo?.couponClass.toLowerCase() ===
                                    'storecredit')
                    )
                );
                setSaved(
                    filterCouponData?.filter(
                        (item: any) =>
                            item?.couponInfo?.isSaved &&
                            !item?.couponInfo?.isRedeemed
                    )
                );
                setRedeemed(
                    filterCouponData?.filter(
                        (item: any) => item?.couponInfo?.isRedeemed
                    )
                );
                setCouponDataFetching(false);
                if (!couponRes?.data?.success) {
                    setCouponsFetchingError(true);
                }
            })
            .catch((error) => {
                setCouponsFetchingError(true);
                console.log(error, 'loyaltyerror');
                setCouponDataFetching(false);
                // setLoading(false);
            });
    };

    // TODO fix variable type
    const handleExtendCouponDate = (offer: any) => {
        setCouponDataFetching(true);
        // TODO use react query to write the service call
        saveDigitalCoupon({
            rewardsNumber,
            // couponCode: 0,
            couponCode: offer?.couponNumber,
            isSaved: !offer?.couponInfo?.isSaved,
        })
            .then((couponRes) => {
                const data = couponRes?.data?.couponDataList;
                setAvailable(
                    data?.filter(
                        (item: any) =>
                            item?.couponInfo?.isNewCoupon &&
                            !item?.couponInfo?.isRedeemed
                    )
                );
                setSaved(
                    data?.filter(
                        (item: any) =>
                            item?.couponInfo?.isSaved &&
                            !item?.couponInfo?.isRedeemed
                    )
                );
                setRedeemed(
                    data?.filter((item: any) => item?.couponInfo?.isRedeemed)
                );
                setCouponDataFetching(false);
                setIsOpenUnclipModal(false);
            })
            .catch((error) => {
                setIsOpenUnclipModal(true);
                setCouponDataFetching(false);
            });
    };

    const handleToggleIndex = (index: number, isScrollToTop = false) => {
        setCouponsFetchingError(false);
        getCouponData();
        setActiveTabIndex(index);
        if (isScrollToTop && scrollContainerRef?.current) {
            scrollContainerRef.current.scroll({ top: 0 });
        }
    };

    useEffect(() => {
        if (rewardsNumber) {
            getCouponData();
        }
    }, [rewardsNumber]);

    useEffect(() => {
        if (activeTabIndex === 1) {
            setCouponsFetchingError(false);
            // refetchLoyaltyOffers();
        }
    }, [activeTabIndex]);

    useEffect(() => {
        if (activeTabIndex === 2) {
            getCouponData();
        }
    }, [rewardsNumber, activeTabIndex]);

    useEffect(() => {
        if (viewcoupon === 'true') {
            localStorage.setItem('viewcoupon', 'false');
            setActiveTabIndex(2);
        }
    }, [viewcoupon]);

    //  show tos notification if user hasn't accepted them
    useEffect(() => {
        if (
            userInfo?.rewardsNumber &&
            !isLoyaltyAccountDetailsLoading &&
            loyaltyAccountDetails?.accounts[0]?.termsandconditionaccepted ===
                'N'
        ) {
            addNotification({
                id: 'loyaltyTOSNotification',
                group: 'loyaltyTOS',
            });
        }
    }, [isLoyaltyAccountDetailsLoading, isFetchingUserInfo]);

    useEffect(() => {
        if (isViewAllOffers || isViewAllMassOffers) {
            scrollContainerRef?.current?.scroll({ top: 0 });
        }
    }, [isViewAllOffers, isViewAllMassOffers]);

    const handleMassOfferBack = () => {
        setIsViewAllMassOffers(false);
        setTimeout(() => {
            document
                ?.getElementById?.('view-all-id-for-offer')
                ?.scrollIntoView(true);
        }, 500);
        setHideFooter(false);
    };

    const handleAllOfferBack = () => {
        setIsViewAllOffers(false);
        setTimeout(() => {
            document?.getElementById?.('your-offer-id')?.scrollIntoView(false);
        }, 500);
        setHideFooter(false);
    };

    useEffect(() => {
        if (isViewAllOffers || isViewAllMassOffers) {
            const unblock = history.block(() => {
                // eslint-disable-next-line no-unused-expressions
                isViewAllOffers ? handleAllOfferBack() : handleMassOfferBack();
                return false;
            });

            return () => {
                unblock();
            };
        }
        return undefined;
    }, [isViewAllOffers, isViewAllMassOffers]);

    useEffect(() => {
        if (
            activeTabIndex === 2 &&
            ((userInfo && !userInfo.rewardsNumber) || isGuestModeOn)
        ) {
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: `easyrewards tab_unenrolled coupons`,
                firebase_screen_class: `easyrewards tab_unenrolled coupons`,
            });
        }
    }, [activeTabIndex, userInfo?.rewardsNumber]);

    useEffect(() => {
        setHideFooter(false);
        if ((userInfo && userInfo.rewardsNumber) || isGuestModeOn) {
            analyticsCustomEvent(eventMetricsMap.screen_view, {
                firebase_screen: 'app_easy rewards',
                firebase_screen_class: 'app_easy rewards',
            });
        }
        return () => setHideFooter(false);
    }, []);

    return (
        <>
            {(isFetchingUserInfo || !userInfo) && !isGuestModeOn ? (
                <div style={{ height: '0vh' }}></div>
            ) : null}

            <PullToRefresh
                onRefresh={async () =>
                    Promise.all([
                        queryClient.invalidateQueries({
                            queryKey: [LoyaltyOffersQueryKey],
                        }),
                        getCouponData(),
                    ])
                }
                bgColor="bgPurple"
                scrollContainerRef={scrollContainerRef}
            >
                <BackToTopFAB
                    containerRef={scrollContainerRef}
                    distanceToTop="5rem"
                />

                <div className={styles.pbNav}>
                    <Header
                        activeTabIndex={activeTabIndex}
                        toggleIndex={handleToggleIndex}
                    />
                    {activeTabIndex === 1 &&
                        ((userInfo && !userInfo.rewardsNumber) ||
                        isGuestModeOn ? (
                            <div style={{ padding: '1rem' }}>
                                <IntroCarousel
                                    variant="non-migrated-rewards-center"
                                    primaryCta={async () => {
                                        if (isGuestModeOn) {
                                            setGuestModal({
                                                open: true,
                                                closeBehavior: 'onboarding',
                                            });
                                        } else if (
                                            userInfo &&
                                            validateUserInfo(userInfo)
                                        ) {
                                            enroll({
                                                FirstName: userInfo.firstName,
                                                LastName: userInfo.lastName,
                                                PhoneNumber: userInfo.phone,
                                                EmailId: userInfo.email,
                                            })
                                                .then((res: any) => {
                                                    console.log({ res });
                                                    if (res?.data?.success) {
                                                        push(
                                                            REWARDS_ENROLLMENT_ROUTES.peronalize
                                                        );
                                                    } else {
                                                        analyticsCustomEvent(
                                                            eventMetricsMap.pop_up,
                                                            {
                                                                click_text:
                                                                    'We were unable to enroll you in Easy Rewards at this time.',
                                                                element_location:
                                                                    'easy_rewards',
                                                            }
                                                        );
                                                        setEnrollmentError({
                                                            errorCode:
                                                                res?.data.code,
                                                            errorDialogMessage:
                                                                res?.data.msg,
                                                        });
                                                    }
                                                })
                                                .catch((error) => {
                                                    analyticsCustomEvent(
                                                        eventMetricsMap.pop_up,
                                                        {
                                                            click_text:
                                                                'We were unable to enroll you in Easy Rewards at this time.',
                                                            element_location:
                                                                'easy_rewards',
                                                        }
                                                    );
                                                    setEnrollmentError({
                                                        errorCode:
                                                            error?.response
                                                                ?.data?.code ||
                                                            'default',
                                                        errorDialogMessage:
                                                            error?.response
                                                                ?.data?.msg ||
                                                            'Please try again',
                                                    });
                                                });
                                        } else {
                                            push(
                                                REWARDS_ENROLLMENT_ROUTES.missingInfo
                                            );
                                        }
                                        analyticsCustomEvent(
                                            eventMetricsMap.easy_rewards,
                                            {
                                                click_text: 'join for free',
                                                element_location:
                                                    'unenrolled state',
                                                event_rewards_number:
                                                    userInfo?.rewardsNumber ||
                                                    '',
                                            }
                                        );
                                    }}
                                />
                            </div>
                        ) : (
                            <>
                                <ManageFavorites />
                                <RecyclingCartridgeEarning />
                                <OfferTab
                                    shouldActivateAllOffers={
                                        shouldActivateAllOffers
                                    }
                                    couponData={heroCoupon}
                                />
                            </>
                        ))}
                    {/*  eslint-disable no-nested-ternary  */}
                    {activeTabIndex === 2 &&
                    (available.length ||
                        saved.length ||
                        redeemed.length ||
                        isCouponsFetchingError) ? (
                        isCouponDataFetching ? (
                            <Player
                                autoplay
                                loop
                                src={require('../../assets/lotties/stapleRevealWordmark.json')}
                                style={{ height: '150px' }}
                            />
                        ) : (
                            <>
                                <CouponTab
                                    available={available}
                                    saved={saved}
                                    isSaveCouponsEnabled={
                                        isSaveCouponsEnabled?.isenabled
                                    }
                                    redeemed={redeemed}
                                    offerActivate={handleExtendCouponDate}
                                    isCouponsFetchingError={
                                        isCouponsFetchingError
                                    }
                                />
                            </>
                        )
                    ) : (
                        <>
                            {activeTabIndex === 2 &&
                                isCouponDataFetching &&
                                !(
                                    available.length ||
                                    saved.length ||
                                    redeemed.length
                                ) && (
                                    <Player
                                        autoplay
                                        loop
                                        src={require('../../assets/lotties/stapleRevealWordmark.json')}
                                        style={{ height: '150px' }}
                                    />
                                )}
                        </>
                    )}
                </div>
            </PullToRefresh>
            <AlertMessage
                open={!!toastMessage}
                icon={<IconStaplesRound />}
                handleClose={() => setToastMessage('')}
                btnName={''}
                message={toastMessage}
                handleBtnClick={() => setToastMessage('')}
                status={''}
            />
            <RewardsEnrollErrorDialog
                error={enrollmentError}
                setError={setEnrollmentError}
            />
            <SwipeableDrawer
                anchor="bottom"
                open={!!tosSource}
                onClose={() => {
                    setTosSource(null);
                    setShowTabs(false);
                }}
                onOpen={() => console.log('open')}
            >
                {showTabs && (
                    <Tabs
                        className={styles.tosTabs}
                        value={tosTabIndex}
                        onChange={(_e, value) => setTosTabIndex(value)}
                        aria-label="simple tabs example"
                    >
                        <Tab label="Staples" />
                        <Tab label="Easy Rewards" />
                    </Tabs>
                )}
                {tosTabIndex === 0 && (
                    <iframe
                        id="privacy-link"
                        title="privacy link iFrame"
                        style={{ height: '90vh', width: '100%', marginTop: 16 }}
                        src={tosSource || '#'}
                    />
                )}
                {tosTabIndex === 1 && (
                    <iframe
                        id="privacy-link"
                        title="privacy link iFrame"
                        style={{ height: '90vh', width: '100%', marginTop: 16 }}
                        src={tosSource || '#'}
                    />
                )}
            </SwipeableDrawer>
            {coachmarksData?.completed === false && userInfo?.rewardsNumber ? (
                <Coachmarks
                    coachmarksData={coachmarksData}
                    setCoachmarksData={setCoachmarksData}
                />
            ) : null}
            {isOpenUnclipModal && (
                <CommonModal open={isOpenUnclipModal} isNoPadding>
                    <>
                        <Row
                            sx={{
                                justifyContent: 'center',
                                p: 2,
                                borderBottom: `1px solid ${COLORS.whiteSmoke}`,
                            }}
                        >
                            <>
                                <Body>Unable to clip coupon</Body>
                            </>
                        </Row>
                        <Row
                            style={{
                                padding: '16px',
                                justifyContent: 'center',
                            }}
                        >
                            <Button
                                onClick={() => setIsOpenUnclipModal(false)}
                                variant="text"
                                color="textLinkBlue"
                            >
                                Close
                            </Button>
                        </Row>
                    </>
                </CommonModal>
            )}
        </>
    );
}

export default EasyRewards;
