import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import useRouter from 'src/hooks/useRouter';
import { optimoveEvents } from 'src/screens/optimove';
import useUserInfo from 'src/hooks/useUserInfo';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import { validateUserInfo } from 'src/utils/userUtils';
import { REWARDS_ENROLLMENT_ROUTES } from 'src/routers/routes';
import useEventsReporter from 'src/hooks/useEventsReporter';
import { useGuestMode } from 'src/hooks';
import {
    useEnrollInRewardsMutation,
    useLoyaltyRewardPointsQuery,
} from 'src/services/easyRewardsServices';
import ROUTES from 'src/lib/routes';
import { Body, Heading, Button } from './UI';
import { RewardsEnrollErrorDialog } from './EasyRewards';
import { IconInfo, IconInkDrops } from './Icons';

const useStyles = makeStyles({
    sectionContainer: {
        backgroundColor: 'white',
        margin: '1rem',
        borderRadius: (props: any) =>
            props?.localExpand ? '1rem 1rem 0 0' : '1rem',
    },
    topContainer: {
        display: 'flex',
        columnGap: '1rem',
    },
    recycleInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '1rem',
        alignItems: 'flex-start',
        justifyContent: 'center',
        padding: '1rem',
    },
    recycleBackgroundImage: {
        alignSelf: 'flex-end',
    },
    secondaryButtonContainer: {
        display: 'flex',
        columnGap: '1rem',
    },
    localImpactButtonContainer: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '20px',
        padding: '1rem',
        borderTop: '1px solid #F2F2F2',
    },
    localImpactButton: {
        background: '#E0F7E2',
        padding: '0.6rem 1rem',
        borderRadius: '1rem',
        display: 'flex',
        flexDirection: 'row',
        rowGap: '1rem',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
    },
    recycleVisitContainer: {
        display: 'flex',
        columnGap: '20px',
    },
    recycleVisitCountContainer: {
        display: 'flex',
        columnGap: '10px',
        alignItems: 'center',
    },
    verticalRule: {
        borderRight: '1px solid #E6E6E6',
        width: 0,
    },
    recycleGreenColor: {
        color: '#81C671',
    },
    recycleInfoIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    upIcon: {
        width: '80%',
        marginTop: '3px',
    },
});
export default function RecycleEveryDay(props: any) {
    const { recycleData, toggleDrawer, setStoreRecycleLink, toggleLocal } =
        props;
    const { storeVisitsCount } = recycleData || { storeVisitsCount: 0 };
    const { inkandTonerCount } = recycleData || { inkandTonerCount: 0 };
    const [localExpand, setLocalExpand] = useState(false);
    const classes = useStyles({ localExpand });
    const recyclingURL = `${process.env.REACT_APP_DOTCOM_URL}/stores/recycling`;
    const rewardcenterURL = `${process.env.REACT_APP_DOTCOM_URL}/grs/rewards/sr/loyaltycenter?openrecycledrawer=yes&closebutton=no`;
    const { userInfo, refetchUserInfo } = useUserInfo();
    const { customEvent, analyticsCustomEvent } = useEventsReporter();
    const { isGuestModeOn } = useGuestMode();
    const { push, prevPath } = useRouter();
    const { setGuestModal } = useUserUpdateContext();
    const { mutateAsync: enroll } = useEnrollInRewardsMutation({
        onSuccess: () => refetchUserInfo(),
    });
    const { data: RewardPoints }: any = useLoyaltyRewardPointsQuery();

    const [loyaltyRewardPoints, setLoyaltyRewardPoints] = useState<
        Record<any, number | string>
    >({});
    const [enrollmentError, setEnrollmentError] = useState<{
        errorCode: string;
        errorDialogMessage: string;
    } | null>(null);

    const handleVisit = (e: any) => {
        if (e.target.id === 'learnMore') {
            customEvent(eventMetricsMap.recycle_reward_learnmore);
            analyticsCustomEvent(eventMetricsMap.recycling, {
                click_text: 'learn more',
                element_location: 'stores page',
            });
            optimoveEvents(eventMetricsMap.scm_recycling, {
                click_text: 'learn more',
                element_location: 'stores page',
            });
        } else if (e.target.id === 'nextVisit') {
            customEvent(eventMetricsMap.recycle_plannextvisit);
            analyticsCustomEvent(eventMetricsMap.recycling, {
                click_text: 'plan your next recycling visit',
                element_location: 'stores page',
            });
            optimoveEvents(eventMetricsMap.scm_recycling, {
                click_text: 'plan your next recycling visit',
                element_location: 'stores page',
            });
        } else {
            customEvent(eventMetricsMap.recycle_planfirstvisit);
            analyticsCustomEvent(eventMetricsMap.recycling, {
                click_text: 'plan your first recycling visit',
                element_location: 'stores page',
            });
            optimoveEvents(eventMetricsMap.scm_recycling, {
                click_text: 'plan your first recycling visit',
                element_location: 'stores page',
            });
        }
        setStoreRecycleLink(recyclingURL);
        analyticsCustomEvent(eventMetricsMap.outbound, {
            click_text: 'recycling',
            link_domain: 'staples.com',
            link_url: recyclingURL,
        });
        optimoveEvents(eventMetricsMap.scm_outbound, {
            click_text: 'recycling',
            link_domain: 'staples.com',
            link_url: recyclingURL,
        });
    };

    const handleJoinFreeClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        if (isGuestModeOn) {
            setGuestModal({
                open: true,
                closeBehavior: 'onboarding',
                previousRoute: prevPath,
            });
        } else if (userInfo && validateUserInfo(userInfo)) {
            enroll({
                FirstName: userInfo.firstName,
                LastName: userInfo.lastName,
                PhoneNumber: userInfo.phone,
                EmailId: userInfo.email,
            })
                .then((res: any) => {
                    console.log({ res });
                    if (res?.data?.success) {
                        push(REWARDS_ENROLLMENT_ROUTES.peronalize);
                    } else {
                        analyticsCustomEvent(eventMetricsMap.pop_up, {
                            click_text:
                                'We were unable to enroll you in Easy Rewards at this time.',
                            element_location: 'easy_rewards',
                        });
                        setEnrollmentError({
                            errorCode: res?.data.code,
                            errorDialogMessage: res?.data.msg,
                        });
                    }
                })
                .catch((error) => {
                    analyticsCustomEvent(eventMetricsMap.pop_up, {
                        click_text:
                            'We were unable to enroll you in Easy Rewards at this time.',
                        element_location: 'easy_rewards',
                    });
                    setEnrollmentError({
                        errorCode: error?.response?.data?.code || 'default',
                        errorDialogMessage:
                            error?.response?.data?.msg || 'Please try again',
                    });
                });
        } else {
            push(ROUTES.rewardsWallet);
        }
        analyticsCustomEvent(eventMetricsMap.stores, {
            click_text: 'join for free',
        });
    };

    const toggleLocalImpact = () => {
        setLocalExpand(!localExpand);
        toggleLocal(!localExpand);
    };
    const handleInfoIconClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        toggleDrawer(true);
    };

    const handleSignIn = () => {
        setGuestModal({ open: true });
        analyticsCustomEvent(eventMetricsMap.home, {
            click_text: 'sign in',
            element_location: `guest mode`,
        });
    };

    useEffect(() => {
        setLoyaltyRewardPoints(RewardPoints?.[0]?.results?.[0]);
    }, [RewardPoints]);

    const RecycleContent = useCallback(() => {
        const text =
            isGuestModeOn || !userInfo?.rewardsNumber
                ? 'Sign in or join Easy Rewards to earn points on recycling and to track your recycling progress.'
                : `Earn ${
                      loyaltyRewardPoints?.points || 'additional'
                  } points on each ink or toner cartridge you recycle, up to ${
                      loyaltyRewardPoints?.maxnumberofcartridgesrecycled ||
                      'a limit'
                  } per month. No purchase required.`;
        const buttonText =
            isGuestModeOn || !userInfo?.rewardsNumber
                ? 'Join for free'
                : 'Plan a visit';

        const buttonClickHandler = (e: React.MouseEvent<HTMLElement>) => {
            if (isGuestModeOn) {
                handleSignIn();
            } else if (userInfo && !userInfo.rewardsNumber) {
                handleJoinFreeClick(e);
            } else {
                handleVisit(e);
            }
        };

        return (
            <>
                <Body size={14} weight="light">
                    {text}
                </Body>
                <Button
                    onClick={buttonClickHandler}
                    variant="contained-rounded"
                    bgColor="bgBlack"
                    color="textWhite"
                >
                    {buttonText}
                </Button>
            </>
        );
    }, [
        isGuestModeOn,
        userInfo,
        userInfo?.rewardsNumber,
        loyaltyRewardPoints,
        loyaltyRewardPoints?.points,
        loyaltyRewardPoints?.maxnumberofcartridgesrecycled,
    ]);

    return (
        <>
            <section className={classes.sectionContainer}>
                <div className={classes.topContainer}>
                    <div className={classes.recycleInfoContainer}>
                        <Heading type="h6" size={20} weight="bold">
                            Recycle every day at Staples
                        </Heading>
                        <RecycleContent />
                        <div className={classes.secondaryButtonContainer}>
                            <Button
                                onClick={() => {
                                    if (isGuestModeOn) {
                                        handleSignIn();
                                    } else {
                                        setStoreRecycleLink(rewardcenterURL);
                                    }
                                }}
                                variant="text"
                                color="textLinkBlue"
                            >
                                or recycle via mail
                            </Button>
                            <Button
                                onClick={(e) => handleInfoIconClick(e)}
                                variant="icon"
                                contentClassName={classes.recycleInfoIcon}
                            >
                                <IconInfo />
                            </Button>
                        </div>
                    </div>
                    <img
                        src={require('../assets/bgs/recycle-everyday-store-section-bg.png')}
                        alt="background"
                        className={classes.recycleBackgroundImage}
                    />
                </div>
                {!isGuestModeOn && (
                    <section
                        className={classes.localImpactButtonContainer}
                        role="button"
                        id="localImpactButton"
                    >
                        {userInfo?.rewardsNumber && storeVisitsCount > 0 && (
                            <>
                                <div>
                                    <Heading type="h6" size={18}>
                                        Your recycling visits
                                    </Heading>
                                    <Body
                                        weight="light"
                                        size={12}
                                        color="textMediumGray"
                                    >
                                        in last 12 months
                                    </Body>
                                </div>

                                <div className={classes.recycleVisitContainer}>
                                    <div
                                        className={
                                            classes.recycleVisitCountContainer
                                        }
                                    >
                                        <Body
                                            className={
                                                classes.recycleGreenColor
                                            }
                                            size={44}
                                            weight="bold"
                                        >
                                            {storeVisitsCount}
                                        </Body>
                                        <Body size={12} weight="light">
                                            Store visits
                                        </Body>
                                    </div>
                                    <div className={classes.verticalRule} />
                                    <div
                                        className={
                                            classes.recycleVisitCountContainer
                                        }
                                    >
                                        <IconInkDrops />
                                        <Body
                                            className={
                                                classes.recycleGreenColor
                                            }
                                            size={44}
                                            weight="bold"
                                        >
                                            {inkandTonerCount}
                                        </Body>
                                        <Body size={12} weight="light">
                                            Ink & toners recycled
                                        </Body>
                                    </div>
                                </div>
                            </>
                        )}

                        <Button
                            fullWidth
                            variant="text"
                            onClick={toggleLocalImpact}
                        >
                            <div className={classes.localImpactButton}>
                                <Body
                                    color="textBlack"
                                    size={14}
                                    textAlign="textCenter"
                                >
                                    See your local and nationwide impact
                                </Body>
                                <div>
                                    {localExpand ? (
                                        <img
                                            aria-hidden="true"
                                            className={classes.upIcon}
                                            src={require('../assets/icons/iconChevronUp.svg')}
                                            alt="arrow"
                                        />
                                    ) : (
                                        <img
                                            aria-hidden="true"
                                            className={classes.upIcon}
                                            src={require('../assets/icons/iconChevronDown.svg')}
                                            alt="arrow"
                                        />
                                    )}
                                </div>
                            </div>
                        </Button>
                    </section>
                )}
            </section>
            <RewardsEnrollErrorDialog
                error={enrollmentError}
                setError={setEnrollmentError}
            />
        </>
    );
}
