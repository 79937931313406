import React from 'react';
import IconInterface from './IconInterface';

function IconCircularDelete({ width = 28, height = 28 }: IconInterface) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.7705 20.6035C17.1693 20.6035 17.4846 20.4977 17.7166 20.2861C17.9485 20.0745 18.0726 19.7653 18.0889 19.3584L18.4795 10.4106H19.3462C19.4927 10.4106 19.6188 10.3577 19.7246 10.252C19.8304 10.1462 19.8874 10.0241 19.8955 9.88574C19.8955 9.73926 19.8406 9.61515 19.7307 9.51343C19.6208 9.4117 19.4927 9.36084 19.3462 9.36084H16.6606V8.46973C16.6606 7.98958 16.5101 7.60913 16.209 7.32837C15.9079 7.04761 15.4969 6.90723 14.9761 6.90723H12.73C12.2091 6.90723 11.7982 7.04761 11.4971 7.32837C11.196 7.60913 11.0454 7.98958 11.0454 8.46973V9.36084H8.34766C8.20117 9.36084 8.07503 9.4117 7.96924 9.51343C7.86344 9.61515 7.81055 9.73926 7.81055 9.88574C7.81055 10.0241 7.86344 10.1462 7.96924 10.252C8.07503 10.3577 8.20117 10.4106 8.34766 10.4106H9.25098L9.6416 19.3584C9.65788 19.7653 9.77995 20.0745 10.0078 20.2861C10.2357 20.4977 10.5531 20.6035 10.96 20.6035H16.7705ZM15.4521 9.36084H12.2539V8.53076C12.2539 8.37614 12.3027 8.2561 12.4004 8.17065C12.498 8.08521 12.6242 8.04248 12.7788 8.04248H14.915C15.0697 8.04248 15.1958 8.08521 15.2935 8.17065C15.3911 8.2561 15.4399 8.37614 15.4399 8.53076L15.4521 9.36084ZM11.4727 18.4307C11.4808 18.6992 11.6191 18.8335 11.8877 18.8335C12.1725 18.8335 12.3109 18.6951 12.3027 18.4185L12.083 11.79C12.083 11.5133 11.9447 11.375 11.668 11.375C11.375 11.375 11.2285 11.5133 11.2285 11.79L11.4727 18.4307ZM13.853 18.8213C13.5601 18.8213 13.4136 18.687 13.4136 18.4185V11.79C13.4136 11.5133 13.5601 11.375 13.853 11.375C14.1379 11.375 14.2803 11.5133 14.2803 11.79V18.4185C14.2803 18.687 14.1379 18.8213 13.853 18.8213ZM15.3789 18.4185C15.3708 18.6951 15.5091 18.8335 15.7939 18.8335C16.0706 18.8335 16.209 18.6992 16.209 18.4307L16.4531 11.79C16.4531 11.5133 16.3107 11.375 16.0259 11.375C15.741 11.375 15.5986 11.5133 15.5986 11.79L15.3789 18.4185Z"
                fill="#CC0000"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.9084 2.85449C7.81087 2.85449 2.8506 7.83748 2.8506 13.9629C2.8506 20.0884 7.81087 25.0714 13.9084 25.0714C20.0059 25.0714 24.9662 20.0884 24.9662 13.9629C24.9662 7.83748 20.0059 2.85449 13.9084 2.85449ZM13.9084 25.9258C7.34219 25.9258 2 20.5592 2 13.9629C2 7.36665 7.34219 2 13.9084 2C20.4746 2 25.8168 7.36665 25.8168 13.9629C25.8168 20.5592 20.4746 25.9258 13.9084 25.9258Z"
                fill="#CC0000"
            />
            <path
                d="M13.9084 2.70449C7.72738 2.70449 2.7006 7.75528 2.7006 13.9629H3.0006C3.0006 7.91968 7.89436 3.00449 13.9084 3.00449V2.70449ZM2.7006 13.9629C2.7006 20.1706 7.72738 25.2214 13.9084 25.2214V24.9214C7.89436 24.9214 3.0006 20.0062 3.0006 13.9629H2.7006ZM13.9084 25.2214C20.0894 25.2214 25.1162 20.1706 25.1162 13.9629H24.8162C24.8162 20.0062 19.9224 24.9214 13.9084 24.9214V25.2214ZM25.1162 13.9629C25.1162 7.75528 20.0894 2.70449 13.9084 2.70449V3.00449C19.9224 3.00449 24.8162 7.91968 24.8162 13.9629H25.1162ZM13.9084 25.7759C7.42568 25.7759 2.15 20.477 2.15 13.9629H1.85C1.85 20.6414 7.2587 26.0758 13.9084 26.0758V25.7759ZM2.15 13.9629C2.15 7.44885 7.42568 2.15 13.9084 2.15V1.85C7.2587 1.85 1.85 7.28446 1.85 13.9629H2.15ZM13.9084 2.15C20.3911 2.15 25.6668 7.44885 25.6668 13.9629H25.9668C25.9668 7.28446 20.5581 1.85 13.9084 1.85V2.15ZM25.6668 13.9629C25.6668 20.477 20.3911 25.7759 13.9084 25.7759V26.0758C20.5581 26.0758 25.9668 20.6414 25.9668 13.9629H25.6668Z"
                fill="#CC0000"
            />
        </svg>
    );
}

export default IconCircularDelete;
