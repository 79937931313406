import React, { useContext, useEffect, useState } from 'react';
import {
    Box,
    Button,
    ButtonBase,
    Dialog,
    DialogTitle,
    makeStyles,
    Typography,
} from '@material-ui/core';
import {
    eventMetricsMap,
    useEventsReporter,
    useRouter,
    useUserInfo,
} from 'src/hooks';
import ROUTES from 'src/lib/routes';
import ShippingInfoContext from 'src/lib/contexts/shipping-info-context';
import ShippingPrivacyTermsHome from 'src/components/shipping-services/shipping-privacy-terms/shipping-privacy-terms-home';
import clsx from 'clsx';

import useConfigInfo from 'src/hooks/useConfigInfo';
import { useShipmentTracking } from 'src/lib/contexts/ShipmentTrackingContext';
import { Body, Heading } from 'src/components/UI';
import Row from 'src/components/Row';
import ScanToTrackIcon from 'src/assets/icons/icon_scan_to_track.svg';
import LabelAndTrackIcon from 'src/assets/icons/icon_labels_and_tracking.svg';
import GetQuoteIcon from 'src/assets/icons/icon_get_a_quote.svg';
import COLORS from '../../../lib/colors';
import Brcd from '../../BarcodeScanner/Brcd';
import { addShipmentTracking } from '../../../lib/api-client/shipment-services';
import LoadingOverlay from '../../loading-overlay';
import ShippingServicesIntro from './shipping-services-intro';
import ScanModal from '../components/ScanModal';
import ShippingRewards from '../shipping-rewards';

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        padding: '0 !important',
    },
    shippingServicesContainer: {
        display: 'flex',
        cursor: 'pointer',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
        padding: '20px 20px 15px 18px',
        margin: '1.5rem',
    },
    shippingServicesSubContainer: {
        marginTop: '49px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        padding: '0px 30px',
    },
    shippingServicesCTATopText: {
        fontSize: '40px',
        fontFamily: 'StaplesNormsBold',
        lineHeight: '34px',
        letterSpacing: '-1px',
        color: '#1E1E1E',
    },
    linkCta: {
        fontWeight: 500,
        marginTop: '15px',
        color: '#086DD2',
        fontSize: '14px',
        lineHeight: '22px',
        marginBottom: '32px',
    },
    shippingServicesCTARightImage: {
        // margin: '0px 0 20px',
        width: '92px',
    },
    iconBack: {
        position: 'absolute',
        left: '14px',
        top: '18px',
        zIndex: 1,
    },
    shortText1: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '26px',
        letterSpacing: '0px',
        color: '#1E1E1E',
        marginBottom: '40px',
    },
    saveTimeAlign: {
        // fontSize: '18px',
        // textAlign: 'center',
        // fontFamily: 'StaplesNormsRegular',
        // lineHeight: '22px',
        letterSpacing: '-0.46pxpx',
        color: '#1E1E1E',
        margin: '5px 50px 30px',
    },
    imageContainer: {
        position: 'relative',
        // marginRight: '1.25rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    shippingServiceContainer: {
        display: 'flex',
        flexDirection: 'column',
        background: '#FFFFFF',
        height: '100vh',
        overflowY: 'auto',
    },
    shippingLogo: {
        position: 'fixed',
        width: '100px',
        height: 'auto',
        top: '0',
        right: '0',
    },
    startShipment: {
        height: '40px',
        width: '100%',
        borderRadius: '20px',
        background: '#CC0000',
        marginBottom: '10px',
        '& span': {
            fontSize: '14px',
            fontWeight: 'normal',
            lineHeight: '17px',
            color: '#FFFFFF',
            textAlign: 'center',
        },
        '&:hover': {
            opacity: 0.8,
        },
    },
    savedLabels: {
        fontFamily: 'StaplesNormsMedium',
        // height: '40px',
        width: '200px',
        gap: '5px',
        '& span': {
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '17px',
            color: '#086DD2',
            textAlign: 'center',
        },
    },
    tileButtonContainer: {
        display: 'flex',
    },
    tileButton: {
        fontFamily: 'StaplesNormsMedium',
        fontSize: '16px',
        background: COLORS.brandRed,
        height: '50px',
        // width: '300px',
        width: '-webkit-fill-available',
        borderRadius: '20px',
        // marginBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
        color: COLORS.primaryWhite,
        fontWeight: 500,
        '& img': {
            width: '54px',
        },
    },
    startShipmentTile: {
        borderRadius: '30px',
        background: `${COLORS.brandRed}`,
        border: `2px solid ${COLORS.brandRed}`,
    },
    paper: { borderRadius: '15px', margin: '24px', padding: '10px 0 0 0' },
    dialogTitle: {
        fontSize: '18px',
        color: '#000',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '-0.2px',
        margin: '10px 0 10px 0px',
    },
    dialogWrapper: {
        padding: '10px 20px 30px 30px',
        textAlign: 'center',
        color: COLORS.secondaryNeutralBlack,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    dialogMessage: {
        marginBottom: '30px',
        fontSize: '14px',
        fontWeight: 400,
    },
    closeIcon: {
        position: 'absolute',
        top: '-3px',
        right: '10px',
        backgroundColor: 'rgba(0,0,0,0)',
        minWidth: 'initial',
        padding: '0px',
    },
    closeButton: {
        position: 'relative',
        textAlign: 'center',
    },
    anotherPackage: {
        margin: '0px',
    },
    successIcon: {
        width: '50px',
        marginBottom: '5px',
    },
    privacyLink: {
        // bottom: '0px',
        // position: 'absolute',
        // height: '40px',
        alignSelf: 'center',
        textDecoration: 'underline',
    },
}));

const ShippingServicesHome = ({ goBack }: { goBack: () => void }) => {
    const classes = useStyles();
    const { history, query } = useRouter();
    const { analyticsCustomEvent } = useEventsReporter();
    const [showTermsPrivacy, setShowTermsPrivacy] = useState<boolean>(false);
    const privacyPolicyURL = 'https://www.staplesconnect.com/privacypolicy';
    const {
        updateFetchSenderInfo,
        updateReviewStage,
        openScanToTrack,
        updateOpenScanToTrack,
        updateShippingTab,
    } = useContext(ShippingInfoContext);
    const {
        handleClose,
        getShipmentTrackingDetails,
        scanMessage,
        scannerData,
        isLoading,
        setScannerData,
        isScanCompleted,
        trackingError,
        setFullShippingShow,
        isFullShippingShow,
    } = useShipmentTracking();
    const [isBarcodeScannerOpen, setIsBarcodeScannerOpen] = useState(false);

    const [isIntroShow, setIsIntroShow] = useState(false);

    const { isRewardShipperEnable } = useUserInfo();
    const { shortdesc: shippingConfigPoints } = useConfigInfo(
        'ShippingOfferPoints'
    );

    useEffect(() => {
        if (history?.location?.state && !isFullShippingShow) {
            updateFetchSenderInfo(true);
            updateReviewStage(false);
            if (isRewardShipperEnable) {
                if (localStorage.getItem('startShipment') === 'true') {
                    history.push({
                        pathname: ROUTES.createShippingInformation,
                        search: '?step=createShipmentSenderInfo',
                    });
                } else {
                    setIsIntroShow(true);
                }
            } else {
                history.push({
                    pathname: ROUTES.createShippingInformation,
                    search: '?step=createShipmentSenderInfo',
                });
            }
        }
        if (isFullShippingShow) {
            setFullShippingShow(false);
        }
    }, []);

    const handleStartShipment = () => {
        analyticsCustomEvent(eventMetricsMap.shipping, {
            click_text: 'start a shipment',
            element_location: 'main shipping page',
        });
        updateFetchSenderInfo(true);
        updateReviewStage(false);

        if (isRewardShipperEnable) {
            if (localStorage.getItem('startShipment') === 'true') {
                history.push({
                    pathname: ROUTES.createShippingInformation,
                    search: '?step=createShipmentSenderInfo',
                });
            } else {
                setIsIntroShow(true);
            }
        } else {
            history.push({
                pathname: ROUTES.createShippingInformation,
                search: '?step=createShipmentSenderInfo',
            });
        }
    };

    useEffect(() => {
        if (openScanToTrack) {
            setIsBarcodeScannerOpen(true);
            updateOpenScanToTrack(false);
        }
    }, [openScanToTrack]);
    const handleScan = () => {
        analyticsCustomEvent(eventMetricsMap.shipping, {
            click_text: 'scan to track',
            element_location: 'main shipping page',
        });
        if (isRewardShipperEnable) {
            if (localStorage.getItem('startShipment') === 'true') {
                setIsBarcodeScannerOpen(true);
            } else {
                history.push({
                    pathname: ROUTES.shippingServices,
                    state: true,
                    search: '?step=scanToTrack',
                });
                setIsIntroShow(true);
            }
        } else {
            setIsBarcodeScannerOpen(true);
        }
    };

    useEffect(() => {
        if (query?.barcode) {
            setScannerData(query.barcode);
        }
    }, [query?.barcode]);

    useEffect(() => {
        if (scannerData) {
            getShipmentTrackingDetails(scannerData);
        }
    }, [scannerData]);

    const handleSavedLabelShipment = (state: string) => {
        if (state) {
            updateShippingTab(state);
        }
        history.push(ROUTES.shippingServicesLabelShipment);
        analyticsCustomEvent(eventMetricsMap.shipping, {
            click_text: 'labels and tracking',
            element_location: 'main shipping page',
        });
    };
    const handlePrivacyPolicy = () => {
        setShowTermsPrivacy(true);
        analyticsCustomEvent(eventMetricsMap.shipping, {
            click_text: 'privacy & terms',
            element_location: 'main shipping page',
        });
    };
    const handleShippingGetQuote = () => {
        history.push({
            pathname: ROUTES.getQuoteShippingInformation,
            search: '?step=getEstimator',
        });
        analyticsCustomEvent(eventMetricsMap.shipping, {
            click_text: 'get a quote',
            element_location: 'main shipping page',
        });
    };
    if (isLoading) {
        return (
            <LoadingOverlay
                variant="wordmark"
                theme="transparent-light"
                open={isLoading}
            />
        );
    }
    const handleGoBack = () => {
        if (isIntroShow) {
            setIsIntroShow(false);
        } else if (query?.backpath) {
            console.log('query', query);
            history.push(query.backpath);
        } else {
            console.log('query', query);
            goBack();
        }
        setFullShippingShow(false);
    };

    const IconButtonWrapper = ({
        img,
        text,
        onClick,
    }: {
        img: string;
        text: string;
        onClick: () => void;
    }) => (
        <ButtonBase className={classes.savedLabels} onClick={onClick}>
            <img src={img} alt={text} />
            <Body size={14} lineHeight={20} color="textLinkBlue">
                {text}
            </Body>
        </ButtonBase>
    );
    return (
        <div className={classes.shippingServiceContainer}>
            <Box
                sx={{
                    px: '16px',
                    pt: '25px',
                }}
            >
                <ButtonBase
                    onClick={handleGoBack}

                    // className={classes.iconBack}
                >
                    <Row alignItems={'center'} gap="3px">
                        <>
                            <img
                                src={require('src/assets/icons/iconBackArrow.svg')}
                                alt="iconBack"
                            />
                            {!isIntroShow ? (
                                <Body size={12} weight="bold">
                                    Go back
                                </Body>
                            ) : null}
                        </>
                    </Row>
                </ButtonBase>
            </Box>

            {isIntroShow && isRewardShipperEnable ? (
                <ShippingServicesIntro />
            ) : (
                <Row
                    direction="column"
                    justifyContent={'space-between'}
                    sx={{
                        height: 'calc(100% - 45.14px)',
                        overflow: 'auto',
                    }}
                >
                    <>
                        <div className={classes.shippingServicesSubContainer}>
                            <Row direction="column" gap="30px">
                                <>
                                    <div className={classes.imageContainer}>
                                        <img
                                            className={
                                                classes.shippingServicesCTARightImage
                                            }
                                            src={require('src/assets/icons/img_shipping_icon.svg')}
                                            alt="shipping services UPS"
                                        />
                                    </div>
                                    <Row direction="column" gap="5px">
                                        <>
                                            <Body
                                                size={32}
                                                weight="bold"
                                                lineHeight={38}
                                                textAlign="textCenter"
                                                // className={classes.shippingServicesCTATopText}
                                            >
                                                Shipping services
                                            </Body>
                                            <Body
                                                // className={classes.saveTimeAlign}
                                                size={16}
                                                lineHeight={20}
                                                weight="light"
                                                textAlign="textCenter"
                                            >
                                                Save time by prepping your label
                                                for faster shipping in store.
                                            </Body>
                                        </>
                                    </Row>
                                    <ButtonBase
                                        className={clsx(
                                            classes.tileButton,
                                            classes.startShipmentTile
                                        )}
                                        onClick={handleStartShipment}
                                    >
                                        <div>Start a shipment</div>
                                    </ButtonBase>
                                </>
                            </Row>
                            <Row direction="column" gap="18px" mt={'40px'}>
                                <>
                                    <IconButtonWrapper
                                        text="Scan to track"
                                        onClick={handleScan}
                                        img={ScanToTrackIcon}
                                    />
                                    <IconButtonWrapper
                                        text="Labels and tracking"
                                        onClick={() =>
                                            handleSavedLabelShipment('create')
                                        }
                                        img={LabelAndTrackIcon}
                                    />
                                    <IconButtonWrapper
                                        text="Get a quote"
                                        onClick={handleShippingGetQuote}
                                        img={GetQuoteIcon}
                                    />
                                </>
                            </Row>
                            {isBarcodeScannerOpen && (
                                <Brcd
                                    isScannerOpen={isBarcodeScannerOpen}
                                    shippingScan
                                    onClose={() => {
                                        setIsBarcodeScannerOpen(false);
                                        handleClose();
                                    }}
                                />
                            )}

                            {isScanCompleted && (
                                <ScanModal
                                    handleSavedLabelShipment={
                                        handleSavedLabelShipment
                                    }
                                    handleScan={handleScan}
                                />
                            )}
                        </div>

                        <Row direction="column" mb={'10px'}>
                            <>
                                {isRewardShipperEnable && (
                                    <ShippingRewards hideBtn isBorder />
                                )}

                                <ButtonBase
                                    className={classes.privacyLink}
                                    onClick={handlePrivacyPolicy}
                                >
                                    <span style={{ margin: '5px' }}>
                                        Privacy & Terms
                                    </span>
                                </ButtonBase>
                            </>
                        </Row>

                        {showTermsPrivacy ? (
                            <ShippingPrivacyTermsHome
                                showDrawer={showTermsPrivacy}
                                setShowTermsPrivacy={setShowTermsPrivacy}
                            />
                        ) : null}
                    </>
                </Row>
            )}
        </div>
    );
};

export default ShippingServicesHome;
