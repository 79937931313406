/* eslint-disable max-len */
import React from 'react';
import IconInterface from './IconInterface';

function IconCornerSplashStore() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="96"
            height="93"
            viewBox="0 0 96 93"
            fill="none"
        >
            <mask
                id="mask0_5096_2558"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="96"
                height="93"
            >
                <path
                    d="M0 0H81C89.2843 0 96 6.71573 96 15V93H0V0Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_5096_2558)">
                <path
                    d="M44.8095 48.1052L76.3811 79.6792C80.6491 83.9472 80.6491 90.8651 76.3811 95.1331C72.1131 99.4011 65.1951 99.4011 60.9271 95.1331L29.3556 63.5591C25.0876 59.2911 25.0876 52.3732 29.3556 48.1052C33.6236 43.8396 40.5415 43.8396 44.8095 48.1052Z"
                    fill="#CC0000"
                />
                <path
                    d="M59.079 43.4837C65.4013 43.4837 70.5265 38.3584 70.5265 32.0362C70.5265 25.7139 65.4013 20.5886 59.079 20.5886C52.7567 20.5886 47.6314 25.7139 47.6314 32.0362C47.6314 38.3584 52.7567 43.4837 59.079 43.4837Z"
                    fill="#3E009F"
                />
                <path
                    d="M35.4475 19.8521C41.7698 19.8521 46.8951 14.7269 46.8951 8.40456C46.8951 2.08227 41.7698 -3.04297 35.4475 -3.04297C29.1252 -3.04297 24 2.08227 24 8.40456C24 14.7269 29.1252 19.8521 35.4475 19.8521Z"
                    fill="#0FBAFF"
                />
                <path
                    d="M96.4566 84.8675L72.9633 61.3742C68.6953 57.1062 68.6953 50.1882 72.9633 45.9202C77.2313 41.6522 84.1492 41.6522 88.4172 45.9202L111.911 69.4136C116.179 73.6816 116.179 80.5995 111.911 84.8675C107.643 89.1355 100.725 89.1355 96.4566 84.8675Z"
                    fill="#5B0069"
                />
                <path
                    d="M91.5298 34.1483L68.0364 10.6549C63.7684 6.38693 63.7684 -0.531007 68.0364 -4.799C72.3044 -9.067 79.2223 -9.067 83.4903 -4.799L106.984 18.6944C111.252 22.9624 111.252 29.8803 106.984 34.1483C102.716 38.4163 95.7978 38.4163 91.5298 34.1483Z"
                    fill="#0058D8"
                />
            </g>
        </svg>
    );
}

export default IconCornerSplashStore;
