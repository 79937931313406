import { Box, Modal, ModalProps } from '@material-ui/core';
import React from 'react';

const initialStyle = (isNoPadding?: boolean) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: isNoPadding ? 0 : 2,
    px: isNoPadding ? 0 : 4,
    pb: isNoPadding ? 0 : 3,
    '&:focus-visible': {
        outline: 'none',
    },
});

interface IProps extends ModalProps {
    children: JSX.Element;
    isNoPadding?: boolean;
}

function CommonModal({ children, isNoPadding, ...rest }: IProps) {
    return (
        <Modal {...rest}>
            <Box
                sx={{
                    borderRadius: '10px',
                    ...initialStyle(isNoPadding),
                }}
            >
                {children}
            </Box>
        </Modal>
    );
}

export default CommonModal;
