import React from 'react';
import { Dialog } from '@material-ui/core';
import { CommonPageProps } from 'src/screens/product-browse/common-page';
import { useProductBrowseContext } from 'src/lib/contexts/product-browse-context';
import ProductSearchLandingScreen from 'src/screens/product-browse/search-landing/search-landing-screen';
import ProductSearchResultsScreen from 'src/screens/product-browse/search-results/search-results-screen';
import ProductFilterScreen from 'src/screens/product-browse/filter/filter-screen';
import ProductSortScreen from 'src/screens/product-browse/sort';
import ProductDetailsScreen from 'src/screens/product-browse/product-details/product-details';
import ProductEmptyScreen from 'src/screens/product-browse/empty';
import { useIsActiveScreen } from 'src/lib/contexts/screen-order-context';
import { useStoreData } from 'src/hooks';
import { SCREEN_NAMES } from './config';

/**
 * Eventually we will want to add transitions to our stack of pages.
 * For now we currently only jump from one page to another.
 */
function PageSwitch(props: CommonPageProps) {
    switch (props?.page?.type) {
        case 'search-landing':
            return <ProductSearchLandingScreen {...props} />;
        case 'search-results':
            return <ProductSearchResultsScreen {...props} />;
        case 'filter':
            return <ProductFilterScreen {...props} />;
        case 'sort':
            return <ProductSortScreen {...props} />;
        case 'product-details':
            return <ProductDetailsScreen {...props} />;
        default:
            return <ProductEmptyScreen {...props} />;
    }
}

/**
 * Contains logic for both product searching and browsing
 * For all intents, searching is just a more exact form of browsing
 */
function ProductBrowseController() {
    const { isBrowsing, currentPage, lastPoppedPage } =
        useProductBrowseContext();
    const isOpen = useIsActiveScreen(SCREEN_NAMES.PRODUCT_BROWSE, isBrowsing);
    const { isStoreModeOn } = useStoreData();

    // TODO: STP-1425  error/empty states & ensuring base user data exists
    return (
        <Dialog
            open={isOpen}
            fullScreen
            className="productDialog"
            style={{ top: isStoreModeOn ? '66px' : '0px' }}
        >
            {/* Show last popped page instead if stack is empty (& we're dismissing the modal) */}
            <PageSwitch page={currentPage || lastPoppedPage} />
            {/* TODO: STP-1425 store switch dialog */}
            {/* TODO: STP-1425 callbacks for committing new quantities */}
            {/* TODO: STP-1425 callbacks for navigating to lists */}
        </Dialog>
    );
}

// The combination of react-oidc and our routes is somehow throwing an error
// saying that we are not in a function component and trying to declare hooks.
// Wrapping in one more functional component appears to fix it.
const WrappedProductsController = () => <ProductBrowseController />;
export default WrappedProductsController;
