import React from 'react';
import { Body, Heading, Button } from 'src/components/UI';
import { eventMetricsMap, useEventsReporter } from 'src/hooks';
import styles from './styles.module.scss';
import '../../../assets/animate/animate.css';

const LetsGoScreen = ({ handleSkipAll }: any) => {
    const animationClass =
        'animate__animated animate__fadeIn animate__delay-0.8s';

    const { analyticsCustomEvent } = useEventsReporter();
    const handleLestGo = () => {
        handleSkipAll();
        analyticsCustomEvent(eventMetricsMap.coachmarks, {
            click_text: `lets go!`,
        });
    };

    return (
        <div className={`${styles.boxWrapper} ${animationClass}`}>
            <img
                src={
                    'https://de-app.staplesconnect.com/images/CoachMarks/cm_img_end_screen_sm.png'
                }
                alt="img"
            />
            <Heading type="h2" size={26} weight="bold" lineHeight={32}>
                You make it look easy!
            </Heading>
            <Body size={16} weight="light" lineHeight={20}>
                You’re ready to start racking up points and saving with Easy
                Rewards.
            </Body>

            <Button bgColor="bgred" onClick={handleLestGo}>
                Lets go!
            </Button>
        </div>
    );
};

export default LetsGoScreen;
