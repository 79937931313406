/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import {
    ButtonBase,
    Dialog,
    makeStyles,
    Typography,
    SwipeableDrawerProps,
    Box,
} from '@material-ui/core';
import clsx from 'clsx';
import {
    eventMetricsMap,
    useEventsReporter,
    useRouter,
    useUserInfo,
} from 'src/hooks';
import COLORS from 'src/lib/colors';
import ShippingInfoContext from 'src/lib/contexts/shipping-info-context';
import ROUTES from 'src/lib/routes';
import { addShipmentTracking } from 'src/lib/api-client/shipment-services';
import { useShipmentTracking } from 'src/lib/contexts/ShipmentTrackingContext';
import { Body, Button, Heading, SwipeableDrawer } from '../../UI';
import { IconScanShipping, IconCornerSplashRight } from '../../Icons';
import styles from './styles.module.scss';
import Brcd from '../../BarcodeScanner/Brcd';
import LoadingOverlay from '../../loading-overlay';
import ScanModal from '../components/ScanModal';
import useStyles from '../styles/shipmentServicesStyles';

interface DrawerMessage {
    drawermessagetype: string;
    drawermessagetitle: string;
    imgurl: string;
    drawermessagetext1: string;
    drawermessagetext2: string;
    drawermessagetext3: string;
    actiontitle: string;
    actiontarget: string;
    actionmetadata: string;
    terms: string;
}

interface MessegesDataProps {
    drawermessages: DrawerMessage[];
    status: string;
    message: string;
    issuccess: boolean;
}

interface ShippingRewardsInfoDialogProps extends SwipeableDrawerProps {
    points: string | undefined;
    hideBtn?: boolean;
    messagesData: MessegesDataProps;
}

function ShippingRewardsInfoDialog(props: ShippingRewardsInfoDialogProps) {
    const classes = useStyles();
    const [isBarcodeScannerOpen, setIsBarcodeScannerOpen] = useState(false);

    const { isRewardShipperEnable } = useUserInfo();
    const {
        handleClose,
        getShipmentTrackingDetails,
        scanMessage,
        scannerData,
        isLoading,
        setScannerData,
        isScanCompleted,
        trackingError,
    } = useShipmentTracking();

    const { push } = useRouter();
    const { analyticsCustomEvent } = useEventsReporter();
    const { updateFetchSenderInfo, updateReviewStage, updateShippingTab } =
        useContext(ShippingInfoContext);

    const handleSavedLabelShipment = (state: string) => {
        if (state) {
            updateShippingTab(state);
        }
        push(ROUTES.shippingServicesLabelShipment);
        analyticsCustomEvent(eventMetricsMap.shipping, {
            click_text: 'go to lables and tracking',
            element_location: 'scan successful',
        });
    };

    const handleStartShipment = () => {
        updateFetchSenderInfo(true);
        updateReviewStage(false);
        push(
            {
                pathname: ROUTES.shippingServices,
                state: true,
                // search: '?step=createShipmentSenderInfo',
            },
            { path: ROUTES.rewardsWallet }
        );
        analyticsCustomEvent(eventMetricsMap.shipping, {
            click_text: 'start a shipment',
            element_location: 'frequent shipper activated drawer',
        });
    };

    const handleScan = (elLoc: string) => {
        if (
            isRewardShipperEnable &&
            localStorage.getItem('startShipment') !== 'true'
        ) {
            push({
                pathname: ROUTES.shippingServices,
                state: true,
                search: '?step=scanToTrack',
            });
            analyticsCustomEvent(eventMetricsMap.shipping, {
                click_text: 'scan to track',
                element_location: 'stores page',
            });
        } else {
            setIsBarcodeScannerOpen(true);
            if (elLoc === 'scan_successful_modal') {
                analyticsCustomEvent(eventMetricsMap.shipping, {
                    click_text: 'scan another package',
                    element_location: 'scan successful',
                });
            } else {
                analyticsCustomEvent(eventMetricsMap.shipping, {
                    click_text: 'scan to track',
                    element_location: 'frequent shipper activated drawer',
                });
            }
        }
    };

    useEffect(() => {
        if (scannerData) {
            getShipmentTrackingDetails(scannerData);
        }
    }, [scannerData]);

    return (
        <>
            {isLoading && (
                <LoadingOverlay
                    variant="wordmark"
                    theme="transparent-light"
                    open={isLoading}
                />
            )}
            <SwipeableDrawer
                anchor="bottom"
                classes={{
                    root: classes['MuiSwipeableDrawer-root'],
                }}
                open={props.open}
                onClose={props.onClose}
                onOpen={props.onOpen}
                drawerAdornment={
                    <span className={styles.drawerAdornment}>
                        <IconCornerSplashRight height={82} width={114} />
                    </span>
                }
            >
                <div className={styles.content}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Heading
                            type="h1"
                            color="textPurple"
                            size={32}
                            weight="bold"
                            textAlign="textCenter"
                        >
                            {props.points} POINTS
                        </Heading>
                        <Box
                            sx={{
                                width: '80%',
                            }}
                        >
                            <Body
                                size={16}
                                weight="light"
                                textAlign="textCenter"
                            >
                                when you ship or drop off a package in store.
                            </Body>
                        </Box>
                    </Box>
                    {!props.hideBtn ? (
                        <>
                            <Button
                                bgColor="bgBlack"
                                color="textWhite"
                                variant="contained-rounded"
                                className={styles.startShipments}
                                onClick={handleStartShipment}
                            >
                                Start a shipment
                            </Button>
                            <Button
                                variant="icon"
                                color="textLinkBlue"
                                onClick={() => handleScan('drawer')}
                            >
                                <span className={styles.buttonContent}>
                                    <IconScanShipping /> Scan to track
                                </span>
                            </Button>
                        </>
                    ) : (
                        ''
                    )}
                    {props?.messagesData &&
                        props?.messagesData?.drawermessages &&
                        props?.messagesData?.drawermessages?.[0]?.terms
                            ?.length && (
                            <Body size={10} weight="light">
                                {
                                    props?.messagesData?.drawermessages?.[0]
                                        ?.terms
                                }
                            </Body>
                        )}
                </div>
            </SwipeableDrawer>
            {isBarcodeScannerOpen && (
                <Brcd
                    isScannerOpen={isBarcodeScannerOpen}
                    shippingScan
                    onClose={() => {
                        setIsBarcodeScannerOpen(false);
                        handleClose();
                    }}
                />
            )}
            {isScanCompleted && (
                <ScanModal
                    handleSavedLabelShipment={handleSavedLabelShipment}
                    handleScan={handleScan}
                />
            )}
        </>
    );
}

export default ShippingRewardsInfoDialog;
