import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, ButtonBase, makeStyles, Typography } from '@material-ui/core';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import useStoreData from 'src/hooks/useStoreData';
import { Store, StoreService } from 'src/services/types';
import { getStoresTsa } from 'src/lib/api-client/staples-stores';
import { useTabsUIStateContext } from 'src/lib/contexts/tabs-ui-state-context';
import FullWidthCard from '../cards/full-width-card';
import COLORS from '../../lib/colors';
import LoadingButton from '../loading-button';
import { openInNewTab } from '../../lib/utils/nav-utils';
import { Body, Button, Heading } from '../UI';

const useStyles = makeStyles(() => ({
    header: {
        '& img': {
            width: '100%',
        },
    },
    contentContainer: {
        display: 'flex',
        columnGap: '1rem',
        alignItems: 'center',
    },
    reg: {
        fontSize: '12px',
    },
    ctaBtn: {
        height: '2.5rem',
        borderRadius: '1.885rem',
        background: COLORS.homeBlack,
        color: COLORS.whiteSmoke,
        textTransform: 'none',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
        },
        fontWeight: 500,
        margin: '1rem 0',
    },
    actions: {
        display: 'flex',
        // flexDirection: 'column',
        columnGap: '1rem',
        alignItems: 'center',
    },
    action: {
        color: COLORS.brandCyan,
        fontSize: '14px',
        fontWeight: 500,
        '&:hover': {
            textDecoration: 'none',
        },
    },
    tsaPre: {
        padding: '1rem',
        margin: '0px 16px 20px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '1rem',
        backgroundColor: 'white',
        borderRadius: 15,
    },
    nearestStore: {
        color: COLORS.secondaryNeutralBlack,
        fontSize: '16px',
        fontWeight: 500,
        '& span': {
            color: COLORS.brandCyan,
        },
    },
    tsaLogoImage: {
        maxWidth: '30%',
    },
}));

export function TsaPreCheck() {
    const classes = useStyles();
    const { mainStore } = useStoreData();
    const { setStoreLinkInNative } = useTabsUIStateContext();
    const [localStoreHasTsa, setLocalStoreHasTsa] = useState(false);
    const [nearestStoreWithTsa, setNearestStoreWithTsa] = useState<
        Store | any
    >();
    const { analyticsCustomEvent } = useEventsReporter();
    const getStarted = `${process.env.REACT_APP_DOT_COM_URL}/stores/services/tsa`;
    const differentLoc = `${process.env.REACT_APP_DOT_COM_URL}/stores/services/tsa#participating-locations`;

    const fetchAPI = async (zipCode: any) => {
        const additionalStores: any = await getStoresTsa(zipCode);
        const nearestStore: any =
            additionalStores?.find((store: any) =>
                (store?.storeServices as unknown as StoreService[])?.find(
                    (item) =>
                        item.id === 'tsa_precheck' ||
                        item.id === 'tsa_precheck_clear'
                )
            ) || undefined;
        return setNearestStoreWithTsa(nearestStore);
    };

    const CardText = useCallback(() => {
        if (localStoreHasTsa) {
            return (
                <Body size={14} weight="light">
                    Your local Staples® store offers TSA PreCheck® enrollment.
                </Body>
            );
        }
        if (!localStoreHasTsa && nearestStoreWithTsa) {
            return (
                <Body size={14} weight="light">
                    <Body size={14} display="inline">
                        {`${nearestStoreWithTsa.address.city}, ${nearestStoreWithTsa.address.region}`}
                    </Body>{' '}
                    Staples is your closest store that offers TSA PreCheck®
                    enrollment.
                </Body>
            );
        }
        if (!localStoreHasTsa && !nearestStoreWithTsa) {
            return (
                <Body size={14} weight="light">
                    TSA PreCheck® enrollment is now available at participating
                    Staples® stores.
                </Body>
            );
        }
        return null;
    }, [localStoreHasTsa, nearestStoreWithTsa]);

    useEffect(() => {
        if (
            (mainStore?.storeServices as unknown as StoreService[])?.some(
                (item) =>
                    item.id === 'tsa_precheck' ||
                    item.id === 'tsa_precheck_clear'
            )
        ) {
            setNearestStoreWithTsa('');
            setLocalStoreHasTsa(true);
        } else {
            setLocalStoreHasTsa(false);
        }
    }, [mainStore]);

    useEffect(() => {
        fetchAPI(mainStore?.address?.postal_code);
    }, [mainStore?.address?.address_1]);

    return (
        <section id="tsa-precheck" className={classes.tsaPre}>
            <div className={classes.contentContainer}>
                <img
                    aria-hidden="true"
                    src={require('../../assets/bgs/TSA_pre_logo.png')}
                    alt="protection plan"
                    className={classes.tsaLogoImage}
                />
                <div id="tsa-precheck-text">
                    <Heading type="h6" size={18}>
                        Upgrade your travel
                    </Heading>
                    <CardText />
                </div>
            </div>
            <div className={classes.actions}>
                <Button
                    id="tsa-pre-get-started"
                    variant="contained-rounded"
                    bgColor="bgBlack"
                    color="textWhite"
                    onClick={() => {
                        setStoreLinkInNative({
                            src: getStarted,
                            show: true,
                        });
                        analyticsCustomEvent(eventMetricsMap.stores, {
                            click_text: 'apply now',
                            element_location: 'promotion tiles',
                            tile_category: 'tsa carousel',
                        });
                    }}
                >
                    <Body size={14}>Get started</Body>
                </Button>
                <Button
                    id="tsa-pre-different-loc"
                    variant="text"
                    color="textLinkBlue"
                    onClick={() => {
                        setStoreLinkInNative({
                            src: differentLoc,
                            show: true,
                        });
                        analyticsCustomEvent(eventMetricsMap.stores, {
                            click_text: 'find different location',
                            element_location: 'promotion tiles',
                            tile_category: 'tsa carousel',
                        });
                    }}
                >
                    <Body size={14}>More locations</Body>
                </Button>
            </div>
        </section>
    );
}
export default TsaPreCheck;
