import { makeStyles } from '@material-ui/core';
import COLORS from 'src/lib/colors';

const useLoginScreenStyles = makeStyles({
    screen: {
        flexGrow: 1,
        background: COLORS.whiteSmoke,
        '& #login-screen': {
            display: 'flex',
            alignItems: 'baseline',
            paddingLeft: '2.4rem',
            borderRadius: 0,
        },
    },
    headerSubTitle: {
        color: COLORS.whiteSmoke,
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '22px',
        letterspacing: '-0.22499999403953552px',
        textAlign: 'left',
        marginTop: '1rem',
    },
    headerLogo: {
        marginTop: '2.4rem',
        alignSelf: 'left',
    },
    container: {
        minHeight: '19.5rem',
        borderRadius: '15px',
        padding: '24px',
        margin: '-5rem 1.5rem 0px',
    },
    subLink: {
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '18.18px',
        color: '#086DD2',
    },
    subLinkSm: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '18.18px',
        color: '#086DD2',
        cursor: 'pointer',
        marginTop: '16px',
    },
    subLinkWrap: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '18.18px',
        marginTop: '16px',
    },
    title: {
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '22px',
    },
    textContainer: {
        display: 'flex',
        gap: '10px',
        flexDirection: 'column',
        alignItems: 'self-start',
    },
    textFieldLabel: {
        '& label': {
            textAlign: 'center',
            bottom: '2rem',
        },
        '& label.Mui-focused': {
            width: '150%',
            textAlign: 'left',
        },
    },
    textFieldLabelError: {
        '& label': {
            bottom: '3.4rem',
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '10px',
    },
    textImageContainer: {
        display: 'flex',
        position: 'relative',
    },
    eyeImageAlign: {
        position: 'absolute',
        right: 0,
        padding: 0,
        top: '22px',
        minWidth: 0,
    },
    btnCta: {
        margin: '20px 0px',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: COLORS.whiteSmoke,
        textAlign: 'center',
        background: COLORS.homeBlack,
        height: '2.5rem',
        // maxWidth: '17.5rem',
        borderRadius: '20px',
        '&:hover': {
            backgroundColor: COLORS.homeBlack,
        },
        '&:active': {
            backgroundColor: COLORS.homeBlack,
        },
    },
    hrLine: {
        display: 'flex',
        position: 'relative',
        margin: '35px 0px',
        '& hr': {
            border: 0,
            borderTop: '1px solid #000000',
            width: '100%',
            opacity: 0.1,
        },
    },
    logoOr: {
        position: 'absolute',
        top: '-8px',
        margin: '0 auto',
        border: '1px solid #E6E6E6',
        borderRadius: '50%',
        borderHeight: '30px',
        display: 'flex',
        alignItems: 'center',
        height: '30px',
        width: '30px',
        justifyContent: 'center',
        left: '0',
        right: '0',
        color: '#585858',
        background: '#ffffff',
    },
    footerContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '2rem 0rem',
    },
    emailContainer: {
        position: 'relative',
        marginBottom: '20px',
    },
    emailAutoComplete: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#ffffff',
        zIndex: 99,
        listStyleType: 'none',
        padding: 0,
        margin: 0,
    },
    emailItem: {
        padding: '4px 10px',
        justifyContent: 'flex-start',
        '&: hover': {
            background: '#ffffff',
        },
    },
    hyperLinkText: {
        cursor: 'pointer',
        textDecoration: 'underline',
        color: '#000000',
    },
    rubyText: {
        display: 'ruby',
    },
    helperText: {
        color: '#585858',
        fontSize: '14px',
        background: '#fff',
        borderRadius: '15px 15px 0 0',
        textAlign: 'center',
        fontWeight: 'bold',
        marginTop: '15px',
        padding: '15px 0 15px 0',
        borderBottom: '1px solid #d9d9d6',
    },
    emailLink: {
        width: '100%',
        color: COLORS.brandCyan,
        '&:hover': {
            textDecoration: 'none',
        },
    },
    openEmailTextAlign: {
        color: '#000000',
        textDecoration: 'underline',
        display: 'inline-block',
    },
});

export default useLoginScreenStyles;
