/* eslint-disable max-len */
import { Box, ButtonBase, makeStyles, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
import QRCode from 'react-qr-code';
import CarouselWithNextPre from 'src/components/Carousel/CarouselWithNextPre';
import useEventsReporter from 'src/hooks/useEventsReporter';
import eventMetricsMap from 'src/hooks/useEventsReporter/event-types';
import COLORS from 'src/lib/colors';
import { useShipmentTracking } from 'src/lib/contexts/ShipmentTrackingContext';
import { openInNewTab } from 'src/lib/utils/nav-utils';
import { mergeClasses } from 'src/stylesheets/utils';
import FullWidthCard from '../../cards/full-width-card';
import { Heading } from '../../UI';

const useStyles = makeStyles((theme) => ({
    trackingContainer: {
        margin: '0px 10px',
        minHeight: '240px',
        '& .MuiCardContent-root': {
            padding: '15px !important',
        },
    },
    trackingContainerCarousel: {
        border: `1px solid ${COLORS.whiteSmoke}`,
        borderRadius: '15px',
        margin: '0 20px',
    },
    addPadding: {
        paddingBottom: '20px',
    },
    shippedTrackingContainer: {
        margin: '10px',
        marginBottom: '0px',
        '& .MuiCardContent-root': {
            padding: '15px !important',
            paddingBottom: '0px !important',
        },
    },
    deliveredTrackingContainer: {
        margin: '10px',
        marginBottom: '0px',
        '& .MuiCardContent-root': {
            padding: '15px !important',
            paddingBottom: '0px !important',
        },
    },
    labelScannerContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    cardContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '272px',
        alignItems: 'center',
        border: `1px solid ${COLORS.whiteSmoke}`,
        borderRadius: '15px',
        padding: '15px',
    },
    scannedLabelText: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '22px',
        color: '#1E1E1E',
        letterSpacing: '0',
    },
    cardMainText: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '22px',
        color: '#1E1E1E',
        letterSpacing: '0',
        marginBottom: '22px',
    },
    shipmentNumberAlign: {
        fontSize: '24px',
        fontWeight: 400,
        lineHeight: '22px',
        color: '#1E1E1E',
        letterSpacing: '0',
        marginBottom: '5px',
    },
    cardStatus: {
        fontSize: '30px',
        fontWeight: 700,
        lineHeight: '34px',
        letterSpacing: '-1px',
        color: '#1E1E1E',
        marginTop: '15px',
    },
    cardLableText: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '22px',
        letterSpacing: '0',
        color: '#101820',
    },
    barcodeAlign: {
        marginBottom: '14px',
    },
    shippedTrackingCardContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '234px',
        alignItems: 'center',
    },
    shippedTrackingName: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '22px',
        letterSpacing: '0px',
        color: '#1E1E1E',
    },
    shippedTrackingNumber: {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '18.88px',
        letterSpacing: '0px',
        color: '#101820',
        marginBottom: '6px',
    },
    shippedName: {
        fontSize: '22px',
        fontWeight: 700,
        lineHeight: '26px',
        letterSpacing: '-1px',
        color: '#1E1E1E',
        marginTop: '4px',
    },
    shippedTrackingAddress: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '22px',
        letterSpacing: '0px',
        color: '#101820',
    },
    shippedTrackingDate: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
        letterSpacing: '0px',
        color: '#858585',
        marginBottom: '24px',
    },
    shippedTrackingLink: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '0px',
        color: '#086DD2',
    },
    deliveredTrackingName: {
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '26px',
        letterSpacing: '-1px',
        color: '#1E1E1E',
    },
    deliveredTrackingImg: {
        width: '100%',
        padding: '15px 0',
    },
    deliveredPackage: {
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '33.75px',
        letterSpacing: '-1px',
        color: '#1E1E1E',
    },
    deliveredTrackingAddress: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '22px',
        letterSpacing: '0px',
        color: '#1E1E1E',
    },
    deliveredTrackingDate: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
        letterSpacing: '0px',
        color: COLORS.textBlack,
        marginTop: '8px',
    },
    deliveredTrackingLink: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '0px',
        color: '#086DD2',
        marginBottom: '10px',
    },
    deliveredTrackingNumberName: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '22px',
        letterSpacing: '0px',
        color: '#1E1E1E',
    },
    deliveredTrackingNumber: {
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '19px',
        letterSpacing: '0px',
        color: '#086DD2',
        marginLeft: '5px',
    },
    deliveredTrackingConatiner: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    pkgConatiner: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '100%',
        marginBottom: '15px',
    },
    trackingNumber: {
        display: 'flex',
        width: '100%',
    },
    upsDisclaimer: {
        margin: '10px 20px 20px 20px',
        textAlign: 'center',
    },
}));

const ShippingServicesTrackingCards = ({ data, activeTab }: any) => {
    const classes = useStyles();
    const { selectedShipmentService } = useShipmentTracking();
    const { shippmentNumber, date, packagedetails, shimpmentstatus } = data;
    const status = data?.shimpmentstatus?.shipmentstatus;
    const pkgStatus = data?.shimpmentstatus?.shipmenttrackingstatus;
    const trackingNumber = data?.shimpmentstatus?.shipmenttrackingnumbers[0];
    const address = data?.recipientdetails?.street1;
    const [responseData, setResponseData] = useState({});
    const [allDelivered, setAllDelivered] = useState(true);
    const { analyticsCustomEvent } = useEventsReporter();
    // eslint-disable-next-line
    const handleUPSNavigation = (index: any) => {
        analyticsCustomEvent(eventMetricsMap.shipping_info, {
            click_text: 'tracking_number',
            element_location: 'qr page',
        });
        return () =>
            openInNewTab(
                `https://www.ups.com/track?loc=en_US&tracknum=${shimpmentstatus?.shipmenttrackingnumbers[index]}`
            );
    };

    const isShipmentTrackingNumber =
        shimpmentstatus?.shipmenttrackingnumbers?.includes(
            selectedShipmentService?.shimpmentstatus
                ?.shipmenttrackingstatus?.[0]?.inquiryNumber
        );
    const trackingNumberIndex = isShipmentTrackingNumber
        ? shimpmentstatus?.shipmenttrackingnumbers?.findIndex(
              (item: any) =>
                  item ===
                  selectedShipmentService?.shimpmentstatus
                      ?.shipmenttrackingstatus?.[0]?.inquiryNumber
          )
        : 0;

    return (
        <>
            <div>
                {data?.shimpmentstatus?.shipmentnumber &&
                activeTab === 'create' ? (
                    <FullWidthCard className={classes.trackingContainer}>
                        <Box className={classes.cardContainer}>
                            <Typography className={classes.cardMainText}>
                                Your shipment number is
                            </Typography>
                            <Typography className={classes.shipmentNumberAlign}>
                                {data?.shimpmentstatus?.shipmentnumber}
                            </Typography>
                            <QRCode
                                size={92}
                                value={data?.shimpmentstatus?.shipmentnumber}
                            />

                            <Typography className={classes.cardStatus}>
                                Status
                            </Typography>
                            <Typography className={classes.cardLableText}>
                                Label created
                            </Typography>
                        </Box>
                    </FullWidthCard>
                ) : null}

                {data?.shipmenttype.toLowerCase() === 'tracking' &&
                data.shimpmentstatus.shipmenttrackingstatus[0].package[0]
                    .activity.status.statusCode === '003' &&
                activeTab === 'create' ? (
                    <FullWidthCard className={classes.shippedTrackingContainer}>
                        <Box className={classes.labelScannerContainer}>
                            <Heading type="h1" size={30} weight="bold">
                                Label Scanned
                            </Heading>
                            <Typography className={classes.scannedLabelText}>
                                at {data?.recipientdetails?.city},{' '}
                                {data?.recipientdetails?.state}
                            </Typography>
                            <Typography
                                className={classes.deliveredTrackingDate}
                            >
                                {`${moment(data?.shipmentcreateddate).format(
                                    'MMM DD, YYYY'
                                )}`}
                            </Typography>
                            <Box
                                className={classes.trackingNumber}
                                style={{ marginTop: '20px' }}
                            >
                                <Typography
                                    className={
                                        classes.deliveredTrackingNumberName
                                    }
                                >
                                    Your tracking number is
                                </Typography>
                                <ButtonBase
                                    className={classes.deliveredTrackingNumber}
                                    onClick={handleUPSNavigation(0)}
                                >
                                    {
                                        data.shimpmentstatus
                                            ?.shipmenttrackingnumbers[0]
                                    }
                                </ButtonBase>
                            </Box>
                        </Box>
                    </FullWidthCard>
                ) : null}
                {activeTab === 'shipped' || activeTab === 'delivered' ? (
                    <div
                        className={mergeClasses(
                            classes.trackingContainerCarousel,
                            shimpmentstatus?.shipmenttrackingnumbers.length > 1
                                ? classes.addPadding
                                : ''
                        )}
                    >
                        <CarouselWithNextPre
                            isAutoPlay={false}
                            activeColor={COLORS.brandRed}
                            defaultIndex={trackingNumberIndex}
                            showNavigation={
                                shimpmentstatus?.shipmenttrackingnumbers
                                    .length > 1
                            }
                            styles={{
                                containerStyle: {
                                    width: '100%',
                                    height: 'auto',
                                },
                            }}
                            content={shimpmentstatus?.shipmenttrackingnumbers?.map(
                                (_: any, index: any) => (
                                    <>
                                        {activeTab === 'shipped' ? (
                                            <FullWidthCard
                                                className={
                                                    classes.shippedTrackingContainer
                                                }
                                            >
                                                <Box>
                                                    <Typography
                                                        className={
                                                            classes.shippedName
                                                        }
                                                    >
                                                        Package {index + 1}
                                                    </Typography>
                                                    <Box
                                                        className={
                                                            classes.trackingNumber
                                                        }
                                                    >
                                                        <Typography
                                                            className={
                                                                classes.deliveredTrackingNumberName
                                                            }
                                                        >
                                                            Tracking #
                                                        </Typography>
                                                        <ButtonBase
                                                            className={
                                                                classes.deliveredTrackingNumber
                                                            }
                                                            onClick={handleUPSNavigation(
                                                                index
                                                            )}
                                                        >
                                                            {
                                                                shimpmentstatus
                                                                    ?.shipmenttrackingnumbers[
                                                                    index
                                                                ]
                                                            }
                                                        </ButtonBase>
                                                    </Box>
                                                    <Typography
                                                        className={
                                                            classes.deliveredTrackingDate
                                                        }
                                                    >
                                                        {' '}
                                                        Estimated delivary date:
                                                        {`${moment(date).format(
                                                            'MMM DD, YYYY'
                                                        )}`}
                                                    </Typography>
                                                </Box>
                                                {pkgStatus[index]?.package[0]
                                                    ?.activity?.status
                                                    ?.statusCode === '003' ? (
                                                    <Box
                                                        className={
                                                            classes.deliveredTrackingConatiner
                                                        }
                                                    >
                                                        <img
                                                            className={
                                                                classes.deliveredTrackingImg
                                                            }
                                                            src={require('../../../assets/icons/shipped-pkg.svg')}
                                                            alt="shipping-shipped"
                                                        />
                                                    </Box>
                                                ) : null}

                                                {pkgStatus[index]?.package[0]
                                                    ?.activity?.status
                                                    ?.statusCode === '021' ? (
                                                    <Box
                                                        className={
                                                            classes.deliveredTrackingConatiner
                                                        }
                                                    >
                                                        <img
                                                            className={
                                                                classes.deliveredTrackingImg
                                                            }
                                                            src={require('../../../assets/icons/out-for-delivery-pkg.svg')}
                                                            alt="shipping-outFor-delivery"
                                                        />
                                                    </Box>
                                                ) : null}

                                                {pkgStatus[index]?.package[0]
                                                    ?.activity?.status
                                                    ?.statusCode === '011' ? (
                                                    <Box
                                                        className={
                                                            classes.deliveredTrackingConatiner
                                                        }
                                                    >
                                                        <Typography
                                                            className={
                                                                classes.shippedName
                                                            }
                                                        >
                                                            Delivered
                                                        </Typography>
                                                        <img
                                                            className={
                                                                classes.deliveredTrackingImg
                                                            }
                                                            src={require('../../../assets/icons/delivered-pkg.svg')}
                                                            alt="shipping-outFor-delivery"
                                                        />
                                                        <Box
                                                            className={
                                                                classes.pkgConatiner
                                                            }
                                                        >
                                                            {data?.shipmenttype ===
                                                            'APPSHIPMENT' ? (
                                                                <span>
                                                                    <Typography
                                                                        className={
                                                                            classes.deliveredPackage
                                                                        }
                                                                    >
                                                                        {
                                                                            packagedetails[
                                                                                index
                                                                            ]
                                                                                ?.description
                                                                        }
                                                                    </Typography>
                                                                    <Typography
                                                                        className={
                                                                            classes.deliveredTrackingAddress
                                                                        }
                                                                    >
                                                                        {
                                                                            address
                                                                        }
                                                                    </Typography>
                                                                </span>
                                                            ) : (
                                                                <span>
                                                                    <Typography
                                                                        className={
                                                                            classes.deliveredPackage
                                                                        }
                                                                    >
                                                                        Scanned
                                                                        package
                                                                    </Typography>
                                                                    <Typography
                                                                        className={
                                                                            classes.deliveredTrackingAddress
                                                                        }
                                                                    >
                                                                        {data?.shimpmentstatus
                                                                            ? `${shimpmentstatus?.shipmenttrackingstatus[index]?.package[0]?.activity?.location?.address?.city}, 
                                            ${shimpmentstatus?.shipmenttrackingstatus[index]?.package[0]?.activity?.location?.address?.stateProvince}`
                                                                            : null}
                                                                    </Typography>
                                                                </span>
                                                            )}
                                                            <Typography
                                                                className={
                                                                    classes.deliveredTrackingDate
                                                                }
                                                            >
                                                                {`${moment(
                                                                    date
                                                                ).format(
                                                                    'MMM DD, YYYY'
                                                                )}`}
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            className={
                                                                classes.trackingNumber
                                                            }
                                                        >
                                                            <Typography
                                                                className={
                                                                    classes.deliveredTrackingNumberName
                                                                }
                                                            >
                                                                Your tracking
                                                                number is
                                                            </Typography>
                                                            <ButtonBase
                                                                className={
                                                                    classes.deliveredTrackingNumber
                                                                }
                                                                onClick={handleUPSNavigation(
                                                                    index
                                                                )}
                                                            >
                                                                {
                                                                    shimpmentstatus
                                                                        ?.shipmenttrackingnumbers[
                                                                        index
                                                                    ]
                                                                }
                                                            </ButtonBase>
                                                        </Box>
                                                    </Box>
                                                ) : null}

                                                {pkgStatus[index]?.package[0]
                                                    ?.activity?.status
                                                    ?.statusCode !== '003' &&
                                                pkgStatus[index]?.package[0]
                                                    ?.activity?.status
                                                    ?.statusCode !== '021' &&
                                                pkgStatus[index]?.package[0]
                                                    ?.activity?.status
                                                    ?.statusCode !== '011' ? (
                                                    <Box
                                                        className={
                                                            classes.deliveredTrackingConatiner
                                                        }
                                                    >
                                                        <img
                                                            className={
                                                                classes.deliveredTrackingImg
                                                            }
                                                            src={require('../../../assets/icons/in-transit-pkg.svg')}
                                                            alt="shipping-inTransit"
                                                        />
                                                    </Box>
                                                ) : null}
                                            </FullWidthCard>
                                        ) : null}

                                        {activeTab === 'delivered' ? (
                                            <>
                                                {
                                                    <FullWidthCard
                                                        className={
                                                            classes.deliveredTrackingContainer
                                                        }
                                                    >
                                                        <Box
                                                            className={
                                                                classes.deliveredTrackingConatiner
                                                            }
                                                        >
                                                            <Typography
                                                                className={
                                                                    classes.deliveredTrackingName
                                                                }
                                                            >
                                                                Package{' '}
                                                                {index + 1}
                                                            </Typography>
                                                            <Box
                                                                className={
                                                                    classes.trackingNumber
                                                                }
                                                            >
                                                                <Typography
                                                                    className={
                                                                        classes.deliveredTrackingNumberName
                                                                    }
                                                                >
                                                                    Tracking #
                                                                </Typography>
                                                                <ButtonBase
                                                                    className={
                                                                        classes.deliveredTrackingNumber
                                                                    }
                                                                    onClick={handleUPSNavigation(
                                                                        index
                                                                    )}
                                                                >
                                                                    {
                                                                        shimpmentstatus
                                                                            ?.shipmenttrackingnumbers[
                                                                            index
                                                                        ]
                                                                    }
                                                                </ButtonBase>
                                                            </Box>
                                                            <Typography
                                                                className={
                                                                    classes.deliveredTrackingDate
                                                                }
                                                            >
                                                                {' '}
                                                                Estimated
                                                                delivary
                                                                date:&nbsp;
                                                                {data?.shimpmentstatus
                                                                    ? `${moment(
                                                                          shimpmentstatus
                                                                              ?.shipmenttrackingstatus[
                                                                              index
                                                                          ]
                                                                              ?.package[0]
                                                                              ?.activity
                                                                              ?.date
                                                                      ).format(
                                                                          'MMMM DD, YYYY'
                                                                      )}`
                                                                    : null}
                                                            </Typography>
                                                            <img
                                                                className={
                                                                    classes.deliveredTrackingImg
                                                                }
                                                                src={require('../../../assets/icons/delivered-pkg.svg')}
                                                                alt="shipping-delivered"
                                                            />
                                                        </Box>
                                                    </FullWidthCard>
                                                }
                                            </>
                                        ) : null}
                                    </>
                                )
                            )}
                            dots={
                                shimpmentstatus?.shipmenttrackingnumbers
                                    .length > 1
                                    ? shimpmentstatus?.shipmenttrackingnumbers
                                          ?.length
                                    : null
                            }
                        />
                    </div>
                ) : null}
            </div>
            <div className={classes.upsDisclaimer}>
                UPS® tracking information only available for shipments made
                within the last 120 days.
            </div>
        </>
    );
};

export default ShippingServicesTrackingCards;
