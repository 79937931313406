import React, { useContext, useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    ButtonBase,
    Dialog,
    Grid,
    makeStyles,
    Step,
    StepConnector,
    StepLabel,
    Stepper,
    Typography,
    Divider,
} from '@material-ui/core';
import { eventMetricsMap, useEventsReporter, useRouter } from 'src/hooks';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import ROUTES from 'src/lib/routes';
import { ShipmentListDataProps } from 'src/types/store';
import { useShipmentTracking } from 'src/lib/contexts/ShipmentTrackingContext';
import COLORS from '../lib/colors';
import FullWidthCard from './cards/full-width-card';
import Row from './Row';
import { Body, Button } from './UI';
import CarouselWithNextPre from './Carousel/CarouselWithNextPre';

const useStyles = makeStyles((theme) => ({
    homeShipping: {
        marginBottom: '20px',
        marginLeft: '16px',
        marginRight: '16px',
    },
    sectionTitle: {
        fontWeight: 700,
        fontSize: '1.125rem',
        marginBottom: '.85rem',
        marginTop: '1.75rem',
        marginLeft: '1.5rem',
    },

    learnMore: {
        margin: '16px 0',
        display: 'inline-block',
        color: COLORS.brandCyan,
        fontSize: '14px',
        fontWeight: 500,
        '& span': {
            display: 'block',
        },
        '&:hover': {
            textDecoration: 'none',
        },
    },

    cardFooter: {
        padding: '0px 16px 16px 16px',
        borderTop: '1px solid #F2F2F2',
        marginTop: '16px',
    },
    accordianRoot: {
        '&::before': {
            border: 0,
            background: '#ffffff',
        },
    },
    accordionSummary: {
        minHeight: '30px',
        padding: '0',
        '&.Mui-expanded': {
            minHeight: '30px',
        },
        '& p': {
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: '500',
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            // margin: '12px 0',
        },
        '& MuiAccordionSummary-expandIcon': {
            padding: '8px',
        },
    },
    ctaListItem: {
        padding: '0 15px 0 0',
    },
    ctaList: {
        display: 'flex',
        borderBottom: '1px solid #ccc',
        width: '100%',
        '& img': {
            marginRight: '20px',
            width: '22px',
            height: 'auto',
        },
    },
    ctaBox: {
        display: 'flex',
        alignItems: 'center',
    },
    detailsRoot: {
        flexDirection: 'column',
        padding: '0',
    },
    paper: { borderRadius: '15px', margin: '24px', padding: '10px 0 0 0' },
    dialogTitle: {
        fontSize: '18px',
        color: '#000',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '-0.2px',
        margin: '10px 0 10px 0px',
    },
    dialogWrapper: {
        padding: '10px 20px 30px 30px',
        textAlign: 'center',
        color: COLORS.secondaryNeutralBlack,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    dialogMessage: {
        marginBottom: '30px',
        fontSize: '14px',
        fontWeight: 400,
    },
    closeIcon: {
        position: 'absolute',
        top: '-3px',
        right: '10px',
        backgroundColor: 'rgba(0,0,0,0)',
        minWidth: 'initial',
        padding: '0px',
    },
    closeButton: {
        position: 'relative',
        textAlign: 'center',
    },
    anotherPackage: {
        margin: '0px !important',
    },
    successIcon: {
        width: '50px',
        marginBottom: '5px',
    },
    startShipment: {
        height: '40px',
        width: '100%',
        borderRadius: '20px',
        background: '#CC0000',
        marginBottom: '10px',
        '& span': {
            fontSize: '14px',
            fontWeight: 'normal',
            lineHeight: '17px',
            color: '#FFFFFF',
            textAlign: 'center',
        },
        '&:hover': {
            opacity: 0.8,
        },
    },
    linkCta: {
        fontWeight: 500,
        marginTop: '15px',
        color: '#086DD2',
        fontSize: '14px',
        lineHeight: '22px',
        marginBottom: '32px',
    },
    learnMoreAlign: {
        color: '#ffffff',
        textDecoration: 'underline',
        fontSize: '14px',
    },
    holidayDisAlign: {
        background: '#CC0000',
        borderRadius: '15px 15px 0px 0px',
        height: '44px',
        margin: '0px 16px',
        display: 'flex',
        alignItems: 'center',
        color: '#ffffff',
        justifyContent: 'space-between',
        padding: '12px 16px',
        width: '100%',
    },
    stepper: {
        backgroundColor: 'transparent',
        padding: '0px',
    },
    stepIcon: {
        width: 12,
        height: 12,
        borderRadius: '50%',
        border: '2px solid #ddd',
        backgroundColor: '#ddd',
    },
    activeStepIcon: {
        border: 'none',
        backgroundColor: 'red',
    },
    completedStepIcon: {
        backgroundColor: 'red',
    },
    stepConnector: {
        '&.MuiStepConnector-root .MuiStepConnector-active': {
            top: '6px',

            '& .MuiStepConnector-line': {
                borderColor: 're',
                borderTopWidth: 2,
                top: '50%',
                bottom: '50%',
                transform: 'translateY(-50%, -50%)',

                width: '100%',
            },
        },
    },
    deliveredTrackingImg: {
        width: '100%',
        padding: '5px 0px',
    },
    deliveredSingleTrackingImg: {
        width: '100%',
        padding: '5px 20px',
    },
    dateAlign: {
        display: 'inline',
    },
    btnAlign: {
        color: COLORS.linkText,
    },
}));

interface TextWrapperProps {
    text: string;
    icon: string;
    // onClick:()=>void;
}

/* eslint-disable max-len */
export function ShippingServicesTracker({
    shipmentListData,
    updateShippingTab,
}: {
    shipmentListData: ShipmentListDataProps[];
    updateShippingTab: any;
}) {
    const classes = useStyles();
    const { history, query } = useRouter();
    const { analyticsCustomEvent } = useEventsReporter();
    const { setSelectedShipmentService } = useShipmentTracking();

    const handleSavedLabelShipment = (state: string) => {
        if (state) {
            updateShippingTab(state);
        }
        history.push(ROUTES.shippingServicesLabelShipment);
        analyticsCustomEvent(eventMetricsMap.shipping, {
            click_text: 'labels and tracking',
            element_location: 'stores page',
        });
    };

    const handleOnClick = (statusCode: string) => {
        if (statusCode === '011') {
            handleSavedLabelShipment('delivered');
        } else {
            handleSavedLabelShipment('shipped');
        }
    };

    const handleTrack = (shipment: ShipmentListDataProps) => {
        setSelectedShipmentService(shipment);
        handleOnClick(
            shipment?.shimpmentstatus?.shipmenttrackingstatus?.[0]?.package[0]
                ?.activity?.status?.statusCode
        );
    };
    function getTrackingStatusImage(statusCode: string) {
        switch (statusCode) {
            case '003':
                return (
                    <img
                        className={
                            shipmentListData.length === 1
                                ? classes.deliveredSingleTrackingImg
                                : classes.deliveredTrackingImg
                        }
                        src={require('../assets/icons/shipped-pkg.svg')}
                        alt="shipping-shipped"
                    />
                );
            case '021':
                return (
                    <img
                        className={
                            shipmentListData.length === 1
                                ? classes.deliveredSingleTrackingImg
                                : classes.deliveredTrackingImg
                        }
                        src={require('../assets/icons/out-for-delivery-pkg.svg')}
                        alt="shipping-outFor-delivery"
                    />
                );
            case '011':
                return (
                    <img
                        className={
                            shipmentListData.length === 1
                                ? classes.deliveredSingleTrackingImg
                                : classes.deliveredTrackingImg
                        }
                        src={require('../assets/icons/delivered-pkg.svg')}
                        alt="shipping-delivered"
                    />
                );
            default:
                return (
                    <img
                        className={
                            shipmentListData.length === 1
                                ? classes.deliveredSingleTrackingImg
                                : classes.deliveredTrackingImg
                        }
                        src={require('../assets/icons/in-transit-pkg.svg')}
                        alt="shipping-inTransit"
                    />
                );
        }
    }

    return (
        <>
            <FullWidthCard
                noPadding
                id="shipping-container"
                style={{
                    borderRadius: '15px',
                }}
                className={classes.homeShipping}
            >
                <CarouselWithNextPre
                    isAutoPlay={false}
                    activeColor={COLORS.brandRed}
                    showNavigation={shipmentListData.length > 1}
                    styles={{
                        containerStyle: {
                            width: '100%',
                            height: 'auto',
                        },
                    }}
                    content={shipmentListData?.map((_: any, index: any) => (
                        <>
                            <Row
                                direction="column"
                                gap="16px"
                                sx={{
                                    p: '16px 5px 0px',
                                }}
                            >
                                <>
                                    <Body size={18} lineHeight={24}>
                                        Shipping services
                                    </Body>
                                    <Row direction="column">
                                        <>
                                            <Row marginBottom={'6px'}>
                                                <Body
                                                    size={18}
                                                    lineHeight={24}
                                                    weight="bold"
                                                >
                                                    {shipmentListData?.[index]
                                                        ?.shipmenttype ===
                                                    'APPSHIPMENT'
                                                        ? shipmentListData?.[
                                                              index
                                                          ]?.recipientdetails
                                                              ?.fullname
                                                        : `${shipmentListData?.[index]?.recipientdetails?.city} 
                                                          ,
                                                          ${shipmentListData?.[index]?.recipientdetails?.state}`}
                                                </Body>
                                            </Row>
                                            <Row>
                                                <>
                                                    <Body
                                                        size={14}
                                                        lineHeight={18}
                                                        weight="light"
                                                    >
                                                        Tracking #:&nbsp;
                                                    </Body>
                                                    <Button
                                                        fontSize={14}
                                                        variant="text"
                                                        fontWeight="normal"
                                                        onClick={() =>
                                                            handleTrack(
                                                                shipmentListData?.[
                                                                    index
                                                                ]
                                                            )
                                                        }
                                                        className={
                                                            classes.btnAlign
                                                        }
                                                    >
                                                        {
                                                            shipmentListData?.[
                                                                index
                                                            ]?.shimpmentstatus
                                                                ?.shipmenttrackingstatus?.[0]
                                                                ?.inquiryNumber
                                                        }
                                                    </Button>
                                                </>
                                            </Row>
                                            <Row>
                                                <>
                                                    {shipmentListData?.[index]
                                                        ?.shimpmentstatus
                                                        .shipmenttrackingstatus?.[0]
                                                        ?.package?.[0]?.activity
                                                        ?.status?.statusCode ===
                                                    '011' ? (
                                                        <Body
                                                            size={14}
                                                            lineHeight={18}
                                                            weight="light"
                                                            color="textRed"
                                                        >
                                                            Delivered&nbsp;
                                                            <Body
                                                                size={14}
                                                                lineHeight={18}
                                                                weight="light"
                                                                color="textBlack"
                                                                className={
                                                                    classes.dateAlign
                                                                }
                                                            >
                                                                {' '}
                                                                {moment(
                                                                    shipmentListData?.[
                                                                        index
                                                                    ]
                                                                        ?.shimpmentstatus
                                                                        .shipmenttrackingstatus[0]
                                                                        .package[0]
                                                                        ?.deliveryDate[0]
                                                                        ?.date
                                                                ).format(
                                                                    'MMMM DD'
                                                                )}
                                                            </Body>
                                                        </Body>
                                                    ) : (
                                                        <Body
                                                            size={14}
                                                            lineHeight={18}
                                                            weight="light"
                                                        >
                                                            Estimated
                                                            Delivery:&nbsp;
                                                            {moment(
                                                                shipmentListData?.[
                                                                    index
                                                                ]
                                                                    ?.shimpmentstatus
                                                                    .shipmenttrackingstatus[0]
                                                                    ?.package[0]
                                                                    ?.deliveryDate[0]
                                                                    ?.date
                                                            ).format('MMMM DD')}
                                                        </Body>
                                                    )}
                                                </>
                                            </Row>
                                        </>
                                    </Row>
                                    <Box>
                                        {getTrackingStatusImage(
                                            shipmentListData?.[index]
                                                ?.shimpmentstatus
                                                .shipmenttrackingstatus[0]
                                                ?.package?.[0]?.activity?.status
                                                ?.statusCode
                                        )}
                                    </Box>
                                </>
                            </Row>
                        </>
                    ))}
                    dots={
                        shipmentListData?.length > 1
                            ? shipmentListData?.length
                            : 1
                    }
                />

                {/* <div className={classes.cardFooter}>
                    <Accordion
                        elevation={0}
                        square
                        className={classes.accordianRoot}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className={classes.accordionSummary}
                        >
                            <Body size={14} lineHeight={18}>
                                More actions
                            </Body>
                        </AccordionSummary>
                        <AccordionDetails className={classes.detailsRoot}>
                            <Row direction="column" gap="16px">
                                <>
                                    <TextWrapper
                                        text="Start a shipment"
                                        icon={StartShipmentIcon}
                                    />
                                    <Divider />
                                    <TextWrapper
                                        text="Get a quote"
                                        icon={QuoteIcon}
                                    />
                                    <Divider />
                                    <TextWrapper
                                        text="Labels and tracking"
                                        icon={LabelTrackIcon}
                                    />
                                    <Divider />
                                    <BodyText text="Go to full services" />
                                </>
                            </Row>
                        </AccordionDetails>
                    </Accordion>
                </div> */}
            </FullWidthCard>
        </>
    );
}
export default ShippingServicesTracker;
