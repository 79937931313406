
import { makeStyles } from "@material-ui/core";
import COLORS from "src/lib/colors";

 const useStyles = makeStyles(() => ({
    linkCta: {
        fontWeight: 700,
        marginTop: '16px',
        color: '#086DD2',
        fontSize: '14px',
        lineHeight: '22px',
        marginBottom: '32px',
    },
    startShipment: {
        height: '40px',
        width: '100%',
        borderRadius: '20px',
        background: '#CC0000',
        // marginBottom: '10px',
        '& span': {
            fontSize: '17px',
            fontWeight: 500,
            lineHeight: 'normal',
            color: '#FFFFFF',
            textAlign: 'center',
        },
        '&:hover': {
            opacity: 0.8,
        },
    },
    paper: { borderRadius: '15px', margin: '24px', padding: '10px 0 0 0', position:'relative' },
    dialogTitle: {
        fontSize: '18px',
        color: '#000',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '-0.2px',
        margin: '10px 0 10px 0px',
    },
    dialogWrapper: {
        padding: '20px 20px 20px 30px',
        textAlign: 'center',
        color: COLORS.secondaryNeutralBlack,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    dialogMessage: {
        marginBottom: '30px',
        fontSize: '14px',
        fontWeight: 400,
    },
    closeIcon: {
        position: 'absolute',
        top: '-3px',
        right: '10px',
        backgroundColor: 'rgba(0,0,0,0)',
        minWidth: 'initial',
        padding: '0px',
    },
    closeButton: {
        position: 'relative',
        textAlign: 'center',
    },
    anotherPackage: {
        margin: '0px',
    },
    bgWhite:{
       backgroundColor:'white', 
    },
    successIcon: {
        width: '50px',
        marginBottom: '5px',
    },
    'MuiSwipeableDrawer-root': {
        position: 'relative',
    },
    linkAlign: {
        color: COLORS.linkText,
        marginTop: '12px',
    },
}));

export default useStyles