/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/prefer-default-export */
import Log from 'src/lib/logging';
import axiosInstance from 'src/lib/api-client/custom-axios';
import {
    ListQueryResult,
    ListResult,
    UpdateItemType,
    FullListResult,
} from 'src/services/types';
import { buildPatchOperations } from 'src/lib/utils/patch-utils';
import {
    listBaseURL,
    listByIdURL,
    listCreateItemURL,
    listGetAllFeaturedURL,
    listGetAllItemsById,
    listGetBySchoolURL,
    listGetSchoolsByZipURL,
    listGetSharedURL,
    listModifyItemURL,
    listSaveFeaturedURL,
    listSaveSharedURL,
    listSharedBaseURL,
    rewardsFeatureEnabledCheckURL,
} from 'src/services/urls';

export async function createList({
    name,
    description,
    // the lowest value of the available lists we have (needed for proper reordering)
    lowestValue = 0,
}: {
    name?: string | null;
    description?: string | null;
    lowestValue?: number;
}): Promise<ListResult | null> {
    const creationResult = await axiosInstance.post(
        `${listBaseURL}`,
        // lower values are displayed higher in the list, decrement from lowest known value
        { name, description, sequence: lowestValue - 1 }
    );
    if (creationResult.data && creationResult.data.id) {
        return creationResult.data as ListResult;
    }

    Log.axios(
        // eslint-disable-next-line max-len
        `received an unexpected response when creating list ${listBaseURL}`
    );
    Log.axios(creationResult);
    return null;
}

/** Gets lists for current user */
export async function getUserLists(): Promise<ListQueryResult | null> {
    const listResult = await axiosInstance.get(`${listBaseURL}`, {});
    if (listResult.data && Array.isArray(listResult.data)) {
        try {
            const filteredResult = listResult.data.filter(
                (item) => !item.sharedBy
            );
            const sorted = (filteredResult as ListQueryResult).sort(
                (a, b) => a.sequence - b.sequence
            );
            // by default, we want to sort these by sequence value
            return sorted;
        } catch (e) {
            Log.axios(e);
        }
    }

    Log.axios(
        // eslint-disable-next-line max-len
        `received an unexpected response when getting list results ${listBaseURL}`
    );
    Log.axios(listResult);
    return null;
}

export async function updateListDetails({
    id,
    name,
    description,
}: {
    id: any;
    name: string;
    description: string;
}): Promise<FullListResult | null> {
    const patchOperations = buildPatchOperations([
        { field: 'productListName', value: name },
        { field: 'description', value: description },
    ]);
    if (patchOperations.length === 0) {
        Log.axios('no new changes detected for list: skipping patch');
        return null;
    }
    const creationResult = await axiosInstance.patch(
        `${listByIdURL}`.replace('%listId%', `${id}`),
        patchOperations
    );
    if (creationResult.data && creationResult.data.id) {
        return creationResult.data as FullListResult;
    }
    Log.axios(
        // eslint-disable-next-line max-len
        `received an unexpected response when retrieving list details ${listByIdURL}`
    );
    Log.axios(creationResult);
    return null;
}

export async function updateListSequence({
    id,
    sequence,
}: {
    id: any;
    sequence: number;
}) {
    const patchOperations = buildPatchOperations([
        { field: 'sequence', value: sequence },
    ]);
    const updateResult = await axiosInstance.patch(
        `${listByIdURL}`.replace('%listId%', `${id}`),
        patchOperations
    );
    if (updateResult?.data?.list && updateResult.data?.list?.id) {
        return updateResult.data.list as FullListResult;
    }
    Log.axios(
        // eslint-disable-next-line max-len
        `received an unexpected response when updating list sequence ${listByIdURL}`
    );
    Log.axios(updateResult);
    return null;
}

export async function getListDetails({
    listId,
    type,
}: {
    listId: string;
    type?: 'shared';
}): Promise<FullListResult | null> {
    const listDetailsResult = await axiosInstance.get(
        `${type === 'shared' ? listGetSharedURL : listByIdURL}`.replace(
            '%listId%',
            `${listId}`
        )
    );

    // we do not have to make a separate data fetching call for all our products
    // since we're already doing that on the middleware side
    const { data } = listDetailsResult;
    if (data) {
        if (type === 'shared') {
            return {
                id: listId,
                productListName: data.productListDetails.productListName,
                description: data.productListDetails.description,
                items: data.productListDetails.items,
                sharedBy: data.sharedBy,
                sharedByRole: data.sharedByRole,
                sharedImageUrl: data.sharedImageUrl,
            } as unknown as FullListResult;
        }
        return listDetailsResult.data as FullListResult;
    }

    Log.axios(
        // eslint-disable-next-line max-len
        `received an unexpected response when retrieving list details ${listBaseURL}`
    );
    Log.axios(listDetailsResult);
    return null;
}

/** returned in the case where we do not get a 204 or 404 result when deleting */
type DeleteError = { error: string };
export async function deleteList({
    listId,
}: {
    listId: string;
}): Promise<null | DeleteError> {
    const deleteListResult = await axiosInstance.delete(
        `${listByIdURL}`.replace('%listId%', `${listId}`)
    );
    if (deleteListResult.status !== 204 && deleteListResult.status !== 404) {
        Log.axios(
            // eslint-disable-next-line max-len
            `received an unexpected response when deleting an item ${listBaseURL}`
        );
        Log.axios(deleteListResult);
        return { error: 'unable to delete' };
    }
    return null;
}

export async function addItem({
    listIds,
    itemName,
    itemNumber,
    quantity,
    storeId,
    itemImageUrl,
    productRating,
    reviewsCount,
}: {
    listIds: number[];
    itemName: string;
    itemNumber: string;
    quantity: number;
    storeId: number;
    itemImageUrl: string;
    productRating: number;
    reviewsCount: number;
}): Promise<{ success: true } | null> {
    const creationResult = await axiosInstance.post(`${listCreateItemURL}`, {
        Name: itemName,
        ItemNumber: itemNumber,
        ListIds: listIds,
        Quantity: quantity,
        StoreId: storeId,
        ItemImageUrl: itemImageUrl,
        ProductRating: productRating,
        ReviewsCount: reviewsCount,
    });
    if (creationResult && creationResult.status === 204) {
        return { success: true };
    }

    Log.axios(
        // eslint-disable-next-line max-len
        `received an unexpected response when adding an item ${listBaseURL}`
    );
    Log.axios(creationResult);
    return null;
}

export async function deleteItem({ itemId }: { itemId: string }) {
    const deleteItemResult = await axiosInstance.delete(
        `${listModifyItemURL}`.replace('%itemId%', `${itemId}`)
    );
    if (deleteItemResult.status !== 204 && deleteItemResult.status !== 404) {
        Log.axios(
            // eslint-disable-next-line max-len
            `received an unexpected response when deleting an item ${listBaseURL}`
        );
        Log.axios(deleteItemResult);
        return { error: 'unable to delete' };
    }
    return null;
}

type ItemField = {
    value: string | number | null | undefined;
    key: UpdateItemType;
};

export async function updateItem({
    itemId,
    fields,
}: {
    itemId: string | null | undefined;
    fields: ItemField[];
}): Promise<null> {
    if (!itemId) {
        Log.axios('updateItem not called: no itemId passed');
        return null;
    }
    await axiosInstance.patch(
        `${listModifyItemURL}`.replace('%itemId%', `${itemId}`),
        fields.map((f) => ({
            op: 'Replace',
            path: `/${f.key}`,
            value: f.value,
        }))
    );
    // no response data ever returned for patch calls
    return null;
}

export async function getSchoolsByZipCode({
    zipCode,
}: {
    zipCode: string | null;
}) {
    const schoolsResult = await axiosInstance.get(
        `${listGetSchoolsByZipURL}/${zipCode}`
    );

    if (schoolsResult.data) {
        try {
            // sort the schools as per lists availability
            const sorted = schoolsResult.data.schools.sort(
                (a: any, b: any) => b.listCount - a.listCount
            );
            return sorted;
        } catch (e) {
            Log.axios(e);
        }
    }
    if (schoolsResult.data) {
        try {
            // sort the schools as per lists availability
            const sorted = schoolsResult.data.schools.sort(
                (a: any, b: any) => b.listCount - a.listCount
            );
            return sorted;
        } catch (e) {
            Log.axios(e);
        }
    }

    Log.axios(
        // eslint-disable-next-line max-len
        `received an unexpected response when fetching schools by zip code ${listBaseURL}`
    );
    Log.axios(schoolsResult);
    return null;
}

export async function getListsBySchool(schoolId?: string) {
    const listsResult = await axiosInstance.get(
        `${listGetBySchoolURL?.replace('%schoolId%', schoolId as string)}`
    );
    if (listsResult.data) {
        return listsResult.data;
    }

    Log.axios(
        // eslint-disable-next-line max-len
        `received an unexpected response when fetching lists by school id ${listGetBySchoolURL}`
    );
    Log.axios(listsResult);
    return null;
}

export async function getItemsByListId(listId: any) {
    const itemsResult = await axiosInstance.get(
        `${listGetAllItemsById?.replace('%listId%', listId as string)}`
    );
    if (itemsResult.data) {
        return itemsResult.data;
    }

    Log.axios(
        // eslint-disable-next-line max-len
        `received an unexpected response when fetching items by list id ${listGetBySchoolURL}`
    );
    Log.axios(itemsResult);
    return null;
}

export async function getFeaturedLists(): Promise<ListQueryResult | null> {
    if (listGetAllFeaturedURL) {
        const response = await axiosInstance.get(listGetAllFeaturedURL);
        if (response.data) {
            return response.data.map((list: any) => ({
                id: list.productListId,
                sequence: list.sequence,
                productListName: list.productListName,
                description: list.productListDescription,
                imageUrl: list.imageUrl,
                type: 'featured',
                featuredListId: list.featuredListId,
                isAdsDisplay: list.isAdsDisplay,
            })) as ListQueryResult;
        }
    }
    return null;
}

export async function getSharedLists(): Promise<ListQueryResult | null> {
    if (listSharedBaseURL) {
        const response = await axiosInstance.get(listSharedBaseURL);
        if (response.data) {
            return response.data.map((list: any) => ({
                id: list.productListId,
                productListName: list.productListName,
                description: list.description,
                sequence: list.sequence,
                imageUrl: list.sharedImageUrl,
                sharedBy: list.sharedBy,
                sharedByRole: list.sharedByRole,
                type: 'saved-shared-list',
            })) as ListQueryResult;
        }
    }
    return null;
}

export async function saveFeaturedList(
    featuredListId?: number | string,
    newListName?: string,
    newListDescription?: string
) {
    if (listSaveFeaturedURL && featuredListId && newListName) {
        await axiosInstance.post(`${listSaveFeaturedURL}/${featuredListId}`, {
            newListName,
            newListDescription,
        });
    }
}
export async function saveSharedList(
    sharedListId?: number | string,
    newListName = '',
    newListDescription = ''
) {
    if (listSaveSharedURL && sharedListId) {
        try {
            const res = await axiosInstance.post(
                listSaveSharedURL.replace('%listId%', `${sharedListId}`)
            );
            if (res.data.productListId && (newListName || newListDescription)) {
                updateListDetails({
                    id: res.data.productListId,
                    name: newListName,
                    description: newListDescription,
                });
            }
        } catch (error) {
            console.error(error);
        }
    }
}

export async function isListEnabled(
    functionalityname: string
): Promise<{ isenabled: boolean } | null> {
    if (functionalityname) {
        const listEnabled = await axiosInstance.get(
            `${rewardsFeatureEnabledCheckURL?.replace(
                '%functionalityname%',
                functionalityname as string
            )}`
        );

        if (listEnabled.data) {
            return listEnabled.data;
        }

        Log.axios(
            `received an unexpected response when checking is list enabled ${rewardsFeatureEnabledCheckURL}`
        );
        Log.axios(listEnabled);
    }
    return null;
}
