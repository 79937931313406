import React from 'react';
import {
    SwipeableDrawer as MUISwipeableDrawer,
    SwipeableDrawerProps as MUISwipeableDrawerProps,
} from '@material-ui/core';
import styles from './styles.module.scss';

interface SwipeableDrawerProps extends MUISwipeableDrawerProps {
    drawerAdornment?: React.ReactNode;
}

function SwipeableDrawer(props: SwipeableDrawerProps) {
    return (
        <MUISwipeableDrawer {...props} disableDiscovery disableSwipeToOpen>
            {props.drawerAdornment}
            <div
                className={styles.indicator}
                aria-label="Close drawer"
                role="button"
            >
                &nbsp;
            </div>
            <div className={styles.container}>
                <div className={styles.contentContainer}>{props.children}</div>
            </div>
        </MUISwipeableDrawer>
    );
}

export default SwipeableDrawer;
